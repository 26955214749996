var QGdstk = null
var AutoToolLibs = require('./libs')

;(function () {
  function AutoToolConnectLine(library, cell) {
    QGdstk = window.QGdstk
    this.library = library
    this.cell = cell
    this.libs = new AutoToolLibs()
    this.conf = [
      {
        layer: 1,
        datatype: 0,
        distance: 3,
      },
      {
        layer: 2,
        datatype: 0,
        distance: 3,
      },
      {
        layer: 6,
        datatype: 0,
        distance: 3,
      },
      {
        layer: 47,
        datatype: 0,
        distance: 1,
      },
    ]
  }

  AutoToolConnectLine.prototype.setConf = function (conf) {
    this.conf = conf
  }

  // 运行
  AutoToolConnectLine.prototype.run = function (data) {
    this.conf = data

    let remove_paths = []
    let add_paths = []
    for (let i = 0; i < this.conf.length; i++) {
      let paths = this.getPathsByLayer(this.cell.flexpaths, this.conf[i].layer)
      let need_connect_paths = []
      paths.forEach(element => {
        if (element.width[0] === 0) {
          need_connect_paths.push(element)
        }
      })

      let rr = this.connect(need_connect_paths, this.conf[i].distance)

      remove_paths.push.apply(remove_paths, rr.remove_paths)
      add_paths.push.apply(add_paths, rr.add_paths)
    }

    return {
      remove_paths: remove_paths,
      add_paths: add_paths,
    }
  }

  AutoToolConnectLine.prototype.connect = function (paths, distance) {
    function getClosetPos(points, distance) {
      let KD = new QGdstk.KDTree(points)
      let indices = KD.neighborhood_indices(points, distance)

      let re_idxs = {}
      for (let i = 0, len = indices.length; i < len; i++) {
        // 去除自身检查结果
        let idx = indices[i]
        if (indices[i].length < 2) continue

        // 其他两两组合
        let t = []
        for (let j = 0; j < idx.length; j++) {
          t.push(idx[j])
        }
        // 去重
        if (re_idxs[[t[0], t[1]]] || re_idxs[[t[1], t[0]]]) {
          continue
        }
        re_idxs[t] = [t[0], t[1]]
      }
      return re_idxs
    }
    function connectLayerPaths(paths, path_length, removed_idxs, remove_paths, add_paths) {
      // 获取对应信息
      // let paths_layer = []
      let paths_idxs = []
      let points = []
      let points_idxs = []
      for (let i = 0, len = paths.length; i < len; i++) {
        // 过滤掉已经移除的线
        if (removed_idxs[i]) {
          continue
        }
        let item = paths[i]
        // let ps = item.spine()
        let ps = item.points
        //取线的端点
        if (ps.length > 1) {
          points.push(ps[0])
          points_idxs.push(0)
          paths_idxs.push(i)

          points.push(ps[ps.length - 1])
          points_idxs.push(ps.length - 1)
          paths_idxs.push(i)
        }
      }

      // 查找邻近点
      let nb_idxs = getClosetPos(points, distance)

      // 已经处理过的线不再处理
      let repeat_path = {}
      let tmp_add_paths = []
      for (key in nb_idxs) {
        let idx = nb_idxs[key]
        // 获取中心点
        let path_idx_1 = paths_idxs[idx[0]]
        let path_idx_2 = paths_idxs[idx[1]]

        // 同一条线两个端点之间距离过近时不需要连接，会造成不断循环连接，导致异常
        if (path_idx_1 == path_idx_2) {
          continue
        }
        // 过滤已经处理过的线
        if (repeat_path[path_idx_1] || repeat_path[path_idx_2]) {
          continue
        }
        repeat_path[path_idx_1] = 1
        repeat_path[path_idx_2] = 1
        // let p_1 = points[idx[0]]
        // let p_2 = points[idx[1]]
        // 获取线上点对应的idx, 用于判断连接顺序
        let point_idx_1 = points_idxs[idx[0]]
        let point_idx_2 = points_idxs[idx[1]]
        // 获取两条需要连接的线
        let path_1 = paths[path_idx_1]
        let path_2 = paths[path_idx_2]

        removed_idxs[path_idx_1] = 1
        removed_idxs[path_idx_2] = 1
        // 超出原始paths范围的不添加到remove_paths中
        if (path_idx_1 < path_length) {
          remove_paths.push(path_1)
        }
        if (path_idx_2 < path_length) {
          remove_paths.push(path_2)
        }
        // 根据两根线的前后顺序进行拼接
        // let t1 = path_1.spine()
        // let t2 = path_2.spine()
        let t1 = path_1.points
        let t2 = path_2.points

        let new_points = []

        // 起点-->起点
        if (point_idx_1 == 0 && point_idx_2 == 0) {
          new_points = new_points.concat(t1.reverse(), t2.splice(1, t2.length))
        }
        // 起点-->终点
        if (point_idx_1 == 0 && point_idx_2 > 0) {
          let t2_rev = t2.reverse()
          new_points = new_points.concat(t1.reverse(), t2_rev.splice(1, t2_rev.length))
        }
        // 终点-->起点
        if (point_idx_1 > 0 && point_idx_2 == 0) {
          new_points = new_points.concat(t1, t2.splice(1, t2.length))
        }
        // 终点-->终点
        if (point_idx_1 > 0 && point_idx_2 > 0) {
          let t2_rev = t2.reverse()
          new_points = new_points.concat(t1, t2_rev.splice(1, t2_rev.length))
        }
        // 复制path并设置新的点位进去
        let path_1_copy = path_1.copy()
        path_1_copy.points = new_points
        tmp_add_paths.push(path_1_copy)
        add_paths.push(path_1_copy)
      }
      return tmp_add_paths
    }

    if (!paths) {
      return {
        add_paths: [],
        remove_paths: [],
      }
    }

    let add_paths = []
    let remove_paths = []

    let remove_idxs = {}
    let add_idxs = {}
    let path_length = paths.length
    let path_end_idx = paths.length
    let cnt_break = 0
    while (1) {
      cnt_break += 1
      if (cnt_break > 10) {
        break
      }

      let res = connectLayerPaths(paths, path_length, remove_idxs, remove_paths, add_paths)

      for (let i = 0; i < remove_paths.length; i++) {}

      if (res.length > 0) {
        paths.push.apply(paths, res)
        for (let i = 0; i < res.length; i++) {
          add_idxs[path_end_idx + i] = 1
        }
        path_end_idx = paths.length
      } else {
        break
      }
    }
    // 取出add idxs中与remove idxs中重复的, 剩余的为真实新增的线
    let new_add_paths = []
    for (k in add_idxs) {
      if (remove_idxs[k]) {
        add_idxs[k] = 0
      }
      if (add_idxs[k] == 1) {
        new_add_paths.push(add_paths[k - path_length])
      }
    }
    return {
      add_paths: new_add_paths,
      remove_paths: remove_paths,
    }
  }

  AutoToolConnectLine.prototype.getClosetPos = function (points, distance) {
    let KD = new QGdstk.KDTree(points)
    let indices = KD.neighborhood_indices(points, distance)

    let re_idxs = {}
    for (let i = 0, len = indices.length; i < len; i++) {
      // 去除自身检查结果
      let idx = indices[i]
      if (indices[i].length < 2) continue

      // 其他两两组合
      let t = []
      for (let j = 0; j < idx.length; j++) {
        t.push(idx[j])
      }
      // 去重
      if (re_idxs[[t[0], t[1]]] || re_idxs[[t[1], t[0]]]) {
        continue
      }
      re_idxs[t] = [t[0], t[1]]
    }
    return re_idxs
  }

  // 根据图层分离骨架线
  AutoToolConnectLine.prototype.getPathsByLayer = function (paths, layer) {
    paths_layer = []
    for (let i = 0, len = paths.length; i < len; i++) {
      let item = paths[i]
      if (item.layers[0] == layer) {
        paths_layer.push(item)
      }
    }
    return paths_layer
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolConnectLine
  } else {
    window.AutoToolConnectLine = AutoToolConnectLine
  }
})()
