import {
  line_shader,
  fill_shader,
  generateLineMat,
  generateFillMat,
  generateLineInsMat,
  generateFillInsMat,
  generateFontMat,
  generateFontInsMat,
  generateKeyPointMat,
  generateAnchorMat,
  generateAxisMat,
  generateMouseCrossMat,
  generateRenderArea3DMat,
} from '../shader/cell_shader'
import { generateFontGeometry, generateFontGeometry2, generateLabelMesh } from './font-util.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
export class CellDisplayConfig {
  constructor() {
    this.mouseCrossMat = new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    this.axisMaterial = generateAxisMat() //坐标背景网格
    this.mouseCrossMat = generateMouseCrossMat() //鼠标十字
    this.keyPointMatTest = generateKeyPointMat(new THREE.Vector4(1, 0, 0, 1)) //关键点测试
    this.anchorMaterial = generateAnchorMat() //标签文字锚点
    this.useMergeBufferGeometries = true //使用合并
    this.showFill = true //生成填充
    //抗锯齿
    this.antialias = {
      useAntialias: true,
      useSmaaPass: false,
      useFxaaPass: true,
      smaaPass: null,
      fxaaPass: null,
    }
    //显示设置
    this.show = {
      axisGrid: true,
      mouseCrossX: true,
      mouseCrossY: true,
    }
    //渲染设置
    this.renderConfig = {
      labelAbsoulate: false,
    }
    //颜色设置
    this.colors = {
      preCheckedCol: '#ffffff',
      checkedCol: '#ffe500',
      alignCol: '#ff0000',
      axisBg: '#191919',
      axisGrid: '#B2B2B2',
      mouseCross: '#00ff00',
      selectBox: '#ffff00',
      adaptBox: '#ff0000',
      cutBox2D: '#ed6900',
      cutBox3D: '#0090ff',
      renderArea3D: '#00ed9c',
      angleText: '#ffffff',
      rulerLine: '#ff0000',
      rulerText: '#ff0000',
      colorPreAddRef: '#ffffff',
      editRef: '#ffffff',
      drcColor: '#ffe500',
    }
    //工具材料
    this.toolsMat = null
    this.updateToolsMat()
    this.count = 0
    let that = this
    this.breathTimer = setInterval(() => {
      that.breath()
      that.count += 0.05
    }, 0.1) //每60秒一次
  }

  //更新颜色
  updateToolsMat() {
    let color_pre_check = new THREE.Color(this.colors.preCheckedCol)
    let color_checked = new THREE.Color(this.colors.checkedCol)
    let col_angle = new THREE.Color(this.colors.angleText)
    let col_align = new THREE.Color(this.colors.alignCol)
    let color_pre_add_ref = new THREE.Color(this.colors.colorPreAddRef)
    // let col_ruler_text = new THREE.Color(this.colors.rulerText)
    if (!this.toolsMat) {
      this.toolsMat = {
        selectBox: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.selectBox) }),
        adaptBox: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.adaptBox) }),
        cutBox2D: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.cutBox2D) }),
        cutBox3D: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.cutBox3D) }),
        cutBox3D_pre_check: new THREE.LineBasicMaterial({ color: color_pre_check }),
        cutBox3D_checked: new THREE.LineBasicMaterial({ color: color_checked }),
        cutBox3D_pre_cut: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.cutBox3D), opacity: 0.5 }),

        renderArea3D: generateRenderArea3DMat(new THREE.Color(this.colors.renderArea3D), 1800, 900),
        renderArea3D_pre_check: generateRenderArea3DMat(new THREE.Color(this.colors.preCheckedCol), 1800, 900),
        renderArea3D_checked: generateRenderArea3DMat(new THREE.Color(this.colors.checkedCol), 1800, 900),
        renderArea3D_pre_cut: generateRenderArea3DMat(new THREE.Color(this.colors.renderArea3D), 1800, 900, 0.5),
        angleText: generateFontMat(new THREE.Vector4(col_angle.r, col_angle.g, col_angle.b, 1), new THREE.Vector3(), new THREE.Vector3()),
        rulerLine: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.rulerLine) }),
        rulerLine_pre_check: new THREE.LineBasicMaterial({ color: color_pre_check }),
        rulerLine_checked: new THREE.LineBasicMaterial({ color: color_checked }),
        rulerLine_pre_cut: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.rulerLine), opacity: 0.5 }),
        rulerText: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.rulerText) }), //generateFontMat(new THREE.Vector3(col_ruler_text.r, col_ruler_text.g, col_ruler_text.b), new THREE.Vector3(), new THREE.Vector3()),
        rulerText_pre_check: new THREE.LineBasicMaterial({ color: color_pre_check }), //generateFontMat(new THREE.Vector3(color_pre_check.r, color_pre_check.g, color_pre_check.b), new THREE.Vector3(), new THREE.Vector3()),
        rulerText_checked: new THREE.LineBasicMaterial({ color: color_checked }), //generateFontMat(new THREE.Vector3(color_checked.r, color_checked.g, color_checked.b), new THREE.Vector3(), new THREE.Vector3()),
        rulerText_pre_cut: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.rulerText), opacity: 0.5 }),
        preCheckPoint: new THREE.PointsMaterial({ color: color_pre_check, size: 10 }),
        preCheckLine: new THREE.LineBasicMaterial({ color: color_pre_check }),
        checkedPoints: new THREE.PointsMaterial({ color: color_checked, size: 10 }),
        checkedLines: new THREE.LineBasicMaterial({ color: color_checked }),
        preCutLines: new THREE.LineBasicMaterial({ color: color_checked, transparent: true, opacity: 0.5 }), //剪切器件的透明框
        checkedAlignPoint: new THREE.PointsMaterial({ color: col_align, size: 20 }),
        checkedAlignLine: new THREE.LineBasicMaterial({ color: col_align }),
        preAddRefLine: new THREE.LineBasicMaterial({ color: color_pre_add_ref }), //预放置的外部器件线
        preAddRefFill: new THREE.MeshBasicMaterial({ color: color_pre_add_ref }), //预放置的外部器件填充
        preAddRefPoint: generateKeyPointMat(new THREE.Vector4(color_pre_add_ref.r, color_pre_add_ref.g, color_pre_add_ref.b, 1)), //预放置的外部器件点
        editRefBorder: new THREE.LineBasicMaterial({ color: new THREE.Color(this.colors.editRef) }), //编辑下层引用边框
        drcLineMat: new LineMaterial({ color: new THREE.Color(this.colors.drcColor), linewidth: 10, dashed: false, alphaToCoverage: true }), //drc标记颜色
      }
    } else {
      this.toolsMat.selectBox.setColor(new THREE.Color(this.colors.selectBox))
      this.toolsMat.adaptBox.setColor(new THREE.Color(this.colors.adaptBox))
      this.toolsMat.cutBox2D.setColor(new THREE.Color(this.colors.cutBox2D))
      this.toolsMat.cutBox3D.setColor(new THREE.Color(this.colors.cutBox3D))
      this.toolsMat.cutBox3D_pre_check.setColor(color_pre_check)
      this.toolsMat.cutBox3D_checked.setColor(color_checked)
      this.toolsMat.cutBox3D_pre_cut.setColor(new THREE.Color(this.colors.cutBox3D))
      let col_ra3d = new THREE.Color(this.colors.renderArea3D)
      this.toolsMat.renderArea3D.uniforms['color'].value = new THREE.Vector4(col_ra3d.r, col_ra3d.g, col_ra3d.b, 1)
      this.toolsMat.renderArea3D_pre_check.uniforms['color'].value = new THREE.Vector4(color_pre_check.r, color_pre_check.g, color_pre_check.b, 1)
      this.toolsMat.renderArea3D_checked.uniforms['color'].value = new THREE.Vector4(color_checked.r, color_checked.g, color_checked.b, 1)
      this.toolsMat.renderArea3D_pre_cut.uniforms['color'].value = new THREE.Vector4(col_ra3d.r, col_ra3d.g, col_ra3d.b, 0.5)
      let col_angle = new THREE.Color(this.colors.angleText)
      this.toolsMat.angleText.uniforms['color'].value = new THREE.Vector3(col_angle.r, col_angle.g, col_angle.b)
      // let col_ruler_text = new THREE.Color(this.colors.rulerText)
      this.toolsMat.rulerLine.setColor(new THREE.Color(this.colors.rulerLine))
      this.toolsMat.rulerLine_pre_check.setColor(color_pre_check)
      this.toolsMat.rulerLine_checked.setColor(color_checked)
      this.toolsMat.rulerLine_pre_cut.setColor(new THREE.Color(this.colors.rulerLine))
      this.toolsMat.rulerText.setColor(new THREE.Color(this.colors.rulerText)) //.uniforms['color'].value = new THREE.Vector3(col_ruler_text.r, col_ruler_text.g, col_ruler_text.b)
      this.toolsMat.rulerText_pre_check.setColor(color_pre_check) //.uniforms['color'].value = new THREE.Vector3(color_pre_check.r, color_pre_check.g, color_pre_check.b)
      this.toolsMat.rulerText_checked.setColor(color_checked) //.uniforms['color'].value = new THREE.Vector3(color_checked.r, color_checked.g, color_checked.b)
      this.toolsMat.rulerText_pre_cut.setColor(new THREE.Color(this.colors.rulerText))
      this.toolsMat.preCheckPoint.setColor(color_pre_check)
      this.toolsMat.preCheckLine.setColor(color_pre_check)
      this.toolsMat.checkedPoints.setColor(color_checked)
      this.toolsMat.checkedLines.setColor(color_checked)
      this.toolsMat.preCutLines.setColor(color_checked)
      this.toolsMat.checkedAlignPoint.setColor(col_align)
      this.toolsMat.checkedAlignLine.setColor(col_align)
      this.toolsMat.preAddRefLine.setColor(color_pre_add_ref)
      this.toolsMat.preAddRefFill.setColor(color_pre_add_ref)
      this.toolsMat.preAddRefPoint.uniforms['color'].value = new THREE.Vector4(color_pre_add_ref.r, color_pre_add_ref.g, color_pre_add_ref.b, 1)
      this.toolsMat.editRefBorder.setColor({ color: new THREE.Color(this.colors.editRef) })
      this.toolsMat.drcLineMat.setColor({ color: new THREE.Color(this.colors.drcColor) })
    }
    this.toolsMat.drcLineMat.resolution.set(window.innerWidth, window.innerHeight) //设置缩放
  }

  breath() {
    this.toolsMat.drcLineMat.opacity = Math.abs(Math.sin(this.count))
  }
  updateLayerMats(layers) {
    this.layerMats = {}
    let color_pre_rgb = hexToRgb(this.colors.preCheckedCol).map(v => v / 255)
    let checked_rgb = hexToRgb(this.colors.checkedCol).map(v => v / 255)
    let color_pre = new THREE.Vector4(color_pre_rgb[0], color_pre_rgb[1], color_pre_rgb[2], 1.0)
    let color_check = new THREE.Vector4(checked_rgb[0], checked_rgb[1], checked_rgb[2], 1.0)
    layers.forEach(layer => {
      let color_v4 = new THREE.Vector4(Math.random() * 1.2, Math.random() * 1.2, Math.random() * 1.2, 1.0)
      let lineMat = generateLineMat(color_v4, color_pre, color_check)
      let fillMat = generateFillMat(color_v4, color_pre, color_check)
      let lineInsMat = generateLineInsMat(color_v4, color_pre, color_check)
      let fillInsMat = generateFillInsMat(color_v4, color_pre, color_check)
      // fillInsMat.side = THREE.DoubleSide //避免翻转后器件填充不显示
      let fontMat = generateFontMat(color_v4, color_pre, color_check)
      let fontInsMat = generateFontInsMat(color_v4, color_pre, color_check)
      let keyPointMat = generateKeyPointMat(color_v4, color_pre, color_check)

      let lineMat_pre_check = generateLineMat(color_pre, color_pre, color_check)
      let fillMat_pre_check = generateFillMat(color_pre, color_pre, color_check)
      let lineInsMat_pre_check = generateLineInsMat(color_pre, color_pre, color_check)
      let fillInsMat_pre_check = generateFillInsMat(color_pre, color_pre, color_check)
      let fontMat_pre_check = generateFontMat(color_pre, color_pre, color_check)
      let fontInsMat_pre_check = generateFontInsMat(color_pre, color_pre, color_check)
      let keyPoint_pre_check = generateKeyPointMat(color_pre, color_pre, color_check)

      let lineMat_checked = generateLineMat(color_check, color_pre, color_check)
      let fillMat_checked = generateFillMat(color_check, color_pre, color_check)
      let lineInsMat_checked = generateLineInsMat(color_check, color_pre, color_check)
      let fillInsMat_checked = generateFillInsMat(color_check, color_pre, color_check)
      let fontMat_checked = generateFontMat(color_check, color_pre, color_check)
      let fontInsMat_checked = generateFontInsMat(color_check, color_pre, color_check)
      let keyPoint_checked = generateKeyPointMat(color_check, color_pre, color_check)

      const color_v4_hf_a = color_v4.clone()
      color_v4_hf_a.w = 0.5
      let lineMat_pre_cut = generateLineMat(color_v4_hf_a, color_pre, color_check)
      let fillMat_pre_cut = generateFillMat(color_v4_hf_a, color_pre, color_check)
      let lineInsMat_pre_cut = generateLineInsMat(color_v4_hf_a, color_pre, color_check)
      let fillInsMat_pre_cut = generateFillInsMat(color_v4_hf_a, color_pre, color_check)
      let fontMat_pre_cut = generateFontMat(color_v4_hf_a, color_pre, color_check)
      let fontInsMat_pre_cut = generateFontInsMat(color_v4_hf_a, color_pre, color_check)
      let keyPointMat_pre_cut = generateKeyPointMat(color_v4_hf_a, color_pre, color_check)

      this.layerMats[layer] = {
        layerNum: layer,

        lineMat,
        fillMat,
        fontMat,
        lineInsMat,
        fillInsMat,
        fontInsMat,
        keyPointMat,

        lineMat_pre_check,
        fillMat_pre_check,
        lineInsMat_pre_check,
        fillInsMat_pre_check,
        fontMat_pre_check,
        fontInsMat_pre_check,
        keyPoint_pre_check,

        lineMat_checked,
        fillMat_checked,
        lineInsMat_checked,
        fillInsMat_checked,
        fontMat_checked,
        fontInsMat_checked,
        keyPoint_checked,

        lineMat_pre_cut,
        fillMat_pre_cut,
        lineInsMat_pre_cut,
        fillInsMat_pre_cut,
        fontMat_pre_cut,
        fontInsMat_pre_cut,
        keyPointMat_pre_cut,
      }
    })
  }

  getlayerMats(layer) {
    return this.layerMats[layer]
  }
  resize(w, h) {
    const v2 = new THREE.Vector2(w, h)
    this.toolsMat.renderArea3D.uniforms['canvas'].value = v2
    this.toolsMat.renderArea3D_pre_check.uniforms['canvas'].value = v2
    this.toolsMat.renderArea3D_checked.uniforms['canvas'].value = v2
    this.toolsMat.renderArea3D_pre_cut.uniforms['canvas'].value = v2
    this.toolsMat.drcLineMat.resolution.set(w, h) //设置缩放
  }
}

export class Tools {
  constructor(toolsGroup, config) {
    let geometry = new THREE.BufferGeometry()
    geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
    this.selectBox = new THREE.LineLoop(geometry.clone(), config.toolsMat.selectBox) //多选框
    this.adaptBox = new THREE.LineLoop(geometry.clone(), config.toolsMat.adaptBox) //自适应框
    this.angleText = new THREE.Mesh(geometry.clone(), config.toolsMat.angleText) //角度文本
    this.cutBox2D = new THREE.LineLoop(geometry.clone(), config.toolsMat.cutBox2D) //区域切除
    this.cutBox3DGroup = new THREE.Group()
    this.renderArea3DGroup = new THREE.Group()
    this.rulerGroup = new THREE.Group()
    this.rulerGroup.position.z = -9.8
    // this.cutBox3D = new THREE.LineLoop(geometry.clone(), config.toolsMat.cutBox3D) //3D剖面区域
    // this.renderArea3D = new THREE.LineLoop(geometry.clone(), config.toolsMat.renderArea3D) //3D渲染区域
    toolsGroup.add(this.selectBox)
    toolsGroup.add(this.adaptBox)
    toolsGroup.add(this.cutBox2D)
    toolsGroup.add(this.angleText)
    toolsGroup.add(this.cutBox3DGroup)
    toolsGroup.add(this.renderArea3DGroup)
    toolsGroup.add(this.rulerGroup)
    toolsGroup.position.z = 10
  }

  //清空工具图形数据
  reset(e) {
    let points = []
    let empty = new THREE.Float32BufferAttribute([], 3)
    this.selectBox.points = points
    this.adaptBox.points = points
    this.cutBox2D.points = points
    this.selectBox.geometry.attributes.position = empty
    this.adaptBox.geometry.attributes.position = empty
    this.cutBox2D.geometry.attributes.position = empty
    //中键松开不清除旋转文本
    if (!e || e.button !== 1) {
      this.angleText.geometry.attributes.position = empty
    }
  }

  //判断画板是否正在绘制
  isDrawingTool() {
    return this.selectBox.points?.length || this.adaptBox.points?.length || this.cutBox2D.points?.length
  }

  //移除只保留一个的工具图形 3D区域 3D剖面区域
  removePastChild() {
    if (this.cutBox3DGroup.children.length > 1) {
      this.cutBox3DGroup.children[0].geometry?.dispose()
      this.cutBox3DGroup.remove(this.cutBox3DGroup.children[0])
    }
    if (this.renderArea3DGroup.children.length > 1) {
      this.renderArea3DGroup.children[0].geometry?.dispose()
      this.renderArea3DGroup.remove(this.renderArea3DGroup.children[0])
    }
  }

  updateAngleText(angleText, pos) {
    let geo = generateFontGeometry(angleText, 'o', 18, 1)
    this.angleText.geometry.dispose()
    this.angleText.geometry = geo
    if (pos) {
      this.angleText.position.x = pos[0]
      this.angleText.position.y = pos[1]
    }
  }
}
//计算引用cell的所有引用矩阵
export function computeRefs(refs, cellsMatMap, befoewRefs = []) {
  let len = refs.length
  for (let i = 0; i < len; i++) {
    let ref = refs[i]
    let cell = ref.cell
    let all_refs = [...befoewRefs, ref]
    let subRefs = cell.references
    let cellMatMap = cellsMatMap[cell.name]
    cellMatMap.refs.push(all_refs)
    if (subRefs.length) {
      computeRefs(subRefs, cellsMatMap, all_refs)
    }
  }
}

//引用用网格区域归纳
export function computeRefByAreas(cellsMatMap, divideAreas) {
  for (const key in cellsMatMap) {
    let obj = cellsMatMap[key]
    obj.groups = []
    if (obj.refs.length > 100000) {
      alert(obj.refs.length)
      obj.groups = generateRefsGroup(obj.refs, divideAreas)
    }
  }
}

//根据网格分组
function generateRefsGroup(refs, divideAreas) {
  let result = []
  for (let i = 0; i < divideAreas.length; i++) {
    result.push({ refs: [], area: divideAreas[i], instanceObj: { path: [], polygons: [] } })
  }
  let l = refs.length

  for (let i = 0; i < l; i++) {
    const pos = refs[i][0].origin //取第一个position
    for (let j = 0; j < divideAreas.length; j++) {
      const area = divideAreas[j]
      if (pos[0] > area.min[0] && pos[1] > area.min[1] && pos[0] < area.max[0] && pos[1] < area.max[1]) {
        result[j].refs.push(refs[i])
        break
      }
    }
  }

  return result
}

//计算引用的矩阵
export function computeRefMats(cellsMatMap) {
  for (const key in cellsMatMap) {
    let obj = cellsMatMap[key]
    if (obj.groups.length) {
      obj.matsArray = computeMatArrays(obj.groups) //多个batch
    } else {
      obj.matsArray = computeMatArray(obj.refs) //一个batch
    }
  }
}
//计算[ref,ref,ref] =>矩阵
export function computeMatArray(refs) {
  let instanceCount = refs.length
  if (instanceCount) {
    let mats = []
    let translateArray = new Float32Array(instanceCount * 4 * 4)
    for (let i = 0, start = 0; i < instanceCount; i++, start += 16) {
      let matrix4 = new THREE.Matrix4()
      let obj = refs[i]
      mats.push(matrix4)
      for (let j = 0; j < obj.length; j++) {
        let refmMat = computeRefsMat(obj[j])
        matrix4 = matrix4.multiply(refmMat)
      }
      let mat = matrix4.elements
      translateArray[start + 0] = mat[0]
      translateArray[start + 1] = mat[1]
      translateArray[start + 2] = mat[2]
      translateArray[start + 3] = mat[3]
      translateArray[start + 4] = mat[4]
      translateArray[start + 5] = mat[5]
      translateArray[start + 6] = mat[6]
      translateArray[start + 7] = mat[7]
      translateArray[start + 8] = mat[8]
      translateArray[start + 9] = mat[9]
      translateArray[start + 10] = mat[10]
      translateArray[start + 11] = mat[11]
      translateArray[start + 12] = mat[12]
      translateArray[start + 13] = mat[13]
      translateArray[start + 14] = mat[14]
      translateArray[start + 15] = mat[15]
    }
    return translateArray
  } else {
    let translateArray = new Float32Array([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])
    return translateArray
  }
}

//计算group引用=>矩阵
export function computeMatArrays(groups) {
  groups.forEach(group => {
    let refs = group.refs
    let instanceCount = refs.length
    let translateArray = new Float32Array(instanceCount * 4 * 4)
    group.matsArray = []
    for (let i = 0, start = 0; i < instanceCount; i++, start += 16) {
      let mats = []
      let matrix4 = new THREE.Matrix4()
      let obj = refs[i]
      mats.push(matrix4)
      for (let j = 0; j < obj.length; j++) {
        let refmMat = computeRefsMat(obj[j])
        matrix4 = matrix4.multiply(refmMat)
      }
      let mat = matrix4.elements
      translateArray[start + 0] = mat[0]
      translateArray[start + 1] = mat[1]
      translateArray[start + 2] = mat[2]
      translateArray[start + 3] = mat[3]
      translateArray[start + 4] = mat[4]
      translateArray[start + 5] = mat[5]
      translateArray[start + 6] = mat[6]
      translateArray[start + 7] = mat[7]
      translateArray[start + 8] = mat[8]
      translateArray[start + 9] = mat[9]
      translateArray[start + 10] = mat[10]
      translateArray[start + 11] = mat[11]
      translateArray[start + 12] = mat[12]
      translateArray[start + 13] = mat[13]
      translateArray[start + 14] = mat[14]
      translateArray[start + 15] = mat[15]
    }
    group.matsArray = translateArray
    group.matBufferAttr = new THREE.InstancedBufferAttribute(translateArray, 16)
  })
}

//计算ref 4*4矩阵
export function computeRefsMat(ref) {
  let trans = new THREE.Object3D()
  let x_mirror = ref.x_reflection ? -1 : 1
  trans.scale.set(ref.magnification, ref.magnification * x_mirror, 1)
  trans.rotation.set(0, 0, ref.rotation)
  trans.position.set(ref.origin[0], ref.origin[1], 0)
  trans.updateMatrix()
  return trans.matrix
}
//计算cell图形对象 非引用
export function computeCellMesh(_group, config, layerMats, obj, mergeArray) {
  let showFill = config.showFill
  let useMergeBufferGeometries = config.useMergeBufferGeometries
  let cell = obj.cell
  let cellInstance = obj.cellInstance
  let paths = cell.paths
  let paths_len = paths.length
  let polygons = cell.polygons
  let polygons_len = polygons.length
  let labels = cell.labels
  let labels_len = labels.length
  for (let i = 0; i < paths_len; i++) {
    let path = paths[i]
    let points = path.get_points()
    let mat = getlayerMats(layerMats, path.layers[0])
    let line = generatePathGeometry(points, mat.lineMat)

    // let status_array = new Float32Array(points.length).fill(1)
    // line.geometry.setAttribute('preCheck', new THREE.BufferAttribute(status_array, 1))
    // line.geometry.setAttribute('checked', new THREE.BufferAttribute(status_array, 1))
    line.geometry.computeBoundingSphere()
    if (useMergeBufferGeometries) {
      mergeArray.lineGeometryArray.push(line.geometry)
      mergeArray.lineMaterialArray.push(line.material)
    } else {
      _group.add(line)
    }
    obj.cellGraphics.paths.push({ line })
  }

  for (let i = 0; i < polygons_len; i++) {
    let polygon = polygons[i]
    let points = polygon.get_points()
    let mat = getlayerMats(layerMats, polygon.layer)
    let line = generatePathGeometry(points, mat.lineMat, true)
    let graphic = { line: null, fill: null }
    line.geometry.computeBoundingSphere()
    // let status_array = new Float32Array(points.length).fill(1)
    // line.geometry.setAttribute('preCheck', new THREE.BufferAttribute(status_array, 1))
    // line.geometry.setAttribute('checked', new THREE.BufferAttribute(status_array, 1))
    if (useMergeBufferGeometries) {
      mergeArray.lineLoopGeometryArray.push(line.geometry)
      mergeArray.lineLoopMaterialArray.push(line.material)
    } else {
      _group.add(line)
    }
    graphic.line = line
    if (showFill) {
      let fill = generatePolygonGeometry(points, mat.fillMat)
      fill.geometry.computeBoundingSphere()
      // let status_array = new Float32Array(points.length).fill(1)
      // fill.geometry.setAttribute('preCheck', new THREE.BufferAttribute(status_array, 1))
      // fill.geometry.setAttribute('checked', new THREE.BufferAttribute(status_array, 1))
      if (useMergeBufferGeometries) {
        mergeArray.meshGeometryArray.push(fill.geometry)
        mergeArray.meshMaterialArray.push(fill.material)
      } else {
        _group.add(fill)
      }
      graphic.fill = fill
    }
    obj.cellGraphics.polygons.push(graphic)
  }

  for (let i = 0; i < labels_len; i++) {
    let label = labels[i]
    let mat = getlayerMats(layerMats, label.layer)
    let labelMesh = generateLabelMesh(label.text, label.origin, label.rotation, label.anchor, mat.fontMat)
    // let status_array = new Float32Array(labelMesh.geometry.attributes.position.count).fill(1)
    // labelMesh.geometry.setAttribute('preCheck', new THREE.BufferAttribute(status_array, 1))
    // labelMesh.geometry.setAttribute('checked', new THREE.BufferAttribute(status_array, 1))

    obj.cellGraphics.labels.push(labelMesh)
    _group.add(labelMesh)
    // positionArray.push(label.origin[0], label.origin[1], 0)
  }
}

function generatePathGeometry(points, material, isloop = false) {
  const position = []
  const length = points.length
  for (let i = 0, l = length; i < l; i++) {
    const point = points[i]
    position.push(point[0], point[1], 0)
  }
  let line_geometry = new THREE.BufferGeometry()
  line_geometry.setAttribute('position', new THREE.Float32BufferAttribute(position, 3))
  if (isloop) {
    return new THREE.LineLoop(line_geometry, material)
  } else {
    return new THREE.Line(line_geometry, material)
  }
}

function generatePolygonGeometry(points, material) {
  let newShape = new THREE.Shape()
  let len = points.length
  newShape.moveTo(points[0][0], points[0][1])
  for (let i = 1; i < len; i++) {
    let p = points[i]
    newShape.lineTo(p[0], p[1])
  }
  let newShapeGeometry = new THREE.ShapeGeometry(newShape)
  // let newShapeGeometry = new THREE.ShapeGeometry();
  // const vertices = new Float32Array( len * 3 );
  // for(let i = 0; i2 = 0, i<len; i++, i2 += 3){
  // 	let p = points[i]
  // 	vertices[i2] = p[0]
  // 	vertices[i2+1] = p[1]
  // 	vertices[i2+2] = 0
  // }
  // newShapeGeometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 2) );

  return new THREE.Mesh(newShapeGeometry, material)
}

//计算最终批处理数据
export function computeCellInstance(_group, config, layerMats, obj, mergeArray) {
  let useMBG = config.useMergeBufferGeometries && mergeArray
  let showFill = config.showFill
  let cell = obj.cell

  let cellInstance = obj.cellInstance
  let instanceCount = obj.refs.length == 0 ? 1 : obj.refs.length
  let groups = obj.groups
  let translateArray = obj.matsArray
  let paths = cell.paths
  let paths_len = paths.length
  let polygons = cell.polygons
  let polygons_len = polygons.length
  let labels = cell.labels
  let labels_len = labels.length

  let instancedBufferAttribute = new THREE.InstancedBufferAttribute(translateArray, 16)
  let onlyOne = instanceCount == 1

  for (let i = 0; i < paths_len; i++) {
    let path = paths[i]
    let points = path.get_points()
    let mat = getlayerMats(layerMats, path.layers[0])
    let linesBatch = generateLineBatch(points, instanceCount, instancedBufferAttribute, mat.lineInsMat, onlyOne)
    // linesBatch.area = path.area() //线的面积？
    linesBatch.lod = {
      originPoints: points,
      originPosition: linesBatch.geometry.attributes.position,
    }
    if (useMBG) {
      mergeArray.lineGeometryArray.push(linesBatch.geometry)
      mergeArray.lineMaterialArray.push(linesBatch.material)
    } else {
      if (groups.length) {
        //区域分解后的batch
        //   groups.forEach(group => {
        //     let groupBatch = linesBatch.copy()
        //     groupBatch.geometry.instanceCount = group.refs.length
        //     groupBatch.geometry.setAttribute('globalMatrix',  group.matBufferAttr)
        //     _group.add(groupBatch)
        //   })
      } else {
        _group.add(linesBatch)
      }
    }

    cellInstance.paths.push(linesBatch)
    if (useMBG) {
      obj.cellGraphics.paths.push({ line })
    }
  }

  for (let i = 0; i < polygons_len; i++) {
    let polygon = polygons[i]
    let mat = getlayerMats(layerMats, polygon.layer)

    let points = polygon.get_points()
    let linesBatch = generateLineLoopBatch(points, instanceCount, instancedBufferAttribute, mat.lineInsMat, onlyOne)
    // linesBatch.area = polygon.area()
    linesBatch.lod = {
      originPoints: points,
      originPosition: linesBatch.geometry.attributes.position,
    }
    let fillBatch
    if (showFill) {
      fillBatch = generateFillIns(points, instanceCount, translateArray, mat.fillInsMat, onlyOne)
      fillBatch.instanceMatrix.needsUpdate = false
      fillBatch.area = linesBatch.area
      fillBatch.lod = {
        originPoints: points,
        originPosition: fillBatch.geometry.attributes.position,
        originIndex: fillBatch.geometry.index,
      }
    }

    if (groups.length) {
      //区域分解后的batch
      groups.forEach(group => {
        let count = group.refs.length
        let boundingSphere = group.area.boundingSphere
        let groupLineBatch = linesBatch.clone()
        groupLineBatch.geometry = new THREE.InstancedBufferGeometry()
        groupLineBatch.geometry.instanceCount = count
        groupLineBatch.geometry.attributes.position = linesBatch.geometry.attributes.position
        groupLineBatch.geometry.setAttribute('globalMatrix', group.matBufferAttr)
        // let status_array = new Float32Array(count).fill(1)
        // groupLineBatch.geometry.setAttribute('preCheck', new THREE.BufferAttribute(status_array, 1))
        // groupLineBatch.geometry.setAttribute('checked', new THREE.BufferAttribute(status_array, 1))
        groupLineBatch.geometry.boundingSphere = boundingSphere
        groupLineBatch.frustumCulled = true
        _group.add(groupLineBatch)

        let groupFillBatch
        if (showFill) {
          groupFillBatch = new THREE.InstancedMesh()
          groupFillBatch.count = count
          groupFillBatch.geometry = new THREE.ShapeGeometry([])
          groupFillBatch.geometry.attributes.position = fillBatch.geometry.attributes.position
          // let status_array = new Float32Array(count).fill(1)
          // groupFillBatch.geometry.setAttribute('preCheck', new THREE.BufferAttribute(status_array, 1))
          // groupFillBatch.geometry.setAttribute('checked', new THREE.BufferAttribute(status_array, 1))
          groupFillBatch.geometry.index = fillBatch.geometry.index
          groupFillBatch.material = fillBatch.material
          groupFillBatch.instanceMatrix.array = group.matsArray
          groupFillBatch.needsUpdate = false
          // let groupFillBatch = generateFillIns(points, group.refs.length, group.matsArray, mat.fillMat, false)
          groupFillBatch.geometry.boundingSphere = boundingSphere
          groupFillBatch.frustumCulled = true
          _group.add(groupFillBatch)
        }

        group.instanceObj.polygons.push({ linesBatch: groupLineBatch, fillBatch: groupFillBatch })
      })
    } else {
      if (useMBG) {
        mergeArray.lineLoopGeometryArray.push(linesBatch.geometry)
        mergeArray.lineLoopMaterialArray.push(linesBatch.material)
      } else {
        _group.add(linesBatch)
      }

      if (showFill) {
        if (useMBG) {
          mergeArray.meshGeometryArray.push(fillBatch.geometry)
          mergeArray.meshMaterialArray.push(fillBatch.material)
        } else {
          _group.add(fillBatch)
        }
      }
    }
    cellInstance.polygons.push({ linesBatch, fillBatch })
    if (useMBG) {
      let graphic = { line: linesBatch, fill: fillBatch }
      obj.cellGraphics.polygons.push(graphic)
    }
  }

  for (let i = 0; i < labels_len; i++) {
    let label = labels[i]
    let mat = getlayerMats(layerMats, label.layer)
    let labelMesh = generateLabelIns(label, instanceCount, translateArray, mat.fontInsMat, onlyOne)
    obj.cellGraphics.labels.push(labelMesh)
    _group.add(labelMesh)
  }
}

export function getlayerMats(mats, layer) {
  return mats[layer]
}

//绘制线
export function generateLineBatch(points, instanceCount, instancedBufferAttribute, lineMat, onlyOne) {
  let vec2Arry = points.map(p => new THREE.Vector2(p[0], p[1]))
  let instancedGeometry = new THREE.InstancedBufferGeometry().copy(new THREE.BufferGeometry().setFromPoints(vec2Arry))
  instancedGeometry.instanceCount = instanceCount
  instancedGeometry.setAttribute('globalMatrix', instancedBufferAttribute)
  // let status_array = new Float32Array(instanceCount).fill(1)
  // instancedGeometry.setAttribute('preCheck', new THREE.InstancedBufferAttribute(status_array, 1))
  // instancedGeometry.setAttribute('checked', new THREE.InstancedBufferAttribute(status_array, 1))
  let linesBatch = new THREE.Line(instancedGeometry, lineMat)
  linesBatch.frustumCulled = false

  if (onlyOne) {
    instancedGeometry.computeBoundingSphere()
    instancedGeometry.boundingSphere.applyMatrix4(converArryToMat4(instancedBufferAttribute.array))
    linesBatch.frustumCulled = true
  }
  return linesBatch
}

//绘制多边形线
export function generateLineLoopBatch(points, instanceCount, instancedBufferAttribute, lineMat, onlyOne) {
  let vec2Arry = points.map(p => new THREE.Vector2(p[0], p[1]))
  let instancedGeometry = new THREE.InstancedBufferGeometry().copy(new THREE.BufferGeometry().setFromPoints(vec2Arry))
  instancedGeometry.instanceCount = instanceCount
  instancedGeometry.setAttribute('globalMatrix', instancedBufferAttribute)
  // let status_array = new Float32Array(instanceCount).fill(1)
  // instancedGeometry.setAttribute('preCheck', new THREE.InstancedBufferAttribute(status_array, 1))
  // instancedGeometry.setAttribute('checked', new THREE.InstancedBufferAttribute(status_array, 1))
  let linesBatch = new THREE.LineLoop(instancedGeometry, lineMat)
  linesBatch.frustumCulled = false

  if (onlyOne) {
    instancedGeometry.computeBoundingSphere()
    instancedGeometry.boundingSphere.applyMatrix4(converArryToMat4(instancedBufferAttribute.array))
    linesBatch.frustumCulled = true
  }
  return linesBatch
}

//绘制多边形填充
export function generateFillIns(points, instanceCount, translateArray, fillMat, onlyOne) {
  let fillGeometry = generateShapeGeometry(points)
  let insMesh = new THREE.InstancedMesh(fillGeometry, fillMat, instanceCount)
  // let status_array = new Float32Array(instanceCount).fill(1)
  // fillGeometry.setAttribute('preCheck', new THREE.InstancedBufferAttribute(status_array, 1))
  // fillGeometry.setAttribute('checked', new THREE.InstancedBufferAttribute(status_array, 1))
  insMesh.instanceMatrix.needsUpdate = false
  insMesh.instanceMatrix.array = translateArray
  insMesh.frustumCulled = false
  if (onlyOne) {
    fillGeometry.computeBoundingSphere()
    fillGeometry.boundingSphere.applyMatrix4(converArryToMat4(translateArray))
    insMesh.frustumCulled = true
  }
  return insMesh
}

//绘制文字
export function generateLabelIns(label, instanceCount, translateArray, fontMat, onlyOne) {
  // label.text, label.origin, label.rotation, label.anchor

  let pos = label.origin
  let fontGeometry = generateFontGeometry(label.text, label.anchor)
  // let status_array = new Float32Array(instanceCount).fill(1)
  // fontGeometry.setAttribute('preCheck', new THREE.BufferAttribute(status_array, 1))
  // fontGeometry.setAttribute('checked', new THREE.BufferAttribute(status_array, 1))
  let insLabel = new THREE.InstancedMesh(fontGeometry, fontMat, instanceCount)
  insLabel.instanceMatrix.needsUpdate = false
  insLabel.instanceMatrix.array = translateArray
  insLabel.frustumCulled = false
  insLabel.rotation.z = label.rotation
  insLabel.position.set(pos[0], pos[1], 0)

  if (onlyOne) {
    fontGeometry.computeBoundingSphere()
    fontGeometry.boundingSphere.applyMatrix4(converArryToMat4(translateArray))
    insLabel.frustumCulled = true
  }
  return insLabel
}

export function generateShapeGeometry(points) {
  let newShape = new THREE.Shape()
  let len = points.length
  newShape.moveTo(points[0][0], points[0][1])
  for (let i = 1; i < len; i++) {
    let p = points[i]
    newShape.lineTo(p[0], p[1])
  }
  return new THREE.ShapeGeometry(newShape)
}

export function getCellLayers(cell) {
  let lib = new QGdstk.Library('temp', 0.000001, 1e-9)
  lib.add(cell)
  lib.add(cell.dependencies(true))

  let polygonlayers = lib
    .layers_and_datatypes()
    .map(data => data[0])
    .sort()
  let labelLayers = lib
    .layers_and_texttypes()
    .map(data => data[0])
    .sort()
  let layers = Array.from(new Set(polygonlayers.concat(labelLayers)))
  layers.sort((a, b) => a - b)

  const res = layers.map(ly => {
    return { layerNumber: ly, border: 'B1', shape: 'F1', color: '#ffffff', borderColor: '#ffffff', hide: false, lock: false }
  })
  return res
}

//生成线
export function generateLine(points, material, camera, w, h) {
  const geometry = generateLineGeometry(points)
  const line = new THREE.Line(geometry, material)
  return line
}
//生成线几何数据
export function generateLineGeometry(points) {
  let vec2Arry = points.map(p => screenToWorld(new THREE.Vector2(p[0], p[1])))
  return new THREE.BufferGeometry().setFromPoints(vec2Arry)
}

export function screenToWorld(vector, camera, w, h) {
  // world to view and view to NDC

  //   vector.unproject(camera)
  //   vector.z = 0

  // NDC to pixel
  //   vector.x = Math.round(((vector.x + 1) * w) / 2)
  //   vector.y = Math.round(((-vector.y + 1) * h) / 2)

  return vector
}
//划分区域
export function divideRectangle(aabb, size) {
  let min_x = aabb[0][0]
  let min_y = aabb[0][1]
  let max_x = aabb[1][0]
  let max_y = aabb[1][1]
  let w = Math.ceil(max_x) - min_x
  let h = Math.ceil(max_y) - min_y
  let divide_w = parseInt(Math.ceil(w / size))
  let divide_h = parseInt(Math.ceil(h / size))
  let divide_w_half = divide_w / 2
  let divide_h_half = divide_h / 2
  let radius = Math.sqrt(divide_w * divide_w + divide_h * divide_h) / 2
  let result = []
  let total = size * size

  for (let i = 0; i < size; i++) {
    //行
    let x = min_x + i * divide_w
    let center_x = x + divide_w_half
    for (let j = 0; j < size; j++) {
      //列
      let y = min_y + j * divide_h
      let center_y = y + divide_h_half
      let aabb = [
        [x, y],
        [x + divide_w, y + divide_h],
      ]
      result.push({
        min: aabb[0],
        max: aabb[1],
        aabb,
        boundingSphere: new THREE.Sphere(new THREE.Vector3(center_x, center_y, 0), radius),
      })
    }
  }
  return result
}

export function updateGroupsPosition(obj, showFill, position, fillIndexs, index) {
  let size = obj.groups.length
  if (size) {
    for (let i = 0; i < size; i++) {
      const group = obj.groups[i]
      let inst = group.instanceObj.polygons[index]

      let lb = inst.linesBatch
      lb.geometry.attributes.position = position
      if (showFill) {
        let fb = inst.fillBatch
        fb.geometry.attributes.position = position
        fb.geometry.index = fillIndexs
      }
    }
  }
}

export function drawRuler(canvasRulerX, canvasRulerY, myaxis) {
  drawRulerX(canvasRulerX, myaxis)
  drawRulerY(canvasRulerY, myaxis)
}

function drawRulerX(canvasRulerX, myaxis) {
  if (!canvasRulerX) {
    return
  }
  const ctx = canvasRulerX.getContext('2d')
  const rulerX = myaxis.rulerX
  ctx.clearRect(0, 0, rulerX.width, rulerX.height)

  ctx.save()
  ctx.strokeStyle = '#ff0000'
  ctx.lineWidth = 1
  ctx.beginPath()
  ctx.moveTo(myaxis.pos_x, rulerX.height)
  ctx.lineTo(myaxis.pos_x, rulerX.height - 40)
  ctx.stroke()
  ctx.restore()

  ctx.fillStyle = '#77787b'
  ctx.strokeStyle = '#77787b'
  ctx.lineWidth = 1
  ctx.beginPath()
  ctx.font = '12px Arial'
  const lines = rulerX.lines

  for (let i = 0; i < lines.paths.length; i++) {
    ctx.moveTo(lines.paths[i][0][0], lines.paths[i][0][1])
    ctx.lineTo(lines.paths[i][1][0], lines.paths[i][1][1])
  }
  for (let i = 0; i < lines.texts.length; i++) {
    ctx.fillText(lines.texts[i].label, lines.texts[i].x, lines.texts[i].y + 18)
  }
  ctx.stroke()
}

function drawRulerY(canvasRulerY, myaxis) {
  if (!canvasRulerY) {
    return
  }
  const ctx = canvasRulerY.getContext('2d')
  const rulerY = myaxis.rulerY
  ctx.clearRect(0, 0, rulerY.width, rulerY.height)

  ctx.save()
  ctx.strokeStyle = '#ff0000'
  ctx.lineWidth = 1
  ctx.beginPath()
  ctx.moveTo(rulerY.width - 40, myaxis.pos_y)
  ctx.lineTo(rulerY.width, myaxis.pos_y)
  ctx.stroke()
  ctx.restore()

  ctx.fillStyle = '#77787b'
  ctx.strokeStyle = '#77787b'
  ctx.font = '12px Arial'
  ctx.lineWidth = 1
  ctx.beginPath()
  const lines = rulerY.lines
  for (let i = 0; i < lines.paths.length; i++) {
    ctx.moveTo(lines.paths[i][0][0], lines.paths[i][0][1])
    ctx.lineTo(lines.paths[i][1][0], lines.paths[i][1][1])
  }
  ctx.stroke()

  for (let i = 0; i < lines.texts.length; i++) {
    ctx.save()
    // const tX = lines.texts[i].translate[0]
    // const tY = lines.texts[i].translate[1]
    // ctx.translate(tX, tY)
    // ctx.rotate(lines.texts[i].rotate)
    // ctx.fillText(lines.texts[i].label, lines.texts[i].x, lines.texts[i].y - 20)
    // ctx.rotate(-lines.texts[i].rotate)
    // ctx.translate(-tX, -tY)

    ctx.translate(5, lines.texts[i].lineY + 2) //对应刻度尺y坐标
    ctx.rotate(lines.texts[i].rotate)
    ctx.fillText(lines.texts[i].label, 0, 0)
    ctx.restore()
  }
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

export function axisLoopOffset(v, step) {
  let loopWidth = 10 * step //坐标系以10 * 刻度尺最小单位的屏幕像素宽度无限循环
  if (Math.abs(v) > loopWidth) {
    v = v % loopWidth
  }
  return v + loopWidth
}

export function converArryToMat4(float32arr) {
  const m = new THREE.Matrix4()
  m.elements = Array.from(float32arr)
  return m
}

export function clearChildern(obj) {
  if (obj.geometry) {
    obj.geometry.dispose()
    obj.cloneFrom = null
  }
  if (obj.children?.length) {
    obj.children.forEach(child => {
      clearChildern(child)
    })
    obj.remove(...obj.children)
  }
}

export function clearGroup(obj) {
  if (obj.children.length) {
    const max = obj.children.length - 1
    const children = obj.children
    for (let i = max; i >= 0; i--) {
      obj.remove(children[i])
    }
  }
}
