/**
 * qeda-axis-js
 * @version 1.0.0
 * @license MIT
 * @author XiongQiuFeng
 */

const { deepClone } = require('../../utils')
const BigNumber = require('../../graphic_f32/bignumber')

class Point {
  constructor(x = 0, y = 0) {
    this.x = x
    this.y = y
  }

  distance(target) {
    return Math.sqrt(Math.pow(this.x - target.x, 2) + Math.pow(this.y - target.y, 2))
  }
}

;(function () {
  /**
   * QedaCellAxis Constructor
   * scale:  m: 1   // 缩放系数1对应的米
   * precision: 1.0e-9 nm
   * unit: m:1, mm:1.0e-3, um:1.0e-6, nm: 1.0e-9
   * 转换需要 unit / precision
   * 例如，如果创建一个半径为 1.5um的圆，我们将unit设置为 1.0e-6 (1 um) 并将 precision 设置为 1.0e-9 (1 nm)，则圆的半径将为 1.5 um 包含 1500 nm 的尺寸
   * @class QedaCellAxis
   * @param {Rect} bounds   bounds of the axis ({ x, y, width, height })
   * @param {number} [width=800]    (optional) axis width (default: 800)
   * @param {number} [height=800]   (optional) axis height (default: 800)
   * @param {number} [unit=1.0e-6]  (optional) unit (um) (default: 10e-6)
   * @param {number} [precision=1.0e-9] (optional) precision (nm) (default: 10e-9)
   */
  function QedaCellAxis(width, height, unit, precision) {
    this.width = width || 800
    this.height = height || 800
    // 以米为单位1
    this.unit = unit || 1.0e-6
    this.precision = precision || 1.0e-9
    this.unit_scale = this.unit / this.precision
    // cell 的系数，由unit / precision
    this.cell_scale = 1
    // 缩放系数
    this.scale = 1 // 1 对应 mm
    this.scale_max = 50000
    this.scale_min = 0.0038
    this.scale_range = []
    // 线宽
    this.line_width = 1
    // 坐标轴偏移量
    this.dx = 0 //width / 2 || 400
    this.dy = 0 //height / 2 || 400
    // matrix: [scaleX, 0, tranX, 0, scaleY, tranY, 0, 0, 1]
    this.matrix = [this.scale, 0, this.dx, 0, -this.scale, this.dy, 0, 0, 1]
    // 单位转换后的真实坐标
    this.real_x = 0
    this.real_y = 0
    // 鼠标移动坐标
    this.pos_x = 0
    this.pos_y = 0
    this.pos_real_x = 0
    this.pos_real_y = 0
    this.mouse_pixel_x = 0
    this.mouse_pixel_y = 0
    // 绘图坐标
    this.draw_x = 0
    this.draw_y = 0
    // 吸附
    this.adsorp = true
    this.adsorp_point = [0, 0]
    this.adsorp_precision = 1
    //需要计算吸附坐标 bug927部分绘制状态不需要十字光标同步
    this.needAdsorption = true
    //点吸附
    this.adsorpPoint = false
    //命中点
    this.hit_point = null
    //斜角吸附
    this.angledAdsorption = false
    //斜角吸附点
    this.angled_dsorp_point = null
    //特殊角度吸附点
    this.angled_dsorp_special_point = null
    //角度吸附
    this.adsorpAngle = false
    // 网格
    this.grid_show = true
    this.grid = {
      lines: [],
      // 是否显示
      show: true,
      // 精度
      precision: 1,
      // 画布网格精度
      graph_precision: 1,
      // 网格线数量
      num_max: 50,
      num_min: 30,
    }
    // 标尺X
    this.rulerX = {
      width: this.width,
      height: 50,
      w: 10,
      num_max: 40,
      num_min: 30,
      lines: {
        paths: [],
        texts: [],
      },
    }
    this.step = 0
    this.step_divid = 0
    this.real_box = {
      x_min: 0,
      y_min: 0,
      x_max: 0,
      y_max: 0,
    }
    // 标尺Y
    this.rulerY = {
      width: 50,
      height: this.height,
      w: 10,
      num_max: 40,
      num_min: 30,
      lines: {
        paths: [],
        texts: [],
      },
    }
    // 边框
    this.bounds = {}
    this.bounds_box = {}
    this.bounds_unit = {}
    this.center_cross = []
    this.mouse_cross = []
    this.drag = false
    this.left_drag = false //左键拖动
    this.middle_drag = false //中键拖动

    this.click_point = null
    this.mouse_real_point = [0, 0]
    this.mouse_point = [0, 0]
    this.pickEnabled = false
    this.obj_checked = false
    this.freeze = false
    this.render()
    this.init()
  }

  QedaCellAxis.prototype.init = function (args) {
    this.setScaleRange()
  }

  /**
   * 配置
   * @memberof QedaCellAxis
   */
  QedaCellAxis.prototype.setConf = function (args) {
    this.unit = args.unit
    this.precision = args.precision
    this.adsorp = args.adsorp
    this.adsorp_precision = args.adsorp_precision
    this.grid_show = args.grid_show
  }

  // 设置单位
  QedaCellAxis.prototype.setUnit = function (val) {
    this.unit = val
    this.render()
  }

  // 设置精度, 精度需要根据版图的单位和精度计算系数
  QedaCellAxis.prototype.setPrecision = function (val) {
    this.precision = val
    this.render()
  }

  // 设置精度, 精度需要根据版图的单位和精度计算系数
  QedaCellAxis.prototype.setCellScale = function (val) {
    this.cell_scale = val
    this.render()
  }

  // 设置吸附
  QedaCellAxis.prototype.setAdsorp = function (val) {
    this.adsorp = val
    this.render()
  }

  // 设置缩放系数
  QedaCellAxis.prototype.setScale = function (val) {
    this.scale = val
    // this.scale = this.getScaleCloset(val)

    this.render()
  }

  // 设置吸附精度
  QedaCellAxis.prototype.setAdsorpPrecision = function (val) {
    this.adsorp_precision = val
    this.render()
  }

  // 设置坐标系是否可以拖拽
  QedaCellAxis.prototype.setDrag = function (val, button = null) {
    this.drag = val
    this.left_drag = !this.drag && button === 0
    this.middle_drag = button === 1
    this.render()
  }

  // 现在坐标中心
  QedaCellAxis.prototype.setCenter = function (val) {
    this.dx = 0 //this.width / 2
    this.dy = 0 //this.height / 2
    this.scale = 1
    this.render()
  }

  QedaCellAxis.prototype.getXY = function () {
    return { x: this.real_x, y: this.real_y }
  }

  QedaCellAxis.prototype.getLineWidth = function () {
    return 1 / this.scale
  }

  /**
   * 配置
   * @memberof QedaCellAxis
   */
  QedaCellAxis.prototype.getValue = function () {
    return {
      x: this.real_x,
      y: this.real_y,
      scale: this.scale,
      line_width: this.line_width,
      unit: this.unit,
      precision: this.precision,
    }
  }

  /**
   *  将器件放入画布中心, nm为单位
   * @param {Object} [[x_min, y_min], [x_max, y_max]] 器件边框信息
   * @returns {Object}
   */
  QedaCellAxis.prototype.setCenterCell = function (box) {
    this.setCenter()
    const s = 2 / 3
    const window_width = this.width * s
    const window_height = this.height * s
    const cell_width = box[1][0] - box[0][0]
    const cell_height = box[1][1] - box[0][1]
    let scale = 1
    if (cell_height > cell_width) {
      scale = window_height / cell_height
    } else {
      scale = window_width / cell_width
    }
    this.scale = this.getScaleCloset(scale)

    this.dx -= (box[0][0] + cell_width / 2) * this.scale
    this.dy += (box[0][1] + cell_height / 2) * this.scale
    // this.scale = parseFloat(scale.toFixed(6))
    this.render()
  }

  // 设置缩放范围
  QedaCellAxis.prototype.setScaleRange = function (box) {
    this.scale_range = []
    let num = 1
    for (let i = 20; i > 0; i--) {
      // num = parseFloat((num / 2).toFixed(9))
      num = parseFloat(BigNumber(Math.pow(0.5, i)).toString())
      // num = parseFloat(BigNumber(num).div(2).toString())
      this.scale_range.push(num)
    }
    this.scale_range.push(1)
    num = 1
    for (let i = 0; i < 20; i++) {
      num = num * 2
      this.scale_range.push(num)
    }
  }

  // 二分法查找，获取符合的缩放系数
  QedaCellAxis.prototype.getScaleCloset = function (target) {
    let arr = this.scale_range
    let left = 0
    let right = arr.length - 1
    let mid = 0
    while (left <= right) {
      mid = left + ((right - left) >> 1)
      if (arr[mid] == target) {
        return arr[mid]
      } else if (arr[mid] > target) {
        right = mid - 1
      } else {
        left = mid + 1
      }

      if (left > right) {
        if (arr[left] < arr[right]) {
          return arr[left]
        } else {
          return arr[right]
        }
      }
    }
    return null
  }

  /**
   * 计算网格吸附点
   * @return {number[]}
   * @memberof QedaCellAxis
   */
  QedaCellAxis.prototype.getGridAdsorpPoint = function (pos) {
    // 网格精度吸附点
    let x = this.real_x
    let y = this.real_y
    if (pos) {
      x = pos.x
      y = pos.y
    }
    const p = this.adsorp_precision / this.unit_scale
    const x_min = Math.floor(x / p)
    const y_min = Math.floor(y / p)

    const x_max = Math.ceil(x / p)
    const y_max = Math.ceil(y / p)

    let point = new Point(x, y)
    let p_left_down = new Point(x_min * p, y_min * p)
    let p_left_top = new Point(x_min * p, y_max * p)
    let p_rigth_down = new Point(x_max * p, y_min * p)
    let p_rigth_top = new Point(x_max * p, y_max * p)

    let d_left_down = point.distance(p_left_down)
    let d_left_top = point.distance(p_left_top)
    let d_rigth_down = point.distance(p_rigth_down)
    let d_rigth_top = point.distance(p_rigth_top)
    let min_d = Math.min(d_left_down, d_left_top, d_rigth_down, d_rigth_top)

    let valid_point = {}
    if (d_left_down == min_d) {
      valid_point = p_left_down
    }
    if (d_left_top == min_d) {
      valid_point = p_left_top
    }
    if (d_rigth_down == min_d) {
      valid_point = p_rigth_down
    }
    if (d_rigth_top == min_d) {
      valid_point = p_rigth_top
    }

    return valid_point
  }

  QedaCellAxis.prototype.getGridAdsorpPoint2 = function (p) {
    let unit = Math.ceil(this.unit_scale)
    let pos = deepClone(p)
    if (pos) {
      pos.x /= unit
      pos.y /= unit
      let res = this.getGridAdsorpPoint(pos)
      res.x *= unit
      res.y *= unit
      res.x = Math.round(res.x * 1000) / 1000
      res.y = Math.round(res.y * 1000) / 1000
      return res
    }
  }

  QedaCellAxis.prototype.updateValue = function () {
    //this.unit_scale = parseFloat(BigNumber(this.unit).div(this.precision).toString())
    this.unit_scale = this.unit / (this.precision * this.cell_scale)
    // 像素坐标
    this.pos_real_x = this.pos_x - this.dx
    this.pos_real_y = -(this.pos_y - this.dy)
    // 真实坐标，单位转换
    this.real_x = this.pos_real_x / this.scale / this.unit_scale
    this.real_y = this.pos_real_y / this.scale / this.unit_scale
    this.board_real_x = this.real_x
    this.board_real_y = this.real_y
    // 固定坐标精度
    if (this.unit === 1.0e-2) {
      this.real_x = parseFloat(this.real_x.toFixed(8))
      this.real_y = parseFloat(this.real_y.toFixed(8))
    }
    if (this.unit === 1.0e-3) {
      this.real_x = parseFloat(this.real_x.toFixed(7))
      this.real_y = parseFloat(this.real_y.toFixed(7))
    }
    if (this.unit === 1.0e-6) {
      this.real_x = parseFloat(this.real_x.toFixed(6))
      this.real_y = parseFloat(this.real_y.toFixed(6))
    }
    if (this.unit === 1.0e-9) {
      this.real_x = parseFloat(this.real_x.toFixed(1))
      this.real_y = parseFloat(this.real_y.toFixed(1))
    }
    // 绘图坐标
    this.draw_x = this.real_x / this.unit
    this.draw_y = this.real_y / this.unit

    // 线宽
    this.line_width = 1 / this.scale

    // 计算边框
    this.bounds = {
      x_min: -this.dx,
      x_max: this.width - this.dx,
      y_min: -(this.height - this.dy),
      y_max: this.dy,
    }
    this.bounds_box = {
      x_min: this.bounds.x_min / this.scale,
      y_min: this.bounds.y_min / this.scale,
      x_max: this.bounds.x_max / this.scale,
      y_max: this.bounds.y_max / this.scale,
    }
    const s_unit = 1 / this.scale / this.unit_scale
    this.bounds_unit = {
      x_min: this.bounds.x_min * s_unit,
      y_min: this.bounds.y_min * s_unit,
      x_max: this.bounds.x_max * s_unit,
      y_max: this.bounds.y_max * s_unit,
    }

    let final_x = this.real_x
    let final_y = this.real_y
    // 鼠标吸附
    if (this.adsorp) {
      let ab_point = this.getGridAdsorpPoint()
      final_x = ab_point.x
      final_y = ab_point.y
      // this.adsorp_point.x = ab_point.x * this.unit_scale
      // this.adsorp_point.y = ab_point.y * this.unit_scale
      this.adsorp_point[0] = parseFloat(BigNumber(ab_point.x * this.unit_scale).toFixed(3))
      this.adsorp_point[1] = parseFloat(BigNumber(ab_point.y * this.unit_scale).toFixed(3))
    }
    // 转换矩阵
    this.matrix = [this.scale, 0, this.dx, 0, -this.scale, this.dy, 0, 0, 1]

    this.mouse_real_point = [parseFloat(BigNumber(this.real_x * this.unit_scale).toFixed(3)), parseFloat(BigNumber(this.real_y * this.unit_scale).toFixed(3))]
    this.mouse_point = [parseFloat(BigNumber(final_x * this.unit_scale).toFixed(3)), parseFloat(BigNumber(final_y * this.unit_scale).toFixed(3))]
  }

  /**
   * update axis bounds
   * @param type 'box', 'box_unit'
   * @memberof QedaCellAxis
   */
  QedaCellAxis.prototype.getBounds = function (type) {
    if (type == 'box') {
      return this.bounds_box
    }
    if (type == 'unit') {
      return this.bounds_unit
    }
    return this.bounds
  }

  // 鼠标十字线
  QedaCellAxis.prototype.getMouseCrossLine = function () {
    const bounds = this.bounds_box
    // let x = this.pos_real_x / this.scale
    // let y = this.pos_real_y / this.scale
    let x = this.real_x * this.unit_scale
    let y = this.real_y * this.unit_scale
    if (this.adsorp) {
      // p = this.getGridAdsorpPoint()
      x = this.adsorp_point[0]
      y = this.adsorp_point[1]
    }
    //强制物体吸附
    if (this.hit_point) {
      x = this.hit_point[0]
      y = this.hit_point[1]
    }
    if (this.angled_dsorp_point) {
      x = this.angled_dsorp_point[0]
      y = this.angled_dsorp_point[1]
    }
    if (this.needAdsorption) {
      //斜角吸附
      if (this.angledAdsorption && this.angled_dsorp_point) {
        x = this.angled_dsorp_point[0]
        y = this.angled_dsorp_point[1]
      }
      //物体吸附
      if (this.adsorpPoint && this.hit_point) {
        x = this.hit_point[0]
        y = this.hit_point[1]
      }
      if (this.angled_dsorp_special_point) {
        x = this.angled_dsorp_special_point[0]
        y = this.angled_dsorp_special_point[1]
      }
    }
    // let lines = [
    //   [
    //     [x, bounds.y_min - 10 * this.scale],
    //     [x, bounds.y_max + 10 * this.scale],
    //   ],
    //   [
    //     [bounds.x_min - 10 * this.scale, y],
    //     [bounds.x_max + 10 * this.scale, y],
    //   ],
    // ]
    // this.mouse_cross = lines
    this.mouse_pixel_x = x * this.scale + this.dx
    this.mouse_pixel_y = -(y * this.scale - this.dy)
    // return lines
  }

  /**
   * 坐标中心十字线
   * @param {}
   * @return {Lines}
   * @memberof QedaCellAxis
   */
  QedaCellAxis.prototype.getCenterCrossLine = function () {
    const bounds = this.bounds
    let lines = []
    lines.push([
      [0, bounds.y_min / this.scale],
      [0, bounds.y_max / this.scale],
    ])
    lines.push([
      [bounds.x_min / this.scale, 0],
      [bounds.x_max / this.scale, 0],
    ])
    this.center_cross = lines

    return lines
  }

  /**
   * 生成网格线
   * @param {}
   * @return {Lines}
   * @memberof QedaCellAxis
   */
  QedaCellAxis.prototype.getGridLine = function () {
    const bounds = this.bounds
    const width = this.width
    const height = this.height
    let scale = this.scale
    const unit_scale = this.unit
    // 根据缩放比例归一化宽度为整数刻度
    let w = 50
    let s = w / scale
    for (let i = 10; i >= 0; i--) {
      if (s > Math.pow(10, i)) {
        s = s - (s % Math.pow(10, i))
        break
      }
      if (s < Math.pow(0.1, i)) {
        s = Math.pow(0.1, i)
        break
      }
    }
    s = Math.ceil(s)
    w = s * scale
    // 刻度细分梯度设计
    let gradient = 1
    let err = width / w
    let num = Math.abs(bounds.x_max - bounds.x_min) / w
    if (num < this.grid.num_min) {
      while (err < 10) {
        err = err * 10
        gradient = gradient * 10
      }
    }

    // 单个画布网格精度
    const graph_precision = w / gradient / scale
    // 单个真实网格精度
    // const precision = (graph_precision * unit_scale) / this.precision
    // const precision = graph_precision / this.precision
    const precision = graph_precision / this.unit_scale

    // 计算网格数量, 向上取整
    const x_left = Math.ceil((bounds.x_min / w) * gradient)
    const x_right = Math.ceil((bounds.x_max / w) * gradient)
    const y_left = Math.ceil((bounds.y_min / w) * gradient)
    const y_right = Math.ceil((bounds.y_max / w) * gradient)

    // 计算x、y最大，最小

    let s_x_min = bounds.x_min / scale
    let s_x_max = bounds.x_max / scale
    let s_y_min = bounds.y_min / scale
    let s_y_max = bounds.y_max / scale
    // if (scale > 0) {
    //   s_x_min = s_x_min - 800
    //   s_x_max = s_x_max + 800
    // }

    // 简化运算，提前算好系数
    // 单个画布网格精度
    this.grid.graph_precision = graph_precision
    // 单个真实网格精度
    this.grid.precision = precision
    // 左右范围
    this.grid.x_left = x_left
    this.grid.x_right = x_right
    this.grid.y_left = y_left
    this.grid.y_right = y_right

    // 宽度
    this.grid.w = w
    // 尺子跟着动
    this.rulerX.w = w
    this.rulerY.w = w
    this.grid.points = []
    // 画网格线
    let lines = []

    // if (this.grid_show) {
    //   // 网格线
    //   for (let i = x_left; i < x_right; i++) {
    //     lines.push([
    //       [i * graph_precision, s_y_min],
    //       [i * graph_precision, s_y_max],
    //     ])
    //   }
    //   for (let i = y_left; i < y_right; i++) {
    //     lines.push([
    //       [s_x_min, i * graph_precision],
    //       [s_x_max, i * graph_precision],
    //     ])
    //   }
    // }
    this.grid.lines = lines
  }

  // 获取尺子X刻度和文字
  QedaCellAxis.prototype.getRulerX = function () {
    let i = 0
    // 返回值
    let res = {
      paths: [],
      texts: [],
    }
    let dx = this.dx
    let dy = this.rulerX.height
    // 缩放系数
    let scale = this.scale

    const left = this.grid.x_left
    const right = this.grid.x_right
    const s1 = this.grid.graph_precision * scale
    const s2 = this.grid.precision
    this.step = s1
    this.step_divid = s2

    // 判断单位刻度值是否过于大或过小，放置后面0个数太多，显示重合，需要减少数量
    // 获取线条和文字
    for (i = left; i < right; i++) {
      let label = BigNumber((i * s2 * this.unit_scale).toFixed(6)).toString()
      if (i % 10 == 0) {
        res.paths.push([
          [dx + i * s1, dy],
          [dx + i * s1, dy - 30],
        ])
        res.texts.push({ x: dx + i * s1 + 2, y: dy - 30, label: label, transform: '' })
      } else if (i % 5 == 0) {
        res.paths.push([
          [dx + i * s1, dy],
          [dx + i * s1, dy - 20],
        ])
        res.texts.push({ x: dx + i * s1 + 2, y: dy - 30, label: label, transform: '' })
      } else {
        res.paths.push([
          [dx + i * s1, dy],
          [dx + i * s1, dy - 10],
        ])
      }
    }
    this.rulerX.lines = res
    this.real_box.x_min = left * s2
    this.real_box.x_max = right * s2
  }

  // 获取尺子Y刻度和文字
  QedaCellAxis.prototype.getRulerY = function () {
    // 返回值
    let i = 0
    let res = {
      paths: [],
      texts: [],
    }
    // 偏移
    let dx = this.rulerY.width
    let dy = this.dy
    // 缩放系数
    let scale = this.scale
    // 边框
    const left = this.grid.y_left
    const right = this.grid.y_right
    const s1 = this.grid.graph_precision * scale
    // const s2 = this.unit / this.precision / scale
    const s2 = this.grid.precision

    // 判断单位刻度值是否过于大或过小，放置后面0个数太多，显示重合，需要减少数量
    // 获取线条和文字
    for (i = left; i < right; i++) {
      let label = BigNumber((i * s2 * this.unit_scale).toFixed(6)).toString()
      if (i % 10 == 0) {
        res.paths.push([
          [dx, dy - i * s1],
          [dx - 30, dy - i * s1],
        ])
        res.texts.push({ y: 40, x: -i * s1 + 2, label: label, translate: [dx, dy], rotate: (90 * Math.PI) / 180, lineY: dy - i * s1 })
      } else if (i % 5 == 0) {
        res.paths.push([
          [dx, dy - i * s1],
          [dx - 20, dy - i * s1],
        ])
        res.texts.push({ y: 40, x: -i * s1 + 2, label: label, translate: [dx, dy], rotate: (90 * Math.PI) / 180, lineY: dy - i * s1 })
      } else {
        res.paths.push([
          [dx, dy - i * s1],
          [dx - 10, dy - i * s1],
        ])
      }
    }
    this.rulerY.lines = res
    this.real_box.y_min = left * s2
    this.real_box.y_max = right * s2
  }

  QedaCellAxis.prototype.render = function () {
    this.updateValue()
    this.getMouseCrossLine()
    this.getCenterCrossLine()
    this.getGridLine()
    this.getRulerX()
    this.getRulerY()
  }

  /**
   * mouse move
   * @param {number}drag  is move the axis
   * @param {obj}e mouse event
   * @memberof QedaCellAxis
   */
  QedaCellAxis.prototype.onMouseMove = function (e) {
    this.click_point = null //清空点击记录
    // this.mouse_point = {
    //   x: (e.offsetX - this.dx) / this.scale,
    //   y: (this.dy - e.offsetY) / this.scale,
    // }
    // 鼠标按下移动坐标系
    if (this.middle_drag && !this.freeze) {
      this.dx = this.dx + e.offsetX - this.pos_x
      this.dy = this.dy + e.offsetY - this.pos_y
    }
    // 鼠标位置
    this.pos_x = e.offsetX
    this.pos_y = e.offsetY

    this.updateValue()
    this.getMouseCrossLine()
    if (this.middle_drag) {
      this.getCenterCrossLine()
      this.getGridLine()
    }
    this.getRulerX()
    this.getRulerY()
  }
  QedaCellAxis.prototype.onMouseDown = function (e) {
    this.click_point = {
      x: (e.offsetX - this.dx) / this.scale,
      y: (this.dy - e.offsetY) / this.scale,
    }
  }
  QedaCellAxis.prototype.onMouseUp = function (e) {
    this.click_point = null
  }
  QedaCellAxis.prototype.onMouseWheel = function (e, scale_center) {
    if (this.freeze || this.drag) {
      return
    }
    let s = 2

    if (scale_center) {
      this.pos_x = this.width / 2
      this.pos_y = this.height / 2
    }
    if (e.deltaY > 0) {
      if (this.scale < this.scale_min * s) {
        // this.scale = this.scale_min / s
        // this.scale = this.getScaleCloset(this.scale)
      } else {
        this.scale = this.scale / s
        // this.scale = this.getScaleCloset(this.scale)
        this.dx = this.dx + (this.pos_x - this.dx) / s
        this.dy = this.dy + (this.pos_y - this.dy) / s
      }
    } else {
      if (this.scale > this.scale_max / s) {
        // this.scale = this.scale_max * s
        // this.scale = this.getScaleCloset(this.scale)
      } else {
        this.scale = this.scale * s
        // this.scale = this.getScaleCloset(this.scale)

        this.dx = this.dx - (this.pos_x - this.dx)
        this.dy = this.dy - (this.pos_y - this.dy)
      }
    }

    this.updateValue()
    this.getMouseCrossLine()
    this.getCenterCrossLine()
    this.getGridLine()
    this.getRulerX()
    this.getRulerY()
  }
  QedaCellAxis.prototype.getDisplayMousePos = function () {
    let display_mous_pos = this.mouse_point
    if (this.hit_point) {
      display_mous_pos = this.hit_point
    }
    if (this.angledAdsorption && this.angled_dsorp_point) {
      display_mous_pos = this.angled_dsorp_point
    }
    if (this.angled_dsorp_special_point) {
      display_mous_pos = this.angled_dsorp_special_point
    }

    return display_mous_pos
  }
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = QedaCellAxis
  } else {
    window.QedaCellAxis = QedaCellAxis
  }
})()
