import { BOARDMODE, BOARDMODEMAP, Status } from '../const/board-status'
import { QedaCellLayout } from '../qeda-cell-layout'
import { updateGraphicPosition, updateGDSGraphic, updateRulerGraphic, getCircleData, getRectPoints, getAngle, KeyPoint, Ruler, mouseInsideCanvas } from '../utils/graphics-util'
import { validDrawLinePoints, validDrawCirclePoints, validCompleteDraw, validDrawGraphic, checkDrawing, isBasicGraphic, checkCanTrigger, cantrigger } from '../validator/validator.js'
import { moveBoard, adaptObj, adaptScreen, checkAll, removeCheckedObjs, undo, redo, exportGdsFile, moveObj, rotateObjs, recordHistory, copyOP, prePasteData } from '../utils/layout-function'
import bus from '@/components/common/bus'
import { transOutRef, transToRef } from '../utils/reference-util'
import { transDrawMsg } from '../../message_serve/cell-message'
import { updateCharcterCell } from '../utils/cell-generate'

export function KEY_DOWN(e) {
  if (!cantrigger(e)) return
  // this指向layout
  this.STATUS.keydown(e) //更新画板状态数据
  const key = e.key.toLowerCase()
  moveBoard(this, e)
  if (e.key === 'f') {
    //画板适应
    adaptObj(this)
    return
  }
  if (this.STATUS.viewMode) return
  if (e.ctrlKey) {
    //全选
    if (key === 'a') {
      e.preventDefault()
      checkAll(this)
      return
    }
    //撤销
    if (key === 'z') {
      undo(this)
      return
    }
    //恢复
    if (key === 'y') {
      redo(this)
      return
    }
    //复制
    if (key === 'c') {
      copyOP(this)
      return
    }
    //剪切
    if (key === 'x') {
      copyOP(this, true)
      return
    }
    //粘贴
    if (key == 'v') {
      prePasteData(this)
      return
    }
    //切割
    if (key == 'q') {
      this.switchMode(BOARDMODE.CUT_X)
      return
    }
  }
  if (e.shiftKey) {
    if (key == 'c') {
      //框选区域切割
      bus.$emit('areaExcision', null)
      return
    }
    if (key === 'm') {
      //合并
      bus.$emit('merge', null)
      return
    }
    // if (key === 'x') {
    //   //进入下层
    //   bus.$emit('enterlowerLevel', null)
    //   return
    // }
    // if (key === 'b') {
    //   //返回上层
    //   bus.$emit('backUpperLevel', null)
    //   return
    // }
  }
  if (key === 'r' && e.shiftKey && e.altKey) {
    //切换旋转模式
    this.switchMode(BOARDMODE.ROTATE)
    return
  }
  // if (key === 'x') {
  //   //编辑下层
  //   bus.$emit('editLowerLevel', null)
  // }
  if (key === 't') {
    //测试
    // bus.$emit('alignment', null) //对齐
    // bus.$emit('horizontalFlip', null) //水平翻转
    // bus.$emit('verticalFlip', null) //垂直翻转
    // bus.$emit('matrix', null) //阵列
    // bus.$emit('pointAlignment', null)
    // bus.$emit('lineAlignment', null)
    //bus.$emit('areaExcision', null)
    // bus.$emit('merge', null)
    // bus.$emit('boolean', null)
    // bus.$emit('breakUp', null)
    // bus.$emit('addDevice', null)
    // bus.$emit('enterlowerLevel', null)
    // bus.$emit('saveAsCell', null)
    if (this.CELL.js_obj.WS) {
      this.CELL.js_obj.WS.sendMsg()
    }
    this.CELL.js_obj.WS

    return
  }
  // if (key === 'y') {
  //   // bus.$emit('verticalFlip', null) //垂直翻转
  //   bus.$emit('backUpperLevel', null)
  //   // bus.$emit('backTop', null)
  // }
  // if (key == 'q') {
  //   let data = {
  //     value_backgroundColor: '#9c9c9c',
  //     value_gridColor: '#000000',
  //     gridVisible: '1',
  //     adsorp: false,
  //     adsorption: 10,
  //   }
  //   bus.$emit('boardDesign', data)
  //   return
  // }
  // if (key == 'a') {
  //   this.STAGE.frustumCullMergeData()
  //   return
  // }

  /********测试 ********/
  // if (key == '1') {
  //   this.switchMode(BOARDMODE.DRAW_LINE)
  //   return
  // }
  // if (key == '2') {
  //   this.switchMode(BOARDMODE.DRAW_CIRCLE)
  //   return
  // }
  // if (key == '3') {
  //   this.switchMode(BOARDMODE.DRAW_RECT)
  //   return
  // }
  // if (key == '4') {
  //   this.switchMode(BOARDMODE.DRAW_POLYGON)
  //   return
  // }
  // if (key == '5') {
  //   this.switchMode(BOARDMODE.CUT_AREA)
  //   return
  // }
  // if (key == '6') {
  //   this.switchMode(BOARDMODE.DRAW_3D_CUT_AREA)
  //   return
  // }
  // if (key == '7') {
  //   this.switchMode(BOARDMODE.DRAW_3D_RENDER_AREA)
  //   return
  // }
  // if (key == '8') {
  //   this.switchMode(BOARDMODE.CUT_X)

  //   return
  // }
  if (key == 'd') {
    if (this.STATUS.checked_objs.length) {
      this.switchMode(BOARDMODE.MOVE)
    }
    return
  }
  // if (key == 'l') {
  //   let input = 'New Label A,New Label B,New Label C'
  //   let params = { layer: 1, text: input, fontSize: 16, direction: 'o' }
  //   this.switchMode(BOARDMODE.DRAW_LABEL, params)
  //   return
  // }
  // if (key == 'p') {
  //   let input = 'Key Point 1,Key Point 2,Key Point 3'
  //   let params = { layer: 1, text: input, fontSize: 16, direction: 'o', useLabel: true }
  //   this.switchMode(BOARDMODE.DRAW_KP, params)
  //   return
  // }
  if (key == 'k') {
    this.switchMode(BOARDMODE.DRAW_RULER)
    return
  }

  if (key == 'j') {
    // bus.$emit('newLayer', false)
    // bus.$emit('delLayer', false)
    //bus.$emit('hideClick', false)
    // bus.$emit('allHide', false)
    // bus.$emit('changeLayerNumber', false)
    // bus.$emit('mergeLayer', false)
    // bus.$emit('layerChange', false)
  }

  if (key == 'escape') {
    this.resetLayout()
    e.target.blur()
    return
  }
  if (key == 'enter') {
    autoCompleteDraw(this)

    return
  }
  //标签，关键点，文本图形，点线对齐弹窗
  if (key === 'q' && e.shiftKey) {
    if (this.MODE == BOARDMODE.ALIGN_PICK_POINT) {
      bus.$emit('pointAlignmentDialog', true)
    } else if (this.MODE == BOARDMODE.ALIGN_PICK_LINE) {
      bus.$emit('lineAlignmentDialog', true)
    } else if (this.MODE == BOARDMODE.DRAW_LABEL) {
      bus.$emit('label', true)
    } else if (this.MODE == BOARDMODE.DRAW_KP) {
      bus.$emit('keyPoint', true)
    } else if (this.MODE == BOARDMODE.DRAW_TEXT_GRAPHIC) {
      bus.$emit('textGraphics', true)
    }
    return
  }

  //XY切割空格切换
  if (key === ' ') {
    if (this.MODE == BOARDMODE.CUT_X) {
      this.MODE = BOARDMODE.CUT_Y
      this.STAGE.updateMouseCross(false, true)

      return
    } else if (this.MODE == BOARDMODE.CUT_Y) {
      this.MODE = BOARDMODE.CUT_X
      this.STAGE.updateMouseCross(true, false)

      return
    }
  }
  if (key == 'delete') {
    removeCheckedObjs(this)
  }
  if (this.STATUS.drawingObj) {
    updateDrawObj(this, this.getMousePos())
  }
}

export function KEY_UP(e) {
  // this指向layout
  this.STATUS.keyup(e)
  //   updateDrawObj(this, this.getMousePos())
}

export function MOUSE_MOVE(e) {
  e.preventDefault()
  if (!e.movementX && !e.movementY) {
    //判断鼠标未移动，避免长按左键触发mousemove
    return
  }
  // this指向layout
  this.AXIS.onMouseMove(e)
  this.STAGE.mouseMove(this)
  let pos = this.getMousePos()
  //正在绘制图形
  updateDrawObj(this, pos)
  this.updateEditRefBox()
  this.runMouseMoveFunc(e, pos)
  this.updateEditRefCheckedLable()
}

export function MOUSE_DOWN(e) {
  if (!mouseInsideCanvas(this.canvas, e)) return
  // this指向layout
  this.STATUS.mouseDown(e)
  this.AXIS.onMouseDown(e)
  this.AXIS.setDrag(!this.STATUS.left_press, e.button)
  let pos = this.getMousePos()
  this.updateEditRefBox()
  if (!checkDrawing(this)) {
    initDrawObj(this, pos)
  } else {
    confirmDrawObj(this, pos)
    completeDraw(this)
  }
  this.runMouseDownFunc(e, pos)
}

export function MOUSE_UP(e) {
  if (!mouseInsideCanvas(this.canvas, e)) return
  // this指向layout
  this.AXIS.onMouseUp(e)
  this.AXIS.setDrag(false, null)
  if (this.STATUS.left_press) {
    this.STAGE.frustumCullMergeData()
  }
  this.transfromObjs() //判断对象旋转或缩放
  this.STATUS.mouseUp(e)
  let pos = this.getMousePos()
  confirmDrawObj(this, pos)
  this.updateEditRefBox()

  pickObjs(e, this, pos)
  this.runMouseUpFunc(e, pos)
  this.STAGE.tools.reset(e)
}

// 双击事件
export function DBCLICK(e) {
  if (!mouseInsideCanvas(this.canvas, e)) return
  if (this.STATUS.drag_count) return
  if (this.STATUS.checked_objs.length == 1) {
    const obj = this.STATUS.checked_objs[0]
    // alert(1)
    if (obj.$$?.ptrType.name == 'Reference*' && obj.cell?.text) {
      // alert(2)

      const textCell = obj.cell
      const text = textCell.text
      let inputBox = document.getElementById('textInputBox')
      inputBox.value = text.text
      inputBox.style.left = e.clientX + 'px'
      inputBox.style.top = e.clientY + 'px'
      inputBox.style.display = 'block'
      inputBox.focus()
      let that = this
      obj.need_update = false
      inputBox.onblur = function () {
        if (inputBox.value !== '' && inputBox.value !== text.text) {
          obj.need_update = true
          that.recordProperty('property', [obj], 'property')
          obj.need_update = false
          text.text = inputBox.value
          updateCharcterCell(textCell, that.fileInfo.fileId)
          //涉及更新其他cell数据 重渲染*//
          that.updateByMessageServe()
          that.STAGE.updateCheckedRefs(that)
          that.STAGE.updateRefArray(that)
        }
        inputBox.style.display = 'none'
      }
    }
  }

  // if (window.getSelection)
  //   //清除选中元素 防止影响mouseMove执行
  //   window.getSelection().removeAllRanges()
  // else if (document.selection) document.selection.empty()
  // this.render()
}

export function MOUSE_WHEEL(e, scale_center = false) {
  // this指向layout
  if (e.ctrlKey) return
  this.AXIS.onMouseWheel(e, scale_center)
  this.STAGE.mouseWheel(e, this)
}

export function RESIZE() {
  // this指向layout
  var canvas = document.getElementById(this.canvasId)
  if (!canvas) return
  // var stage = document.getElementById('canvas_container')
  var stage = document.getElementById('stage-container') //document.getElementsByClassName('box-container')[0] //document.getElementById('box-container')

  this.AXIS.width = stage.offsetWidth - 22
  this.AXIS.height = stage.offsetHeight - 22
  canvas.style.width = this.AXIS.width
  canvas.style.height = this.AXIS.height
  this.AXIS.rulerX.width = this.AXIS.width
  this.AXIS.rulerY.height = this.AXIS.height
  this.AXIS.render()
  this.STAGE.resize(this)
}

//初始化绘制对象
function initDrawObj(LAYOUT, globalPos) {
  let pos = transToRef(LAYOUT, globalPos)
  if (LAYOUT.STATUS.left_press) {
    if (LAYOUT.MODE == BOARDMODE.OVER_SEL || LAYOUT.MODE == BOARDMODE.PART_SEL) {
      LAYOUT.STATUS.updateLastMousePos(globalPos)
      let points = [globalPos]
      LAYOUT.STAGE.tools.selectBox.points = points
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_ADAPT_RECT) {
      LAYOUT.STATUS.updateLastMousePos(globalPos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.ROTATE) {
      LAYOUT.STATUS.updateLastMousePos(globalPos)
      return
    }

    if (LAYOUT.MODE == BOARDMODE.MOVE) {
      LAYOUT.STATUS.updateLastMousePos(globalPos)
      LAYOUT.STATUS.setMovePos(globalPos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.CUT_AREA) {
      LAYOUT.STATUS.updateLastMousePos(globalPos)
      return
    }

    if (LAYOUT.MODE == BOARDMODE.DRAW_LINE) {
      LAYOUT.addPath(pos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_CIRCLE) {
      LAYOUT.addEllipse(pos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_RECT) {
      LAYOUT.addRect(pos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_POLYGON) {
      LAYOUT.addPolygon(pos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_RULER) {
      LAYOUT.addRuler(pos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_3D_CUT_AREA) {
      LAYOUT.add3DCutArea(pos)
      return
    }

    if (LAYOUT.MODE == BOARDMODE.DRAW_3D_RENDER_AREA) {
      LAYOUT.add3DRenderArea(pos)
      return
    }
  }

  if (LAYOUT.STATUS.right_press) {
    if (LAYOUT.MODE == BOARDMODE.OVER_SEL || LAYOUT.MODE == BOARDMODE.PART_SEL) {
      LAYOUT.STATUS.updateLastMousePos(pos)
      return
    }
  }
}

//鼠标移动时更新绘制图形数据
function updateDrawObj(LAYOUT, globalPos) {
  let pos = transToRef(LAYOUT, globalPos)
  //工具绘制
  if (LAYOUT.MODE == BOARDMODE.OVER_SEL || LAYOUT.MODE == BOARDMODE.PART_SEL) {
    if (LAYOUT.STATUS.left_press) {
      let points = getRectPoints(LAYOUT.STATUS.lastMousePos, globalPos)
      //拖动物体不绘制框选矩形
      if (LAYOUT.STATUS.canDrag()) {
        points = []
      }
      LAYOUT.STAGE.tools.selectBox.points = points
      updateGraphicPosition(LAYOUT.STAGE.tools.selectBox, points)
      return
    }
    if (LAYOUT.STATUS.right_press) {
      let points = getRectPoints(LAYOUT.STATUS.lastMousePos, globalPos)
      LAYOUT.STAGE.tools.adaptBox.points = points
      updateGraphicPosition(LAYOUT.STAGE.tools.adaptBox, points)
      return
    }
  }

  if (LAYOUT.MODE == BOARDMODE.DRAW_ADAPT_RECT && LAYOUT.STATUS.left_press) {
    let points = getRectPoints(LAYOUT.STATUS.lastMousePos, pos)
    LAYOUT.STAGE.tools.adaptBox.points = points
    updateGraphicPosition(LAYOUT.STAGE.tools.adaptBox, points)
    return
  }
  if (LAYOUT.MODE == BOARDMODE.ROTATE) {
    if (!LAYOUT.STATUS.lastMousePos || !LAYOUT.STATUS.checked_objs.length) return
    if (LAYOUT.STATUS.drag_count == 0) {
      recordHistory(LAYOUT, 'shape', 'ROTATE')
    }
    LAYOUT.STATUS.drag_count++
    let center = LAYOUT.STATUS.checked_objs_center
    //选中单个器件且无矩阵属性绕器件原点旋转
    if (LAYOUT.STATUS.checked_objs.length == 1 && LAYOUT.STATUS.checked_objs[0].$$?.ptrType.name == 'Reference*' && !LAYOUT.STATUS.checked_objs[0].js_obj.expanRefs?.length) {
      center = LAYOUT.STATUS.checked_objs[0].origin
    }
    center = transOutRef(LAYOUT, center)

    let start = transOutRef(LAYOUT, LAYOUT.STATUS.lastMousePos)
    let end = globalPos
    let angle = -getAngle(
      {
        x: start[0] - center[0],
        y: start[1] - center[1],
      },
      {
        x: end[0] - center[0],
        y: end[1] - center[1],
      }
    )
    if (LAYOUT.AXIS.angledAdsorption) {
      angle = Math.round(angle / 45) * 45 //45度旋转吸附
    }

    const radian = (angle * Math.PI) / 180
    LAYOUT.STATUS.radian = radian

    rotateObjs(LAYOUT, radian)
    LAYOUT.STAGE.tools.updateAngleText(`${angle.toFixed(2)}°`)
    LAYOUT.STAGE.updateGlobalKP(LAYOUT)
    LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
    LAYOUT.STAGE.updateRefArray(LAYOUT)
    LAYOUT.STAGE.updateEditRefBorder(LAYOUT)
    return
  }

  if (LAYOUT.MODE == BOARDMODE.MOVE) {
    if (!LAYOUT.STATUS.movePos.length || !LAYOUT.STATUS.checked_objs.length) return
    LAYOUT.STATUS.setMovePos(pos)

    // let start = LAYOUT.STATUS.movePos[0]
    // let end = LAYOUT.STATUS.movePos[1]
    if (LAYOUT.STATUS.drag_count == 0) {
      if (LAYOUT.STATUS.checked_objs.filter(obj => obj.js_obj.STATE.partChecked).length) {
        recordHistory(LAYOUT, 'shape', 'MOVE')
      } else {
        recordHistory(LAYOUT, 'transform', 'MOVE')
      }
    }

    LAYOUT.STATUS.drag_count++
    let objs = LAYOUT.STATUS.checked_objs
    for (let i = 0; i < objs.length; i++) {
      const obj = objs[i]
      moveObj(LAYOUT, obj, pos)
    }
    LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
    LAYOUT.STAGE.updateGlobalKP(LAYOUT)
    LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
    LAYOUT.STAGE.updateRefArray(LAYOUT)
    LAYOUT.STAGE.updateEditRefBorder(LAYOUT)
    return
  }
  if (LAYOUT.MODE == BOARDMODE.CUT_AREA) {
    if (LAYOUT.STATUS.lastMousePos) {
      let points = getRectPoints(LAYOUT.STATUS.lastMousePos, globalPos)
      LAYOUT.STAGE.tools.cutBox2D.points = points
      updateGraphicPosition(LAYOUT.STAGE.tools.cutBox2D, points)
    }
    return
  }

  //图形绘制
  let drawingObj = LAYOUT.STATUS.drawingObj
  if (drawingObj) {
    if (LAYOUT.MODE == BOARDMODE.DRAW_LINE) {
      updateGDSGraphic(drawingObj, pos, LAYOUT)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_CIRCLE) {
      updateGDSGraphic(drawingObj, pos, LAYOUT)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_RECT) {
      updateGDSGraphic(drawingObj, pos, LAYOUT)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_POLYGON) {
      updateGDSGraphic(drawingObj, pos, LAYOUT)
      return
    }

    if (LAYOUT.MODE == BOARDMODE.DRAW_3D_CUT_AREA) {
      // drawingObj.points = getRectPoints(LAYOUT.STATUS.lastMousePos, pos, LAYOUT.STATUS.shift_press)
      // updateGraphicPosition(drawingObj, drawingObj.points)
      updateGDSGraphic(drawingObj, pos, LAYOUT)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_3D_RENDER_AREA) {
      // updateGraphicPosition(drawingObj, drawingObj.points, pos)
      updateGDSGraphic(drawingObj, pos, LAYOUT)
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_LABEL) {
      updateGDSGraphic(drawingObj, pos, LAYOUT)
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_KP) {
      updateGDSGraphic(drawingObj, pos, LAYOUT)
      LAYOUT.STAGE.updateGlobalKP(LAYOUT)
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_RULER) {
      updateRulerGraphic(drawingObj, LAYOUT.AXIS, LAYOUT.STATUS.lastMousePos, pos, LAYOUT)

      //   updateGraphicPosition(drawingObj, drawingObj.points, pos)
      return
    }
  }
}

//绘制对象 确认绘制顶点
function confirmDrawObj(LAYOUT, globalPos) {
  let pos = transToRef(LAYOUT, globalPos)
  let obj = LAYOUT.STATUS.drawingObj
  //左键点击确认绘制
  if (LAYOUT.STATUS.left_press) {
    if (LAYOUT.MODE == BOARDMODE.DRAW_LINE) {
      if (!validDrawLinePoints(pos, LAYOUT.STATUS.lastMousePos, obj.js_obj.points)) return
      obj.js_obj.points.push(pos)
      updateGDSGraphic(obj, null, LAYOUT)
      LAYOUT.STATUS.updateLastMousePos(pos)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_POLYGON) {
      if (!validDrawLinePoints(pos, LAYOUT.STATUS.lastMousePos, obj.js_obj.points)) return
      obj.js_obj.points.push(pos)
      updateGDSGraphic(obj, null, LAYOUT)
      LAYOUT.STATUS.updateLastMousePos(pos)
      return
    }

    //两点确认的图形
    if (LAYOUT.MODE == BOARDMODE.DRAW_CIRCLE) {
      updateGDSGraphic(obj, pos, LAYOUT)
      completeDraw(LAYOUT, !obj.points.length)
      return
    }
    if (LAYOUT.MODE == BOARDMODE.DRAW_RECT) {
      updateGDSGraphic(obj, pos, LAYOUT)
      completeDraw(LAYOUT, !obj.points.length)
      return
    }

    if (LAYOUT.MODE == BOARDMODE.cutBox3D) {
      // obj.points = getRectPoints(LAYOUT.STATUS.lastMousePos, pos)
      // completeDraw(LAYOUT, !obj.points.length)
      updateGDSGraphic(obj, pos, LAYOUT)
      completeDraw(LAYOUT, !obj.points.length)
      return
    }

    if (LAYOUT.MODE == BOARDMODE.DRAW_RULER) {
      updateRulerGraphic(obj, LAYOUT.AXIS, LAYOUT.STATUS.lastMousePos, pos)
      completeDraw(LAYOUT, !obj.points.length)
      return
    }

    if (LAYOUT.MODE == BOARDMODE.DRAW_3D_RENDER_AREA) {
      // if (!validDrawLinePoints(pos, LAYOUT.STATUS.lastMousePos, obj.points)) return
      // obj.points.push(pos)
      // updateGraphicPosition(obj, obj.points)
      // LAYOUT.STATUS.updateLastMousePos(pos)
      if (!validDrawLinePoints(pos, LAYOUT.STATUS.lastMousePos, obj.js_obj.points)) return
      obj.js_obj.points.push(pos)
      updateGDSGraphic(obj, null, LAYOUT)
      LAYOUT.STATUS.updateLastMousePos(pos)
      return
    }
  }
  if (LAYOUT.STATUS.left_press || LAYOUT.STATUS.right_press) {
    if (LAYOUT.MODE == BOARDMODE.CUT_AREA) {
      if (LAYOUT.STATUS.lastMousePos) {
        let points = getRectPoints(LAYOUT.STATUS.lastMousePos, globalPos)
        LAYOUT.STAGE.tools.cutBox2D.points = points
        updateGraphicPosition(LAYOUT.STAGE.tools.cutBox2D, points)
        LAYOUT.STATUS.updateLastMousePos(null)
        // completeDraw(LAYOUT, !obj.points.length)
      }
      return
    }
  }
}

//完成图形绘制
export function completeDraw(LAYOUT, remove = false) {
  if (LAYOUT.STATUS.drawingObj) {
    if (validCompleteDraw(LAYOUT) || remove) {
      LAYOUT.STATUS.updateLastMousePos()
      let obj = LAYOUT.STATUS.drawingObj
      if (remove || !validDrawGraphic(LAYOUT)) {
        //场景中删除图形
        // LAYOUT.removeObj(obj)
        LAYOUT.deleteObj(LAYOUT.CELL, obj, true)
      } else {
        //基础图形
        if (isBasicGraphic(LAYOUT.MODE)) {
          updateGDSGraphic(obj, null, LAYOUT)
        }

        // const action = [{ action: 'add', cell: LAYOUT.CELL, objs: [obj], remark: BOARDMODEMAP[LAYOUT.MODE] }]
        // LAYOUT.HISTORY.record(action)
        const history = {
          func: BOARDMODEMAP[LAYOUT.MODE],
          actions: [{ action: 'add', objs: [obj] }],
        }
        LAYOUT.HISTORY.record(history)
        LAYOUT.sendMessage(history)
      }
      LAYOUT.STAGE.tools.removePastChild()
      LAYOUT.STATUS.drawingObj = null
    }
  }
}

//选择对象
function pickObjs(e, LAYOUT, pos) {
  let l_release = e.button == 0 //左键释放
  let m_release = e.button == 1 //中键释放
  let r_release = e.button == 2 //右键释放
  if (l_release) {
    //整体选择
    if (LAYOUT.MODE == BOARDMODE.OVER_SEL) {
      return
    }
    //部分选择
    if (LAYOUT.MODE == BOARDMODE.PART_SEL) {
      return
    }

    //点对齐选择点
    if (LAYOUT.MODE == BOARDMODE.ALIGN_PICK_POINT) {
      let hit = [0, 0]
      LAYOUT.STATUS.updatePickPoints(hit)
      return
    }

    //线对齐选择线
    if (LAYOUT.MODE == BOARDMODE.ALIGN_PICK_LINE) {
      let hit = [
        [0, 0],
        [0, 0],
      ]
      LAYOUT.STATUS.updatePickLines(hit)
      return
    }
  }
}

//自动绘制结束
function autoCompleteDraw(LAYOUT) {
  if (LAYOUT.MODE == BOARDMODE.DRAW_LINE || LAYOUT.MODE == BOARDMODE.DRAW_POLYGON || LAYOUT.MODE == BOARDMODE.DRAW_3D_RENDER_AREA) {
    LAYOUT.STATUS.right_press = true
  } else {
    LAYOUT.STATUS.left_press = true
    confirmDrawObj(LAYOUT, LAYOUT.getMousePos())
  }
  completeDraw(LAYOUT)
  LAYOUT.STATUS.left_press = false
  LAYOUT.STATUS.right_press = false
}
