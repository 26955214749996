var QGdstk = null
var QGeosJs = require('./geos')

const { PriorityQueue } = require('@datastructures-js/priority-queue')
var AutoToolLibs = require('./libs')

;(function () {
  function AutoToolWBPad(library, cell) {
    QGdstk = window.QGdstk
    this.libs = new AutoToolLibs()
    this.library = library
    this.cell = cell
    this.conf = {
      base_bounds: [
        [0, 0],
        [33000, 33000],
      ],
      a: 573,
      b: 973,
      c: 600,
      text: {
        x: 50,
        y: 40,
        size: 50,
        layer: 1001,
      },
      size: {
        A: 456,
        A1: 250,
        B: 323,
        B1: 250,
        B2: 100,
        C: 8,
        C1: 4,
        layer: 1,
      },

      l1: 8,
      l2: 4,
      l3: 250,
      l4: 456,
      w1: 120,
      w2: 250,
      w3: 73,
      layer: 5,
      datatype: 0,
    }
  }
  AutoToolWBPad.prototype.setConf = function (conf) {
    this.conf = conf
  }

  AutoToolWBPad.prototype.run = function (conf) {
    this.conf = conf
    if (!this.cell) {
      return
    }

    let result = {
      add_paths: [],
      add_texts: [],
      remove_paths: [],
      add_cells: [],
      add_references: [],
    }

    let wbpad_cell = this.WBPadCell(this.conf.size.layer, this.conf.size.datatype, this.conf.size.A, this.conf.size.A1, this.conf.size.B, this.conf.size.B1, this.conf.size.B2, this.conf.size.C, this.conf.size.C1)
    let wbpad_cell_k = this.libs.GdstkCell2KernelCell(wbpad_cell, this.conf.fileLayerList)
    let box = wbpad_cell.bounding_box()
    let heigth = box[1][1] - box[0][1]
    let d1 = this.conf.a + heigth
    let d2 = this.conf.b + heigth

    let valid_paths = []
    this.cell.flexpaths.forEach(element => {
      if (element.width[0] === 0) {
        valid_paths.push(element)
      }
    })

    // let res = this.getPos(this.cell.flexpaths, this.conf.base_bounds, this.conf.c, d1, d2)
    let res = this.getPos(valid_paths, this.conf.base_bounds, this.conf.c, d1, d2)
    if (!res) return

    // let references = []
    res.items.forEach(t => {
      // let ref = new window.Kernel.Reference(wbpad_cell_k, t.pos, t.angle - Math.PI / 2, 1, false, 1, 1, [0, 0])
      let ref = new window.Kernel.Reference()
      ref.cell = wbpad_cell_k
      ref.origin = t.pos
      ref.rotation = t.angle - Math.PI / 2

      result.add_references.push(ref)
    })
    result.add_cells.push(wbpad_cell_k)

    let drc_layer = this.conf.fileLayerDict[1002]
    if (drc_layer) {
      let wbpad_cell_drc = this.WBPadCell(1002, 0, this.conf.size.A, this.conf.size.A1, this.conf.size.B, this.conf.size.B1, this.conf.size.B2, this.conf.size.C, this.conf.size.C1)
      let wbpad_cell_drc_k = this.libs.GdstkCell2KernelCell(wbpad_cell_drc, this.conf.fileLayerList)
      wbpad_cell_drc_k.name = 'WBPad_DRC'
      res.items.forEach(t => {
        // let ref_drc = new window.Kernel.Reference(wbpad_cell_drc_k, t.pos, t.angle - Math.PI / 2, 1, false, 1, 1, [0, 0])
        let ref_drc = new window.Kernel.Reference()
        ref_drc.cell = wbpad_cell_drc_k
        ref_drc.origin = t.pos
        ref_drc.rotation = t.angle - Math.PI / 2
        result.add_references.push(ref_drc)
      })
      result.add_cells.push(wbpad_cell_drc_k)
    }
    result.add_paths = res.add_paths
    result.add_texts = res.add_texts
    result.remove_paths = res.remove_paths

    return result
  }

  AutoToolWBPad.prototype.getPos = function (paths = [], base_bounds = [], border_margin = 600, d1 = 493, d2 = 936) {
    function priority_queue_x() {
      const queue = new PriorityQueue((a, b) => {
        return a['pos'][0] < b['pos'][0] ? -1 : 1
      })
      return queue
    }
    function priority_queue_y() {
      const queue = new PriorityQueue((a, b) => {
        return a['pos'][1] < b['pos'][1] ? -1 : 1
      })
      return queue
    }
    function check(point, border_line, direction, path_idx, path_point_idx) {
      // let len = ln.length
      // if (len < 2) {
      //   return false
      // }

      angle = 0
      if (direction == 'left') angle = Math.PI
      if (direction == 'right') angle = 0
      if (direction == 'top') angle = Math.PI / 2
      if (direction == 'bottom') angle = -Math.PI / 2

      let s_point = new QGeosJs('Point', point)
      let d = s_point.distance(border_line)
      if (d < border_margin) {
        return { pos: point, angle: angle, path_idx: path_idx, path_point_idx: path_point_idx }
      }

      return false
    }

    let remove_paths = []
    let add_paths = []

    // 获取lines
    let lines_ends = {}
    let lines = []
    for (let i = 0, len = paths.length; i < len; i++) {
      let path = paths[i]
      // lines.push(path.spine())
      // let spine = path.spine()
      lines.push(path.points)
      let spine = path.points

      lines_ends[spine[0]] = { path_id: i, path_point_id: 0, point: spine[0] }
      lines_ends[spine[spine.length - 1]] = { path_id: i, path_point_id: spine.length - 1, point: spine[spine.length - 1] }

      remove_paths.push(path)
      let path_copy = path.copy()
      add_paths.push(path_copy)
    }
    // let geos_multiline = new QGeosJs('MultiLineString', lines)
    // let bounds = geos_multiline.bounds()
    let bounds = this.cell.bounding_box()
    if (!bounds) return
    let min_x = bounds[0][0]
    let min_y = bounds[0][1]
    let max_x = bounds[1][0]
    let max_y = bounds[1][1]
    let bound_line_left = new QGeosJs('LineString', [
      [min_x, min_y],
      [min_x, max_y],
    ])
    let bound_line_top = new QGeosJs('LineString', [
      [min_x, max_y],
      [max_x, max_y],
    ])
    let bound_line_right = new QGeosJs('LineString', [
      [max_x, max_y],
      [max_x, min_y],
    ])
    let bound_line_bottom = new QGeosJs('LineString', [
      [min_x, min_y],
      [max_x, min_y],
    ])
    // 创建优先队列
    let top_queue = priority_queue_x()
    let bottom_queue = priority_queue_x()
    let left_queue = priority_queue_y()
    let right_queue = priority_queue_y()

    for (let k in lines_ends) {
      let item = lines_ends[k]
      let tl = check(item.point, bound_line_left, 'left', item.path_id, item.path_point_id)
      if (tl) {
        left_queue.push(tl)
        continue
      }

      let tr = check(item.point, bound_line_right, 'right', item.path_id, item.path_point_id)
      if (tr) {
        right_queue.push(tr)
        continue
      }

      let tt = check(item.point, bound_line_top, 'top', item.path_id, item.path_point_id)
      if (tt) {
        top_queue.push(tt)
        continue
      }

      let tb = check(item.point, bound_line_bottom, 'bottom', item.path_id, item.path_point_id)
      if (tb) {
        bottom_queue.push(tb)
        continue
      }
    }
    let left_res = []
    let right_res = []
    let top_res = []
    let bottom_res = []
    left_queue = left_queue.toArray()
    right_queue = right_queue.toArray()
    top_queue = top_queue.toArray()
    bottom_queue = bottom_queue.toArray()
    // 左侧交错排列
    for (let i = 0; i < left_queue.length; i++) {
      let tl = left_queue[i]
      if (i % 2 == 0) {
        tl['pos'][0] = base_bounds[0][0] + d1
      } else {
        tl['pos'][0] = base_bounds[0][0] + d2
      }
      left_res.push(tl)
    }
    for (let i = 0; i < right_queue.length; i++) {
      let tr = right_queue[i]
      if (i % 2 == 0) {
        tr['pos'][0] = base_bounds[1][0] - d1
      } else {
        tr['pos'][0] = base_bounds[1][0] - d2
      }
      right_res.push(tr)
    }
    for (let i = 0; i < top_queue.length; i++) {
      let tt = top_queue[i]
      if (i % 2 == 0) {
        tt['pos'][1] = base_bounds[1][1] - d1
      } else {
        tt['pos'][1] = base_bounds[1][1] - d2
      }
      top_res.push(tt)
    }
    for (let i = 0; i < bottom_queue.length; i++) {
      let tb = bottom_queue[i]
      if (i % 2 == 0) {
        tb['pos'][1] = base_bounds[0][1] + d1
      } else {
        tb['pos'][1] = base_bounds[0][1] + d2
      }
      bottom_res.push(tb)
    }

    let res = {
      left: left_res,
      right: right_res,
      top: top_res,
      bottom: bottom_res,
    }

    let ppp = []
    for (let key in res) {
      let item = res[key]
      for (let i = 0, len = item.length; i < len; i++) {
        let t = item[i]

        // add_paths[t.path_idx].points[t.path_point_idx] = t.pos
        add_paths[t.path_idx].points_vec.set(t.path_point_idx, t.pos)
        ppp.push(t)
      }
    }

    // wbpad线标
    let texts = []
    let text_idx = 1
    let dx = this.conf.text.x
    let dy = this.conf.text.y

    left_res.forEach(t => {
      texts.push({ origin: [t.pos[0] + dx, t.pos[1] + dy], rotation: 0, text: text_idx, anchor: 'sw', font: '宋体', fontSize: this.conf.text.size, layer: this.conf.text.layer, datatype: 0 })
      text_idx += 1
    })
    right_res.forEach(t => {
      texts.push({ origin: [t.pos[0] - dx, t.pos[1] - dy], rotation: 180, text: text_idx, anchor: 'sw', font: '宋体', fontSize: this.conf.text.size, layer: this.conf.text.layer, datatype: 0 })
      text_idx += 1
    })
    top_res.forEach(t => {
      texts.push({ origin: [t.pos[0] + dy, t.pos[1] - dx], rotation: -90, text: text_idx, anchor: 'sw', font: '宋体', fontSize: this.conf.text.size, layer: this.conf.text.layer, datatype: 0 })
      text_idx += 1
    })
    bottom_res.forEach(t => {
      texts.push({ origin: [t.pos[0] - dy, t.pos[1] + dx], rotation: 90, text: text_idx, anchor: 'sw', font: '宋体', fontSize: this.conf.text.size, layer: this.conf.text.layer, datatype: 0 })
      text_idx += 1
    })

    return {
      items: ppp,
      add_texts: texts,
      add_paths: add_paths,
      remove_paths: remove_paths,
    }
  }

  AutoToolWBPad.prototype.getPos_bk = function (paths = [], base_bounds = [], border_margin = 600, d1 = 493, d2 = 936) {
    function priority_queue_x() {
      const queue = new PriorityQueue((a, b) => {
        return a['pos'][0] < b['pos'][0] ? -1 : 1
      })
      return queue
    }
    function priority_queue_y() {
      const queue = new PriorityQueue((a, b) => {
        return a['pos'][1] < b['pos'][1] ? -1 : 1
      })
      return queue
    }
    function check(ln, border_line, direction, path_idx) {
      let len = ln.length
      if (len < 2) {
        return false
      }

      angle = 0
      if (direction == 'left') angle = Math.PI
      if (direction == 'right') angle = 0
      if (direction == 'top') angle = Math.PI / 2
      if (direction == 'bottom') angle = -Math.PI / 2

      // 判断起点
      let head = ln[0]
      let head_point = new QGeosJs('Point', head)
      let head_d = head_point.distance(border_line)
      if (head_d < border_margin) {
        return { pos: head, angle: angle, path_idx: path_idx, path_point_idx: 0 }
      }

      // 判断尾点
      let tail = ln[len - 1]
      let tail_point = new QGeosJs('Point', tail)
      let tail_d = tail_point.distance(border_line)

      if (tail_d < border_margin) {
        return { pos: tail, angle: angle, path_idx: path_idx, path_point_idx: len - 1 }
      }

      return false
    }

    let remove_paths = []
    let add_paths = []

    // 获取lines
    let lines = []
    for (let i = 0, len = paths.length; i < len; i++) {
      let path = paths[i]
      lines.push(path.spine())

      remove_paths.push(path)
      let path_copy = path.copy()
      add_paths.push(path_copy)
    }
    // let geos_multiline = new QGeosJs('MultiLineString', lines)
    // let bounds = geos_multiline.bounds()
    let bounds = this.cell.bounding_box()
    let min_x = bounds[0][0]
    let min_y = bounds[0][1]
    let max_x = bounds[1][0]
    let max_y = bounds[1][1]
    let bound_line_left = new QGeosJs('LineString', [
      [min_x, min_y],
      [min_x, max_y],
    ])
    let bound_line_top = new QGeosJs('LineString', [
      [min_x, max_y],
      [max_x, max_y],
    ])
    let bound_line_right = new QGeosJs('LineString', [
      [max_x, max_y],
      [max_x, min_y],
    ])
    let bound_line_bottom = new QGeosJs('LineString', [
      [min_x, min_y],
      [max_x, min_y],
    ])
    // 创建优先队列
    let top_queue = priority_queue_x()
    let bottom_queue = priority_queue_x()
    let left_queue = priority_queue_y()
    let right_queue = priority_queue_y()

    for (let i = 0, len = lines.length; i < len; i++) {
      let ln = lines[i]
      let tl = check(ln, bound_line_left, 'left', i)
      if (tl) {
        left_queue.push(tl)
        continue
      }

      let tr = check(ln, bound_line_right, 'right', i)
      if (tr) {
        right_queue.push(tr)
        continue
      }

      let tt = check(ln, bound_line_top, 'top', i)
      if (tt) {
        top_queue.push(tt)
        continue
      }

      let tb = check(ln, bound_line_bottom, 'bottom', i)
      if (tb) {
        bottom_queue.push(tb)
        continue
      }
    }
    let left_res = []
    let right_res = []
    let top_res = []
    let bottom_res = []
    left_queue = left_queue.toArray()
    right_queue = right_queue.toArray()
    top_queue = top_queue.toArray()
    bottom_queue = bottom_queue.toArray()
    // 左侧交错排列
    for (let i = 0; i < left_queue.length; i++) {
      let tl = left_queue[i]
      if (i % 2 == 0) {
        tl['pos'][0] = base_bounds[0][0] + d1
      } else {
        tl['pos'][0] = base_bounds[0][0] + d2
      }
      left_res.push(tl)
    }
    for (let i = 0; i < right_queue.length; i++) {
      let tr = right_queue[i]
      if (i % 2 == 0) {
        tr['pos'][0] = base_bounds[1][0] - d1
      } else {
        tr['pos'][0] = base_bounds[1][0] - d2
      }
      right_res.push(tr)
    }
    for (let i = 0; i < top_queue.length; i++) {
      let tt = top_queue[i]
      if (i % 2 == 0) {
        tt['pos'][1] = base_bounds[1][1] - d1
      } else {
        tt['pos'][1] = base_bounds[1][1] - d2
      }
      top_res.push(tt)
    }
    for (let i = 0; i < bottom_queue.length; i++) {
      let tb = bottom_queue[i]
      if (i % 2 == 0) {
        tb['pos'][1] = base_bounds[0][1] + d1
      } else {
        tb['pos'][1] = base_bounds[0][1] + d2
      }
      bottom_res.push(tb)
    }

    let res = {
      left: left_res,
      right: right_res,
      top: top_res,
      bottom: bottom_res,
    }

    let ppp = []
    for (let key in res) {
      let item = res[key]
      for (let i = 0, len = item.length; i < len; i++) {
        let t = item[i]
        add_paths[t.path_idx].points[t.path_point_idx] = t.pos
        ppp.push(t)
      }
    }
    return {
      items: ppp,
      add_paths: add_paths,
      remove_paths: remove_paths,
    }
  }
  AutoToolWBPad.prototype.WBPadCell = function (layer = 1, datatype = 0, A = 456, A1 = 250, B = 323, B1 = 250, B2 = 100, C = 8, C1 = 4) {
    // path
    var path = new QGdstk.Curve([-C1 / 2, 0])
    path.commands(['l', -(C - C1) / 2, 0, 'l', -(A - C) / 2, B2, 'l', 0, B, 'l', A, 0, 'l', 0, -B, 'l', -(A - C) / 2, -B2, 'l', -(C - C1) / 2, 0, 'l', (A1 - C1) / 2, B2, 'l', 0, B1, 'l', -A1, 0, 'l', 0, -B1])

    var pad = new QGdstk.Polygon(path.points(), layer, datatype)
    var O1 = new QGdstk.Label('O1', [0, 0], (anchor = 's'), (rotation = 0), (magnification = 1), (x_reflection = false), (layer = 0), (texttype = 0))
    var O = new QGdstk.Label('O', [0, 0], (anchor = 'n'), (rotation = 0), (magnification = 1), (x_reflection = false), (layer = 0), (texttype = 0))
    // cell
    var cell = new QGdstk.Cell('WBPad')
    cell.add([pad, O1])
    cell.add(O)
    return cell
  }

  AutoToolWBPad.prototype.getWbpadCell = function (central = [0, 0], angle = 0, layer = 0, datatype = 0, l1 = 8, l2 = 4, l3 = 250, l4 = 456, w1 = 120, w2 = 250, w3 = 73) {
    // 多边形wbpad, 对应有12个点，顺时针由外到内取点
    let x = central[0]
    let y = central[1]
    let points = [
      [x, y + l2 / 2.0],
      [x, y + l1 / 2.0],
      [x + w1, y + l4 / 2.0],
      [x + w1 + w2 + w3, y + l4 / 2.0],
      [x + w1 + w2 + w3, y - l4 / 2.0],
      [x + w1, y - l4 / 2.0],
      [x, y - l1 / 2.0],
      [x, y - l2 / 2.0],
      [x + w1, y - l3 / 2.0],
      [x + w1 + w2, y - l3 / 2.0],
      [x + w1 + w2, y + l3 / 2.0],
      [x + w1, y + l3 / 2.0],
    ]
    let wbpad = new QGdstk.Polygon((points = points), (layer = layer), (datatype = datatype))
    // # 按中心点选择角度 angle
    wbpad.rotate(angle, central)
    let cell = new QGdstk.Cell('wbpad')
    cell.add([wbpad])
    return cell
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolWBPad
  } else {
    window.AutoToolWBPad = AutoToolWBPad
  }
})()
