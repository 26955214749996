import { deepClone } from '../../utils'
import { getCtLabelText } from '../utils/graphics-util'
import { getScreenAABB, updateObjMat } from '../utils/graphics-util'
import { transOutRef, transOutRefPoints, transToRef } from '../utils/reference-util'
//画板状态
export const BOARDMODE = {
  OVER_SEL: 0, //整体选择
  PART_SEL: 1, //部分选择
  DRAW_LINE: 2, //绘制线
  DRAW_CIRCLE: 3, //绘制圆
  DRAW_RECT: 4, //绘制矩形
  DRAW_POLYGON: 5, //绘制多边形
  DRAW_LABEL: 6, //绘制标签
  DRAW_KP: 7, //绘制关键点
  DRAW_RULER: 8, //绘制尺子
  DRAW_3D_CUT_AREA: 9, //绘制3d剖面区域
  DRAW_3D_RENDER_AREA: 10, //绘制3d渲染区域
  ROTATE: 11, //旋转
  PRE_ADD_CELL: 12, //预添加器件
  PRE_PASTE: 13, //预粘贴数据
  CUT_AREA: 14, //区域切割
  CUT_X: 15, //x垂直切割
  CUT_Y: 16, //y水平切割
  ALIGN_PICK_POINT: 17, //点对齐 选择点
  ALIGN_PICK_LINE: 18, //线对齐 选择线
  Z_LINE: 19, //z线模式
  DRAW_ADAPT_RECT: 20, //绘制自适应矩形
  MOVE: 21, //点击移动
  DRAW_TEXT_GRAPHIC: 22, //绘制文本图形
  EDIT_LOWER_CELL: 23, //编辑下层器件
  JJSELECT: 24,
}

export const BOARDMODEMAP = {
  2: 'DRAW_LINE', //绘制线
  3: 'DRAW_CIRCLE', //绘制圆
  4: 'DRAW_RECT', //绘制矩形
  5: 'DRAW_POLYGON', //绘制多边形
  6: 'DRAW_LABEL', //绘制标签
  7: 'DRAW_KP', //绘制关键点
  8: 'DRAW_RULER', //绘制尺子
  9: 'DRAW_3D_CUT_AREA', //绘制3d剖面区域
  10: 'DRAW_3D_RENDER_AREA', //绘制3d渲染区域
  17: 'ALIGN_PICK_POINT', //点对齐 选择点
  18: 'ALIGN_PICK_LINE', //线对齐 选择线
  19: 'Z_LINE', //z线选择模式
}

//特殊图形
export const SPECIALGRAPHIC = {
  CUTAREA3D: '1',
  RENDERAREA: '2',
}
//画板状态容器
export class Status {
  constructor() {
    this.active = true //画板激活状态
    this.viewMode = false //只读模式
    this.layer = null //当前选中图层
    this.isStretch = false //拉伸角度锁定
    this.left_press = false //鼠标左键
    this.middle_press = false //鼠标中键
    this.right_press = false //鼠标右键
    this.shift_press = false //shift键
    this.ctrl_press = false //ctrl键
    this.lastMousePos = null //上一个确认鼠标位置
    this.angle = 0 //旋转角度
    this.movePos = [] //点击移动
    this.loopText = null //循环文本
    this.ctText = [] //连续绘制文本数组
    this.textLayer = null //文本自定义图层
    this.font_size = 16 //文本大小
    this.direction = 'o' //参考的位置
    this.useLabel = false //关键点生成标签
    this.characterRotation = 0 //文本图形旋转角度
    this.pickPoints = [] //点对齐选择的两个点
    this.pickLines = [] //线对齐选择的两条线
    this.drawingObj = null //绘制对象
    /************* 整体选择 *************/
    this.preCheckObj = null //预选中对象
    this.checked_objs = [] //选中对象
    this.checked_objs_aabb = null //选中对象包围盒
    this.checked_objs_center = null //选中对象中心
    this.hited_objs_id = [] //命中图形index列表
    this.cover_index = -1 //当前选中图形index 根据列表循环切换
    /************* 部分选择 *************/
    this.preCheckPoint = null //部分选择点
    this.preCheckLine = null //部分选择线
    this.checkedPoints = [] //选中点
    this.checkedLine = [] //选中线
    /**************操作标志 ********/
    this.drag_count = 0 //开始拖动
    this.checked_objs_hited = false //命中选中目标之一
    this.trans = [0, 0] //移动位移
    this.radian = 0 //旋转弧度
    /**************复制数据操作 ********/
    this.prePasteData = null //预粘贴数据
    this.preCutObjs = null //预剪切对象
    this.isCut = false //是否剪切
    this.fromExternalFile = false //来自外部文件
    this.crossCopyData = null
    this.crossBoardCopy = false
    /**************点对齐线对齐 ********/
    this.pointsAlign = [] //点对齐的两个点
    this.linesAlign = [] //线对齐的两条线
    this.pointOffset = [0, 0] //偏移量
    /************* 器件操作相关 ********/
    this.affectCellPtrs = []
    this.repetitionCellsPtrs = []
    this.preAddRef = null //器件调用的引用
    this.preAddRefExist = false //调用的器件已经在文件中存在
    this.addNewCells = null //器件预调用时新增的cell
    this.textCell = null //连续添加的文本图形
    /**************编辑下层 ********/
    this.isTop = true //是否是顶层cell
  }

  //更新上一次确认的坐标点
  updateLastMousePos(pos = null) {
    this.lastMousePos = pos
  }

  updatePickPoints(point) {
    this.pickPoints.push(point)
  }

  updatePickLines(line) {
    this.pickLines.push(line)
  }

  setMovePos(pos) {
    if (this.movePos.length == 2) {
      this.movePos[1] = pos
    } else {
      this.movePos.push(pos)
    }
  }

  //设置连续放置文本
  setLoopText(input) {
    this.loopText = input
    this.ctText = input.split(',')
  }

  //获取下一个连续放置文本
  getCtText() {
    let text = getCtLabelText(this.ctText)
    if (text == null) {
      this.ctText = this.loopText.split(',')
      text = getCtLabelText(this.ctText)
    }
    return text
  }
  mouseDown(e) {
    if (e.button == 0) {
      this.left_press = true
    } else if (e.button == 1) {
      this.middle_press = true
    } else if (e.button == 2) {
      this.right_press = true
    }
  }

  mouseUp(e) {
    if (e.button == 0) {
      this.left_press = false
      this.checked_objs_hited = false //清除拖动相关状态
      this.drag_count = 0
    } else if (e.button == 1) {
      this.middle_press = false
    } else if (e.button == 2) {
      this.right_press = false
    }
  }

  keydown(e) {
    this.shift_press = e.shiftKey
    this.ctrl_press = e.ctrlKey
  }
  keyup(e) {
    this.shift_press = false
    this.ctrl_press = false
  }
  reset(LAYOUT, clearCKObjs = true, clearPreCutObjs = true) {
    this.left_press = false
    this.middle_press = false
    this.right_press = false
    this.shift_press = false
    this.ctrl_press = false
    this.angle = 0
    this.movePos = []
    this.loopText = null
    this.ctText = []
    this.textLayer = null
    this.font_size = 0
    this.direction = 'o'
    this.useLabel = false
    this.pickPoints = []
    this.pickLines = []
    this.lastMousePos = null
    this.pointsAlign = []
    this.linesAlign = []
    this.pointOffset = [0, 0]
    this.trans = [0, 0]
    this.radian = 0
    if (clearCKObjs) {
      this.resetCheckStatus(LAYOUT)
      this.updateCheckedPointsAndLines(LAYOUT)
    }
    if (this.preCheckObj) {
      this.preCheckObj.js_obj.STATE.reset()
      updateObjMat(this.preCheckObj, LAYOUT)
      this.preCheckObj = null
      LAYOUT.STAGE.updatePreCheckObj(this.preCheckObj, LAYOUT)
    }
    this.preCheckPoint = null
    this.preCheckLine = null
    this.drag_count = 0
    LAYOUT.STAGE.updatePreCheckPoint(this.preCheckPoint)
    LAYOUT.STAGE.updatePreCheckLine(this.preCheckLine)
    LAYOUT.STAGE.updateAlignPoints([])
    LAYOUT.STAGE.updateAlignLines([])
    if (clearPreCutObjs) {
      this.resetPreCutStatus(LAYOUT)
    }
    this.clearPrePasteObj(LAYOUT)
    if (this.preAddRef) {
      //器件放置中途取消
      LAYOUT.clearPreAddRef()
      this.preAddRef = null
      this.preAddRefExist = false
      this.addNewCells = null
    }
    this.textCell = null
    this.fromExternalFile = false
    this.affectCellPtrs = []
    this.repetitionCellsPtrs = []
    this.crossCopyData = null
    this.crossBoardCopy = false
  }
  //设置对象剪切状态
  setPreCutObjs(LAYOUT, cutObjs) {
    cutObjs.forEach(obj => {
      // obj.js_obj.STATE.reset()
      obj.js_obj.STATE.preCut = true
      obj.js_obj.mouse_offset = null
      obj.js_obj.points_mouse_offset = null
      updateObjMat(obj, LAYOUT)
    })
    this.preCutObjs = cutObjs
  }
  //还原对象剪切前状态
  resetPreCutStatus(LAYOUT) {
    if (!this.preCutObjs) return
    if (window.CELLBOARD?.copyData?.fromCell?.snow_id === LAYOUT.CELL.snow_id) {
      window.CELLBOARD?.copyData?.fromData.forEach(obj => {
        obj.js_obj.STATE.preCut = false
        updateObjMat(obj, LAYOUT)
      })
    }
    LAYOUT.STAGE.updateCheckedRefs(LAYOUT, [])
    // this.preCutObjs.forEach(obj => {
    //   obj.js_obj.STATE.preCut = false
    //   updateObjMat(obj, LAYOUT)
    // })
    this.preCutObjs = null
    this.isCut = false
  }
  updateCheckStatus() {
    this.checked_objs.forEach(obj => {
      obj.js_obj.STATE.checked = true
    })
  }
  resetCheckStatus(LAYOUT) {
    this.checked_objs.forEach(obj => {
      obj.js_obj.STATE.reset()
      obj.js_obj.mouse_offset = null
      obj.js_obj.points_mouse_offset = null
      updateObjMat(obj, LAYOUT)
    })
    this.checked_objs = []
    this.checked_objs_hited = false
    this.updateCheckedObjsAABB()
    LAYOUT.STAGE.updateCheckedRefs(null)
    LAYOUT.STAGE.updateCheckedEditRefCheckedObjs(LAYOUT)
  }
  updateCheckedObjsAABB() {
    this.checked_objs_aabb = null
    this.checked_objs_center = null
    this.checked_objs.forEach(obj => {
      const TYPE = obj.constructor.name
      let aabb = obj.js_obj.bounding_box
      // if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
      //   aabb = [obj.origin, obj.origin]
      // }
      this.checked_objs_aabb = calGraphicsAABB(this.checked_objs_aabb, aabb)
    })
    if (this.checked_objs_aabb) {
      //计算选中物体包围盒中心点坐标
      const w = this.checked_objs_aabb[1][0] - this.checked_objs_aabb[0][0]
      const h = this.checked_objs_aabb[1][1] - this.checked_objs_aabb[0][1]
      this.checked_objs_center = [this.checked_objs_aabb[0][0] + w / 2, this.checked_objs_aabb[0][1] + h / 2]
    }
  }

  updateCoverIndex() {
    //计算重叠状态下选择图形下标
    const hited_length = this.hited_objs_id.length
    const max_index = hited_length - 1
    if (hited_length > 1) {
      // if(this.drag_objs) return
      this.cover_index += 1
      if (this.cover_index > max_index) this.cover_index = 0
    } else {
      this.cover_index = -1
    }
    this.hited_objs_id = []
  }
  //更新预选中点
  setPreCheckPoint(point, LAYOUT) {
    this.preCheckPoint = point
    const renderPoint = transOutRef(LAYOUT, this.preCheckPoint?.point)
    LAYOUT.STAGE.updatePreCheckPoint(renderPoint)
  }
  //更新预选中线
  setPreCheckLine(line, LAYOUT) {
    this.preCheckLine = line
    const renderLine = transOutRefPoints(LAYOUT, this.preCheckLine?.line)
    LAYOUT.STAGE.updatePreCheckLine(renderLine)
  }

  //更新预选中顶点和线
  updateCheckedPointsAndLines(LAYOUT) {
    let points = []
    let lines = []
    for (let i = 0; i < this.checked_objs.length; i++) {
      const obj = this.checked_objs[i]
      if (obj.js_obj.STATE.partChecked) {
        const TYPE = obj.$$?.ptrType.name //obj.constructor.name
        let obj_points = obj.points
        if (TYPE == 'GdsEllipse*') {
          obj_points = obj.js_obj.borderBox.points
        } else if (TYPE == 'GdsRectangle*') {
          obj_points = obj.js_obj.borderBox.points
        }
        points.push(...getPointsByIndexs(obj.js_obj.checked_points_index, obj_points))
        lines.push(...obj.js_obj.checked_lines_pos)
      }
    }
    let renderPoints = transOutRefPoints(LAYOUT, points)
    const renderLines = lines
    for (let i = 0; i < renderLines.length; i++) {
      renderLines[i] = transOutRefPoints(LAYOUT, renderLines[i])
    }
    LAYOUT.STAGE.updateCheckedPointsAndLines(renderPoints, lines)
  }

  setPreCheckObj(obj, LAYOUT) {
    if (this.preCheckObj == obj) return
    if (this.preCheckObj) {
      this.preCheckObj.js_obj.STATE.preCheck = false
      updateObjMat(this.preCheckObj, LAYOUT)
    }
    this.preCheckObj = obj

    LAYOUT.STAGE.updatePreCheckObj(this.preCheckObj, LAYOUT)
  }

  //获取未选中的对象
  getHitUnCheckedObj(hits, loop = 0) {
    if (loop > 1) return false
    if (this.cover_index == -1) {
      return hits[0]
    } else {
      let hit = hits[this.cover_index]
      if (!hit.js_obj.STATE.checked) {
        return hit
      } else {
        for (let i = 0; i < hits.length; i++) {
          if (i > this.cover_index) {
            let hit = hits[i]
            if (!hit.js_obj.STATE.checked) {
              //找到对象
              this.cover_index = i
              return hit
            }
          }
        }
        loop += 1
        return this.getHitUnCheckedObj(hits, loop)
      }
    }
  }

  //获取选中的对象
  getHitCheckedObj(hits, loop = 0) {
    if (loop > 1) return false
    if (this.cover_index == -1) {
      return hits[0]
    } else {
      let hit = hits[this.cover_index]
      if (hit.js_obj.STATE.checked) {
        return hit
      } else {
        for (let i = 0; i < hits.length; i++) {
          if (i > this.cover_index) {
            let hit = hits[i]
            if (hit.js_obj.STATE.checked) {
              //找到对象
              this.cover_index = i
              return hit
            }
          }
        }
        loop += 1
        this.cover_index = 0
        return this.getHitCheckedObj(hits, loop)
      }
    }
  }

  //是否可以拖动 整体选择
  canDrag() {
    return this.left_press && !this.viewMode && this.checked_objs.length && this.checked_objs_hited
  }

  //是否可以拖动 部分选择
  canPartDrag() {
    return this.left_press && !this.viewMode && this.checked_objs.length && this.checked_objs_hited
  }

  //记录点对齐选择的顶点
  recordAlignPoints(point) {
    this.pointsAlign.push(point)
  }
  //记录线对齐选择的线
  recordAlignLines(line) {
    this.linesAlign.push(line)
  }

  //清除复制对象
  clearPrePasteObj(LAYOUT) {
    if (this.prePasteData) {
      this.prePasteData.forEach(obj => LAYOUT.deleteObj(LAYOUT.CELL, obj))
      LAYOUT.updateTargetCellInsRefs(this.affectCellPtrs)
    }
    this.prePasteData = null
  }
}

//计算所有对象为整体的AABB
function calGraphicsAABB(result, aabb) {
  if (!result) return deepClone(aabb)
  if (result[0][0] > aabb[0][0]) {
    //minx
    result[0][0] = aabb[0][0]
  }
  if (result[0][1] > aabb[0][1]) {
    //miny
    result[0][1] = aabb[0][1]
  }
  if (result[1][0] < aabb[1][0]) {
    //maxx
    result[1][0] = aabb[1][0]
  }
  if (result[1][1] < aabb[1][1]) {
    //maxy
    result[1][1] = aabb[1][1]
  }
  return result
}

//通过下标获取选中顶点
function getPointsByIndexs(indexs, points) {
  return indexs.map(index => points[index])
}
