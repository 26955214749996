import store from '@/store/index'
import { hexToRgb } from './render-util'
import { generateLineMat, generateFillMat, generateLineInsMat, generateFillInsMat, generateFontMat, generateFontInsMat, generateKeyPointMat, generateKeyPointInsMat } from '../shader/cell_shader'
import { initLine, updateObjMat } from './graphics-util'
import { updateRefsName } from './reference-util'
//选择新图层
export function selectLayer(LAYOUT, data) {
  // LAYOUT.LAYER_STATE.layer = LAYOUT.LAYER_STATE.layer == 1 ? 2 : 1
}
//新建
export function newLayer(LAYOUT, data) {}
//图层数据修改
export function layerChange(LAYOUT, data) {
  // LAYOUT.LAYER_STATE.updateLayerMats([
  //   // { layerNum: 1, border: 'B3', fill: 'F3' },
  //   // { layerNum: 2, border: 'B4', fill: 'F4' },
  // ])
}

//隐藏
export function hideLayer(LAYOUT, data) {
  LAYOUT.resetLayout(null, true)
  LAYOUT.reRender()
}

//隐藏全部
export function allHide(LAYOUT, data) {
  LAYOUT.resetLayout(null, true)
  LAYOUT.reRender()
}
//锁定
export function lockLayer(LAYOUT, data) {
  LAYOUT.resetLayout(null, true)
}
//锁定全部
export function allLock(LAYOUT, data) {
  LAYOUT.resetLayout(null, true)
}

//删除图层
export function delLayer(LAYOUT, data) {
  LAYOUT.resetLayout(null, true)
  LAYOUT.rebuildCellsQuadTree()
  LAYOUT.reRender()
}

//合并图层
export function mergeLayer(LAYOUT, data) {
  LAYOUT.updateMat()
  // let from = [1]
  // let to = 2
  // LAYOUT.LAYER_STATE.mergeLayer(from, to)
  // LAYOUT.LAYER_STATE.layer = 2
}

//图层编号修改
export function changeLayerNumber(LAYOUT, data) {
  LAYOUT.updateMat()
  // let from = 1
  // let to = 5
  // LAYOUT.LAYER_STATE.changeLayerNumber(from, to)
}

//批量修改图层
export function batchChangeLayers(LAYOUT, data) {}

export const QedaGraphicFillType = {
  F0: [-1, 0, 0, 0, 0, 0, 0, 1], //填充
  F1: [2, 15, 0, 0, 45, 0, 0, 1], //斜线1
  F2: [5, 15, 0, 0, 45, 0, 0, 1], //斜线2
  F3: [2, 15, 0, 0, 90, 0, 0, 1], //斜线3
  F4: [1, 20, 0, 0, 45, 1, 0, 1], //斜网格
  F5: [2, 20, 5, 10, 45, 0, 0, 1], //间隙线
  F6: [4, 15, 10, 5, 45, 0, 0, 1], //循环方格点
  F7: [2, 6, 0, 0, 45, 1, 1, 1], //点阵密集
  F8: [10, 20, 0, 0, 45, 1, 1, 1], //点阵
  F9: [1, 20, 10, 10, 45, 1, 0, 1], //循环十字
  F10: [2, 40, 20, 20, 0, 1, 0, 2], //循环十字2
  F11: [6, 40, 10, 10, 45, 1, 0, 2], //特殊纹理1
  F12: [5, 20, 40, 10, 45, 1, 0, 2], //特殊纹理2
  F13: [2, 10, 20, 10, 45, 1, 0, 1], //特殊纹理3
  F14: [4, 20, 20, 20, 45, 1, 0, 1], //特殊纹理4
}

//图层面板填充缩略图参数
export const QedaGraphicFillTypeImg = {
  F0: [10000, 0, 0, 0, 0, 0, 0, 1], //填充
  F1: [5, 20, 0, 0, 45, 0, 0, 1], //斜线1
  F2: [10, 15, 0, 0, 45, 0, 0, 1], //斜线2
  F3: [5, 20, 0, 0, 135, 0, 0, 1], //斜线3
  F4: [1, 20, 0, 0, 45, 1, 0, 1], //斜网格
  F5: [4, 20, 5, 10, 45, 0, 0, 1], //间隙线
  F6: [5, 15, 10, 5, 45, 0, 0, 1], //循环方格点
  F7: [2, 6, 0, 0, 45, 1, 1, 1], //点阵密集
  F8: [15, 25, 0, 0, 45, 1, 1, 1], //点阵
  F9: [1, 20, 10, 10, 45, 1, 0, 1], //循环十字
  F10: [2, 40, 20, 20, 0, 1, 0, 2], //循环十字2
  F11: [6, 40, 10, 10, 45, 1, 0, 2], //特殊纹理1
  F12: [5, 20, 40, 10, 45, 1, 0, 2], //特殊纹理2
  F13: [2, 10, 20, 10, 45, 1, 0, 1], //特殊纹理3
  F14: [4, 20, 20, 20, 45, 1, 0, 1], //特殊纹理4
}

//图层面板边框缩略图参数
export const QedaGraphicBorderType = {
  B1: [0, 0, 0, 0],
  B2: [100, 70, 100, 70],
  B3: [50, 50, 50, 50],
  B4: [20, 20, 20, 20],
  B5: [10, 10, 10, 10],
}

//图层面板边框缩略图参数
export const QedaGraphicBorderTypeImg = {
  B1: [0, 0, 0, 0],
  B2: [5, 3, 5, 3],
  B3: [2, 0, 0, 2],
  B4: [1, 1, 1, 1],
  B5: [0.5, 1, 0.5, 1],
}

//填充
const FillShader = `
      uniform float4 col; //颜色
      uniform float width; //线宽
      uniform float space; //网格间距
      uniform float line_widht; //线段长度
      uniform float dot; //线段空隙间距
      uniform float angle; //旋转角度
      uniform float only_side; //只绘制一边 0 || 1 默认0
      uniform float use_point_grid; //使用点阵 0 || 1 默认0
      uniform float scale; //缩放 默认1
      uniform float2 position; //位移
      uniform float axis_scale;//坐标系缩放
      float fix_scale = 1000000;
      float grid(float value, float width, float space, float vertical, float line_widht, float dot) {
          float total = line_widht + dot; //线长加间距 -----  ----- -----
          float pos = mod(vertical, total);
          float dot_weight = 0;
          if(pos <= line_widht){
              dot_weight = width + 1; //计算点距权重
          }
          return mod(value + width * 0.5, space) - width + dot_weight;
      }
      half4 main(float2 p) {
          if(width > 1000){
            fix_scale = 1.0;
          }
          float4 color; //输出颜色
          float4 color_bg; //背景颜色
          float deg =  angle * (3.1415926/ 180);
          mat3 matrix = mat3( //变换矩阵
          float3(cos(deg) * scale,sin(deg),0),
          float3(-sin(deg),cos(deg) * scale,0),
          float3(fix_scale, fix_scale,1)
          );
          p *= axis_scale;
          p += 2000;
          // p -= position;
          float2 pp = (matrix * float3(p.x,p.y,1)).xy; //旋转后像素坐标
          float mx = 1-smoothstep(0, 1, grid(pp.x, width, space, pp.y - line_widht/2, line_widht, dot));
          float my;
          if(bool(only_side)){
              my = 1-smoothstep(0, 1, grid(pp.y, width, space, pp.x - line_widht/2, line_widht, dot));
          }
          color = mix(color_bg, col, clamp(mx + my, 0.0, col.w));//网格
          if(bool(use_point_grid)){
              color = mix(color_bg, col, clamp(mx + my - 1, 0.0, col.w));//点阵
          }
          return half4(color);
      }
  `
//生成边框和填充模板填充模板图片
export function getFillAndBorderImgs() {
  const fillImgs = []
  const borderImgs = []
  const rect_fill = CanvasKit.LTRBRect(0, 0, 250, -250)
  const fill_pt = new CanvasKit.Paint()
  const fillFact = CanvasKit.RuntimeEffect.Make(FillShader) //图形填充纹理

  // let canvas = document.createElement('canvas')
  // canvas.id = 'img_temp'
  for (let key in QedaGraphicFillTypeImg) {
    fill_pt.setShader(fillFact.makeShader([...CanvasKit.BLACK, ...QedaGraphicFillType[key], 0, 0, 1]))
    const surface = CanvasKit.MakeCanvasSurface('fill_img_temp')
    const canvas = surface.getCanvas()
    canvas.scale(1, -1)
    canvas.translate(0, 0)
    canvas.clear(CanvasKit.WHITE)
    // const outline_pt = this.stage.layer_paints_img[index]
    canvas.save()
    canvas.scale(3, 3)
    canvas.drawRect(rect_fill, fill_pt)
    canvas.restore()

    surface.flush()
    let data = surface.makeImageSnapshot().encodeToBytes()
    let blob = new Blob([data], { type: 'image/jpeg' })
    data = null // 要置null，否则存在内存泄漏风险
    let url = URL.createObjectURL(blob)
    fillImgs.push(url)
  }

  const rect_border = CanvasKit.LTRBRect(0, 0, 11.5, -6)
  const outline_pt = new CanvasKit.Paint()
  for (let key in QedaGraphicBorderTypeImg) {
    const dep = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderTypeImg[key])
    outline_pt.setPathEffect(dep)
    outline_pt.setColor(CanvasKit.BLACK)
    outline_pt.setStyle(CanvasKit.PaintStyle.Stroke)
    outline_pt.setStrokeWidth(1.5)
    const surface = CanvasKit.MakeCanvasSurface('border_img_temp')
    const canvas = surface.getCanvas()
    canvas.scale(1, -1)
    canvas.translate(0, 0)
    canvas.clear(CanvasKit.WHITE)
    canvas.save()
    canvas.scale(30, 30)
    canvas.drawRect(rect_border, outline_pt)
    canvas.restore()
    surface.flush()
    let border_data = surface.makeImageSnapshot().encodeToBytes()
    let border_blob = new Blob([border_data], { type: 'image/jpeg' })
    border_data = null // 要置null，否则存在内存泄漏风险
    let border_url = URL.createObjectURL(border_blob)
    borderImgs.push(border_url)
  }
  return { fillImgs, borderImgs }
}

//生成图层的边框和填充图片
export function generateFillImg(layerNumber = 'All', fileInfo) {
  const result = []
  const rect_border = CanvasKit.LTRBRect(0, 0, 8.3, -5)
  const rect_fill = CanvasKit.LTRBRect(0, 0, 250, -150)
  if (!store.state.fileLayerListTable) return
  if (!store.state.fileLayerListTable.filter(item => fileInfo.fileId === item.fileId)?.length) return
  let data = store.state.fileLayerListTable.filter(item => fileInfo.fileId === item.fileId)[0].fileLayerDatas

  if (!data?.length) return
  if (layerNumber !== 'All') {
    data = data.filter(layer => layer.layerNumber === layerNumber)
  }

  const surface = CanvasKit.MakeCanvasSurface('fill_canvas')
  const canvas = surface.getCanvas()
  let paints = generateLayersPaint(data) //生成图层边框和填充paint
  data.forEach((layer, i) => {
    // if(layer.color !== ''){
    canvas.save()
    canvas.scale(1, -1)
    canvas.translate(0, 0)
    canvas.clear(CanvasKit.WHITE)
    const index = layer.layerNumber
    const outline_pt = paints.layer_paints_img[index]
    const fill_pt = paints.layer_fill_paints_img[index]
    canvas.save()
    canvas.scale(3, 3)
    canvas.drawRect(rect_fill, fill_pt)
    canvas.restore()
    canvas.save()
    canvas.scale(30, 30)
    canvas.drawRect(rect_border, outline_pt)
    canvas.restore()
    canvas.restore()
    // surface.flush()
    // const img = document.getElementById('fill_img')
    // const img2 = document.getElementById('fill_img2')
    let data = surface.makeImageSnapshot().encodeToBytes(CanvasKit.ImageFormat.JPEG, 0)
    let blob = new Blob([data], { type: 'image/jpeg' })
    data = null // 要置null，否则存在内存泄漏风险
    let url = URL.createObjectURL(blob)
    blob = null
    // img.src = url
    // img2.src = url
    result.push(url)
  })
  //删除canvaskit对象
  for (const layer in paints.layer_paints_img) {
    paints.layer_paints_img[layer].delete()
  }
  for (const layer in paints.layer_fill_paints_img) {
    paints.layer_fill_paints_img[layer].delete()
  }
  surface.flush()
  surface.delete()
  return { layerNumber, imgs: result }
  // bus.$emit('layerImgChange', { layerNumber, imgs: result })
}

//生成图层边框和填充
function generateLayersPaint(layerDatas) {
  if (!window.FillFact) {
    window.FillFact = CanvasKit.RuntimeEffect.Make(FillShader) //图形填充纹理
  }
  let layer_paints_img = {}
  let layer_fill_paints_img = {}
  layerDatas.forEach(layer => {
    let res = getLayerPaint(layer)
    const index = layer.layerNumber //图层和编号对应
    layer_paints_img[index] = res.paint_img
    layer_fill_paints_img[index] = res.pt_fill_img
  })
  return { layer_paints_img, layer_fill_paints_img }
}

//生成CanvasKit Paint
function getLayerPaint(layer) {
  const shape = layer.shape
  let rgb = hexToRgb(layer.color)

  if (!rgb) {
    rgb = [0, 0, 0]
  }
  let border_rgb = hexToRgb(layer.borderColor)

  if (!border_rgb) {
    border_rgb = [0, 0, 0]
  }
  const paint_img = new CanvasKit.Paint()
  paint_img.setColor(CanvasKit.Color(border_rgb[0], border_rgb[1], border_rgb[2]))
  paint_img.setStyle(CanvasKit.PaintStyle.Stroke)
  paint_img.setStrokeWidth(1.5)
  const border_style = layer.border
  if (border_style === 'B1') {
    //   paint.setStrokeWidth(line_width)
  } else if (border_style) {
    const dep_img = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderTypeImg[border_style])
    paint_img.setPathEffect(dep_img)
  } else {
    paint_img.setAlphaf(0)
  }

  const pt_fill_img = new CanvasKit.Paint()
  if (!shape) {
    pt_fill_img.setAlphaf(0)
  } else {
    pt_fill_img.setShader(FillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillTypeImg[shape], 0, 0, 1]))
  }
  return { paint_img, pt_fill_img }
}

//图层数据管理
export class LayerState {
  constructor(layers) {
    this.preCheckedCol = '#ffffff'
    this.checkedCol = '#ffe500'
    this.canvas = { width: 1000, height: 800 }
    this.layers = layers
    this.layer = 1 //当前图层
    this.layerId = '' //当前id
    this.layerDatas = [] //图层数据
    this.layerMats = {} //图层材质
  }

  currentLayerIsHide() {
    let hide = this.layerMats[this.layer]?.hide
    if (hide == undefined) return true
    return hide
  }

  updateLayerDatas(datas) {
    this.layerDatas = datas
  }
  getLayerMatsArray(layer) {
    let data = this.layerMats[layer]
    if (data) {
      return [
        data.lineMat,
        data.fillMat,
        data.fontMat,
        data.lineInsMat,
        data.fillInsMat,
        data.fontInsMat,
        data.keyPointMat,

        data.lineMat_pre_check,
        data.fillMat_pre_check,
        data.lineInsMat_pre_check,
        data.fillInsMat_pre_check,
        data.fontMat_pre_check,
        data.fontInsMat_pre_check,
        data.keyPointMat_pre_check,

        data.lineMat_checked,
        data.fillMat_checked,
        data.lineInsMat_checked,
        data.fillInsMat_checked,
        data.fontMat_checked,
        data.fontInsMat_checked,
        data.keyPointMat_checked,

        data.lineMat_pre_cut,
        data.fillMat_pre_cut,
        data.lineInsMat_pre_cut,
        data.fillInsMat_pre_cut,
        data.fontMat_pre_cut,
        data.fontInsMat_pre_cut,
        data.keyPointMat_pre_cut,
      ]
    }
    return []
  }
  //彻底删除图层材质
  deletLayerMats(layer) {
    this.getLayerMatsArray(layer).forEach(mat => {
      mat.dispose()
    })
    delete this.layerMats[layer]
  }
  //隐藏图层
  hideLayer(layer) {
    let data = this.layerMats[layer]
    if (data) {
      for (const key in data) {
        let mat = data[key]
        if (mat instanceof Object) {
          mat.visible = !mat.visible
        }
      }
    }
  }
  //隐藏全部图层
  hideAllLayer(layer) {
    for (const num in this.layerMats) {
      let data = this.layerMats[num]
      for (const key in data) {
        let mat = data[key]
        if (mat instanceof Object) {
          mat.visible = !mat.visible
        }
      }
    }
  }
  //图层编号修改
  changeLayerNumber(from, to) {
    let matData = this.layerMats[from]
    delete this.layerMats[from]
    this.layerMats[to] = matData
  }
  //合并图层
  mergeLayer(from_layer_nums, to_layer_num) {
    from_layer_nums.forEach(num => {
      this.deletLayerMats(num)
    })
  }
  updateCanvas(canvas) {
    this.canvas = canvas
    this.updateLayerMats(this.layers)
  }
  //更新图层材质
  updateLayerMats(layers) {
    this.layers = layers
    let color_pre_rgb = hexToRgb(this.preCheckedCol).map(v => v / 255)
    let checked_rgb = hexToRgb(this.checkedCol).map(v => v / 255)
    let color_pre = new THREE.Vector4(color_pre_rgb[0], color_pre_rgb[1], color_pre_rgb[2], 1.0)
    let color_check = new THREE.Vector4(checked_rgb[0], checked_rgb[1], checked_rgb[2], 1.0)
    let width = this.canvas?.width
    let height = this.canvas?.height
    let canvasV2 = { x: this.canvas.width, y: this.canvas.height }
    layers.forEach(layer => {
      const BT = layer.border
      const FT = layer.shape
      let layerMatsData = this.layerMats[layer.layerNumber]
      let color_fill
      let fill = true
      if (layer.color) {
        let color = hexToRgb(layer.color).map(v => v / 255)
        color_fill = new THREE.Vector4(color[0], color[1], color[2], 1.0)
      } else {
        fill = false
        color_fill = new THREE.Vector4(0, 0, 0, 0)
      }
      let borderColor = hexToRgb(layer.borderColor).map(v => v / 255)
      let color_border = new THREE.Vector4(borderColor[0], borderColor[1], borderColor[2], 1.0)
      const color_border_hf_a = color_border.clone()
      const color_fill_hf_a = color_fill.clone()
      color_border_hf_a.w = 0.5
      color_fill_hf_a.w = 0.5
      if (layerMatsData) {
        //存在图层数据
        updateLineMat(layerMatsData.lineMat, BT, canvasV2, color_border, color_pre, color_check)
        updateFillMat(layerMatsData.fillMat, FT, color_fill, color_pre, color_check)
        updateLineMat(layerMatsData.lineInsMat, BT, canvasV2, color_border, color_pre, color_check)
        updateFillMat(layerMatsData.fillInsMat, FT, color_fill, color_pre, color_check)
        updateFontMat(layerMatsData.fontMat, color_border, color_pre, color_check)
        updateFontMat(layerMatsData.fontInsMat, color_border, color_pre, color_check)
        updateFontMat(layerMatsData.keyPointMat, color_border, color_pre, color_check)
        updateFontMat(layerMatsData.keyPointInsMat, color_border, color_pre, color_check)

        updateLineMat(layerMatsData.lineMat_pre_check, BT, canvasV2, color_pre, color_pre, color_check)
        updateFillMat(layerMatsData.fillMat_pre_check, FT, color_pre, color_pre, color_check, fill)
        updateLineMat(layerMatsData.lineInsMat_pre_check, BT, canvasV2, color_pre, color_pre, color_check)
        updateFillMat(layerMatsData.fillInsMat_pre_check, FT, color_pre, color_pre, color_check, fill)
        updateFontMat(layerMatsData.fontMat_pre_check, color_pre, color_pre, color_check)
        updateFontMat(layerMatsData.fontInsMat_pre_check, color_pre, color_pre, color_check)
        updateFontMat(layerMatsData.keyPointMat_pre_check, color_pre, color_pre, color_check)

        updateLineMat(layerMatsData.lineMat_checked, BT, canvasV2, color_check, color_pre, color_check)
        updateFillMat(layerMatsData.fillMat_checked, FT, color_check, color_pre, color_check, fill)
        updateLineMat(layerMatsData.lineInsMat_checked, BT, canvasV2, color_check, color_pre, color_check)
        updateFillMat(layerMatsData.fillInsMat_checked, FT, color_check, color_pre, color_check, fill)
        updateFontMat(layerMatsData.fontMat_checked, color_check, color_pre, color_check)
        updateFontMat(layerMatsData.fontInsMat_checked, color_check, color_pre, color_check)
        updateFontMat(layerMatsData.keyPointMat_checked, color_check, color_pre, color_check)

        updateLineMat(layerMatsData.lineMat_pre_cut, BT, canvasV2, color_border_hf_a, color_pre, color_check)
        updateFillMat(layerMatsData.fillMat_pre_cut, FT, color_fill_hf_a, color_pre, color_check, fill)
        updateLineMat(layerMatsData.lineInsMat_pre_cut, BT, canvasV2, color_border_hf_a, color_pre, color_check)
        updateFillMat(layerMatsData.fillInsMat_pre_cut, FT, color_fill_hf_a, color_pre, color_check, fill)
        updateFontMat(layerMatsData.fontMat_pre_cut, color_fill_hf_a, color_pre, color_check)
        updateFontMat(layerMatsData.fontInsMat_pre_cut, color_fill_hf_a, color_pre, color_check)
        updateFontMat(layerMatsData.keyPointMat_pre_cut, color_fill_hf_a, color_pre, color_check)

        layerMatsData.hide = layer.hide
        layerMatsData.lock = layer.lock
      } else {
        let lineMat = generateLineMat(color_border, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineMat, BT)
        let fillMat = generateFillMat(color_fill, color_pre, color_check)
        updateFillMatTexture(fillMat, FT)
        let lineInsMat = generateLineInsMat(color_border, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineInsMat, BT)
        let fillInsMat = generateFillInsMat(color_fill, color_pre, color_check)
        fillInsMat.side = THREE.DoubleSide //避免翻转后器件填充不显示
        updateFillMatTexture(fillInsMat, FT)
        let fontMat = generateFontMat(color_fill, color_pre, color_check)
        let fontInsMat = generateFontInsMat(color_fill, color_pre, color_check)
        fontInsMat.side = THREE.DoubleSide //避免翻转后器件填充不显示
        let keyPointMat = generateKeyPointMat(color_fill, color_pre, color_check)
        let keyPointInsMat = generateKeyPointInsMat(color_fill, color_pre, color_check)

        let lineMat_pre_check = generateLineMat(color_pre, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineMat_pre_check, BT)
        let fillMat_pre_check = generateFillMat(color_pre, color_pre, color_check, fill)
        updateFillMatTexture(fillMat_pre_check, FT)
        let lineInsMat_pre_check = generateLineInsMat(color_pre, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineInsMat_pre_check, BT)
        let fillInsMat_pre_check = generateFillInsMat(color_pre, color_pre, color_check, fill)
        updateFillMatTexture(fillInsMat_pre_check, FT)
        let fontMat_pre_check = generateFontMat(color_pre, color_pre, color_check)
        let fontInsMat_pre_check = generateFontInsMat(color_pre, color_pre, color_check)
        let keyPointMat_pre_check = generateKeyPointMat(color_pre, color_pre, color_check)

        let lineMat_checked = generateLineMat(color_check, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineMat_checked, BT)
        let fillMat_checked = generateFillMat(color_check, color_pre, color_check, fill)
        updateFillMatTexture(fillMat_checked, FT)
        let lineInsMat_checked = generateLineInsMat(color_check, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineInsMat_checked, BT)
        let fillInsMat_checked = generateFillInsMat(color_check, color_pre, color_check, fill)
        updateFillMatTexture(fillInsMat_checked, FT)
        let fontMat_checked = generateFontMat(color_check, color_pre, color_check)
        let fontInsMat_checked = generateFontInsMat(color_check, color_pre, color_check)
        let keyPointMat_checked = generateKeyPointMat(color_check, color_pre, color_check)

        let lineMat_pre_cut = generateLineMat(color_border_hf_a, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineMat_pre_cut, BT)
        let fillMat_pre_cut = generateFillMat(color_fill_hf_a, color_pre, color_check, fill)
        updateFillMatTexture(fillMat_pre_cut, FT)
        let lineInsMat_pre_cut = generateLineInsMat(color_border_hf_a, this.canvas, color_pre, color_check)
        updateLineMatBorder(lineInsMat_pre_cut, BT)
        let fillInsMat_pre_cut = generateFillInsMat(color_fill_hf_a, color_pre, color_check, fill)
        updateFillMatTexture(fillInsMat_pre_cut, FT)
        let fontMat_pre_cut = generateFontMat(color_fill_hf_a, color_pre, color_check)
        let fontInsMat_pre_cut = generateFontInsMat(color_fill_hf_a, color_pre, color_check)
        let keyPointMat_pre_cut = generateKeyPointMat(color_fill_hf_a, color_pre, color_check)

        // const lines = initLine(lineMat)
        this.layerMats[layer.layerNumber] = {
          layerNum: layer.layerNumber,

          lineMat,
          fillMat,
          fontMat,
          lineInsMat,
          fillInsMat,
          fontInsMat,
          keyPointMat,
          keyPointInsMat,

          lineMat_pre_check,
          fillMat_pre_check,
          lineInsMat_pre_check,
          fillInsMat_pre_check,
          fontMat_pre_check,
          fontInsMat_pre_check,
          keyPointMat_pre_check,

          lineMat_checked,
          fillMat_checked,
          lineInsMat_checked,
          fillInsMat_checked,
          fontMat_checked,
          fontInsMat_checked,
          keyPointMat_checked,

          lineMat_pre_cut,
          fillMat_pre_cut,
          lineInsMat_pre_cut,
          fillInsMat_pre_cut,
          fontMat_pre_cut,
          fontInsMat_pre_cut,
          keyPointMat_pre_cut,
          // lines,
          hide: layer.hide,
          lock: layer.lock,
        }
      }
    })
  }
}

function updateLineMat(lineMat, BT, canvas, color, color_pre, color_check) {
  lineMat.uniforms.canvas.value = canvas
  lineMat.uniforms.color.value = color
  lineMat.uniforms.preCheckColor.value = color_pre
  lineMat.uniforms.checkedColor.value = color_check
  updateLineMatBorder(lineMat, BT)
}

function updateFillMat(fillMat, FT, color, color_pre, color_check, fill = true) {
  if (!fill) {
    color = color.clone()
    color.w = 0
  }
  fillMat.uniforms.color.value = color
  fillMat.uniforms.preCheckColor.value = color_pre
  fillMat.uniforms.checkedColor.value = color_check
  updateFillMatTexture(fillMat, FT)
}
function updateFontMat(mat, color, color_pre, color_check) {
  mat.uniforms.color.value = color
  if (mat.uniforms.preCheckColor) {
    mat.uniforms.preCheckColor.value = color_pre
    mat.uniforms.checkedColor.value = color_check
  }
}
//更新边框参数
function updateLineMatBorder(lineMat, boarderType) {
  let borderParams = QedaGraphicBorderType[boarderType]
  if (!borderParams[0] && !borderParams[1]) {
    //实线
    lineMat.uniforms.isDash.value = false
    lineMat.uniforms.dash.value = 0
    lineMat.uniforms.gap.value = 0
  } else {
    //虚线
    lineMat.uniforms.isDash.value = true
    lineMat.uniforms.dash.value = borderParams[0]
    lineMat.uniforms.gap.value = borderParams[1]
  }
}
//更新填充参数
function updateFillMatTexture(fillMat, fillType) {
  let fillParams = QedaGraphicFillType[fillType]
  if (!fillParams || fillParams[0] < 0) {
    //无填充花纹
    fillMat.uniforms.useTexture.value = false
  } else {
    //有填充花纹
    fillMat.uniforms.useTexture.value = true
    fillMat.uniforms.width.value = fillParams[0]
    fillMat.uniforms.space.value = fillParams[1]
    fillMat.uniforms.line_width.value = fillParams[2]
    fillMat.uniforms.dot.value = fillParams[3]
    fillMat.uniforms.angle.value = fillParams[4]
    fillMat.uniforms.only_side.value = fillParams[5]
    fillMat.uniforms.use_point_grid.value = fillParams[6]
    fillMat.uniforms.scale.value = fillParams[7]
  }
}

//将线合并到一个对象
export function updateLayerLines(LAYOUT) {
  // const cell = LAYOUT.CELL
  // const flexpaths = cell.flexpaths
  // const polygons = cell.polygons
  // const labels = cell.labels
  // const objs_pos = []
  // LAYOUT.LAYER_STATE.layerMats
  // for (let i = 0; i < flexpaths.length; i++) {
  //   objs_pos.push(flexpaths[i].points)
  // }
  // for (let i = 0; i < polygons.length; i++) {
  //   const polygon = polygons[i]
  //   objs_pos.push(polygon[i].points)
  // }
}

//覆盖写入图层id
export function applyLayerIdToCell(cell, layerDatas) {
  applyLayerIdToCellSub(cell, layerDatas)
  cell.depend_cells(-1).forEach(subCell => applyLayerIdToCellSub(subCell, layerDatas))
}
export function applyLayerIdToCellSub(cell, layerDatas) {
  if (!cell) return
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    const ids = []
    path.layers.forEach(layer => ids.push(getLayerIdByNum(layer, layerDatas)))
    path.id = ids
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    polygon.id = getLayerIdByNum(polygon.layer, layerDatas)
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    label.id = getLayerIdByNum(label.layer, layerDatas)
  }
}
export function applyLayerIdToObj(obj, layerDatas) {
  const T = obj.$$?.ptrType.name
  if (T == 'GdsFlexpath*') {
    const ids = []
    obj.layers.forEach(layer => ids.push(getLayerIdByNum(layer, layerDatas, layerDatas)))
    obj.id = ids
  } else if (T == 'GdsPolygon*' || T == 'GdsRectangle*' || T == 'GdsEllipse*' || T == 'GdsLabel*' || T == 'GdsKeyPoint*') {
    obj.id = getLayerIdByNum(obj.layer, layerDatas)
  } else if (T == 'Reference*') {
    updateRefsName([obj])
  }
}
export function getLayerIdByNum(layer, layerDatas) {
  for (let i = 0; i < layerDatas.length; i++) {
    if (layerDatas[i].layerNumber == layer) {
      return layerDatas[i].id
    }
  }
}

//判断对象是否被隐藏或锁定
export function objlockOrHide(item, layerState) {
  const T = item.$$?.ptrType.name //item.constructor.name
  if (T == 'Reference*' || item.constructor.name == 'Ruler' || item.js_obj.type) return false
  if (T == 'GdsFlexpath*') {
    return layerLockOrHide(item.layers[0], layerState)
  } else {
    return layerLockOrHide(item.layer, layerState)
  }
}

//判断对象是否被隐藏
export function objHide(item, layerState) {
  const T = item.$$?.ptrType.name //item.constructor.name
  if (T == 'Reference*' || (item.constructor.name == 'Ruler') == 'Ruler' || item.js_obj.type) return false
  if (T == 'GdsFlexpath*') {
    return layerIsHide(item.layers[0], layerState)
  } else {
    return layerIsHide(item.layer, layerState)
  }
}

export function layerLockOrHide(layer, layerState) {
  return layerState.layerMats[layer].lock || layerState.layerMats[layer].hide
}

export function layerIsHide(layer, layerState) {
  return layerState.layerMats[layer].hide
}

// //隐藏画板图层
// export function layoutHide(LAYOUT) {
//   hideCellGraphics(LAYOUT, cell, layerDatas)
//   cell.depend_cells(-1).forEach(subCell => hideCellGraphics(LAYOUT, subCell, layerDatas, true))
// }

// //清除图形
// export function hideCellGraphics(LAYOUT, cell, layerDatas, isRefCell) {
//   if (!cell) return
//   const flexpaths = cell.flexpaths
//   const flexpaths_size = flexpaths.length
//   const polygons = cell.polygons
//   const polygon_size = polygons.length
//   const labels = cell.labels
//   const labels_size = labels.length
//   for (let i = 0; i < flexpaths_size; i++) {
//     const path = flexpaths[i]
//     if (layerIsHide(path.layers[0], layerDatas)) {
//       cleraGraphics(LAYOUT,path,isRefCell)
//     }else{
//       addGraphics(LAYOUT,path,isRefCell)
//     }
//   }
//   for (let i = 0; i < polygon_size; i++) {
//     const polygon = polygons[i]
//     if (layerIsHide(polygon.layer[0], layerDatas)) {
//       cleraGraphics(LAYOUT,polygon,isRefCell)
//     }else{
//       addGraphics(LAYOUT,polygon,isRefCell)
//     }
//   }
//   for (let i = 0; i < labels_size; i++) {
//     const label = labels[i]
//     if (layerIsHide(label.layer[0], layerDatas)) {
//       cleraGraphics(LAYOUT,label,isRefCell)
//     }else{
//       addGraphics(LAYOUT,label,isRefCell)
//     }
//   }
// }

// export function cleraGraphics(LAYOUT,obj,isRefCell = false){
//   if(isRefCell){
//     LAYOUT.STAGE.refsGroup.remove()
//   }else{
//     LAYOUT.STAGE.cellGroup.remove()
//   }
// }

// export function addGraphics(LAYOUT,obj,isRefCell = false){
//   if(isRefCell){
//     LAYOUT.STAGE.refsGroup
//   }else{
//     LAYOUT.STAGE.cellGroup
//   }
// }
