;(function () {
  function CanvasFill(canvas, cell, scale) {
    this.fill_paint = new window.CanvasKit.Paint()
    this.fill_paint.setColor(window.CanvasKit.Color4f(0.5, 0.0, 0.0, 1.0))
    this.fill_paint.setStyle(window.CanvasKit.PaintStyle.Fill)
    // this.fill_paint.setAntiAlias(false)

    this.back_paint = new window.CanvasKit.Paint()
    this.back_paint.setColor(window.CanvasKit.Color4f(0.0, 0.0, 0.0, 1.0))
    this.back_paint.setStyle(window.CanvasKit.PaintStyle.Fill)

    this.border_paint = new window.CanvasKit.Paint()
    this.border_paint.setColor(window.CanvasKit.Color4f(0.5, 0.0, 0.0, 1.0))
    this.border_paint.setStyle(window.CanvasKit.PaintStyle.Stroke)
    this.border_paint.setStrokeWidth(1)
    // this.border_paint.setAntiAlias(false)

    // 填充完成标志
    this.done = false
    // 填充点
    this.fill_points = []

    if (!cell || !canvas) return
    if (cell.polygons.length < 1) {
      this.done = true
      return
    }

    this.cell = cell
    // 缩放系数
    this.scale = scale

    // 获取canvas宽、高
    let box = cell.bounding_box()

    this.tranX = box[0][0]
    this.tranY = box[0][1]

    // 平移到原点
    // this.cell.paths.forEach(element => {
    //   element.translate(-box[0][0], -box[0][1])
    //   element.scale(1 / scale)
    // })
    this.cell.polygons.forEach(element => {
      element.translate(-box[0][0], -box[0][1])
      element.scale(1 / scale, 1 / scale, [0, 0])
    })

    box = this.cell.bounding_box()
    this.width = Math.ceil(box[1][0] - box[0][0])
    this.height = Math.ceil(box[1][1] - box[0][1])


    this.canvasOFF = canvas
    this.canvasOFF.width = this.width
    this.canvasOFF.height = this.height
    // 创建surface
    this.surface = window.CanvasKit.MakeCanvasSurface(this.canvasOFF.id)
    if (!this.surface) {
      console.error('Could not make surface')
      return
    }

    // 绑定绘制函数
    this.drawFrameFunc = this.drawFrame.bind(this)

    this.run()
  }

  CanvasFill.prototype.run = function () {
    this.surface.drawOnce(this.drawFrameFunc)
  }

  CanvasFill.prototype.drawFrame = function (canvas) {
    this.drawCell(canvas)
    this.getValidPos(canvas)
  }

  CanvasFill.prototype.drawCell = function (canvas) {
    canvas.save()
    // canvas.drawColor(window.CanvasKit.BLACK)
    canvas.drawRect4f(0, 0, this.width, this.height, this.back_paint)
    // let flexpath_cpath = new window.CanvasKit.Path()
    // this.cell.paths.forEach(element => {
    //   flexpath_cpath.addPoly([].concat.apply([], element.get_points()), false)
    // })
    // canvas.drawPath(flexpath_cpath, this.border_paint)

    let polygon_cpath = new window.CanvasKit.Path()
    this.cell.polygons.forEach(element => {
      polygon_cpath.addPoly([].concat.apply([], element.get_points()), true)
    })
    canvas.drawPath(polygon_cpath, this.border_paint)
    canvas.drawPath(polygon_cpath, this.fill_paint)
    canvas.restore()
  }

  CanvasFill.prototype.getValidPos = function (canvas) {
    function getPos(rect) {
      let left = rect[0]
      let top = rect[1]
      let right = rect[2]
      let bottom = rect[3]
      let image_width = right - left
      let image_height = bottom - top
      // 创建imageinfo，用于截取image
      let imageInfo = {
        alphaType: window.CanvasKit.AlphaType.Opaque,
        colorType: window.CanvasKit.ColorType.RGBA_8888,
        colorSpace: window.CanvasKit.ColorSpace.SRGB,
        width: image_width,
        height: image_height,
      }

      // 获取canvas像素
      let pixels = canvas.readPixels(left, top, imageInfo)

      if (!pixels) return []
      let len = parseInt(pixels.length / 4)
      let row = 0
      let col = 0
      for (let i = 0; i < len; i++) {
        // if (i % 2 == 0) continue
        if (i > 0 && i % image_width == 0) {
          row += 1
        }
        col = i - row * image_width
        if (pixels[4 * i] > 120 && pixels[4 * i] < 130) {
          fill_points.push([col * scale + tranX, row * scale + tranY])
        }
      }
    }
    let scale = this.scale
    let tranX = this.tranX
    let tranY = this.tranY
    let w = this.width
    let h = this.height

    let max_wh = w > h ? w : h
    let n = 1
    // let sp = Math.floor(max_wh / 800)


    // while (max_wh / n > 2000) {
    //   n = n + 1
    // }
    // let num = h / n
    this.fill_points = []
    let fill_points = this.fill_points
    getPos([0, 0, Math.floor(w), Math.floor(h)])
    // for (let i = 0; i < n; i++) {
    //   for (let j = 0; j < n; j++) {
    //     getPos([Math.floor(i * num), Math.floor(j * num), Math.floor((i + 1) * num), Math.floor((j + 1) * num)])
    //   }
    // }

    this.done = true
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = CanvasFill
  } else {
    window.CanvasFill = CanvasFill
  }
})()
