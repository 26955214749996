//版图四叉树
import { rectHitObjs, rectHitPartObjs, insertLine } from './collision'
import { BOARDMODE } from '../const/board-status'
import { transToRef } from './reference-util'
import { countBy } from 'underscore'
import { objlockOrHide } from './layer-util'
export class CellQuadTree {
  constructor(cell) {
    this.cell = cell
    this.showConfig = null
    this.#initial_quadtree()
  }

  #bounding_box_to_quadtreebox(bounding_box) {
    var top = bounding_box[1][1]
    var left = bounding_box[0][0]
    var width = Math.abs(bounding_box[1][0] - bounding_box[0][0])
    var height = Math.abs(bounding_box[1][1] - bounding_box[0][1])
    // for origin point (0,0) is most left top point, y aix is reverse to cartesian coord
    var box = new QGdstk.QuadtreeBox(left, -top, width, height)
    return box
  }

  #path_bounding_box(path) {
    var points = path.spine()
    var x = []
    var y = []
    points.forEach(element => {
      x.push(element[0])
      y.push(element[1])
    })
    var x_max = Math.max(x)
    var x_min = Math.min(x)
    var y_max = Math.max(y)
    var y_min = Math.min(y)
    return [
      [x_min, y_min],
      [x_max, y_max],
    ]
  }

  #initial_quadtree() {
    var bounding_box = [
      [-Infinity, -Infinity],
      [Infinity, Infinity],
    ]
    var box = this.#bounding_box_to_quadtreebox(bounding_box)
    this.quadtree = new QGdstk.Quadtree(box)
    this.id_body_table = new Map()
    this.current_id = 0
    this.#process_cell(this.cell)
  }

  #process_cell(cell) {
    var flexpaths = cell.flexpaths
    for (let i = 0; i < flexpaths.length; i++) {
      const flexpath = flexpaths[i]
      this.addNode(flexpath)
    }

    var polygons = cell.polygons
    for (let i = 0; i < polygons.length; i++) {
      const polygon = polygons[i]
      this.addNode(polygon)
    }

    var labels = cell.labels
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i]
      this.addNode(label)
    }

    var referens = cell.referens
    for (let i = 0; i < referens.length; i++) {
      const ref = referens[i]
      this.addNode(ref)
    }
  }

  reBuild() {
    this.#initial_quadtree()
  }

  addNode(el, isUpdate = false) {
    let aabb = el.js_obj.bounding_box
    const TYPE = el.$$?.ptrType.name //el.constructor.name
    if (TYPE == 'GdsEllipse*') {
      aabb = el.js_obj.borderBox?.aabb
    }
    if (!aabb) {
      aabb = [
        [0, 0],
        [0, 0],
      ]
    }
    var box = this.#bounding_box_to_quadtreebox(aabb)
    if (!isUpdate || !el.js_obj?.tree_id) {
      var node = new QGdstk.QuadtreeNode(this.current_id, box)
      el.js_obj.tree_id = this.current_id
      this.id_body_table.set(this.current_id, { obj: el, node })
      this.quadtree.add(node)
      this.current_id++
    } else {
      var node = new QGdstk.QuadtreeNode(el.js_obj.tree_id, box)
      this.id_body_table.set(el.js_obj.tree_id, { obj: el, node })
      this.quadtree.add(node)
    }
  }

  removeNode(el) {
    let node = this.id_body_table.get(el.js_obj.tree_id)?.node
    if (!node) return false
    this.id_body_table.delete(el.js_obj.tree_id)
    this.quadtree.remove(node)
    return true
  }

  updateNode(el) {
    let exist = this.removeNode(el)
    if (exist) {
      this.addNode(el, true)
    }
  }

  query(x, y, width, height) {
    var box = new QGdstk.QuadtreeBox(x, -y, width, height)
    var node = this.quadtree.query(box)
    var result = []
    for (let i in node) {
      result.push(this.id_body_table.get(node[i].id).obj)
    }
    return result
  }

  queryByAABB(aabb, flag, boxShape, layerState) {
    var quadtreebox = this.#bounding_box_to_quadtreebox(aabb)
    var node = this.quadtree.query(quadtreebox)
    var result = []
    if (flag) {
      node = node.filter(n => quadtreebox.contains(n.box))
    }
    let rect
    if (boxShape) {
      rect = boxShape
    } else {
      rect = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]])
    }

    for (let i in node) {
      let item = this.id_body_table.get(node[i].id).obj
      if (objlockOrHide(item, layerState)) continue
      //   if (this.showConfig) {
      //     if (!this.showConfig.pinName && item.belong_to?.constructor.name === 'Pin') continue
      //     if (!this.showConfig.netName && item.belong_to?.constructor.name === 'CpLine') continue
      //     if (!this.showConfig.normalLabel && item.constructor.name === 'Label' && item.belong_to == null) continue
      //   }
      if (!flag) {
        //判断完全包围或者内部有相交
        if (quadtreebox.contains(node[i].box) || rectHitObjs(rect, item)) {
          result.push(item)
        }
      } else {
        result.push(item)
      }
    }
    return result
  }

  queryPartObjByAABB(aabb, flag, layerState) {
    var quadtreebox = this.#bounding_box_to_quadtreebox(aabb)
    var node = this.quadtree.query(quadtreebox)
    var result = []
    if (flag) {
      node = node.filter(n => quadtreebox.contains(n.box))
    }
    let rect = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]])
    for (let i in node) {
      let item = this.id_body_table.get(node[i].id).obj
      if (objlockOrHide(item, layerState)) continue
      //   if (this.showConfig) {
      //     if (!this.showConfig.pinName && item.belong_to?.constructor.name === 'Pin') continue
      //     if (!this.showConfig.netName && item.belong_to?.constructor.name === 'CpLine') continue
      //     if (!this.showConfig.normalLabel && item.constructor.name === 'Label' && item.belong_to == null) continue
      //   }
      if (!flag) {
        //判断完全包围或者内部有相交
        if (quadtreebox.contains(node[i].box) || rectHitPartObjs(rect, item)) {
          result.push(item)
        }
      } else {
        result.push(item)
      }
    }
    return result
  }
}

export function queryObjByPos(LAYOUT, pos) {
  return LAYOUT.QUAD_TREE.queryByAABB(getMouseAABB(pos, LAYOUT.AXIS.scale), false, null, LAYOUT.LAYER_STATE)
}

export function queryPartObjByPos(LAYOUT, pos, dist) {
  return LAYOUT.QUAD_TREE.queryPartObjByAABB(getMouseAABB(pos, LAYOUT.AXIS.scale, dist), false, LAYOUT.LAYER_STATE)
}

export function queryAdsropObjByPos(LAYOUT, pos, dist) {
  let QT = LAYOUT.QUAD_TREE
  if (LAYOUT.EDIT_REF) {
    QT = LAYOUT.RENDER_CELL.js_obj.QUAD_TREE
  }
  return QT.queryPartObjByAABB(getMouseAABB(pos, LAYOUT.AXIS.scale, dist), false, LAYOUT.LAYER_STATE)
}

export function checkInsideCheckedObjs(LAYOUT, pos) {
  if (LAYOUT.MODE === BOARDMODE.OVER_SEL) {
    let hits = queryObjByPos(LAYOUT, pos)
    for (let i = 0; i < hits.length; i++) {
      const hit = hits[i]
      if (hit.js_obj.STATE.checked) {
        return true
      }
    }
  } else if (LAYOUT.MODE === BOARDMODE.PART_SEL) {
    let hits = queryPartObjByPos(LAYOUT, pos)
    const aabb = getMouseAABB(pos, LAYOUT.AXIS.scale)
    let mouseRect = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]])
    for (let i = 0; i < hits.length; i++) {
      const hit = hits[i]

      if (hit.js_obj.STATE.checked) {
        return true
      }
      if (hit.js_obj.STATE.partChecked) {
        const checkedLines = hit.js_obj.checked_lines_pos
        for (let j = 0; j < checkedLines.length; j++) {
          const line = checkedLines[j]
          if (insertLine(mouseRect, line)) {
            return true
          }
        }
        let points = hit.points
        const TYPE = hit.$$?.ptrType.name //hit.constructor.name
        if (TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*') {
          points = hit.js_obj.borderBox.points
        }
        const checkedPoints = hit.js_obj.checked_points_index.map(index => points[index])
        for (let k = 0; k < checkedPoints.length; k++) {
          if (mouseRect.contain(checkedPoints[k])) {
            return true
          }
        }
      }
    }
  }

  return false
}

export function checkInsideCheckedObjsPart(LAYOUT, pos) {
  let point = LAYOUT.STATUS.preCheckPoint //预选中的点 判断点是否已选中
  let line = LAYOUT.STATUS.preCheckLine //预选中的线  判断线是否已选中

  // let hits = queryObjByPos(LAYOUT, pos)
  // for (let i = 0; i < hits.length; i++) {
  //   const hit = hits[i]
  //   if (hit.js_obj.STATE.checked) {
  //     return true
  //   }
  // }
  return false
}
export function getMouseAABB(pos, scale, d) {
  let dist = 5 / scale
  if (d) {
    dist = d / scale
  }
  if (dist < 0.001) {
    dist = 0.001
  }
  return [
    [pos[0] - dist, pos[1] - dist],
    [pos[0] + dist, pos[1] + dist],
  ]
}
