var QGdstk = null
var KDTreeFast = null
var QGeosJs = require('./geos')
var AutoToolLibs = require('./libs')
const Config = require('./config.js')

// import * as kdtreefast from '/public/wasm/kdtree-fast'
// import * as kdtreefast from 'kdtree-fast'
;(function () {
  function AutoToolAirBridge(library, cell) {
    QGdstk = window.QGdstk
    KDTreeFast = window.KDTreeFast
    this.library = library
    this.cell = cell
    // this.cell_name = cell_name
    this.libs = new AutoToolLibs()
    this.conf = {
      step: 200,
      end_min_distance: 50,
      boom: {
        A: 48,
        A1: 17,
        A2: 16,
        A3: 14,
        A4: 21,
        layer1: 5,
        layer2: 6,
        datatype1: 0,
        datatype2: 0,
      },
      corner_min_distance: 30,
      self_min_distance: 100,
      inflect_type: 'Avoid',
      inflect_avoid_min_distance: 20,
      inflect_circular_radius: 20,
      place: {
        layer: 3,
        datatype: 0,
      },
      avoid: [
        {
          layer: 4,
          datatype: 0,
          distance: 100,
        },
      ],
    }
    this.console_name = '[AirBridge]:'
    this.cell_ab = null
  }

  AutoToolAirBridge.prototype.setConf = function (conf) {
    this.conf = conf
  }

  AutoToolAirBridge.prototype.run = function (data) {
    this.conf = data

    let size = this.conf.boom
    this.cell_ab = this.AB_1(size.layer_pier, size.datatype_pier, size.layer_deck, size.datatype_deck, size.A, size.A1, size.A2, size.A3, size.A4)
    this.cell_ab.remove(this.cell_ab.labels)
    let vertices_max_distance = Math.sqrt(Math.pow(size.A, 2) + Math.pow(size.A4, 2))

    // 创建避开kd树集合
    let avoid_kdtree = []
    let avoid = this.conf.avoid
    for (let i = 0; i < avoid.length; i++) {
      let item = avoid[i]

      // let distance = Math.floor(item.distance / Math.cos(Math.PI / 6))
      let distance = item.distance
      let points = []

      // 按图层区分paths
      if (item.layer !== this.conf.place_layer) {
        let avoid_paths = this.cell.get_paths(true, -1, item.layer, item.datatype)
        if (avoid_paths) {
          let paths_points = this.libs.split_paths_k(avoid_paths, distance)
          paths_points.forEach(element => {
            points.push(element)
          })
        }
      }

      // 获取指定图层多边形
      let avoid_polygons = this.cell.get_polygons(true, false, -1, item.layer, item.datatype)
      if (avoid_polygons) {
        let polygons_points = this.libs.split_polygons_edge_k(avoid_polygons, distance, true)
        polygons_points.forEach(element => {
          points.push(element)
        })
      }

      // 创建避开的KDTree
      if (points) {
        let kd = new QGdstk.KDTree(points)
        avoid_kdtree.push({
          kdtree: kd,
          // distance: item.distance + vertices_max_distance,
          distance: item.distance / Math.cos(Math.PI / 6) + vertices_max_distance,
          // distance: item.distance,
          layer: item.layer,
          datatype: item.datatype,
        })
      }
    }

    // 按照起点、终点、拐点偏移量切割放置的骨架线
    let place_paths = this.getPathsByLayer(this.cell.flexpaths, this.conf.place_layer, this.conf.place_datatype)

    // 获取放置位置
    let place_pos = this.getPlacePosFasterCircle(place_paths, avoid_kdtree)

    // 放置空气桥
    let res = this.placeAriBridgeKernel(place_pos)
    return res
  }

  AutoToolAirBridge.prototype.checkPos = function () {}

  // 弧度放置空气桥
  AutoToolAirBridge.prototype.getPlacePosFasterCircle = function (lines = [], avoid = []) {
    function checkPosValid(pos) {
      // 检测首尾
      let d_start = Math.pow(start_pos[0] - pos[0], 2) + Math.pow(start_pos[1] - pos[1], 2)
      if (d_start < Math.pow(end_min_distance, 2)) return false
      let d_end = Math.pow(end_pos[0] - pos[0], 2) + Math.pow(end_pos[1] - pos[1], 2)
      if (d_end < Math.pow(end_min_distance, 2)) return false
      //检测自身
      let inds = kdtree_self.within(pos, Math.pow(self_min_distance, 2))
      if (inds.length > 0) {
        return false
      }
      // 检测拐点
      if (inflect_type == 'Avoid') {
        for (let i = 0; i < inflects_pos.length; i++) {
          let d = Math.pow(inflects_pos[i][0] - pos[0], 2) + Math.pow(inflects_pos[i][1] - pos[1], 2)
          if (d < Math.pow(inflect_avoid_min_distance, 2)) return false
        }
      }

      // 检测其他图层
      for (let i = 0; i < avoid.length; i++) {
        let ad = avoid[i]
        let inds = ad.kdtree.neighborhood_indices_pos(pos[0], pos[1], ad.distance)
        if (inds.length > 0) {
          return false
        }
      }
      return true
    }

    // 配置
    let step = this.conf.step
    // let step_small = this.conf.step_small
    let step_small = Math.ceil(step / 10)
    let self_min_distance = this.conf.self_min_distance
    let end_min_distance = this.conf.end_min_distance
    let inflect_type = this.conf.inflect_type
    let inflect_avoid_min_distance = this.conf.inflect_avoid_min_distance
    let inflect_circular_radius = this.conf.inflect_circular_radius
    // 其他参数
    let place = [] // 有效放置点
    let kdtree_self = new KDTreeFast.KdTree(2) // 自身距离检测kdtree
    let step_pos = [0, 0] // 当前走步位置
    let start_pos = [] // 起点
    let end_pos = [] // 终点
    let inflects_pos = [] // 拐点
    let distance = 0
    let step_sum = 0 // 步长累加
    let step_small_ok = false
    let pt = [] // 前一个点位
    let ag = 0 // 角度
    let isok = false
    let line = null
    // 取出圆弧中心线
    let points = null
    let path_center = null
    let new_points = null
    // 进度
    let len = lines.length
    let process_n = Math.ceil(len / 10)
    for (let idx = 0; idx < lines.length; idx++) {
      if (idx % process_n == 0) {
      } else if (idx == len - 1) {
      }
      line = lines[idx]
      // points = line.spine()
      points = line.points
      if (points.length < 1) {
        continue
      }
      // 首尾点
      start_pos = points[0]
      end_pos = points[points.length - 1]
      // 拐点
      if (inflect_type == 'Avoid') {
        inflects_pos = points.slice(1, -1)
        new_points = points
      }
      if (inflect_type == 'Circular') {
        // 取出圆弧中心线
        path_center = new QGdstk.FlexPath(points, 1, 0, 'round', 'flush', inflect_circular_radius, null, 1e-2, false, true, 0, 0)
        new_points = path_center.element_center()
      }

      step_sum = 0
      let pos_ok = []
      let tmp_lines = []

      for (let i = 1; i < new_points.length; i++) {
        tmp_lines.push([new_points[i - 1], new_points[i]])
      }
      new_points = this.libs.split_lines(tmp_lines, step_small)

      step_sum = step
      for (let i = 1; i < new_points.length; i++) {
        let p1 = new_points[i - 1]
        let p2 = new_points[i]
        ag = Math.atan2(p1[1] - p2[1], p1[0] - p2[0])
        distance = Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2))
        step_sum += distance
        if (step_sum >= step - step_small / 2) {
          isok = checkPosValid(p2)
          if (isok) {
            kdtree_self.add(p2, 0)
            place.push({ pos: [p2[0], p2[1]], angle: ag - Math.PI })
            step_sum = 0.0
          }
        }
      }
    }

    return place
  }

  AutoToolAirBridge.prototype.getPlacePosFaster = function (lines = [], avoid = [], step = 200, self_min_distance = 90) {
    let len = lines.length
    let process_n = Math.ceil(len / 10)
    let valid = []

    let kdtree_self = new KDTreeFast.KdTree(2)
    for (let line_idx = 0; line_idx < len; line_idx++) {
      if (line_idx % process_n == 0) {
      } else if (line_idx == len - 1) {
      }
      let line = lines[line_idx]
      if (line.length < 1) {
        continue
      }

      // 起点 终点
      let p1 = line[0]
      let p2 = line[line.length - 1]
      let dx = p2[0] - p1[0]
      let dy = p2[1] - p1[1]
      //// 线段角度
      let angle = this.libs.angle_direction(p1, p2)
      // 正常步距
      let d = Math.sqrt(dx * dx + dy * dy)
      let num = d / step
      let x_step = dx / num
      let y_step = dy / num
      // 微调步距
      let step_small = step / 10
      let num_small = d / step_small
      let x_step_small = dx / num_small
      let y_step_small = dy / num_small
      // step_nums = 10
      let pos = p1
      // 判断是否走完当前线段
      // while (Math.abs(angle - this.libs.angle_direction(pos, p2)) < 0.1) {
      let break_flag = false
      let exist_other = false
      while (1) {
        if (Math.abs(angle - this.libs.angle_direction(pos, p2)) > 0.1) {
          pos = p2
          break_flag = true
        }
        exist_other = true
        // 检测周围有没有其他空气桥
        let inds = kdtree_self.within(pos, self_min_distance * self_min_distance)
        if (inds.length > 0) {
          pos[0] = pos[0] + x_step_small
          pos[1] = pos[1] + y_step_small
          // 在当前位置进行微调步距，判断是否走完当前线段
          while (Math.abs(angle - this.libs.angle_direction(pos, p2)) < 0.1) {
            inds = kdtree_self.within(pos, self_min_distance * self_min_distance)
            if (inds.length > 0) {
              // 附近有空气桥或其他需要避开的器件，继续往前走, 微调步距
              // pos = [pos[0]+x_step/step_nums, pos[1]+y_step/step_nums]
              pos[0] = pos[0] + x_step_small
              pos[1] = pos[1] + y_step_small
              continue
            } else {
              exist_other = false
              break
            }
          }
        } else {
          exist_other = false
        }

        for (let i = 0; i < avoid.length; i++) {
          let ad = avoid[i]
          inds = ad.kdtree.neighborhood_indices_pos(pos[0], pos[1], ad.distance)
          if (inds.length > 0) {
            exist_other = true
          }
        }
        if (!exist_other) {
          valid.push({ pos: pos, angle: angle })
          kdtree_self.add(pos, line_idx)
        }
        // 继续下一次走步
        pos = [pos[0] + x_step, pos[1] + y_step]
        if (break_flag) {
          break
        }
      }
    }
    return valid
  }

  AutoToolAirBridge.prototype.cutLine = function (lines, end_min_distance, corner_radius) {
    let res = []
    let len = lines.length
    let process = Math.ceil(len / 10)
    for (let i = 0; i < len; i++) {
      if (i % process == 0) {
      } else if (i == len - 1) {
      }
      let line = lines[i]
      let m = line.length
      if (m < 2) continue

      let start_p = new QGeosJs('Point', line[0])
      let end_p = new QGeosJs('Point', line[m - 1])
      let line_s = new QGeosJs('LineString', line)

      // difference
      line_s = line_s.difference(start_p.buffer(end_min_distance))
      if (line_s == null) {
        continue
      }
      line_s = line_s.difference(end_p.buffer(end_min_distance))
      if (line_s == null) {
        continue
      }

      for (let j = 1; j < m - 1; j++) {
        let p = new QGeosJs('Point', line[j])
        line_s = line_s.difference(p.buffer(corner_radius))
      }
      if (line_s == null) {
        continue
      }
      if (line_s.type == 'LineString') {
        res.push(line_s.coordinates)
      }
      if (line_s.type == 'MultiLineString') {
        for (let j = 0; j < line_s.coordinates.length; j++) {
          res.push(line_s.coordinates[j])
        }
        // res.push.apply(res, line_s.coordinates)
      }
    }
    return res
  }

  AutoToolAirBridge.prototype.pathsTranLines = function (paths) {
    let lines = []
    for (let i = 0; i < paths.length; i++) {
      lines.push(paths[i].spine())
    }
    return lines
  }
  AutoToolAirBridge.prototype.AB_1 = function (layer_pier = 0, datatype_pier = 0, layer_deck = 0, datatype_deck = 0, A = 48, A1 = 17, A2 = 16, A3 = 14, A4 = 21) {
    // points
    var p1 = [-A4 / 2, 0]
    var p2 = [-A4 / 2, A]
    var p3 = [A4 / 2, A]
    var p4 = [A4 / 2, 0]
    var p5 = [-A1 / 2, (A - A3 * 2 - A2) / 2]
    var p6 = [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3]
    var p7 = [A1 / 2, (A - A3 * 2 - A2) / 2 + A3]
    var p8 = [A1 / 2, (A - A3 * 2 - A2) / 2]
    var p9 = [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3 + A2]
    var p10 = [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3 * 2 + A2]
    var p11 = [A1 / 2, (A - A3 * 2 - A2) / 2 + A3 * 2 + A2]
    var p12 = [A1 / 2, (A - A3 * 2 - A2) / 2 + A3 + A2]

    // polygon
    var deck = new QGdstk.Polygon([p1, p2, p3, p4], layer_deck, datatype_deck)
    var pier_upper = new QGdstk.Polygon([p9, p10, p11, p12], layer_pier, datatype_pier)
    var pier_lower = new QGdstk.Polygon([p5, p6, p7, p8], layer_pier, datatype_pier)
    var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, 0, 0)

    // translate tmp
    var cell_tmp = new QGdstk.Cell('AB_1')
    cell_tmp.add([deck, pier_upper, pier_lower])
    let box = cell_tmp.bounding_box()
    let center = [box[0][0] + (box[1][0] - box[0][0]) / 2, box[0][1] + (box[1][1] - box[0][1]) / 2]
    deck.translate(-center[0], -center[1])
    pier_upper.translate(-center[0], -center[1])
    pier_lower.translate(-center[0], -center[1])

    // cell
    var cell = new QGdstk.Cell('AB_1')
    cell.add([deck, pier_upper, pier_lower])
    // cell.add(O)

    return cell
  }

  AutoToolAirBridge.prototype.placeAriBridge = function (pos) {
    if (!this.cell_ab) {
    }

    let references = []
    for (let i = 0; i < pos.length; i++) {
      let t = pos[i]
      let ref = new QGdstk.Reference(this.cell_ab, t.pos, t.angle, 1, false, 1, 1, null)
      references.push(ref)
    }
    // this.library.add(airbridge_cell)
    // this.cell.add(references)
    let data = {
      add_cells: [this.cell_ab],
      add_references: references,
    }
    return data
  }

  AutoToolAirBridge.prototype.placeAriBridgeKernel = function (pos) {
    let kernel_cell = this.libs.GdstkCell2KernelCell(this.cell_ab, this.conf.fileLayerList)
    let references = []
    for (let i = 0; i < pos.length; i++) {
      let t = pos[i]
      // let ref = new window.Kernel.Reference(kernel_cell, t.pos, t.angle, 1, false, 1, 1, [0, 0])
      let ref = new window.Kernel.Reference()
      ref.cell = kernel_cell
      ref.origin = t.pos
      ref.rotation = t.angle
      references.push(ref)
    }
    let data = {
      add_cells: [kernel_cell],
      add_references: references,
    }
    return data
  }

  AutoToolAirBridge.prototype.getPathsByLayer = function (paths, layer, datatype) {
    let paths_layer = []
    for (let i = 0, len = paths.length; i < len; i++) {
      if (paths[i].layers[0] == layer && paths[i].width[0] == 0) {
        paths_layer.push(paths[i])
      }
    }
    return paths_layer
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolAirBridge
  } else {
    window.AutoToolAirBridge = AutoToolAirBridge
  }
})()
