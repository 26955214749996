import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'

export function initTextGeo() {
  let textGeo = {}
  let number = '1234567890.'
  number.split('').forEach(n => {
    let font_geo = generateFontGeometry(n, 'sw', 12, 1)
    let new_geo = new THREE.BufferGeometry()
    new_geo.attributes = font_geo.attributes
    new_geo.groups = font_geo.groups
    textGeo[n] = new_geo
  })

  window.RulerTextGeo = textGeo
}
export function generateRulerLabelGeometry(num_str, anchor) {
  let geo_array = []
  let num_arry = num_str.split('')
  let space = -6
  num_arry.forEach((n, index) => {
    let geo = RulerTextGeo[n].clone()
    if (n == '.') {
      space += 10
    } else {
      space += 10
    }
    geo.translate(space, 0, 0)
    geo_array.push(geo)
  })
  let mergedNumGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(geo_array)

  mergedNumGeometries.computeBoundingBox()
  return mergedNumGeometries
}
export function generateRulerLabelMesh(num, pos, rotation, anchor, mat) {
  let fontMesh = new THREE.Mesh(generateRulerLabelGeometry(num, anchor), mat)
  fontMesh.rotation.z = rotation
  fontMesh.position.set(pos[0], pos[1], 0)

  return fontMesh
}

export function generateTextMesh(text, mat, size, color) {
  const shapes = ThreeFont.generateShapes(text, size)
  if (!mat) {
    mat = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: 1,
      // side: THREE.DoubleSide,
      // wireframe:true
    })
  }

  const geometry = new THREE.ShapeGeometry(shapes)
  geometry.computeBoundingBox()
  // const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x)

  // geometry.translate(xMid, 0, 0)
  return new THREE.Mesh(geometry, mat)
}

export function generateFontGeometry2(text, anchor, rotation, pos, size = 16) {
  const geometry = new TextGeometry(text, {
    font: ThreeFont,
    size: size,
    height: 0,
    curveSegments: 4,
  })
  geometry.computeBoundingBox()
  transGeometryByAnchor(geometry, size, anchor)

  // geometry.rotation.z = rotation
  // geometry.translate(pos[0], pos[1], 0.1)
  return geometry
}

export function generateFontGeometry(text, anchor, size = 16, cs = 4, kp = false, mat4 = null) {
  // const shapes = ThreeFont.generateShapes(text, size)
  // const geometry = new THREE.ShapeGeometry(shapes)
  const geometry = new TextGeometry(text, {
    font: ThreeFont,
    size: size,
    height: 0,
    curveSegments: cs,
  })
  geometry.computeBoundingBox()
  transGeometryByAnchor(geometry, size, anchor, kp)
  geometry.params = {
    text,
    anchor,
    size,
    cs,
    kp,
    mat4,
  }
  if (mat4) {
    geometry.applyMatrix4(mat4)
  }
  // geometry.computeBoundingBox()
  return geometry
}

export function generateLabelMesh(text, pos, rotation, anchor, mat, size = 16, cs = 4, kp = false) {
  let fontMesh = new THREE.Mesh(generateFontGeometry(text, anchor, size, cs, kp), mat)
  fontMesh.rotation.z = rotation
  fontMesh.position.set(pos[0], pos[1], 0.1)
  // applyObjMatix(fontMesh)

  return fontMesh
}

export function generateLabelStaticMesh(label, anchor, mat, cs = 4, kp = false) {
  const mat4 = computeFontMat(label)

  let fontMesh = new THREE.Mesh(generateFontGeometry(label.text, anchor, label.font_size, cs, kp, mat4), mat)
  // fontMesh.rotation.z = rotation
  // fontMesh.position.set(pos[0], pos[1], 0.1)
  // applyObjMatix(fontMesh)

  return fontMesh
}

export function computeFontMat(label) {
  let trans = new THREE.Object3D()
  let x_mirror = label.x_reflection ? -1 : 1
  trans.scale.set(label.magnification, label.magnification * x_mirror, 1)
  trans.rotation.set(0, 0, label.angle)
  trans.position.set(label.origin[0], label.origin[1], 0)
  trans.updateMatrix()
  return trans.matrix
}
//几何顶点应用对象数据
export function applyObjMatix(object) {
  object.updateMatrix()

  object.geometry.applyMatrix4(object.matrix)

  object.position.set(0, 0, 0)
  object.rotation.set(0, 0, 0)
  object.scale.set(1, 1, 1)
  object.updateMatrix()
}

export function transGeometryByAnchor(geometry, h, anchor, kp = false) {
  let w = geometry.boundingBox.max.x - geometry.boundingBox.min.x
  geometry.translate(-geometry.boundingBox.min.x, 0, 0) //修正输入数字时左下角没对齐问题

  if (anchor == 'sw') {
    //默认左下角
    return
  }
  if (anchor == 'o') {
    const offx = w / 2
    const offy = h / 2
    geometry.translate(-offx, -offy, 0)
  } else if (anchor == 'e') {
    const offx = w
    const offy = h / 2
    geometry.translate(-offx, -offy, 0)
  } else if (anchor == 's') {
    const offx = w / 2
    let offy = 0
    if (kp) offy = h / 2
    geometry.translate(-offx, offy, 0)
  } else if (anchor == 'w') {
    const offy = h / 2
    geometry.translate(0, -offy, 0)
  } else if (anchor == 'n') {
    const offx = w / 2
    let offy = h
    if (kp) offy = h + h / 2
    geometry.translate(-offx, -offy, 0)
  } else if (anchor == 'ne') {
    const offx = w
    const offy = h
    geometry.translate(-offx, -offy, 0)
  } else if (anchor == 'se') {
    const offx = w
    geometry.translate(-offx, 0, 0)
  } else if (anchor == 'nw') {
    const offy = h
    geometry.translate(0, -offy, 0)
  }
  // if (anchor == 'sw') {
  // }
}
