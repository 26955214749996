import {
  getScreenAABB,
  updateObjMat,
  updateObjCheckedPoints,
  removePartCheckData,
  setObjStateCheck,
  reBuildGDSGraphic,
  getGdsPolygon,
  aabbCenter,
  copyObjs,
  copyObj,
  getPosition,
  updateLines,
  moveLines,
  movePolygonLines,
  getRectInfo,
  getAABBCenter,
  updateMouseOffSet,
  getSlope,
  getVerticalCrossPoint,
  getObjGdsPolygon,
  updateCellInsRefs,
  getLineOffset,
  reBuildGdsData,
  copyFileObj,
  addObjToCell,
  copyObjProperty,
  applayObjProperty,
  mergeSameLayerPolygons,
  transCopyDataToRef,
  isParamsDevice,
} from './graphics-util'
import { checkCanTrigger, canCutObj, canCutArea, canMerge, canBoolean, validAABB, canEnterRef } from '../validator/validator.js'
import { BOARDMODE, BOARDMODEMAP } from '../const/board-status'
import { queryObjByPos, queryPartObjByPos, getMouseAABB } from './quad-tree'
import { getNearLine, getNearPoint, getNearAdropPoint, getNearAdropLine, boxContain, getHitPointsIndexesByAABB, axisHitPolygon, axisHitLine, getMidPoint, insertPolygon } from './collision'
import { sliceLines } from './boolean'
import { deepClone } from '../../utils'
import {
  addCellInsToCurrentCell,
  expanRefs,
  getExpandRefs,
  getRefPolygons,
  getRefsCellsPtr,
  getRepetitionCellsPtr,
  getCellPtrs,
  ReBuildCellRefs,
  ReBuildTargetCellRefs,
  transToRef,
  transToRefPoints,
  transOutRef,
  transOutRefPoints,
  existCell,
} from './reference-util'
import { getPDKInfo_api } from '@/api/objectStorage/objectStorage.js'
import { getObjectDataFn } from '@/components/homes/fileList/fileUtils.js'
import { fileKernelCell2Gdstk, isCircle, saveCellFn } from '../../../components/homes/fileList/function/fileListPublicFn'
import router from '@/router'
import { applyLayerIdToObj, getLayerIdByNum } from './layer-util'
import bus from '@/components/common/bus'
import i18n from '../../../common/lang/i18n.js'
import { Message } from 'element-ui'
import { getFileLayerListFromTable } from '../../../components/homes/rightList/layerListFn'
import { getPermission } from '../../../components/homes/fileList/function/fileListApiFn'
const BasicGraphType = ['GdsFlexpath*', 'GdsRectangle*', 'GdsEllipse*', 'GdsPolygon*']
const alignMap = {
  竖向中线对齐: 0,
  横向中线对齐: 1,
  左对齐: 2,
  右对齐: 3,
  下对齐: 4,
  上对齐: 5,
  竖向中线对齐: 6,
  横向中线对齐: 7,
}
//移动画板
export function moveBoard(LAYOUT, e) {
  const AXIS = LAYOUT.AXIS
  const delta = AXIS.width * 0.2 //以画板20%移动
  let trigger_flag = checkCanTrigger()
  if (!trigger_flag) return
  let needReRender = false
  if (e.key === 'ArrowRight') {
    AXIS.dx -= delta
    needReRender = true
  } else if (e.key === 'ArrowLeft') {
    AXIS.dx += delta
    needReRender = true
  } else if (e.key === 'ArrowUp') {
    AXIS.dy += delta
    needReRender = true
  } else if (e.key === 'ArrowDown') {
    AXIS.dy -= delta
    needReRender = true
  }
  if (needReRender) {
    LAYOUT.AXIS.render()
    LAYOUT.STAGE.mouseMove(LAYOUT, true)
  }
}

//对象自适应
export function adaptObj(LAYOUT, onlyCell = false, onlyObj = false) {
  let aabb = null
  if (onlyCell) {
    aabb = LAYOUT.CELL.bounding_box()
  } else if (onlyObj) {
    let objs = LAYOUT.STATUS.checked_objs
    if (objs.length) {
      LAYOUT.STATUS.updateCheckedObjsAABB()
      aabb = LAYOUT.STATUS.checked_objs_aabb
    } else {
      return
    }
  } else {
    aabb = getCheckedObjsBoundingBox(LAYOUT)
  }
  if (validAABB(aabb)) {
    adaptScreen(LAYOUT, aabb)
  }
}

//区域自适应屏幕
export function adaptScreen(LAYOUT, target_aabb) {
  if (!target_aabb) return
  let AXIS = LAYOUT.AXIS
  let screen_aabb = getScreenAABB(AXIS)
  //计算坐标系统缩放比例
  const target_h = target_aabb[1][1] - target_aabb[0][1]
  const stage_h = screen_aabb[1][1] - screen_aabb[0][1]
  const target_w = target_aabb[1][0] - target_aabb[0][0]
  const stage_w = screen_aabb[1][0] - screen_aabb[0][0]
  const scale_h = stage_h / target_h
  const scale_w = stage_w / target_w
  let scale = AXIS.scale * (scale_w > scale_h ? scale_h : scale_w) * 0.9
  if (scale > AXIS.scale_max * 2) {
    scale = AXIS.scale_max * 2
  } else if (scale < AXIS.scale_min / 2) {
    scale = AXIS.scale_min / 2
  }

  AXIS.scale = scale
  //计算坐标系统偏移量
  const centerX = target_aabb[0][0] + (target_aabb[1][0] - target_aabb[0][0]) / 2
  const centery = target_aabb[0][1] + (target_aabb[1][1] - target_aabb[0][1]) / 2
  AXIS.dx = AXIS.width / 2 - centerX * AXIS.scale
  AXIS.dy = AXIS.height / 2 + centery * AXIS.scale
  LAYOUT.AXIS.render()
  LAYOUT.STAGE.resize(LAYOUT)
}

//返回选中对象包围盒，没有则返回cell包围盒
export function getCheckedObjsBoundingBox(LAYOUT) {
  let aabb = null
  let objs = LAYOUT.STATUS.checked_objs
  if (objs.length) {
    LAYOUT.STATUS.updateCheckedObjsAABB()
    aabb = LAYOUT.STATUS.checked_objs_aabb
  } else {
    aabb = LAYOUT.CELL.bounding_box()
  }
  return aabb
}

//预选中整体对象
export function updatePreCheckObj(LAYOUT, pos) {
  let hits = queryObjByPos(LAYOUT, pos)
  let preCheckObj = null
  let all_len = hits.length
  if (all_len) {
    hits.sort((a, b) => b.js_obj.tree_id - a.js_obj.tree_id) //排序
    let hits_no_checked = hits.filter(hit => !hit.js_obj.STATE.checked) //过滤未选中
    let index = null
    let no_checked_len = hits_no_checked.length //没有被选中的元素

    if (no_checked_len) {
      let index = getIndex(LAYOUT, hits)

      if (index !== null && hits[index]) {
        preCheckObj = hits[index]
        preCheckObj.js_obj.STATE.preCheck = true
        updateObjMat(preCheckObj, LAYOUT)
      }
    }
  }
  LAYOUT.STATUS.setPreCheckObj(preCheckObj, LAYOUT)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
}

//预选中部分对象
export function updatePartPreCheckObj(LAYOUT, pos) {
  let hits = queryPartObjByPos(LAYOUT, pos)
  let all_len = hits.length
  let preCheckPoint = null
  let preCheckLine = null
  let preCheckObj = null
  LAYOUT.STATUS.setPreCheckObj(preCheckObj, LAYOUT)
  if (all_len) {
    let dist = 10 / LAYOUT.AXIS.scale
    preCheckPoint = getNearPoint(pos, dist, hits)

    if (!preCheckPoint) {
      preCheckLine = getNearLine(pos, dist, hits)
      if (!preCheckLine) {
        updatePreCheckObj(LAYOUT, pos)
      }
    }
  }

  LAYOUT.STATUS.setPreCheckPoint(preCheckPoint, LAYOUT)
  LAYOUT.STATUS.setPreCheckLine(preCheckLine, LAYOUT)
  // LAYOUT.STATUS.setPreCheckObj(preCheckObj, LAYOUT)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
}

//点对齐预选择点
export function updatePreCheckAlignPoint(LAYOUT, pos) {
  let hits = queryPartObjByPos(LAYOUT, pos)
  let all_len = hits.length
  let preCheckPoint = null

  if (all_len) {
    let dist = 10 / LAYOUT.AXIS.scale
    preCheckPoint = getNearAdropPoint(pos, dist, hits, LAYOUT.AXIS.scale, LAYOUT.LAYER_STATE)
  }

  LAYOUT.STATUS.setPreCheckPoint(preCheckPoint, LAYOUT)
}

//线对齐预选择线
export function updatePreCheckAlignLine(LAYOUT, pos, mode) {
  let hits = queryPartObjByPos(LAYOUT, pos)
  let all_len = hits.length

  let preCheckLine = null
  if (all_len) {
    let dist = 10 / LAYOUT.AXIS.scale
    if (mode == 'ZLine') {
      preCheckLine = getNearAdropLine(pos, dist, hits, false, false, false, null, LAYOUT.AXIS.scale, LAYOUT.LAYER_STATE)
    } else {
      if (!LAYOUT.STATUS.linesAlign.length) {
        //选择第一条线
        preCheckLine = getNearAdropLine(pos, dist, hits, true, false, false, null, LAYOUT.AXIS.scale, LAYOUT.LAYER_STATE)
      } else {
        const line = LAYOUT.STATUS.linesAlign[0]
        preCheckLine = getNearAdropLine(pos, dist, hits, false, true, true, line, LAYOUT.AXIS.scale, LAYOUT.LAYER_STATE)
      }
    }
  }

  LAYOUT.STATUS.setPreCheckLine(preCheckLine, LAYOUT)
}

function getIndex(LAYOUT, hits) {
  let cover_index = LAYOUT.STATUS.cover_index
  //没有重叠默认第一项
  let index = 0
  //重叠状态下判断，重叠状态有选中对象
  if (cover_index > -1) {
    index = cover_index + 1
  }
  //返回第一项
  let all_len = hits.length
  let last = all_len - 1
  if (index > last && hits[0] && !hits[0].js_obj.STATE.checked) {
    index = 0
  } else {
    for (let i = index; i < all_len; i++) {
      if (!hits[index].js_obj.STATE.checked) {
        index = i
        break
      }
      index++
    }
    if (index > last) {
      LAYOUT.STATUS.hited_objs_id = hits.map(hit => hit.js_obj.tree_id)
      LAYOUT.STATUS.updateCoverIndex()
      index = getIndex(LAYOUT, hits)
    }
  }
  return index
}
//整体选择点击选择对象
export function selectObjByClick(LAYOUT) {
  let mousePos = LAYOUT.STAGE.tools.selectBox.points[0]
  mousePos = transToRef(LAYOUT, mousePos)
  let hits = queryObjByPos(LAYOUT, mousePos)
  hits.sort((a, b) => b.js_obj.tree_id - a.js_obj.tree_id)
  LAYOUT.STATUS.hited_objs_id = hits.map(hit => hit.js_obj.tree_id)
  LAYOUT.STATUS.updateCoverIndex()

  if (hits.length) {
    let hit = LAYOUT.STATUS.ctrl_press ? LAYOUT.STATUS.getHitCheckedObj(hits) : LAYOUT.STATUS.getHitUnCheckedObj(hits)

    if (!hit) return //反选没有选中对象时

    //反选
    if (LAYOUT.STATUS.ctrl_press) {
      setObjStateCheck(hit, false)
      updateObjMat(hit, LAYOUT)
      LAYOUT.STATUS.checked_objs = LAYOUT.STATUS.checked_objs.filter(obj => obj !== hit)
    } else if (LAYOUT.STATUS.shift_press) {
      //多选
      hit.js_obj.STATE.checked = true
      updateObjMat(hit, LAYOUT)
      LAYOUT.STATUS.checked_objs.push(hit)
      LAYOUT.STATUS.checked_objs = noRepeatArray(LAYOUT.STATUS.checked_objs) //去重
    } else {
      LAYOUT.STATUS.checked_objs.forEach(obj => {
        setObjStateCheck(obj, false)
        updateObjMat(obj, LAYOUT)
      })
      hit.js_obj.STATE.checked = true
      updateObjMat(hit, LAYOUT)
      LAYOUT.STATUS.checked_objs = [hit]
    }
  } else {
    if (!LAYOUT.STATUS.ctrl_press && !LAYOUT.STATUS.shift_press) {
      //重置所有
      LAYOUT.STATUS.checked_objs.forEach(obj => {
        setObjStateCheck(obj, false)
        updateObjMat(obj, LAYOUT)
      })
      LAYOUT.STATUS.checked_objs = []
    }
  }

  updatePreCheckObj(LAYOUT, mousePos)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
  LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
  LAYOUT.STAGE.updateCheckedEditRefCheckedObjs(LAYOUT)
}

//整体选择框选对象
export function selectObjsByBox(LAYOUT) {
  let box_points_global = LAYOUT.STAGE.tools.selectBox.points
  let box_points = transToRefPoints(LAYOUT, box_points_global)
  let flag = box_points_global[0][0] > box_points_global[2][0] ? false : true //框选半选模式 全选模式
  let boxShape = new QGdstk.Polygon(box_points) //转换后的形状
  let aabb = boxShape.bounding_box()
  let hits = LAYOUT.QUAD_TREE.queryByAABB(aabb, flag, boxShape, LAYOUT.LAYER_STATE)

  //反选
  if (LAYOUT.STATUS.ctrl_press) {
    hits.forEach(obj => {
      setObjStateCheck(obj, false)
      updateObjMat(obj, LAYOUT)
    })
    LAYOUT.STATUS.checked_objs = LAYOUT.STATUS.checked_objs.filter(obj => !hits.includes(obj))
  } else if (LAYOUT.STATUS.shift_press) {
    //多选
    hits.forEach(obj => {
      obj.js_obj.STATE.checked = true
      updateObjMat(obj, LAYOUT)
    })
    LAYOUT.STATUS.checked_objs.push(...hits)
    LAYOUT.STATUS.checked_objs = noRepeatArray(LAYOUT.STATUS.checked_objs) //去重
  } else {
    LAYOUT.STATUS.checked_objs.forEach(obj => {
      setObjStateCheck(obj, false)
      updateObjMat(obj, LAYOUT)
    })
    LAYOUT.STATUS.checked_objs = hits
    LAYOUT.STATUS.checked_objs.forEach(obj => {
      obj.js_obj.STATE.checked = true
      updateObjMat(obj, LAYOUT)
    })
  }
  LAYOUT.STATUS.updateCheckedObjsAABB()
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
  LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
  LAYOUT.STAGE.updateCheckedEditRefCheckedObjs(LAYOUT)
}

//部分选择点击选中
export function selectObjPartByClick(LAYOUT) {
  let point = LAYOUT.STATUS.preCheckPoint
  let line = LAYOUT.STATUS.preCheckLine
  let remove = LAYOUT.STATUS.ctrl_press
  let add = LAYOUT.STATUS.shift_press
  let replace = !remove && !add
  if (replace) {
    //清空所有
    LAYOUT.STATUS.checked_objs.forEach(obj => {
      obj.js_obj.STATE.checked = false
      obj.js_obj.STATE.preCheck = false
      removePartCheckData(obj)
      updateObjMat(obj, LAYOUT)
    })
  }
  if (point) {
    updateObjCheckedPoints(LAYOUT, point.obj, [point.index], replace, remove, add)
  } else if (line) {
    updateObjCheckedPoints(LAYOUT, line.obj, line.indexes, replace, remove, add)
  } else {
    selectObjByClick(LAYOUT)
    LAYOUT.STATUS.checked_objs.forEach(obj => {
      if (obj.js_obj.STATE.checked) {
        obj.js_obj.STATE.partChecked = false
        const TYPE = obj.$$?.ptrType.name
        if (BasicGraphType.indexOf(TYPE) !== -1) {
          obj.js_obj.checked_points_index = obj.points.map((p, index) => index)
        }
      }
    })
  }

  LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
}

//部分选择框选
export function selectObjsjPartByBox(LAYOUT) {
  let box_points = LAYOUT.STAGE.tools.selectBox.points
  let aabb = new QGdstk.Polygon(box_points).bounding_box()
  let flag = box_points[0][0] > box_points[2][0] ? false : true //框选半选模式 全选模式
  aabb = transToRefPoints(LAYOUT, aabb)
  let hits = LAYOUT.QUAD_TREE.queryPartObjByAABB(aabb, false, LAYOUT.LAYER_STATE)
  let remove = LAYOUT.STATUS.ctrl_press
  let add = LAYOUT.STATUS.shift_press
  let replace = !remove && !add
  if (replace) {
    //清空所有
    LAYOUT.STATUS.checked_objs.forEach(obj => {
      obj.js_obj.STATE.checked = false
      obj.js_obj.STATE.preCheck = false
      removePartCheckData(obj)
      updateObjMat(obj, LAYOUT)
    })
  }

  //可以部分选择的图形
  const canPartCheckObjs = hits.filter(hit => BasicGraphType.indexOf(hit.$$?.ptrType.name) !== -1)
  const rect = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]])
  for (let i = 0; i < canPartCheckObjs.length; i++) {
    const hit = canPartCheckObjs[i]
    const TYPE = hit.$$?.ptrType.name //hit.constructor.name
    //完全选择
    if (boxContain(aabb, hit.js_obj.bounding_box)) {
      hit.js_obj.STATE.checked = true
      hit.js_obj.STATE.partChecked = false
      hit.js_obj.checked_points_index = hit.points.map((p, index) => index)
      updateObjMat(hit, LAYOUT)
    } else {
      //部分选中
      let shape = hit.points
      if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
        shape = hit.js_obj.borderBox.points
      }
      const isLoop = TYPE !== 'GdsFlexpath*'
      let hitIndexes = getHitPointsIndexesByAABB(shape, rect, isLoop, flag)

      if (hitIndexes.length) {
        updateObjCheckedPoints(LAYOUT, hit, hitIndexes, replace, remove, add)
      }
    }
  }
  //不可以部分选择的对象关键点 标签，尺子，器件
  let cantPartCheckObjs = hits.filter(hit => BasicGraphType.indexOf(hit.$$?.ptrType.name) == -1)
  if (flag) {
    cantPartCheckObjs = cantPartCheckObjs.filter(obj => boxContain(aabb, obj.js_obj.bounding_box))
  }

  for (let i = 0; i < cantPartCheckObjs.length; i++) {
    const hit = cantPartCheckObjs[i]
    if (add || replace) {
      hit.js_obj.STATE.checked = true
      LAYOUT.STATUS.checked_objs.push(hit)
    } else if (remove) {
      hit.js_obj.STATE.checked = false
      LAYOUT.STATUS.checked_objs = LAYOUT.STATUS.checked_objs.filter(obj => obj !== hit)
    }
    updateObjMat(hit, LAYOUT)
  }
  if (replace) {
    LAYOUT.STATUS.checked_objs = hits
  }
  LAYOUT.STATUS.checked_objs = noRepeatArray(LAYOUT.STATUS.checked_objs)
  LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
  LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
}

//全选
export function checkAll(LAYOUT) {
  let cell = LAYOUT.CELL

  let checkedObjs = []
  cell.flexpaths.forEach(obj => {
    obj.js_obj.STATE.checked = true
    updateObjMat(obj, LAYOUT)
    checkedObjs.push(obj)
  })
  cell.polygons.forEach(obj => {
    obj.js_obj.STATE.checked = true
    updateObjMat(obj, LAYOUT)
    checkedObjs.push(obj)
  })
  cell.labels.forEach(obj => {
    obj.js_obj.STATE.checked = true
    updateObjMat(obj, LAYOUT)
    checkedObjs.push(obj)
  })
  cell.referens.forEach(obj => {
    obj.js_obj.STATE.checked = true
    checkedObjs.push(obj)
  })
  cell.js_obj.rulers.forEach(obj => {
    obj.js_obj.STATE.checked = true
    updateObjMat(obj, LAYOUT)
    checkedObjs.push(obj)
  })
  LAYOUT.STATUS.checked_objs = checkedObjs
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
  LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
}

//点对齐选择点
export function selectAlignPointByClick(LAYOUT) {
  let point = LAYOUT.STATUS.preCheckPoint

  if (point) {
    LAYOUT.STATUS.recordAlignPoints(point.point)
    if (LAYOUT.STATUS.pointsAlign.length == 2) {
      const start = LAYOUT.STATUS.pointsAlign[0]
      const end = transOutRef(LAYOUT, LAYOUT.STATUS.pointsAlign[1])
      const existPartChecked = LAYOUT.STATUS.checked_objs.filter(obj => obj.js_obj.STATE.partChecked).length
      const existKP = LAYOUT.STATUS.checked_objs.filter(obj => obj.$$?.ptrType.name == 'GdsKeyPoint*').length
      const existRef = LAYOUT.STATUS.checked_objs.filter(obj => obj.$$?.ptrType.name == 'Reference*').length
      if (existPartChecked) {
        recordHistory(LAYOUT, 'shape', BOARDMODEMAP[LAYOUT.MODE])
      } else {
        recordHistory(LAYOUT, 'transform', BOARDMODEMAP[LAYOUT.MODE])
      }
      updateMouseOffSet(LAYOUT.STATUS.checked_objs, start)
      end[0] += LAYOUT.STATUS.pointOffset[0]
      end[1] += LAYOUT.STATUS.pointOffset[1]
      moveObjs(LAYOUT, LAYOUT.STATUS.checked_objs, end)
      LAYOUT.transfromObjs(start, end)
      if (existPartChecked) {
        LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
      }
      if (existKP) {
        LAYOUT.STAGE.updateGlobalKP(LAYOUT)
      }
      if (existRef) {
        LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
        LAYOUT.STAGE.updateRefArray(LAYOUT)
      }
      LAYOUT.resetLayout()
      return
    }
  }
  const renderPoints = transOutRefPoints(LAYOUT, LAYOUT.STATUS.pointsAlign)
  LAYOUT.STAGE.updateAlignPoints(renderPoints)
}

//线对齐选择线
export function selectAlignLineByClick(LAYOUT, mode) {
  let line = LAYOUT.STATUS.preCheckLine

  if (mode == 'ZLine') {
    //z线选择模式,选中线
    if (line) {
      bus.$emit('TOOL_SQUID_ZLINE_SELECT', line.line)
      LAYOUT.resetLayout()
    }
    return
  }
  //默认线对齐模式
  if (line) {
    LAYOUT.STATUS.recordAlignLines(line.line)
    if (LAYOUT.STATUS.linesAlign.length == 2) {
      const startLine = LAYOUT.STATUS.linesAlign[0] //transToRefPoints(LAYOUT, LAYOUT.STATUS.linesAlign[0])
      const endLine = LAYOUT.STATUS.linesAlign[1] //transOutRefPoints(LAYOUT, LAYOUT.STATUS.linesAlign[1])
      const offset_origin = getLineOffset(startLine, endLine)

      const existPartChecked = LAYOUT.STATUS.checked_objs.filter(obj => obj.js_obj.STATE.partChecked).length
      const existKP = LAYOUT.STATUS.checked_objs.filter(obj => obj.$$?.ptrType.name == 'GdsKeyPoint*').length
      const existRef = LAYOUT.STATUS.checked_objs.filter(obj => obj.$$?.ptrType.name == 'Reference*').length
      if (existPartChecked) {
        recordHistory(LAYOUT, 'shape', BOARDMODEMAP[LAYOUT.MODE])
      } else {
        recordHistory(LAYOUT, 'transform', BOARDMODEMAP[LAYOUT.MODE])
      }
      const start = [0, 0]
      const end = offset_origin
      end[0] += LAYOUT.STATUS.pointOffset[0]
      end[1] += LAYOUT.STATUS.pointOffset[1]
      updateMouseOffSet(LAYOUT.STATUS.checked_objs, start)
      moveObjs(LAYOUT, LAYOUT.STATUS.checked_objs, end, false, true)
      LAYOUT.transfromObjs(start, end)
      if (existPartChecked) {
        LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
      }
      if (existKP) {
        LAYOUT.STAGE.updateGlobalKP(LAYOUT)
      }
      if (existRef) {
        LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
        LAYOUT.STAGE.updateRefArray(LAYOUT)
      }
      LAYOUT.resetLayout()
      return
    }
  }

  const renderLines = [transOutRefPoints(LAYOUT, LAYOUT.STATUS.linesAlign[0])]
  LAYOUT.STAGE.updateAlignLines(renderLines)
}

//删除选中对象
export function removeCheckedObjs(LAYOUT) {
  const CELL = LAYOUT.CELL
  const objs = LAYOUT.STATUS.checked_objs
  const refs = objs.filter(obj => obj.$$?.ptrType.name == 'Reference*')
  const affectCellPtrs = getRefsCellsPtr(LAYOUT.CELL.depend_cells(-1), refs) //在cell删除引用之前获取引用器件
  const history = {
    func: 'DELETE',
    actions: [{ action: 'delete', objs: objs }],
  }
  LAYOUT.sendMessage(history)
  // removeObjs(LAYOUT, CELL, objs)
  LAYOUT.deleteObj(CELL, objs)
  // LAYOUT.HISTORY.record([{ action: 'delete', cell: LAYOUT.CELL, objs: objs }])
  LAYOUT.HISTORY.record(history)
  LAYOUT.STATUS.checked_objs = []
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)

  //重构引用渲染数据

  if (affectCellPtrs) {
    updateCellInsRefs(LAYOUT.RENDER_CELL, affectCellPtrs)
    LAYOUT.STAGE.updateRefArray(LAYOUT, refs, affectCellPtrs)
    LAYOUT.STAGE.updateCheckedRefs()

    bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
  }
}
// //删除对象
// export function removeObjs(LAYOUT, CELL, objs) {
//   let QUAD_TREE = LAYOUT.QUAD_TREE
//   let GROUP = LAYOUT.STAGE.cellGroup
//   let RULER_GROUP = LAYOUT.STAGE.tools.rulerGroup
//   let REF_Group = LAYOUT.STAGE.refsGroup
//   if (LAYOUT.EDIT_REF) {
//     //编辑下层
//     for (let i = 0; i < objs.length; i++) {
//       const obj = objs[i]
//       const TYPE = obj.constructor.name
//       if (TYPE == 'GdsFlexpath*') {
//         REF_Group.remove(obj.js_obj.graphics.lineIns)
//         REF_Group.remove(obj.js_obj.graphics.lineLoopIns)
//         REF_Group.remove(obj.js_obj.graphics.fillIns)
//         CELL.remove_flexpath(obj)
//       } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
//         REF_Group.remove(obj.js_obj.graphics.lineLoopIns)
//         REF_Group.remove(obj.js_obj.graphics.fillIns)
//         CELL.remove_polygon(obj)
//       } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
//         let fillIns = obj.js_obj.graphics?.fillIns
//         if (fillIns) {
//           REF_Group.remove(obj.js_obj.graphics.fillIns)
//         }
//         CELL.remove_label(obj)
//       }
//       obj.js_obj.STATE.reset()
//       updateObjMat(obj, LAYOUT)
//       QUAD_TREE.removeNode(obj)
//     }
//     LAYOUT.STATUS.resetCheckStatus(LAYOUT)
//     LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
//   } else {
//     //编辑当前层
//     for (let i = 0; i < objs.length; i++) {
//       const obj = objs[i]
//       const TYPE = obj.constructor.name
//       if (TYPE == 'GdsFlexpath*') {
//         let line = obj.js_obj.graphics.line
//         let lineLoop = obj.js_obj.graphics.lineLoop
//         let fill = obj.js_obj.graphics.fill
//         GROUP.remove(line)
//         GROUP.remove(lineLoop)
//         GROUP.remove(fill)
//         CELL.remove_flexpath(obj)
//       } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
//         let lineLoop = obj.js_obj.graphics.lineLoop
//         let fill = obj.js_obj.graphics.fill
//         GROUP.remove(lineLoop)
//         GROUP.remove(fill)
//         CELL.remove_polygon(obj)
//       } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
//         let fill = obj.js_obj.graphics?.fill
//         if (fill) {
//           GROUP.remove(fill)
//         }
//         CELL.remove_label(obj)
//       } else if (TYPE == 'Reference*') {
//         CELL.remove_reference(obj)
//       } else if (TYPE == 'Ruler') {
//         removeArrayItem(CELL.js_obj.rulers, obj)
//         RULER_GROUP.remove(obj.line)
//         RULER_GROUP.remove(obj.text)
//       }
//       obj.js_obj.STATE.reset()
//       updateObjMat(obj, LAYOUT)
//       QUAD_TREE.removeNode(obj)
//     }
//   }
// }

//移动物体
export function moveObjs(LAYOUT, objs, globalPos, paste = false, useGlobal = false) {
  let pos = transToRef(LAYOUT, globalPos)
  if (useGlobal) {
    pos = globalPos
  }
  const size = objs.length
  for (let i = 0; i < size; i++) {
    moveObj(LAYOUT, objs[i], pos, paste)
  }
}
//移动物体
export function moveObj(LAYOUT, obj, pos, paste = false, preAdd = false) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name

  if (obj.js_obj.STATE?.checked || paste || preAdd) {
    //整体选择

    movePosByMouse(LAYOUT, obj, pos)
  } else if (obj.js_obj.STATE.partChecked) {
    //部分选择

    movePosPartByMouse(LAYOUT, obj, pos)
  }
  if (obj.js_obj.STATE?.partChecked && (obj.$$?.ptrType.name == 'GdsEllipse*' || obj.$$?.ptrType.name == 'GdsRectangle*')) {
    reBuildGDSGraphic(obj, LAYOUT, true, false, false, paste || preAdd)
  } else {
    reBuildGDSGraphic(obj, LAYOUT, true, true, false, paste || preAdd)
  }
}

function movePosByMouse(LAYOUT, obj, pos) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
    //以center为基准的基础图形
    obj.center = [obj.js_obj.mouse_offset[0] + pos[0], obj.js_obj.mouse_offset[1] + pos[1]]
  } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
    obj.origin = [obj.js_obj.mouse_offset[0] + pos[0], obj.js_obj.mouse_offset[1] + pos[1]]
  } else if (TYPE == 'Reference*') {
    obj.origin = [obj.js_obj.mouse_offset[0] + pos[0], obj.js_obj.mouse_offset[1] + pos[1]]
  } else if (obj.constructor.name == 'Ruler') {
    for (let i = 0; i < obj.points.length; i++) {
      const offset = obj.js_obj.points_mouse_offset[i]
      obj.points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
    }
  }
}

function movePosPartByMouse(LAYOUT, obj, pos) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  const isRuler = obj.constructor.name == 'Ruler'
  let isStretch = LAYOUT.STATUS.isStretch //角度锁定
  if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsPolygon*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*' || isRuler) {
    let points = obj.points
    if (TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*') {
      points = obj.js_obj.borderBox.points
    }
    const points_mouse_offset = obj.js_obj.points_mouse_offset
    //任意拉伸
    for (let i = 0; i < obj.js_obj.checked_points_index.length; i++) {
      const index = obj.js_obj.checked_points_index[i]
      points[index] = [points_mouse_offset[i][0] + pos[0], points_mouse_offset[i][1] + pos[1]]
    }
    if (!isRuler) {
      if (isStretch || TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*') {
        //角度锁定拉伸

        const checked_lines = obj.js_obj.checked_lines
        const slopes = obj.js_obj.slopes
        const total_size = points.length //点的总数
        const total_last_index = total_size - 1
        if (TYPE == 'GdsFlexpath*') {
          //线
          const line_length = checked_lines.length
          let off_set_index = 0
          for (let i = 0; i < line_length; i++) {
            moveLines(points, slopes, obj.js_obj.points_mouse_offset, pos, total_last_index, checked_lines[i], off_set_index)
            off_set_index += checked_lines[i].length
          }
          let dragHead = obj.js_obj.checked_points_index.indexOf(0) !== -1 && obj.js_obj.checked_points_index.indexOf(1) !== -1 //拖拽第一条边
          let dragEnd = obj.js_obj.checked_points_index.indexOf(total_last_index) !== -1 && obj.js_obj.checked_points_index.indexOf(total_last_index - 1) !== -1 // 拖拽第末尾条边
          if (dragHead) {
            let headOff = obj.js_obj.headAndEndOffset?.head
            if (headOff) {
              points[0] = [points[1][0] + headOff[0], points[1][1] + headOff[1]]
            }
          }
          if (dragEnd) {
            let endOff = obj.js_obj.headAndEndOffset?.end
            if (endOff) {
              points[total_last_index] = [points.at(-2)[0] + endOff[0], points.at(-2)[1] + endOff[1]]
            }
          }
        } else {
          //多边形
          const line_length = checked_lines.length
          for (let i = 0; i < line_length; i++) {
            movePolygonLines(slopes, total_last_index, checked_lines[i], total_size, points)
          }
        }
      }
    }

    if (TYPE == 'GdsEllipse*') {
      obj.js_obj.borderBox.updateAABB()
      const rectData = getRectInfo(points)

      obj.center = rectData.center
      const radius_x = rectData.w / 2
      const radius_y = rectData.h / 2
      if (radius_x) {
        //半径小于等于0会报错
        obj.radius_x = radius_x
      }
      if (radius_y) {
        obj.radius_y = radius_y
      }
    } else if (TYPE == 'GdsRectangle*') {
      obj.js_obj.borderBox.updateAABB()
      const rectData = getRectInfo(points)

      obj.center = rectData.center
      obj.width = rectData.w
      obj.height = rectData.h
    } else {
      obj.points = points
    }
    updateLines(obj)
  }
}

//旋转物体
export function rotateObjs(LAYOUT, angle) {
  let rotat_center = LAYOUT.STATUS.checked_objs_center
  const objs = LAYOUT.STATUS.checked_objs
  const size = objs.length
  if (size == 1) {
    for (let i = 0; i < size; i++) {
      const obj = objs[i]
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*' || TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        obj.angle = obj.js_obj.angle_old + angle
      } else if (TYPE == 'Reference*') {
        obj.rotation = obj.js_obj.angle_old + angle
        if (obj.js_obj.expanRefs?.length) {
          obj.origin = rotatePoint(obj.js_obj.past_center, rotat_center, angle)
          obj.repetition.v1 = rotatePoint(obj.js_obj.past_v1, [0, 0], angle)
          obj.repetition.v2 = rotatePoint(obj.js_obj.past_v2, [0, 0], angle)
        }
      } else if (obj.constructor.name == 'Ruler') {
        obj.points = rotatePoints(obj.js_obj.past_points, rotat_center, angle)
      }
      reBuildGDSGraphic(obj, LAYOUT)
    }
  } else {
    for (let i = 0; i < size; i++) {
      const obj = objs[i]
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
        // obj.center = rotatePoint(obj.js_obj.past_center, rotat_center, angle)
        // obj.angle = obj.js_obj.angle_old + angle

        obj.rotate(obj.js_obj.angle_old + angle, rotat_center, true)
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        obj.origin = rotatePoint(obj.js_obj.past_center, rotat_center, angle)
        obj.angle = obj.js_obj.angle_old + angle
      } else if (TYPE == 'Reference*') {
        obj.origin = rotatePoint(obj.js_obj.past_center, rotat_center, angle)
        obj.rotation = obj.js_obj.angle_old + angle
      } else if (obj.constructor.name == 'Ruler') {
        obj.points = rotatePoints(obj.js_obj.past_points, rotat_center, angle)
      }
      reBuildGDSGraphic(obj, LAYOUT)
    }
  }
}

//一组顶点绕指定中心旋转后的坐标
export function rotatePoints(points, center, angle) {
  // const temp = new QGdstk.Polygon(points)
  // temp.rotate(angle, center)
  // return temp.get_points()
  return points.map(p => rotatePoint(p, center, angle))
}
//顶点绕另一个顶点旋转
export function rotatePoint(p, center, angle) {
  const offX = p[0] - center[0]
  const offY = p[1] - center[1]
  return [offX * Math.cos(angle) - offY * Math.sin(angle) + center[0], offX * Math.sin(angle) + offY * Math.cos(angle) + center[1]]
}

//阵列
export function arrayObjs(LAYOUT, params) {
  if (LAYOUT.MODE == BOARDMODE.PART_SEL) return
  const data = LAYOUT.STATUS.checked_objs
  const data_size = data.length

  if (!data_size) return

  LAYOUT.STATUS.updateCheckedObjsAABB()
  const start = deepClone(LAYOUT.STATUS.checked_objs_center) //起始点
  const start_x = start[0]
  const start_y = start[1]
  const offset = [] //保存偏移量
  for (let i = 0; i < data_size; i++) {
    let p = getPosition(data[i])
    offset.push([start_x - p[0], start_y - p[1]])
  }
  //输入参数
  const row = parseInt(params[0])
  const clo = parseInt(params[1])
  const row_vector = { x: parseFloat(params[2]), y: parseFloat(params[3]) }
  const col_vector = { x: parseFloat(params[4]), y: parseFloat(params[5]) }

  const positions = []
  for (let i = 0; i < row; i++) {
    for (let j = 0; j < clo; j++) {
      let x = start_x + j * col_vector.x + i * row_vector.x
      let y = start_y + i * row_vector.y + j * col_vector.y
      positions.push([x, y])
    }
  }
  positions.shift() //删除被复制项
  const size = positions.length
  let record_data = []
  let affectRefs = [] //新增的器件引用
  for (let i = 0; i < size; i++) {
    for (let j = 0; j < data_size; j++) {
      const p_new = [positions[i][0] - offset[j][0], positions[i][1] - offset[j][1]]
      const copy = copyObj(LAYOUT, data[j], p_new)
      const TYPE = copy.$$?.ptrType.name //copy.constructor.name
      if (copy.constructor.name == 'Ruler') {
        updateObjMat(copy, LAYOUT)
      } else if (TYPE == 'Reference*') {
        affectRefs.push(copy)
      }
      LAYOUT.addObj(LAYOUT.CELL, copy, false)
      record_data.push(copy)
    }
  }

  const history = {
    func: 'ARRAY',
    actions: [{ action: 'add', objs: record_data }],
  }
  LAYOUT.sendMessage(history)
  LAYOUT.HISTORY.record(history)
  // LAYOUT.HISTORY.record([{ action: 'add', cell: LAYOUT.CELL, objs: record_data, remark: 'array' }])
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
  const affectCellPtrs = getRefsCellsPtr(LAYOUT.RENDER_CELL.depend_cells(-1), affectRefs)
  if (affectCellPtrs) {
    updateCellInsRefs(LAYOUT.RENDER_CELL, affectCellPtrs)

    LAYOUT.STAGE.updateRefArray(LAYOUT)
  }
  bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
}

//复制
export function copyOP(LAYOUT, isCut = false) {
  if (LAYOUT.STATUS.viewMode || !LAYOUT.STATUS.checked_objs.length) return
  //复制 剪切
  let copyData = copyObjs(LAYOUT.STATUS.checked_objs)
  LAYOUT.STATUS.updateCheckedObjsAABB()
  let center = deepClone(LAYOUT.STATUS.checked_objs_center)
  window.CELLBOARD.copyData = { data: copyData, center, fromData: LAYOUT.STATUS.checked_objs.map(obj => obj), fromCell: LAYOUT.CELL, fromFileId: LAYOUT.fileInfo.fileId } //记录复制数据信息
  LAYOUT.STATUS.isCut = isCut

  // if (isCut) {
  //   LAYOUT.STATUS.setPreCutObjs(LAYOUT)
  //   LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
  // }
  if (isCut) {
    LAYOUT.STATUS.setPreCutObjs(LAYOUT, window.CELLBOARD.copyData.fromData)
    LAYOUT.STAGE.updateCheckedRefs(LAYOUT, window.CELLBOARD.copyData.fromData)
  }
}

//预粘贴
export function prePasteData(LAYOUT) {
  if (LAYOUT.EDIT_REF) {
    return
  }
  let data = window.CELLBOARD?.copyData?.data
  if (!data?.length) {
    LAYOUT.resetLayout()
    return
  }
  let fromCell = window.CELLBOARD.copyData.fromCell
  let fromFileId = window.CELLBOARD.copyData.fromFileId
  if (LAYOUT.MODE == BOARDMODE.PRE_PASTE) return //已经进入状态模式
  LAYOUT.switchMode(BOARDMODE.PRE_PASTE) //切换预粘贴模式
  let start = window.CELLBOARD.copyData.center //起始点

  const crossBoardCopy = fromCell.snow_id !== LAYOUT.CELL.snow_id //判断跨画板复制
  const crossFileCopy = fromFileId !== LAYOUT.fileInfo.fileId //判断跨文件复制
  LAYOUT.STATUS.crossBoardCopy = crossBoardCopy
  let crossCopyData //跨文件复制数据
  if (crossFileCopy) {
    crossCopyData = deepCopyData(data) //深拷贝数据
  } else {
    if (existLoop(LAYOUT.CELL, getDataCells(data))) {
      Message.error(`${i18n.t('messages.deviceCircularReference')}`)
      LAYOUT.resetLayout()
      return
    }
  }
  LAYOUT.STATUS.crossCopyData = null
  if (crossCopyData) {
    data = crossCopyData.datas
    LAYOUT.STATUS.crossCopyData = crossCopyData
  }
  const data_size = data.length
  let globalPos = LAYOUT.getMousePos()
  const mouse_pos = transToRef(LAYOUT, globalPos)
  if (!start) return
  const offset = [] //保存偏移量
  for (let i = 0; i < data_size; i++) {
    let p = getPosition(data[i])
    offset.push([start[0] - p[0], start[1] - p[1]])
  }

  const pre_copy_data = []
  for (let j = 0; j < data_size; j++) {
    let obj = data[j]
    // let p_new = []
    // let off = offset[j]
    // p_new = [mouse_pos[0] - off[0], mouse_pos[1] - off[1]]
    // const copy = copyObj(LAYOUT, obj, p_new)
    const copy = copyObj(LAYOUT, obj, null, true)
    pre_copy_data.push(copy)
    if (copy.constructor.name == 'Ruler') {
      updateObjMat(copy, LAYOUT)
    }
    // LAYOUT.addObj(LAYOUT.CELL, copy)
  }
  updateMouseOffSet(pre_copy_data, start, true)
  LAYOUT.STATUS.prePasteData = pre_copy_data
  // const affectRefs = pre_copy_data.filter(obj => obj.$$?.ptrType.name == 'Reference*')
  // if (affectRefs.length) {

  //   const affectCellPtrs = getRefsCellsPtr(LAYOUT.CELL.depend_cells(-1), affectRefs)
  //   LAYOUT.STATUS.affectCellPtrs = affectCellPtrs
  //   LAYOUT.STATUS.repetitionCellsPtrs = getRepetitionCellsPtr(affectRefs)
  //   LAYOUT.updateTargetCellInsRefs(affectCellPtrs)
  // }
  LAYOUT.STAGE.updatePrePasteData(transCopyDataToRef(pre_copy_data))
  updatePrePasteData(LAYOUT, mouse_pos, globalPos)
  LAYOUT.STAGE.preAddGroup.position.set(mouse_pos[0] - start[0], mouse_pos[1] - start[1], 5)
  LAYOUT.STATUS.preCutObjs = null
  if (!crossBoardCopy && LAYOUT.STATUS.isCut) {
    LAYOUT.STATUS.setPreCutObjs(LAYOUT, window.CELLBOARD.copyData.fromData)
    LAYOUT.STAGE.updateCheckedRefs(LAYOUT, window.CELLBOARD.copyData.fromData)
  }
}

//更新预粘贴数据位置
export function updatePrePasteData(LAYOUT, globalPos, pos) {
  moveObjs(LAYOUT, LAYOUT.STATUS.prePasteData, globalPos, true)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)

  // // LAYOUT.updateTargetCellInsRefs(LAYOUT.STATUS.affectCellPtrs)
  // LAYOUT.STAGE.updateRefArray(LAYOUT, null, LAYOUT.STATUS.affectCellPtrs, LAYOUT.STATUS.repetitionCellsPtrs)
  // // LAYOUT.updateEditRefBox()
}

//确认粘贴数据
export function confirmPaste(LAYOUT) {
  const pasteData = LAYOUT.STATUS.prePfasteData
  if (LAYOUT.STATUS.prePasteData?.length) {
    let func = 'PASTE'
    const records = []
    const remark = LAYOUT.STATUS.isCut ? 'cut' : 'copy'
    const action1 = { action: 'add', cell: LAYOUT.CELL, objs: LAYOUT.STATUS.prePasteData, remark }
    records.push(action1)
    const data_size = LAYOUT.STATUS.prePasteData.length
    const layerData = getFileLayerListFromTable(LAYOUT.fileInfo.fileId)
    let needRefreshCellChild = false
    for (let j = 0; j < data_size; j++) {
      let obj = LAYOUT.STATUS.prePasteData[j]
      applyLayerIdToObj(obj, layerData.fileLayerDatas)
      // if (obj.constructor.name == 'Ruler') {
      //   updateObjMat(obj, LAYOUT)
      // }
      updateObjMat(obj, LAYOUT, true)
      LAYOUT.addObj(LAYOUT.CELL, obj)
      if (obj.$$?.ptrType.name == 'Reference*') {
        needRefreshCellChild = true
      }
    }

    const affectRefs = LAYOUT.STATUS.prePasteData.filter(obj => obj.$$?.ptrType.name == 'Reference*')
    if (affectRefs.length) {
      const affectCellPtrs = getRefsCellsPtr(LAYOUT.CELL.depend_cells(-1), affectRefs)
      LAYOUT.STATUS.affectCellPtrs = affectCellPtrs
      LAYOUT.STATUS.repetitionCellsPtrs = getRepetitionCellsPtr(affectRefs)
      LAYOUT.STAGE.updateRefArray(LAYOUT, null, LAYOUT.STATUS.affectCellPtrs, LAYOUT.STATUS.repetitionCellsPtrs)
    }

    let action2 = null
    LAYOUT.STATUS.prePasteData = null
    if (LAYOUT.STATUS.isCut && LAYOUT.STATUS.preCutObjs) {
      func = 'CUT'
      action2 = { action: 'delete', cell: LAYOUT.CELL, objs: LAYOUT.STATUS.preCutObjs, remark }
      records.push(action2)
      LAYOUT.STATUS.preCutObjs = null
      LAYOUT.STAGE.updateCheckedRefs(null)
      LAYOUT.STATUS.isCut = false //连续剪切后就是复制
    }
    const history = {
      func: func,
      actions: [action1],
    }
    if (action2) {
      history.actions = [action2, action1]
      LAYOUT.sendMessage(history)
      action2.objs.forEach(obj => LAYOUT.deleteObj(LAYOUT.CELL, obj))
    } else {
      LAYOUT.sendMessage(history)
    }
    LAYOUT.HISTORY.record(history)
    // LAYOUT.HISTORY.record(records)
    LAYOUT.STAGE.updateGlobalKP(LAYOUT)
    if (!LAYOUT.STATUS.crossCopyData) {
      //跨文件复制
      if (LAYOUT.STATUS.crossBoardCopy && LAYOUT.STATUS.affectCellPtrs.length) {
        //同文件跨画板复制
        LAYOUT.updateByMessageServe() //重渲染
      } else {
        //同画板复制
        LAYOUT.updateTargetCellInsRefs(LAYOUT.STATUS.affectCellPtrs)
      }
    } else {
      if (LAYOUT.STATUS.crossCopyData?.cells?.length) {
        LAYOUT.updateByMessageServe() //重渲染
      }
      // LAYOUT.updateTargetCellInsRefs(LAYOUT.STATUS.affectCellPtrs)
    }
    if (needRefreshCellChild) {
      bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
    }
  }
}

//翻转
export function flip(LAYOUT, flip = 'X', offset = 0) {
  if (LAYOUT.MODE == BOARDMODE.PART_SEL) return
  const data = LAYOUT.STATUS.checked_objs
  const data_size = data.length
  if (!data_size) return

  recordHistory(LAYOUT, 'shape', 'MIRROR' + flip) //'flip' + flip
  LAYOUT.STATUS.updateCheckedObjsAABB()
  const center = LAYOUT.STATUS.checked_objs_center
  const positions = []
  for (let i = 0; i < data_size; i++) {
    const obj = data[i]
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    let pos = [0, 0]
    if (obj.constructor.name == 'Ruler') {
    } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*' || TYPE == 'Reference*') {
      pos = obj.origin
    } else {
      pos = obj.center
    }
    positions.push(pos)
  }

  let temp = new QGdstk.Polygon(positions)
  let flip_param
  if (flip === 'X') {
    const X = center[0] + offset
    flip_param = [
      [X, 0],
      [X, 1],
    ]
  } else if (flip === 'Y') {
    const Y = center[1] + offset
    flip_param = [
      [0, Y],
      [1, Y],
    ]
  }
  temp.mirror(flip_param[0], flip_param[1])
  const newpos = temp.get_points()
  for (let i = 0; i < data_size; i++) {
    const obj = data[i]
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    if (obj.constructor.name == 'Ruler') {
      obj.js_obj.path.mirror(flip_param[0], flip_param[1])
      obj.points = obj.js_obj.path.points
    } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
      obj.origin = newpos[i]
      // if (TYPE == 'Reference*') {
      //   obj.x_reflection = !obj.x_reflection
      //   //器件翻转和旋转角度有关
      //   if (flip == 'X') {
      //     obj.rotation -= Math.PI + obj.rotation * 2
      //   }
      //   if (flip == 'Y') {
      //     obj.rotation -= obj.rotation * 2
      //   }
      // }
    } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'Reference*') {
      if (flip === 'X') {
        obj.mirror_y(flip_param[0][0]) //平行于y轴
      } else if (flip === 'Y') {
        obj.mirror_x(flip_param[0][1]) //平行于x轴
      }
    } else {
      obj.mirror(flip_param[0], flip_param[1])
      // obj.center = newpos[i]
    }
    reBuildGDSGraphic(obj, LAYOUT)
  }
  //console.log('完成翻转')
  const history = {
    func: 'MIRROR' + flip,
    params: flip_param,
    actions: [{ action: 'transform', objs: data }],
  }
  LAYOUT.sendMessage(history)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
  LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
  LAYOUT.STAGE.updateRefArray(LAYOUT)
  LAYOUT.STAGE.updateEditRefBorder(LAYOUT)
}

//对齐
export function alignmentObjs(LAYOUT, alignType) {
  if (LAYOUT.MODE == BOARDMODE.PART_SEL) return
  const data_size = LAYOUT.STATUS.checked_objs.length
  if (data_size <= 1) return
  LAYOUT.STATUS.updateCheckedObjsAABB()
  let params = alignMap[alignType]
  recordHistory(LAYOUT, 'transform', 'ALIGN', params)
  const data = LAYOUT.STATUS.checked_objs
  // const center = LAYOUT.checkedGraphicsAABB_center
  const aabb = LAYOUT.STATUS.checked_objs_aabb
  const minX = aabb[0][0]
  const minY = aabb[0][1]
  const maxX = aabb[1][0]
  const maxY = aabb[1][1]
  // let alignType = '上对齐' //params[0]
  let first_center = aabbCenter(data[0].js_obj.bounding_box)
  let objs_minX = first_center[0]
  let objs_minY = first_center[1]
  let centerPosoffsets = []
  if (alignType === '竖向中线对齐' || alignType === '横向中线对齐') {
    for (let i = 0; i < data_size; i++) {
      //查询最小横竖中线
      const obj = data[i]
      let center = aabbCenter(obj.js_obj.bounding_box)
      let pos //移动参考点
      pos = getPosition(obj)
      // const TYPE = obj.constructor.name
      // if (TYPE == 'Ruler') {
      //   pos = obj.points[0]
      //   const pos2 = obj.points[1]
      //   obj.js_obj.point_offset = [pos[0] - pos2[0], pos[1] - pos2[1]]
      // } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
      //   pos = obj.origin
      // } else {
      //   pos = obj.center
      // }
      centerPosoffsets.push([pos[0] - center[0], pos[1] - center[1]])
      objs_minX = objs_minX > center[0] ? center[0] : objs_minX
      objs_minY = objs_minY > center[1] ? center[1] : objs_minY
    }
  }
  for (let i = 0; i < data_size; i++) {
    const obj = data[i]
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    const bounding_box = obj.js_obj.bounding_box

    // alert(obj.text)
    // alert(obj.js_obj.bounding_box[1][0])
    let p
    if (obj.constructor.name == 'Ruler') {
      p = obj.js_obj.path.center
    } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*' || TYPE == 'Reference*') {
      p = deepClone(obj.origin)
    } else {
      p = obj.center
    }

    switch (alignType) {
      case '左对齐': //左对齐
        p[0] = minX + p[0] - bounding_box[0][0]
        break
      case '右对齐': //右对齐
        p[0] = maxX - bounding_box[1][0] + p[0]
        break
      case '下对齐': //下对齐
        p[1] = minY + p[1] - bounding_box[0][1]
        break
      case '上对齐': //上对齐
        p[1] = maxY - bounding_box[1][1] + p[1]
        break
      case '竖向中线对齐': //中垂线对对齐
        p[0] = objs_minX + centerPosoffsets[i][0]
        break
      case '横向中线对齐': //中水平线对齐
        p[1] = objs_minY + centerPosoffsets[i][1]
        break
    }
    if (obj.constructor.name == 'Ruler') {
      obj.js_obj.path.center = p
      obj.points = obj.js_obj.path.points
    } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*' || TYPE == 'Reference*') {
      obj.origin = p
    } else {
      obj.center = p
    }

    reBuildGDSGraphic(obj, LAYOUT)
  }
  const history = {
    func: 'ALIGN',
    params: alignMap[alignType], //消息中传中文直接断开？
    actions: [{ action: 'move', objs: data }],
  }
  LAYOUT.sendMessage(history)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
  LAYOUT.STAGE.updateCheckedRefs(LAYOUT)
  LAYOUT.STAGE.updateRefArray(LAYOUT)
  return
}

//导出文件
export function exportGdsFile(lib) {
  // export_gds.write_gds('export_file')
  // let content = QGdstk.FS.readFile('export_file')
  let filename = 'export.gds'
  let content = binaryDataToUTF8(lib.dump_file(-1, false))[0].data_u8

  let mime = 'application/octet-stream'

  var download_link = document.getElementById('download_gds')
  if (download_link == null) {
    download_link = document.createElement('a')
    download_link.id = 'download_gds'
    download_link.style.display = 'none'
    document.body.appendChild(download_link)
  }
  download_link.download = filename
  download_link.href = URL.createObjectURL(
    new Blob([content], {
      type: mime,
    })
  )
  download_link.click()
}

export function binaryDataToUTF8(binaryDataObj) {
  let U8Array = []
  for (const key in binaryDataObj) {
    let size = binaryDataObj[key].size()
    let binaryArray = []
    for (let i = 0; i < size; i++) {
      binaryArray.push(binaryDataObj[key].get(i))
    }
    let data_u8 = new Uint8Array(binaryArray)
    U8Array.push({
      snow_id: key,
      data_u8,
    })
  }

  return U8Array
}
//切割对象
export function cutObj(LAYOUT) {
  const canCutObjs = LAYOUT.STATUS.checked_objs.filter(obj => canCutObj(obj))
  if (!canCutObjs.length) return
  LAYOUT.STATUS.updateCheckedObjsAABB()
  let axis, val
  const mouse_pos = LAYOUT.getMousePos()
  if (LAYOUT.MODE === BOARDMODE.CUT_X) {
    axis = 'x'
    val = mouse_pos[0]
  } else {
    axis = 'y'
    val = mouse_pos[1]
  }

  const can_cut_polygon_objs = canCutObjs.filter(obj => axisHitPolygon(obj, axis, val)) //可以被切割的多边形
  const can_cut_lines = canCutObjs.filter(obj => axisHitLine(obj, axis, val)) //可以被切割的线
  if (!can_cut_polygon_objs.length && !can_cut_lines.length) return
  // const all_cut_objs = can_cut_polygon_objs.concat(can_cut_lines)
  let record1 = []
  // for (let i = 0; i < all_cut_objs.length; i++) {
  //   const obj = all_cut_objs[i]
  //   // LAYOUT.deleteObj(LAYOUT.CELL, obj)
  //   record1.push(obj)
  // }
  let action1 = { action: 'delete', cell: LAYOUT.CELL, objs: record1 }

  const a = [] //被切割的多边形对象
  for (let i = 0; i < can_cut_polygon_objs.length; i++) {
    const obj = can_cut_polygon_objs[i]
    a.push(getGdsPolygon(obj.points, obj.layer))
    // LAYOUT.deleteObj(LAYOUT.CELL, obj)
    record1.push(obj)
  }
  let record2 = []
  const result = new QGdstk.slice(a, [val], axis, 0.0000001)
  const size = result.length
  for (let j = 0; j < size; j++) {
    const total = result[j].length
    for (let k = 0; k < total; k++) {
      const part = result[j][k]
      const polygon = new Kernel.GdsPolygon()
      polygon.points = part.get_points()
      polygon.layer = part.layer
      polygon.id = LAYOUT.getLayerIdByNum(polygon.layer)
      // LAYOUT.addGdsObj(polygon)
      record2.push(polygon)
    }
  }
  if (can_cut_lines.length) {
    action1.objs.push(...can_cut_lines)
    //被切割的线
    const result_lines = sliceLines(can_cut_lines, val, axis, LAYOUT.STATUS.checked_objs_aabb)

    const size_lines = result_lines.length
    for (let j = 0; j < size_lines; j++) {
      const total = result_lines[j].result.length
      for (let k = 0; k < total; k++) {
        const cutPathsRes = result_lines[j]
        const path = new Kernel.GdsFlexpath(cutPathsRes.result[k], 0, 0, 'round', 'flush', 0, 1e-2, true, true, 0, 0)
        path.layers = cutPathsRes.layers
        path.id = cutPathsRes.id
        path.width = cutPathsRes.width
        path.radius = cutPathsRes.radius
        path.offset = cutPathsRes.offset
        // LAYOUT.addGdsObj(path)
        record2.push(path)
      }
    }
  }
  let action2 = { action: 'add', cell: LAYOUT.CELL, objs: record2 }
  const history = {
    func: 'SLICE',
    actions: [action1, action2],
  }
  LAYOUT.sendMessage(history)
  action1.objs.forEach(obj => LAYOUT.deleteObj(LAYOUT.CELL, obj))
  action2.objs.forEach(obj => LAYOUT.addGdsObj(obj))
  LAYOUT.HISTORY.record(history)

  // let action2 = { action: 'add', cell: LAYOUT.CELL, objs: record2, remark: 'slice' }
  // LAYOUT.HISTORY.record([action1, action2])
}

//区域切除
export function cutAreaObjs(LAYOUT, rectPoints) {
  rectPoints = transToRefPoints(LAYOUT, rectPoints)
  const rect = new QGdstk.Polygon(rectPoints)

  let objs = LAYOUT.STATUS.checked_objs.filter(obj => canCutArea(obj)) //过滤可以区域切除的图形
  objs = objs.filter(obj => insertPolygon(rect, obj.points)) //过滤有相交的图形
  if (objs.length) {
    let record1 = [],
      record2 = []
    for (let i = 0; i < objs.length; i++) {
      const obj = objs[i]
      const target = new QGdstk.Polygon(obj.points)
      target.layer = obj.layer
      const result = new QGdstk.boolean(new QGdstk.Polygon(obj.points), rect, 'not')
      // LAYOUT.deleteObj(LAYOUT.CELL, obj)
      record1.push(obj)
      const size = result.length
      for (let j = 0; j < size; j++) {
        const polygon = new Kernel.GdsPolygon()
        polygon.points = result[j].get_points()
        polygon.layer = obj.layer
        polygon.id = LAYOUT.getLayerIdByNum(polygon.layer)
        // LAYOUT.addGdsObj(polygon)
        record2.push(polygon)
      }
    }
    let action1 = { action: 'delete', cell: LAYOUT.CELL, objs: record1 }
    let action2 = { action: 'add', cell: LAYOUT.CELL, objs: record2 }
    const history = {
      func: 'CUT_AREA',
      actions: [action1, action2],
    }
    LAYOUT.sendMessage(history)
    action1.objs.forEach(obj => LAYOUT.deleteObj(LAYOUT.CELL, obj))
    action2.objs.forEach(obj => LAYOUT.addGdsObj(obj))
    LAYOUT.HISTORY.record(history)
  }
}

//合并图形
export function mergePolygons(LAYOUT) {
  if (LAYOUT.MODE == BOARDMODE.PART_SEL) return
  let objs = LAYOUT.STATUS.checked_objs.filter(obj => canMerge(obj)) //过滤可以合并的图形
  if (objs.length <= 1) {
    return
  }

  let layers = []
  objs.forEach(obj => {
    if (obj.layer !== undefined) {
      layers.push(obj.layer)
    } else if (obj.layers !== undefined) {
      layers.push(obj.layers[0])
    }
  })
  layers = Array.from(new Set(layers))
  if (layers.length > 1) {
    Message.error(`${i18n.t('messages.cellBoardMsg.error.mergeLayersError')}`)
    return
  }
  layers = layers.map(layer => {
    return { layer: layer, objs: [] }
  })

  layers.forEach(layer => {
    layer.objs = objs.filter(obj => {
      if (obj.layer !== undefined) {
        return obj.layer == layer.layer
      }
      if (obj.layers !== undefined) {
        return obj.layers[0] == layer.layer
      }
      return false
    })
  })
  const operation = 'or'
  let action1 = { action: 'delete', cell: LAYOUT.CELL, objs: [], remark: 'merge' }
  let action2 = { action: 'add', cell: LAYOUT.CELL, objs: [], remark: 'merge' }

  layers.forEach(layer => {
    let res = quickBoolObjs(layer.layer, layer.objs, operation, LAYOUT.getLayerIdByNum(layer.layer))
    action1.objs.push(...res[0]) //删除的元素
    action2.objs.push(...res[1]) //新生成的元素
  })
  const history = {
    func: 'MERGE',
    actions: [action1, action2],
  }
  LAYOUT.sendMessage(history)
  action1.objs.forEach(obj => LAYOUT.deleteObj(LAYOUT.CELL, obj))
  action2.objs.forEach(obj => LAYOUT.addGdsObj(obj))
  // LAYOUT.HISTORY.record([action1, action2])
  LAYOUT.HISTORY.record(history)
}

//布尔运算
export function boolCheckedObjs(LAYOUT, params) {
  if (LAYOUT.MODE == BOARDMODE.PART_SEL) return
  let layer = params.layerNumber
  let operation = params.method
  let delet_origin = params.booleanSave === '2'
  let objs = LAYOUT.STATUS.checked_objs.filter(obj => canBoolean(obj)) //过滤可以布尔运算的图形
  if (objs.length <= 1) return
  let result = quickBoolObjs(layer, objs, operation, LAYOUT.getLayerIdByNum(layer))
  const record = []
  let action1
  let actions = []
  //是否删除原有数据
  if (delet_origin) {
    action1 = { action: 'delete', cell: LAYOUT.CELL, objs: result[0] }
    actions.push(action1)
  }
  let action2 = { action: 'add', cell: LAYOUT.CELL, objs: result[1] }
  actions.push(action2)
  // action2.objs.forEach(obj => LAYOUT.addGdsObj(obj))
  // record.push(action2)
  // LAYOUT.HISTORY.record(record)

  const history = {
    func: 'BOOLEAN',
    actions,
  }
  LAYOUT.sendMessage(history)
  if (action1) {
    action1.objs.forEach(obj => LAYOUT.deleteObj(LAYOUT.CELL, obj))
  }
  action2.objs.forEach(obj => LAYOUT.addGdsObj(obj))
  LAYOUT.HISTORY.record(history)
}

export function quickBoolObjs(layer, objs, operation, id) {
  let res = [[], []]
  const length = objs.length
  if (length < 2) res
  const operand1 = []
  const operand2 = []
  let record1 = []
  for (let i = 0; i < length; i++) {
    const obj = objs[i]
    if (operand1.length === 0) {
      operand1.push(...getObjGdsPolygon(obj))
    } else {
      operand2.push(...getObjGdsPolygon(obj))
    }
    record1.push(obj)
  }
  res[0] = record1

  // const result = new QGdstk.boolean(operand1, operand2, operation)

  const result = Kernel.boolean(operand1, operand2, operation, 10e-7, 0, 0)
  const size = result.length
  let record2 = []
  for (let j = 0; j < size; j++) {
    const polygon = new Kernel.GdsPolygon()
    // polygon.points = result[j].get_points()
    polygon.points = result[j].points
    polygon.layer = layer
    polygon.id = id
    record2.push(polygon)
  }
  res[1] = record2
  return res
}

//打散
export function breakRef(LAYOUT, params) {
  if (LAYOUT.MODE == BOARDMODE.PART_SEL) return
  let depth = params[0] === '2' //打散所有
  let keep_params_device = params[1] === '1' //保留参数化器件
  let refs = LAYOUT.STATUS.checked_objs.filter(obj => obj.$$?.ptrType.name == 'Reference*')
  refs = expanRefs(refs) //展开器件
  let ref_len = refs.length
  if (!ref_len) return
  let breakResult = [] //打散后的图形
  let record1 = [] //删除的对象
  let record2 = [] //添加的对象
  let affectRefs = [] //有改动的引用
  let depend_cells = LAYOUT.CELL.depend_cells(-1) //操作前引用的cell
  for (let i = 0; i < ref_len; i++) {
    // //判断参数化器件
    if (keep_params_device && isParamsDevice(refs[i].cell)) {
      continue
    }
    const ref = refs[i]
    record1.push(ref)
    const polygons = getRefPolygons(ref, depth, keep_params_device)
    affectRefs.push(ref)
    breakResult.push(...polygons)
  }

  let break_len = breakResult.length
  for (let i = 0; i < break_len; i++) {
    const addObj = breakResult[i]
    // reBuildGdsData(LAYOUT, addObj) //重构初始图形数据
    // LAYOUT.addObj(LAYOUT.CELL, addObj)
    record2.push(addObj)
    if (addObj.$$?.ptrType.name == 'Reference*') {
    }
  }

  // const remark = 'breakRef'
  // let action1 = { action: 'delete', cell: LAYOUT.CELL, objs: record1.filter(obj => !obj.js_obj.isExpan), remark }
  // let action2 = { action: 'add', cell: LAYOUT.CELL, objs: record2.filter(obj => !obj.js_obj.isExpan), remark }
  // LAYOUT.HISTORY.record([action1, action2])
  const history = {
    func: 'BREAK',
    actions: [
      { action: 'delete', cell: LAYOUT.CELL, objs: record1.filter(obj => !obj.js_obj.isExpan) },
      { action: 'add', cell: LAYOUT.CELL, objs: record2.filter(obj => !obj.js_obj.isExpan) },
    ],
  }
  LAYOUT.sendMessage(history)
  for (let i = 0; i < record2.length; i++) {
    const addObj = record2[i]
    reBuildGdsData(LAYOUT, addObj) //重构初始图形数据
    LAYOUT.addObj(LAYOUT.CELL, addObj)
  }
  for (let i = 0; i < record1.length; i++) {
    const deleteObj = record1[i]
    if (deleteObj.js_obj.isExpan) continue
    LAYOUT.deleteObj(LAYOUT.CELL, deleteObj)
  }
  LAYOUT.HISTORY.record(history)
  LAYOUT.STATUS.resetCheckStatus(LAYOUT)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)
  const affectCellPtrs = getRefsCellsPtr(depend_cells, affectRefs)

  if (affectCellPtrs) {
    updateCellInsRefs(LAYOUT.RENDER_CELL, affectCellPtrs)

    LAYOUT.STAGE.updateRefArray(LAYOUT, null, affectCellPtrs)
  }
  bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
}

//调用器件 fromExternalFile调用全新的器件或从外部文件调用
export function addDevice(LAYOUT, ref, fromExternalFile = false, addCharacterCell = false) {
  if (LAYOUT.EDIT_REF) {
    return
  }
  // alert('调用器件')

  //首先判断环
  if (!addCharacterCell) {
    LAYOUT.switchMode(BOARDMODE.PRE_ADD_CELL)
  }
  // const deviceName = 'Device-' + parseInt(Math.random() * 100)
  // let decive1 = new Kernel.Cell(deviceName) //外部器件调用测试
  // let label = new Kernel.GdsLabel()
  // label.text = deviceName
  // let polygon = new Kernel.GdsPolygon()
  // polygon.points = [
  //   [-50, 0],
  //   [-50, 100],
  //   [0, 50],
  //   [50, 100],
  //   [50, 0],
  // ]
  // decive1.add_polygon(polygon)
  // decive1.add_polygon(new Kernel.GdsRectangle([0, 75], 15, 15))
  // decive1.add_label(label)
  // let ref = new Kernel.Reference()
  // ref.cell = decive1 //LAYOUT.SELF_CELL
  if (ref.cell) {
    ref.cell.js_obj.bounding_box = ref.cell.bounding_box()
  }
  reBuildGdsData(LAYOUT, ref)
  LAYOUT.STAGE.addPreAddRef(ref) //生成临时图形数据
  // const addNewCells = addCellInsToCurrentCell(LAYOUT, ref) //判断是否有新器件调用，构建相关数据
  // if (addNewCells.length) {
  //   const cellInsMaps = LAYOUT.CELL.js_obj.cellInsMaps

  //   const cellsPtrs = addNewCells.map(cell => cell.$$.ptr)
  //   for (const cell in cellInsMaps) {
  //     const obj = cellInsMaps[cell]
  //     if (cellsPtrs.indexOf(obj.cell.$$.ptr) !== -1) {
  //       const insCount = obj.refs.length
  //       const matsArray = obj.matsArray
  //       LAYOUT.STAGE.addCellInst(LAYOUT.STAGE.refsGroup, obj.cell, insCount, matsArray) //向引用渲染组添加图形数据
  //     }
  //   }
  // }

  // LAYOUT.addObj(LAYOUT.CELL, ref)
  // const affectCellPtrs = getRefsCellsPtr(LAYOUT.CELL.depend_cells(-1), [ref])

  // updateCellInsRefs(LAYOUT.CELL, affectCellPtrs)
  // LAYOUT.STAGE.updateRefArray(LAYOUT, null, affectCellPtrs)
  // LAYOUT.STATUS.addNewCells = addNewCells
  // LAYOUT.STATUS.affectCellPtrs = affectCellPtrs
  // LAYOUT.STATUS.repetitionCellsPtrs = getRepetitionCellsPtr([ref])
  LAYOUT.STATUS.preAddRef = ref
  LAYOUT.STATUS.preAddRefExist = existCell(LAYOUT.CELL, ref.cell) //调用的器件已经在文件中存在
  LAYOUT.STATUS.fromExternalFile = fromExternalFile
}

//进入下层
export async function enterlowerLevel(LAYOUT) {
  let ref = LAYOUT.STATUS.checked_objs[0]
  if (!LAYOUT.EDIT_REF && LAYOUT.STATUS.checked_objs.length === 1 && canEnterRef(ref)) {
    LAYOUT.resetLayout()
    let targetCell = ref.cell
    if (!targetCell) {
      return
    }
    let canedit = await LAYOUT.hasCellPermission(targetCell.snow_id)

    if (!canedit) {
      return
    }

    let canAccess = await LAYOUT.canAccessFile(targetCell.snow_id)
    if (!canAccess) {
      return
    }
    let history = {
      dx: LAYOUT.AXIS.dx,
      dy: LAYOUT.AXIS.dy,
      scale: LAYOUT.AXIS.scale,
      cell: LAYOUT.CELL,
      // quadTree: LAYOUT.quadTree,
    }
    // LAYOUT.unLockFile() //解锁当前文件
    LAYOUT.CellHistory.push(history) //添加记录
    LAYOUT.CELL = targetCell
    LAYOUT.RENDER_CELL = targetCell
    LAYOUT.QUAD_TREE = LAYOUT.CELL.js_obj.QUAD_TREE
    LAYOUT.HISTORY = LAYOUT.CELL.js_obj.HISTORY
    // buildRenderData(enter_schema, LAYOUT.config)
    ReBuildCellRefs(LAYOUT)
    LAYOUT.enterCell(targetCell)
    LAYOUT.lockFile(targetCell.snow_id) //锁定当前文件
    adaptObj(LAYOUT)
    bus.$emit('cellHistoryUpdate', LAYOUT.RENDER_CELL)
  }
}

//返回上层,最上层
export async function backUpperLevel(LAYOUT, toTop = false) {
  if (LAYOUT.EDIT_REF) {
    //正在编辑下层状态先返回上层
    const editCell = LAYOUT.EDIT_REF.cell
    // let canAccess = await LAYOUT.canAccessFile(LAYOUT.RENDER_CELL.snow_id)
    // if (!canAccess) {

    //   return
    // }
    // LAYOUT.unLockFile(LAYOUT.CELL.snow_id) //解锁当前文件
    LAYOUT.lockFile(LAYOUT.RENDER_CELL.snow_id) //锁定当前文件
    await forceSaveCells([editCell], LAYOUT, true)
    LAYOUT.resetLayout()
    LAYOUT.EDIT_REF = null
    LAYOUT.CELL = LAYOUT.RENDER_CELL
    LAYOUT.HISTORY = LAYOUT.CELL.js_obj.HISTORY
    LAYOUT.QUAD_TREE = LAYOUT.CELL.js_obj.QUAD_TREE
    LAYOUT.STAGE.updateEditRefBorder(LAYOUT)
    let affectCellPtrs = getCellPtrs(editCell)
    ReBuildTargetCellRefs(LAYOUT.CELL, affectCellPtrs) //更新编辑的器件相关引用
    return
  }
  if (LAYOUT.CellHistory.length) {
    let toHistory
    if (toTop) {
      toHistory = LAYOUT.CellHistory[0]
    } else {
      toHistory = LAYOUT.CellHistory.at(-1)
    }
    // let canAccess = await LAYOUT.canAccessFile(toHistory.cell.snow_id)
    // if (!canAccess) {

    //   return
    // }
    let history
    let needForceSaveCells = [LAYOUT.CELL]
    if (toTop) {
      //返回最上层
      history = LAYOUT.CellHistory[0]
      // let unlockCells = [LAYOUT.CELL]
      LAYOUT.CellHistory.forEach((history, i) => {
        if (i) {
          needForceSaveCells.push(history.cell)
        }
      })
      // await LAYOUT.unLockFile(needForceSaveCells) //解锁当前文件
      LAYOUT.lockFile(history.cell.snow_id) //锁定当前文件
    } else {
      history = LAYOUT.CellHistory.pop() //读取记录
      // LAYOUT.unLockFile(LAYOUT.CELL) //解锁当前文件
      LAYOUT.lockFile(history.cell.snow_id) //锁定当前文件
    }
    // let affectCellPtrs = getCellPtrs(LAYOUT.CELL)
    await forceSaveCells(needForceSaveCells, LAYOUT, true)
    LAYOUT.resetLayout()
    LAYOUT.RENDER_CELL = history.cell
    LAYOUT.CELL = history.cell
    // LAYOUT.QUAD_TREE = LAYOUT.CELL.js_obj.QUAD_TREE
    // ReBuildTargetCellRefs(LAYOUT.CELL, affectCellPtrs)
    ReBuildCellRefs(LAYOUT)
    LAYOUT.enterCell(history.cell)
    LAYOUT.AXIS.scale = history.scale
    LAYOUT.AXIS.dx = history.dx
    LAYOUT.AXIS.dy = history.dy
    LAYOUT.QUAD_TREE = LAYOUT.CELL.js_obj.QUAD_TREE
    LAYOUT.HISTORY = LAYOUT.CELL.js_obj.HISTORY
    LAYOUT.resize()
    bus.$emit('cellHistoryUpdate', LAYOUT.RENDER_CELL)
  }
}

//编辑下层器件
export async function editLowerLevel(LAYOUT) {
  let objs = LAYOUT.STATUS.checked_objs
  if (!LAYOUT.EDIT_REF && objs.length === 1 && objs[0].$$?.ptrType.name == 'Reference*' && objs[0].cell && !objs[0].js_obj.expanRefs?.length) {
    // LAYOUT.switchMode(BOARDMODE.EDIT_LOWER_CELL)

    let editRef = objs[0]
    let editCell = editRef.cell
    let canAccess = await LAYOUT.canAccessFile(editCell.snow_id)
    if (!canAccess) {
      return
    }
    LAYOUT.EDIT_REF = editRef
    LAYOUT.CELL = editCell
    LAYOUT.HISTORY = editCell.js_obj.HISTORY
    LAYOUT.QUAD_TREE = editCell.js_obj.QUAD_TREE
    LAYOUT.STATUS.resetCheckStatus(LAYOUT)
    LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
    LAYOUT.STAGE.updateEditRefBorder(LAYOUT)
  }
}

//另存为器件
export function saveAsCell(LAYOUT, name) {
  if (LAYOUT.STATUS.checked_objs.length) {
    LAYOUT.STATUS.updateCheckedObjsAABB()
    let center = LAYOUT.STATUS.checked_objs_center
    let objs = LAYOUT.STATUS.checked_objs
    let len = objs.length
    let newCell = new Kernel.Cell(name)
    let newPos = []
    for (let i = 0; i < len; i++) {
      let p = getPosition(objs[i])
      newPos.push([p[0] - center[0], p[1] - center[1]])
    }
    for (let j = 0; j < len; j++) {
      const obj = objs[j]
      const p_new = newPos[j]
      const copy = copyFileObj(obj, p_new)
      addObjToCell(newCell, copy)
    }

    return newCell
  }
  return null
}

//3D页面跳转
export async function route3DView(LAYOUT) {
  let proj_gns = LAYOUT.proInfo.projectId
  let pdk_res = await getPDKInfo_api({ project_snow_id: proj_gns })
  let xst_data
  if (pdk_res.code === 200000) {
    var config = pdk_res.data.url_data

    if (config.xst) {
      // 获取drf文件内容并解析为json
      let xst_u8 = await getObjectDataFn(config.xst)

      let xst_fr = new FileReader()
      xst_fr.readAsText(new Blob([xst_u8]))
      let result = new Promise(resolve => {
        xst_fr.onload = evt => {
          resolve(evt)
        }
      })
      await result.then(evt => {
        xst_data = JSON.parse(evt.target.result) //解析工艺文件数据
      })
    }
  }
  let pathInfo = router.resolve({
    path: '/3d-view',
  })

  let KCELL = LAYOUT.RENDER_CELL //画板器件转->GDS_CELL
  let lib = fileKernelCell2Gdstk(KCELL)
  let QCELL = lib.cells.filter(cell => cell.name == LAYOUT.RENDER_CELL.name)[0]
  if (!QCELL) retrun

  window['3DViewCell'] = null
  window['CrossSectionProcess'] = null
  window['3DViewCellCutBox'] = null
  if (xst_data?.CrossSectionProcess) {
    window['CrossSectionProcess'] = xst_data.CrossSectionProcess
  }
  let cell3DCutBox = LAYOUT.RENDER_CELL.js_obj.cutArea3D //3D剖面区域

  if (cell3DCutBox.length) {
    let box = cell3DCutBox[0].js_obj.bounding_box
    let w = box[1][0] - box[0][0]
    let h = box[1][1] - box[0][1]
    let center = [box[0][0] + w / 2, box[0][1] + h / 2]
    window['3DViewCellCutBox'] = { w, h, center }
  }
  let cell3dArea
  let cell3dAreas = LAYOUT.RENDER_CELL.js_obj.render3DArea //3D渲染区域
  if (cell3dAreas.length) {
    cell3dArea = new QGdstk.Polygon(cell3dAreas[0].points)
  } else {
    let aabb = QCELL.bounding_box()
    if (aabb) {
      let expand = 1000
      aabb[0][0] -= expand
      aabb[0][1] -= expand
      aabb[1][0] += expand
      aabb[1][1] += expand
      cell3dArea = new QGdstk.rectangle(aabb[0], aabb[1])
    }
  }
  if (cell3dArea) {
    let cell3D = new QGdstk.Cell('3dCell')
    let filter_polygons = []
    let aabb = cell3dArea.js_obj?.bounding_box
    if (!aabb) {
      aabb = cell3dArea.bounding_box()
    }
    let cell_polygons = QCELL.get_polygons()
    let operand1 = [cell3dArea]
    for (let i = 0; i < cell_polygons.length; i++) {
      const polygon = cell_polygons[i]
      let box = polygon.bounding_box()
      if (!(box[0][0] > aabb[1][0] || box[1][0] < aabb[0][0] || box[0][1] > aabb[1][1] || box[1][1] < aabb[0][1])) {
        let res = new QGdstk.boolean(operand1, [polygon], 'and')
        res.forEach(obj => {
          obj.datatype = polygon.datatype
          obj.layer = polygon.layer
        })
        filter_polygons.push(...res)
      }
    }
    cell3D.add(filter_polygons)
    let cellLayers = []
    for (const num in LAYOUT.LAYER_STATE.layerMats) {
      cellLayers.push(num)
    }

    window['3DViewCell'] = mergeSameLayerPolygons(cell3D, cellLayers)
  } else {
    window['3DViewCell'] = new QGdstk.Cell('3dCell')
  }
  window.open(pathInfo.href, '_blank')
}

//记录操作前的状态
export function recordHistory(LAYOUT, type, remark, params) {
  const checked_objs = LAYOUT.STATUS.checked_objs
  let len = checked_objs.length
  if (!len) return
  const targets = []
  const from = []
  const to = []
  for (let i = 0; i < len; i++) {
    let obj = checked_objs[i]
    if (type === 'transform') {
      //只针对移动
      targets.push(obj)
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsPolygon*') {
        from.push({ pos: obj.points })
      } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
        from.push({ pos: obj.center, angle: obj.angle }) //拷贝pos angle
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        from.push({ pos: obj.origin, angle: obj.angle }) //拷贝pos angle
      } else if (obj.constructor.name == 'Ruler') {
        from.push({ pos: deepClone(obj.points) }) //拷贝点坐标
      } else if (TYPE == 'Reference*') {
        from.push({ pos: deepClone(obj.origin), angle: obj.rotation, columns: obj.repetition.columns, rows: obj.repetition.rows, v1: deepClone(obj.repetition.v1), v2: deepClone(obj.repetition.v2) }) //拷贝坐标
      }
      to.push(null)
    } else if (type === 'shape') {
      //形状修改
      targets.push(obj)
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      if (TYPE == 'GdsFlexpath*') {
        from.push({ pos: deepClone(obj.points) })
      } else if (TYPE == 'GdsPolygon*') {
        from.push({ pos: obj.points }) //拷贝pos angle
      } else if (TYPE == 'GdsRectangle*') {
        from.push({ center: obj.center, width: obj.width, height: obj.height, angle: obj.angle })
      } else if (TYPE == 'GdsEllipse*') {
        from.push({ center: obj.center, radius_x: obj.radius_x, radius_y: obj.radius_y, angle: obj.angle })
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        from.push({ pos: obj.origin, angle: obj.angle }) //拷贝pos angle
      } else if (TYPE == 'Reference*') {
        from.push({ pos: deepClone(obj.origin), angle: obj.rotation, x_reflection: obj.x_reflection, columns: obj.repetition.columns, rows: obj.repetition.rows, v1: deepClone(obj.repetition.v1), v2: deepClone(obj.repetition.v2) }) //拷贝坐标 rotation
      } else if (obj.constructor.name == 'Ruler') {
        from.push({ pos: deepClone(obj.points) }) //拷贝点坐标
      }
      to.push(null)
    }
  }
  // LAYOUT.HISTORY.record([{ action: type, cell: LAYOUT.CELL, objs: targets, from: from, to: to, remark }])
  let record = { func: remark, actions: [{ action: type, cell: LAYOUT.CELL, objs: targets, from: from, to: to }] }
  if (params !== undefined) {
    record.params = params
  }
  LAYOUT.HISTORY.record(record)
}

//记录当前属性
export function recordObjsProperty(LAYOUT, type, objs) {
  const from = []
  const to = []
  for (let i = 0; i < objs.length; i++) {
    let obj = objs[i]
    let recordCellParams = obj.need_update //是否记录器件参数
    from.push(copyObjProperty(obj, recordCellParams))
    if (obj.need_update) {
      if (obj.cell.text) {
        obj.js_obj.textChange = true
      } else if (obj.cell.pcell) {
        obj.js_obj.paramsChange = true
      }
    }
  }
  const history = {
    func: 'PROPERTY',
    actions: [{ action: type, cell: LAYOUT.CELL, objs: objs, from: from, to: to }],
  }
  LAYOUT.HISTORY.record(history)
  setTimeout(() => LAYOUT.sendMessage(history)) //延迟发送，此时属性值还未修改
}
//撤销
export function undo(LAYOUT) {
  if (LAYOUT.MODE == BOARDMODE.PRE_PASTE || LAYOUT.STATUS.drawingObj) return
  let cells_before = LAYOUT.RENDER_CELL.depend_cells(-1)
  let undos = LAYOUT.HISTORY.undo()

  const refs = []
  let childCellChange = false //引用器件内部发生变化
  if (Array.isArray(undos)) {
    //旧的撤销数据结构数组
    let undo_len = undos?.length
    if (!undo_len) return
    //有些操作同时带有多个操作

    for (let i = 0; i < undo_len; i++) {
      undoHistorys(LAYOUT, undos[i])
      refs.push(...undos[i].objs.filter(obj => obj.$$?.ptrType.name == 'Reference*'))
    }
  } else {
    //新的撤销结构对象
    const actions = undos.actions
    let undo_len = actions?.length
    if (!undo_len) return
    let advSendMsg = false //是否提前发送消息同步数据
    if (actions.filter(act => act.action == 'add').length) {
      //存在删除操作需要提前计算下标，否则下标会不存在
      advSendMsg = true
    }
    if (advSendMsg) {
      LAYOUT.sendMessage(undos, true, false)
    }

    for (let i = 0; i < undo_len; i++) {
      childCellChange = undoHistorys(LAYOUT, actions[i])

      refs.push(...actions[i].objs.filter(obj => obj.$$?.ptrType.name == 'Reference*'))
    }
    if (!advSendMsg) {
      LAYOUT.sendMessage(undos, true, false)
    }
  }

  let cells_after = LAYOUT.RENDER_CELL.depend_cells(-1)
  let cells = getUniqueCells(cells_before.concat(cells_after))
  LAYOUT.STATUS.resetCheckStatus(LAYOUT)
  LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
  LAYOUT.STAGE.updatePreCheckObj(null, LAYOUT)
  LAYOUT.STAGE.updatePreCheckPoint(null)
  LAYOUT.STAGE.updatePreCheckLine(null)
  LAYOUT.STAGE.updateGlobalKP(LAYOUT)

  if (childCellChange) {
    LAYOUT.updateByMessageServe()
  } else {
    //重构引用渲染数据
    const affectCellPtrs = getRefsCellsPtr(cells, refs)
    if (affectCellPtrs.length) {
      updateCellInsRefs(LAYOUT.RENDER_CELL, affectCellPtrs)
      LAYOUT.STAGE.updateRefArray(LAYOUT, refs, affectCellPtrs)
    }
  }
  LAYOUT.STAGE.updateEditRefBorder(LAYOUT)
  LAYOUT.STAGE.reRenderCell(LAYOUT.RENDER_CELL, LAYOUT.LAYER_STATE)
  if (refs.length) {
    bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
  }
  return
}
//恢复
export function redo(LAYOUT) {
  if (LAYOUT.MODE == BOARDMODE.PRE_PASTE || LAYOUT.STATUS.drawingObj) return
  let redos = LAYOUT.HISTORY.redo()

  const refs = []
  const cells_before = LAYOUT.RENDER_CELL.depend_cells(-1) //撤销前记录否则删除后无法找到对应cell更新ref图形数据
  let childCellChange = false
  if (Array.isArray(redos)) {
    //旧的恢复据结构数组
    let redo_len = redos?.length
    if (!redo_len) return
    if (redo_len) {
      // let order = { add: 0, delete: 1, transform: 2, mirror: 3, property: 4, text: 5 }
      // redos.sort((a, b) => order[a.action] - order[b.action])

      for (let i = 0; i < redo_len; i++) {
        redoHistorys(LAYOUT, redos[i])
        refs.push(...redos[i].objs.filter(obj => obj.$$?.ptrType.name == 'Reference*'))
      }
    }
  } else {
    //新的撤销结构对象
    const actions = redos.actions
    let redo_len = actions?.length
    if (!redo_len) return
    let advSendMsg = false //是否提前发送消息同步数据
    if (actions.filter(act => act.action == 'delete').length) {
      //存在删除操作需要提前计算下标，否则下标会不存在
      advSendMsg = true
    }
    if (advSendMsg) {
      LAYOUT.sendMessage(redos, false, true)
    }

    for (let i = 0; i < redo_len; i++) {
      childCellChange = redoHistorys(LAYOUT, actions[i])
      refs.push(...actions[i].objs.filter(obj => obj.$$?.ptrType.name == 'Reference*'))
    }
    if (!advSendMsg) {
      LAYOUT.sendMessage(redos, false, true)
    }
  }

  let cells_after = LAYOUT.RENDER_CELL.depend_cells(-1)
  let cells = getUniqueCells(cells_before.concat(cells_after))
  LAYOUT.STATUS.resetCheckStatus(LAYOUT)
  LAYOUT.STATUS.updateCheckedPointsAndLines(LAYOUT)
  LAYOUT.STAGE.updatePreCheckObj(null, LAYOUT)
  LAYOUT.STAGE.updatePreCheckPoint(null)
  LAYOUT.STAGE.updatePreCheckLine(null)

  if (childCellChange) {
    LAYOUT.updateByMessageServe()
  } else {
    //重构引用渲染数据
    const affectCellPtrs = getRefsCellsPtr(cells, refs)

    if (affectCellPtrs.length) {
      updateCellInsRefs(LAYOUT.RENDER_CELL, affectCellPtrs)
      LAYOUT.STAGE.updateRefArray(LAYOUT, refs, affectCellPtrs)
    }
  }
  LAYOUT.STAGE.updateEditRefBorder(LAYOUT)
  LAYOUT.STAGE.reRenderCell(LAYOUT.RENDER_CELL, LAYOUT.LAYER_STATE)
  if (refs.length) {
    bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
  }
}

//执行撤销指令
function undoHistorys(LAYOUT, history) {
  let undo_objs = history.objs
  let action_objs_len = undo_objs.length
  let isDelete = history.action === 'delete'
  let isAdd = history.action === 'add'
  let isTransform = history.action === 'transform' //平移旋转
  let isShape = history.action === 'shape' //修改形状
  let isPropertyUpdate = history.action === 'property'
  let isTextUpdate = history.action === 'text'

  let cell = history.cell
  if (!cell) cell = LAYOUT.CELL
  let childCellChange = false
  for (let i = 0; i < action_objs_len; i++) {
    let obj = undo_objs[i]
    if (isDelete) {
      //判断反向操作 delete->add add->delete
      LAYOUT.addObj(cell, obj)
    } else if (isAdd) {
      LAYOUT.deleteObj(cell, obj)
    } else if (isTransform) {
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      const trans = history.from[i]
      if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsPolygon*') {
        history.to[i] = { pos: deepClone(obj.points) }
        obj.points = trans.pos
      } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
        history.to[i] = { pos: deepClone(obj.center), angle: obj.angle } //撤销前记录当前位置
        obj.center = trans.pos
        obj.angle = trans.angle
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        history.to[i] = { pos: deepClone(obj.origin), angle: obj.angle } //拷贝pos angle
        obj.origin = trans.pos
        obj.angle = trans.angle
      } else if (TYPE == 'Reference*') {
        history.to[i] = { pos: deepClone(obj.origin), angle: obj.rotation, columns: obj.repetition.columns, rows: obj.repetition.rows, v1: deepClone(obj.repetition.v1), v2: deepClone(obj.repetition.v2) } //拷贝坐标 rotation
        obj.origin = trans.pos
        obj.rotation = trans.angle

        obj.repetition.columns = trans.columns
        obj.repetition.rows = trans.rows
        if (trans.columns || trans.rows) {
          obj.repetition.v1 = trans.v1
          obj.repetition.v2 = trans.v2
        }
      } else if (obj.constructor.name == 'Ruler') {
        history.to[i] = { pos: deepClone(obj.points) } //拷贝点坐标
        obj.points = trans.pos
      }
      reBuildGDSGraphic(obj, LAYOUT)
    } else if (isShape) {
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      const shape = history.from[i]
      if (TYPE == 'GdsFlexpath*') {
        history.to[i] = { pos: deepClone(obj.points) }
        obj.points = shape.pos
      } else if (TYPE == 'GdsPolygon*') {
        history.to[i] = { pos: deepClone(obj.points) } //撤销前记录当前顶点
        obj.points = shape.pos
      } else if (TYPE == 'GdsRectangle*') {
        history.to[i] = { center: deepClone(obj.center), width: obj.width, height: obj.height, angle: obj.angle } //撤销前记录当前属性
        obj.center = shape.center
        obj.width = shape.width
        obj.height = shape.height
        obj.angle = shape.angle
      } else if (TYPE == 'GdsEllipse*') {
        history.to[i] = { center: deepClone(obj.center), radius_x: obj.radius_x, radius_y: obj.radius_y, angle: obj.angle } //撤销前记录当前属性
        obj.center = shape.center
        obj.radius_x = shape.radius_x
        obj.radius_y = shape.radius_y
        obj.angle = shape.angle
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        history.to[i] = { pos: obj.origin, angle: obj.angle } //拷贝pos angle
        obj.origin = shape.pos
        obj.angle = shape.angle
      } else if (TYPE == 'Reference*') {
        history.to[i] = { pos: deepClone(obj.origin), angle: obj.rotation, x_reflection: obj.x_reflection, columns: obj.repetition.columns, rows: obj.repetition.rows, v1: deepClone(obj.repetition.v1), v2: deepClone(obj.repetition.v2) } //拷贝坐标 rotation
        obj.origin = shape.pos
        obj.rotation = shape.angle
        obj.x_reflection = shape.x_reflection
        obj.repetition.columns = shape.columns
        obj.repetition.rows = shape.rows
        if (shape.columns || shape.rows) {
          obj.repetition.v1 = shape.v1
          obj.repetition.v2 = shape.v2
        }
      } else if (obj.constructor.name == 'Ruler') {
        history.to[i] = { pos: deepClone(obj.points) } //拷贝点坐标
        obj.points = shape.pos
      }
      reBuildGDSGraphic(obj, LAYOUT)
    } else if (isPropertyUpdate) {
      let from = history.from[i]
      let recordParams = false
      if (from.text || from.paras) {
        //器件参数发生修改
        obj.js_obj.textChange = false
        obj.js_obj.paramsChange = false
        recordParams = true
        childCellChange = true
        if (from.text) {
          obj.js_obj.textChange = true
        }
        if (from.paras) {
          obj.js_obj.paramsChange = true
        }
      }

      let nowProperty = copyObjProperty(obj, recordParams)
      history.to[i] = nowProperty
      applayObjProperty(obj, from, LAYOUT)
      reBuildGDSGraphic(obj, LAYOUT, true, true, true)
      updateObjMat(obj, LAYOUT)
    }
  }
  return childCellChange
}

//执行恢复指令
function redoHistorys(LAYOUT, history) {
  let redo_objs = history.objs
  let action_objs_len = redo_objs.length
  let isDelete = history.action === 'delete'
  let isAdd = history.action === 'add'
  let isTransform = history.action === 'transform'
  let shape = history.action === 'shape'
  let isPropertyUpdate = history.action === 'property'
  let isTextUpdate = history.action === 'text'
  let cell = history.cell
  if (!cell) cell = LAYOUT.CELL
  let childCellChange = false
  for (let i = 0; i < action_objs_len; i++) {
    let obj = redo_objs[i]
    if (obj.isRefBox) {
      editRef = true
    }
    if (isAdd) {
      LAYOUT.addObj(cell, obj)
    } else if (isDelete) {
      LAYOUT.deleteObj(cell, obj)
    } else if (isTransform) {
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      const transform = history.to[i]
      if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsPolygon*') {
        obj.points = deepClone(transform.pos)
      } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
        obj.center = deepClone(transform.pos)
        obj.angle = transform.angle
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        obj.origin = deepClone(transform.pos)
        obj.angle = transform.angle
      } else if (TYPE == 'Reference*') {
        obj.origin = deepClone(transform.pos)
        obj.rotation = transform.angle
        obj.repetition.columns = transform.columns
        obj.repetition.rows = transform.rows
        obj.repetition.columns = transform.columns
        obj.repetition.rows = transform.rows
        if (transform.columns || transform.rows) {
          obj.repetition.v1 = trans.v1
          obj.repetition.v2 = trans.v2
        }
      } else if (obj.constructor.name == 'Ruler') {
        obj.points = deepClone(transform.pos)
      }
      reBuildGDSGraphic(obj, LAYOUT)
    } else if (shape) {
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      const shape = history.to[i]
      if (TYPE == 'GdsFlexpath*') {
        obj.points = deepClone(shape.pos)
      } else if (TYPE == 'GdsPolygon*') {
        obj.points = shape.pos
      } else if (TYPE == 'GdsRectangle*') {
        obj.center = shape.center
        obj.width = shape.width
        obj.height = shape.height
        obj.angle = shape.angle
      } else if (TYPE == 'GdsEllipse*') {
        obj.center = shape.center
        obj.radius_x = shape.radius_x
        obj.radius_y = shape.radius_y
        obj.angle = shape.angle
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        obj.origin = deepClone(shape.pos)
        obj.angle = shape.angle
      } else if (TYPE == 'Reference*') {
        obj.origin = deepClone(shape.pos)
        obj.rotation = shape.angle
        obj.x_reflection = shape.x_reflection
        obj.repetition.columns = shape.columns
        obj.repetition.rows = shape.rows
        if (shape.columns || shape.rows) {
          obj.repetition.v1 = shape.v1
          obj.repetition.v2 = shape.v2
        }
      } else if (obj.constructor.name == 'Ruler') {
        obj.points = deepClone(shape.pos)
      }
      reBuildGDSGraphic(obj, LAYOUT)
    } else if (isPropertyUpdate) {
      let to = history.to[i]
      obj.js_obj.textChange = false
      obj.js_obj.paramsChange = false
      if (to.text || to.params) {
        childCellChange = true
        if (to.text) {
          obj.js_obj.textChange = true
        }
        if (to.params) {
          obj.js_obj.paramsChange = true
        }
      }
      applayObjProperty(obj, to, LAYOUT)
      reBuildGDSGraphic(obj, LAYOUT, true, true, true)
      updateObjMat(obj, LAYOUT)
    }
  }
  return childCellChange
}

//强制保存器件
async function forceSaveCells(cells, LAYOUT, needUnLock = false) {
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i]

    const cell_gns = getCellGns(cell, LAYOUT)
    await saveCellFn(cell.snow_id, cell_gns, true, needUnLock)
  }
}

function getCellGns(cell, LAYOU) {
  const topInfo = LAYOU.topInfo
  const proInfo = LAYOU.proInfo
  const fileInfo = LAYOU.fileInfo
  const cellInfo = LAYOU.cellInfo
  if (topInfo.teamId) {
    return `gns://${topInfo.teamId}/${proInfo.projectId}/${fileInfo.fileId}/${cell.snow_id}`
  } else {
    return `gns://${proInfo.projectId}/${fileInfo.fileId}/${cell.snow_id}`
  }
}

//深拷贝版图数据
export function deepCopyData(datas) {
  const res = { datas: datas.filter(obj => obj.$$?.ptrType.name !== 'Reference*'), cells: [], layers: [], copyCell: null }
  let tempCell = new Kernel.Cell('')
  for (let i = 0; i < datas.length; i++) {
    const obj = datas[i]
    if (datas[i].$$?.ptrType.name == 'Reference*') {
      tempCell.add_reference(obj)
    }
  }
  let copyDatas = tempCell.deep_copy_logic()

  let copyCell
  for (let i = 0; i < copyDatas.length; i++) {
    const data = copyDatas[i]
    if (data.$$?.ptrType.name == 'Cell*' && data.name === '') {
      copyCell = data
    }
  }
  // if (!copyCell) {
  //   copyCell = new Kernel.Cell('')
  res.datas.forEach(obj => {
    const TYPE = obj.$$?.ptrType.name
    if (TYPE == 'GdsFlexpath*') {
      copyCell.add_flexpath(obj)
    } else if (TYPE == 'GdsPolygon*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
      copyCell.add_polygon(obj)
    } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
      copyCell.add_label(obj)
    }
  })
  // }
  res.layers = []
  res.datas.forEach(obj => {
    if (obj.layers !== undefined) {
      res.layers.push(obj.layers[0])
    } else if (obj.layer !== undefined) {
      res.layers.push(obj.layer)
    }
  })
  if (copyCell) {
    const refs = copyCell.referens
    refs.forEach(ref => {
      if (ref.cell) {
        res.layers.push(...ref.cell.layers)
      }
    })
    res.datas = res.datas.concat(refs)
    res.cells = copyCell.depend_cells(-1)
    res.copyCell = copyCell
  }
  res.layers = noRepeatArray(res.layers)

  return res
}

export function getDataCells(datas) {
  const refs = datas.filter(obj => obj.$$?.ptrType.name == 'Reference*')
  const cells = []
  refs.forEach(ref => {
    if (ref.cell) {
      if (cells.indexOf(ref.cell) == -1) {
        cells.push(ref.cell)
      }
    }
  })

  return cells
}

//存在闭环
export function existLoop(boardCell, cells) {
  for (let i = 0; i < cells.length; i++) {
    if (isCircle(cells[i], boardCell)) {
      return true
    }
  }
  return false
}
