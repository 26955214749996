const LayerInfo = [
  {
    layerName: 'none',
    lock: false,
    hide: false,
    layerNumber: 0,
    color: '#1955FF',
    shape: 'F1',
    border: 'B1',
    borderColor: '#1955FF',
    isExport: true,
  },
  {
    layerName: 'Metal1',
    lock: false,
    hide: false,
    layerNumber: 1,
    color: '#1955FF',
    shape: 'F1',
    border: 'B1',
    borderColor: '#1955FF',
    isExport: true,
  },
  {
    layerName: 'Metal2',
    lock: false,
    hide: false,
    layerNumber: 2,
    color: '#7DAEF7',
    shape: 'F6',
    border: 'B1',
    borderColor: '#7DAEF7',
    isExport: true,
  },
  {
    layerName: 'Metal3',
    lock: false,
    hide: false,
    layerNumber: 3,
    color: '#9B51EE',
    shape: 'F3',
    border: 'B1',
    borderColor: '#9B51EE',
    isExport: true,
  },
  {
    layerName: 'Metal3-Flip',
    lock: false,
    hide: false,
    layerNumber: 4,
    color: '#C660F1',
    shape: 'F3',
    border: 'B1',
    borderColor: '#C660F1',
    isExport: true,
  },
  {
    layerName: 'Metal4',
    lock: false,
    hide: false,
    layerNumber: 5,
    color: '#A6B65A',
    shape: 'F3',
    border: 'B1',
    borderColor: '#A6B65A',
    isExport: true,
  },
  {
    layerName: 'Metal4-Flip',
    lock: false,
    hide: false,
    layerNumber: 6,
    color: '#7FB65A',
    shape: 'F3',
    border: 'B1',
    borderColor: '#7FB65A',
    isExport: true,
  },
  {
    layerName: 'Indium',
    lock: false,
    hide: false,
    layerNumber: 7,
    color: '#E8CE3B',
    shape: 'F1',
    border: 'B1',
    borderColor: '#E8CE3B',
    isExport: true,
  },
  {
    layerName: 'Indium-Flip',
    lock: false,
    hide: false,
    layerNumber: 8,
    color: '#DEB22B',
    shape: 'F3',
    border: 'B1',
    borderColor: '#DEB22B',
    isExport: true,
  },
  {
    layerName: 'TiN',
    lock: false,
    hide: false,
    layerNumber: 9,
    color: '#B069E7',
    shape: 'F1',
    border: 'B1',
    borderColor: '#B069E7',
    isExport: true,
  },
  {
    layerName: 'TiN-Flip',
    lock: false,
    hide: false,
    layerNumber: 10,
    color: '#D469E7',
    shape: 'F3',
    border: 'B1',
    borderColor: '#D469E7',
    isExport: true,
  },
  {
    layerName: 'Junction',
    lock: false,
    hide: false,
    layerNumber: 11,
    color: '#E60012',
    shape: 'F4',
    border: 'B1',
    borderColor: '#E60012',
    isExport: true,
  },
  {
    layerName: 'Label',
    lock: false,
    hide: false,
    layerNumber: 12,
    color: '',
    shape: '',
    border: 'B1',
    borderColor: '#000000',
    isExport: true,
  },
]

const WBPadSize = [
  { name: 'A', value: 456, unit: 'um', change: true, min: 300, max: 600 },
  { name: 'A1', value: 250, unit: 'um', change: true, min: 100, max: 500 },
  { name: 'B', value: 323, unit: 'um', change: true, min: 200, max: 500 },
  { name: 'B1', value: 250, unit: 'um', change: true, min: 150, max: 400 },
  { name: 'B2', value: 100, unit: 'um', change: true, min: 50, max: 200 },
  { name: 'C', value: 8, unit: 'um', change: true, min: 8, max: 20 },
  { name: 'C1', value: 4, unit: 'um', change: true, min: 4, max: 10 },
  { name: 'layer', value: 1, unit: '', change: true, min: 0, max: 255 },
]

const ComponentDefaultLayer = {
  metal1: 1,
  metal2: 2,
  metal3: 3,
  metal3_flip: 4,
  metal4: 5,
  metal4_flip: 6,
  indium: 7,
  indium_flip: 8,
  tin: 9,
  tin_flip: 10,
  juction: 11,
  // semiconductor layers
  screen: 200,
  co_zone: 201,
  co: 202,
  barrier: 203,
  plunger: 204,
  ion: 205,
  tiau: 206,
  mesa: 207,
  qubit: 208,
  lead: 209,
  channel: 210,
  source: 211,
  fin: 212,
  pad: 213,
  resistor: 214,
  resonator: 215,
}

module.exports = {
  LayerInfo: LayerInfo,
  ComponentDefaultLayer: ComponentDefaultLayer,
}
