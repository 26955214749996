import { distanceToLineSegment } from '../../graphic_f32/point-to-line-segment'
import { getSlope } from './graphics-util'
import { getMouseAABB } from './quad-tree'

//图形相交检测
export function rectHitObjs(rect, obj) {
  if (obj.$$?.ptrType.name == 'GdsFlexpath*') {
    return insertLine(rect, obj.points)
  } else if (obj.$$?.ptrType.name == 'GdsRectangle*') {
    return insertPolygon(rect, obj.points)
  } else if (obj.$$?.ptrType.name == 'GdsEllipse*') {
    return insertPolygon(rect, obj.points)
  } else if (obj.$$?.ptrType.name == 'GdsPolygon*') {
    return insertPolygon(rect, obj.points)
  } else if (obj.$$?.ptrType.name == 'GdsLabel*') {
    return insertPolygon(rect, obj.js_obj.borderBox.points)
  } else if (obj.$$?.ptrType.name == 'GdsKeyPoint*') {
    if (insertPolygon(rect, obj.js_obj.borderBox.points)) {
      return true
    } else {
      return rect.contain(obj.origin)
    }
  } else if (obj.$$?.ptrType.name == 'Reference*') {
    return insertPolygon(rect, obj.js_obj.borderBox.points)
  } else if (obj.constructor.name == 'Ruler') {
    return insertLine(rect, obj.points)
  }
  return false
}

//部分选择相交检测
export function rectHitPartObjs(rect, obj) {
  if (obj.$$?.ptrType.name == 'GdsFlexpath*') {
    let flag = insertLine(rect, obj.points)
    if (flag) {
      return flag
    }

    if (obj.js_obj.polygons?.length) {
      for (let i = 0; i < obj.js_obj.polygons.length; i++) {
        if (insertPolygon(rect, obj.js_obj.polygons[i].points)) {
          return true
        }
      }
    }
    return false
  } else if (obj.$$?.ptrType.name == 'GdsRectangle*') {
    return insertPolygon(rect, obj.js_obj.borderBox.points)
  } else if (obj.$$?.ptrType.name == 'GdsEllipse*') {
    return insertPolygon(rect, obj.js_obj.borderBox.points)
  } else if (obj.$$?.ptrType.name == 'GdsPolygon*') {
    return insertPolygon(rect, obj.points)
  } else if (obj.$$?.ptrType.name == 'GdsLabel*') {
    return insertPolygon(rect, obj.js_obj.borderBox.points)
    // return insertPolygon(rect, obj.js_obj.shape)
  } else if (obj.$$?.ptrType.name == 'GdsKeyPoint*') {
    if (insertPolygon(rect, obj.js_obj.borderBox.points)) {
      return true
    } else {
      return rect.contain(obj.origin)
    }
    // return insertPolygon(rect, obj.js_obj.shape)
  } else if (obj.$$?.ptrType.name == 'Reference*') {
    if (insertPolygon(rect, obj.js_obj.borderBox.points)) {
      return true
    } else {
      return rect.contain(obj.origin)
    }
  } else if (obj.constructor.name == 'Ruler') {
    return insertLine(rect, obj.points)
  }
  return false
}
//获取最近的线
export function getNearLine(pos, dist, hits) {
  let lines = []
  for (let i = 0; i < hits.length; i++) {
    const obj = hits[i]
    if (obj.$$?.ptrType.name == 'GdsFlexpath*') {
      lines.push(...insertLines(pos, dist, obj.points, obj))
    } else if (obj.$$?.ptrType.name == 'GdsRectangle*') {
      lines.push(...insertLines(pos, dist, obj.js_obj.borderBox.points, obj, true))
    } else if (obj.$$?.ptrType.name == 'GdsEllipse*') {
      lines.push(...insertLines(pos, dist, obj.js_obj.borderBox.points, obj, true))
    } else if (obj.$$?.ptrType.name == 'GdsPolygon*') {
      lines.push(...insertLines(pos, dist, obj.points, obj, true))
    }
  }
  lines.sort((a, b) => a.dist - b.dist) //按距离排序

  if (lines.length) {
    return lines[0]
  }
  return null
}

//获取最近的点
export function getNearPoint(pos, dist, hits) {
  let points = []
  for (let i = 0; i < hits.length; i++) {
    const obj = hits[i]
    if (obj.$$?.ptrType.name == 'GdsFlexpath*') {
      points.push(...insertPoints(pos, dist, obj.points, obj))
    } else if (obj.$$?.ptrType.name == 'GdsRectangle*') {
      points.push(...insertPoints(pos, dist, obj.js_obj.borderBox.points, obj))
    } else if (obj.$$?.ptrType.name == 'GdsEllipse*') {
      points.push(...insertPoints(pos, dist, obj.js_obj.borderBox.points, obj))
    } else if (obj.$$?.ptrType.name == 'GdsPolygon*') {
      points.push(...insertPoints(pos, dist, obj.points, obj))
    } else if (obj.constructor.name == 'Ruler') {
      points.push(...insertPoints(pos, dist, obj.points, obj))
    }
  }
  points.sort((a, b) => a.dist - b.dist) //按距离排序

  if (points.length) {
    return points[0]
  }
  return null
}

//获取最近的可吸附点 点线圆顶点，边的中点，图形中心点，关键点，标签参考点
export function getNearAdropPoint(pos, dist, hits, scale, LAYER_STATE) {
  let points = []
  for (let i = 0; i < hits.length; i++) {
    const obj = hits[i]
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    if (TYPE == 'GdsFlexpath*') {
      points.push(...adsorpPoints(pos, dist, obj.points, obj.center, obj, false))
      if (obj.js_obj.polygons?.length) {
        //吸附共面波导多边形顶点
        obj.js_obj.polygons.forEach(polygon => {
          points.push(...adsorpPoints(pos, dist, polygon.points, polygon.center, polygon))
        })
      }
    } else if (TYPE == 'GdsRectangle*') {
      points.push(...adsorpPoints(pos, dist, obj.js_obj.borderBox.points, obj.center, obj))
    } else if (TYPE == 'GdsEllipse*') {
      points.push(...adsorpPoints(pos, dist, obj.js_obj.borderBox.points, obj.center, obj))
    } else if (TYPE == 'GdsPolygon*') {
      points.push(...adsorpPoints(pos, dist, obj.points, obj.center, obj))
    } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
      points.push(...adsorpPoints(pos, dist, [], obj.origin, obj))
    } else if (TYPE == 'Reference*') {
      let transMousePos = transInRefPos([pos], obj)[0] //将鼠标坐标转换到引用内部
      let refs_hits = obj.cell.js_obj.QUAD_TREE.queryPartObjByAABB(getMouseAABB(transMousePos, scale), false, LAYER_STATE)

      let hitPoint = getNearAdropPoint(transMousePos, dist, refs_hits, scale, LAYER_STATE)

      if (hitPoint) {
        hitPoint.point = transOutRefPos([hitPoint.point], obj)[0] //获取的坐标转换到引用外部
        points.push(hitPoint)
      }
    } else if (obj.constructor.name == 'Ruler') {
      points.push(...adsorpPoints(pos, dist, obj.points, null, obj, false, false))
    }
  }

  points.sort((a, b) => a.dist - b.dist) //按距离排序

  if (points.length) {
    return points[0]
  }
  return null
}

//获取最近的可选中的线，线，多边形,圆的边框.needChecked 是否需要被选中的元素,needComMid是否需要对比中垂线,slope是否需要斜率相同
export function getNearAdropLine(pos, dist, hits, needChecked = false, needCompMid = false, needCompSlope = false, line = null, scale, LAYER_STATE) {
  let slope
  if (needCompSlope && line) {
    slope = getSlope(line[0], line[1])
  }

  let lines = []
  if (needChecked) {
    hits = hits.filter(obj => obj.js_obj.STATE.checked || obj.js_obj.STATE.partChecked) //过滤选中的图形
  }

  for (let i = 0; i < hits.length; i++) {
    const obj = hits[i]
    if (obj.$$?.ptrType.name == 'GdsFlexpath*') {
      lines.push(...insertLines(pos, dist, obj.points, obj, false, needCompMid, needCompSlope, slope, scale))
      //有宽度的线
      if (obj.js_obj.polygons?.length) {
        obj.js_obj.polygons.forEach(polygon => lines.push(...insertLines(pos, dist, polygon.points, obj, true, needCompMid, needCompSlope, slope, scale)))
      }
    } else if (obj.$$?.ptrType.name == 'GdsRectangle*') {
      lines.push(...insertLines(pos, dist, obj.js_obj.borderBox.points, obj, true, needCompMid, needCompSlope, slope, scale))
    } else if (obj.$$?.ptrType.name == 'GdsEllipse*') {
      lines.push(...insertLines(pos, dist, obj.js_obj.borderBox.points, obj, true, needCompMid, needCompSlope, slope, scale))
    } else if (obj.$$?.ptrType.name == 'GdsPolygon*') {
      lines.push(...insertLines(pos, dist, obj.points, obj, true, needCompMid, needCompSlope, slope, scale))
    } else if (obj.$$?.ptrType.name == 'Reference*') {
      let transMousePos = transInRefPos([pos], obj)[0] //将鼠标坐标转换到引用内部
      let refs_hits = obj.cell.js_obj.QUAD_TREE.queryPartObjByAABB(getMouseAABB(transMousePos, scale), false, LAYER_STATE)

      let transLine
      if (line) {
        transLine = transInRefPos(line, obj) //将线转换至坐标系统内部
      }
      let hitLine = getRefNearAdropLine(transMousePos, dist, refs_hits, needChecked, needCompMid, needCompSlope, transLine, scale, LAYER_STATE)

      if (hitLine) {
        hitLine.line = transOutRefPos(hitLine.line, obj) //获取的坐标转换到引用外部
        hitLine.inRef = true //标记引用内部的取出的线
        lines.push(hitLine)
      }
    }
  }
  if (needChecked) {
    lines = lines.filter(line => {
      const obj = line.obj

      if (line.inRef) {
        return true
      }
      if (obj.js_obj.STATE.checked) {
        return true
      }
      if (obj.js_obj.STATE.partChecked) {
        return isInclude(obj.js_obj.checked_points_index, line.indexes)
      }
    })
  }

  lines.sort((a, b) => a.dist - b.dist) //按距离排序

  if (lines.length) {
    return lines[0]
  }
  return null
}

export function getRefNearAdropLine(pos, dist, hits, needChecked = false, needCompMid = false, needCompSlope = false, line = null, scale, LAYER_STATE) {
  let slope
  if (needCompSlope && line) {
    slope = getSlope(line[0], line[1])
  }

  let lines = []
  for (let i = 0; i < hits.length; i++) {
    const obj = hits[i]
    if (obj.$$?.ptrType.name == 'GdsFlexpath*') {
      lines.push(...insertLines(pos, dist, obj.points, obj, false, needCompMid, needCompSlope, slope, scale))
    } else if (obj.$$?.ptrType.name == 'GdsRectangle*') {
      lines.push(...insertLines(pos, dist, obj.js_obj.borderBox.points, obj, true, needCompMid, needCompSlope, slope, scale))
    } else if (obj.$$?.ptrType.name == 'GdsEllipse*') {
      lines.push(...insertLines(pos, dist, obj.js_obj.borderBox.points, obj, true, needCompMid, needCompSlope, slope, scale))
    } else if (obj.$$?.ptrType.name == 'GdsPolygon*') {
      lines.push(...insertLines(pos, dist, obj.points, obj, true, needCompMid, needCompSlope, slope, scale))
    } else if (obj.$$?.ptrType.name == 'Reference*') {
      let transMousePos = transInRefPos([pos], obj)[0] //将鼠标坐标转换到引用内部
      let refs_hits = obj.cell.js_obj.QUAD_TREE.queryPartObjByAABB(getMouseAABB(transMousePos, scale), false, LAYER_STATE)

      let transLine
      if (line) {
        transLine = transInRefPos(line, obj) //将线转换至坐标系统内部
      }
      let hitLine = getRefNearAdropLine(transMousePos, dist, refs_hits, needChecked, needCompMid, needCompSlope, transLine, scale)

      if (hitLine) {
        hitLine.line = transOutRefPos(hitLine.line, obj) //获取的坐标转换到引用外部
        lines.push(hitLine)
      }
    }
  }

  lines.sort((a, b) => a.dist - b.dist) //按距离排序

  if (lines.length) {
    return lines[0]
  }
  return null
}

//判断arr1是否完全包含arr2
export function isInclude(arr1, arr2) {
  return arr2.every(val => arr1.includes(val))
}

//多边形与多边形相交
export function insertPolygon(rect, points) {
  let temp = new QGdstk.Polygon(points)
  if (new QGdstk.boolean([rect], [temp], 'and').length) {
    return true
  }
}

//多边形与线相交
export function insertLine(rect, points) {
  if (!points.length) return false
  let path = new QGdstk.FlexPath(points, 0.1, 0, 'natural', 'flush', 0, null, 1e-2, false, true, 0, 0)
  if (new QGdstk.boolean([rect], [path], 'and').length) {
    return true
  }
}

//切割线与线相交
export function axisInsertLine(start, end, axis, val) {
  if (axis == 'x' && ((val >= start[0] && val <= end[0]) || (val <= start[0] && val >= end[0]))) {
    return true
  } else if (axis == 'y' && ((val >= start[1] && val <= end[1]) || (val <= start[1] && val >= end[1]))) {
    return true
  }
  return false
}

//切割线与线相交
export function axisInsertPolygon(start, end, axis, val) {
  if (axis == 'x' && ((val >= start[0] && val <= end[0]) || (val <= start[0] && val >= end[0]))) {
    return true
  } else if (axis == 'y' && ((val >= start[1] && val <= end[1]) || (val <= start[1] && val >= end[1]))) {
    return true
  }
  return false
}

//切割线与包围盒相交
export function axisInsertAABB(box, axis, val) {
  if (axis == 'x' && val >= box[0][0] && val <= box[1][0]) {
    return true
  } else if (axis == 'y' && val >= box[0][1] && val <= box[1][1]) {
    return true
  }
  return false
}

//求附近的线
export function insertLines(pos, range, points, obj, loop = false, needCompMid = false, needCompSlope = false, targetSlope = null, scale) {
  if (!points.length) return []
  let res = []

  if (loop) {
    points = points.concat([points[0]])
  }

  let max = points.length - 1
  for (let i = 0; i < max; i++) {
    let start_index = i
    let end_index = i + 1
    let start = points[i]
    let end = points[i + 1]
    let d = distanceToLineSegment(start[0], start[1], end[0], end[1], pos[0], pos[1])
    if (d <= range) {
      if (loop && end_index == max) {
        end_index = 0
      }
      if (needCompSlope) {
        //判断平行线
        const line_slope = getSlope(start, end)
        if (isSameSlope(line_slope, targetSlope)) {
          // result.point_indexes.push(i, i + 1)
          // result.push([start, end])
          res.push({ line: [start, end], indexes: [start_index, end_index], dist: d, obj })
        }
        if (needCompMid) {
          const slope_v = getVerticalSlope(line_slope) //线的垂直斜率
          if (isSameSlope(slope_v, targetSlope)) {
            if (distPoint(pos, getMidPoint(start, end)) <= range) {
              const line = getMidVertialLine(start, end, slope_v, scale)
              res.push({ line, indexes: [null, null], dist: d, obj, remark: 'cv' })
            }
          }
        }
      } else {
        res.push({ line: [start, end], indexes: [start_index, end_index], dist: d, obj })
      }
    }
  }

  return res
}

function isSameSlope(s1, s2) {
  if (s1 == null || s2 == null) {
    return s1 === s2
  } else {
    return parseFloat(s1.toFixed(2)) == parseFloat(s2.toFixed(2))
  }
}

//获取斜率的垂直斜率
export function getVerticalSlope(s) {
  if (s === null) {
    return 0
  }
  if (s === 0) {
    return null
  }
  return -1 / s
}

function getMidVertialLine(p1, p2, slope, scale = 1) {
  const mid_p = getMidPoint(p1, p2)
  const p_start = [0, 0]
  const p_end = [0, 0]
  const d = 10 / scale
  // 线的角度
  var theta = Math.atan(slope)
  if (slope === null) {
    theta = Math.PI / 2
  }

  //计算附近两点
  p_start[0] = mid_p[0] + d * Math.cos(theta)
  p_start[1] = mid_p[1] + d * Math.sin(theta)

  p_end[0] = mid_p[0] - d * Math.cos(theta)
  p_end[1] = mid_p[1] - d * Math.sin(theta)

  return [p_start, p_end]
}

//求附近的点
export function insertPoints(pos, range, points, obj) {
  if (!points.length) return []
  let res = []
  for (let i = 0; i < points.length; i++) {
    const p = points[i]
    const d = distPoint(pos, p)
    if (d < range) {
      res.push({ point: p, index: i, dist: d, obj })
    }
  }
  return res
}

//求附近的可吸附的点
export function adsorpPoints(pos, range, points, center, obj, isLoop = true, adsropMid = true) {
  if (isLoop && points.length) {
    points = points.concat([points[0]])
  }
  const maxIndex = points.length - 1
  let res = []
  for (let i = 0; i < maxIndex; i++) {
    const start_index = i
    const end_index = i + 1
    const start = points[start_index]
    const end = points[end_index]
    const d_s = distPoint(start, pos)
    if (d_s < range) {
      res.push({ point: start, dist: d_s, obj })
    }
    if (adsropMid) {
      const mid = getMidPoint(start, end)
      const d_m = distPoint(mid, pos)
      if (d_m < range) {
        res.push({ point: mid, dist: d_m, obj })
      }
    }

    if (end_index == maxIndex) {
      const d_e = distPoint(end, pos)
      if (d_e < range) {
        res.push({ point: end, dist: d_e, obj })
      }
    }
  }
  if (center) {
    const d_c = distPoint(pos, center)
    if (d_c < range) {
      res.push({ point: center, dist: d_c, obj })
    }
  }
  return res
}

//计算中点
export function getMidPoint(p1, p2) {
  return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2]
}

//点和点距离
export function distPoint(p1, p2) {
  const x = p1[0] - p2[0]
  const y = p1[1] - p2[1]
  return Math.sqrt(x * x + y * y)
}

//判断box1是否包含box2
export function boxContain(box1, box2) {
  return box1[0][0] <= box2[0][0] && box1[0][1] <= box2[0][1] && box1[1][0] >= box2[1][0] && box1[1][1] >= box2[1][1]
}

//获取盒子内命中的顶点下标
export function getHitPointsIndexesByAABB(orgpos, rect, isLoop = true, flag = false) {
  let indexes = []
  const maxIndex = orgpos.length - 1
  let points = orgpos
  if (isLoop) {
    points = orgpos.concat([orgpos[0]])
  }

  for (let i = 0; i < points.length - 1; i++) {
    // const p = points[i]
    // const x = p[0]
    // const y = p[1]
    // if (x >= aabb[0][0] && x <= aabb[1][0] && y >= aabb[0][1] && y <= aabb[1][1]) {
    //   indexes.push(i)
    // }
    if (flag) {
      //全包选择
      const startIndex = i
      const endIndex = i + 1
      const start = points[startIndex]
      const end = points[endIndex]
      if (rect.contain(start)) {
        indexes.push(startIndex)
      }
      if (rect.contain(end)) {
        indexes.push(endIndex)
      }
    } else {
      //相交选择
      const startIndex = i
      const endIndex = i + 1
      const start = points[startIndex]
      const end = points[endIndex]
      if (insertLine(rect, [start, end])) {
        indexes.push(startIndex)
        if (isLoop && endIndex > maxIndex) {
          indexes.push(0)
        } else {
          indexes.push(endIndex)
        }
      }
    }
  }

  return noRepeatArray(indexes)
}

//轴向命中多边形
export function axisHitPolygon(obj, axis, val) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
    if (axisInsertAABB(obj.js_obj.bounding_box, axis, val)) {
      return true
    }
  }
  return false
}

//轴向命中骨架线
export function axisHitLine(obj, axis, val) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    const points = obj.points
    const size = points.length
    const max = size - 1
    if (axisInsertAABB(obj.js_obj.bounding_box, axis, val)) {
      for (let i = 0; i < max; i++) {
        const start = points[i]
        const end = points[i + 1]
        if (axisInsertLine(start, end, axis, val)) {
          return true
        }
      }
    }
  }
  return false
}

//将坐标转换至引用坐标系
export function transInRefPos(points, ref) {
  let temp = new QGdstk.Polygon(points)
  temp.translate([-ref.origin[0], -ref.origin[1]])
  temp.rotate(-ref.rotation)
  const x_reflection = ref.x_reflection ? -1 : 1
  temp.scale(1 / ref.magnification, (1 / ref.magnification) * x_reflection)
  return temp.get_points()
}

//将坐标转换出引用外部
export function transOutRefPos(points, ref) {
  let temp = new QGdstk.Polygon(points)
  const x_reflection = ref.x_reflection ? -1 : 1
  temp.scale(ref.magnification, x_reflection * ref.magnification)
  temp.rotate(ref.rotation)
  temp.translate([ref.origin[0], ref.origin[1]])
  return fixPoints(temp.get_points())
}

//修复坐标点
export function fixPoints(points) {
  for (let i = 0; i < points.length; i++) {
    const p = points[i]
    p[0] = fixPoint(p[0])
    p[1] = fixPoint(p[1])
  }
  return points
}
export function fixPoint(p) {
  return parseFloat(p.toFixed(6))
}
