export class AutoToolWaferLayout {
  constructor() {
    this.conf = {
      wafer: {
        radius: 10,
        center: { x: 0, y: 0 },
        layer: 0,
        padding: 0,
      },
      cell: {
        object: null,
        name: 'cell1',
        space: 10,
      },
    }
  }

  run(conf) {
    // 辅助函数：检查给定位置是否可用
    // padding: 距离边缘的距离， 正值在内，负值在外
    function is_position_available(layout, x, y, width, height, padding) {
      let container_width = layout.width
      let container_height = layout.height

      // 检查位置是否与已放置的物体重叠
      for (let i = 0; i < layout.objects.length; i++) {
        let obj = layout.objects[i]
        if (x < obj.x + obj.width && x + width > obj.x && y < obj.y + obj.height && y + height > obj.y) return false
      }

      // 计算容器中心位置
      let cx = container_width / 2
      let cy = container_height / 2
      let r = cx

      // 平移到原点，再根据半径判断
      let tx = x - cx
      let ty = y - cy

      let max_rr = (r - padding) * (r - padding)

      // 矩形左下角点是否在容器内
      if (tx * tx + ty * ty > max_rr) {
        return false
      }
      // 矩形右上角点是否在容器内
      if ((tx + width) * (tx + width) + (ty + height) * (ty + height) > max_rr) {
        return false
      }
      // 矩形右下角点是否在容器内
      if ((tx + width) * (tx + width) + ty * ty > max_rr) {
        return false
      }
      // 矩形左上角点是否在容器内
      if (tx * tx + (ty + height) * (ty + height) > max_rr) {
        return false
      }
      return true
    }

    // 辅助函数：在给定布局中找到最佳位置
    function find_best_position(layout, obj, step) {
      let container_width = layout.width
      let container_height = layout.height
      let object_width = obj.width
      let object_height = obj.height
      let cx = parseInt(container_width / 2)
      let cy = parseInt(container_height / 2)

      let padding = conf.wafer.padding
      // let step = Math.min(object_width, object_height)
      // let step = 1

      // 圆心到四周遍历容器内所有可能的位置
      // 右上区域
      for (let y = cy; y < container_height - object_height + 1; y += step) {
        for (let x = cx; x < container_width - object_width + 1; x += step) {
          if (is_position_available(layout, x, y, object_width, object_height, padding)) {
            return { x: x, y: y }
          }
        }
      }
      // 左下区域
      for (let y = cy - object_height; y > -1; y -= step) {
        for (let x = cx - object_width; x > -1; x -= step) {
          if (is_position_available(layout, x, y, object_width, object_height, padding)) return { x: x, y: y }
        }
      }
      // 右下区域
      for (let y = cy - object_height; y > -1; y -= step) {
        for (let x = cx; x < container_width - object_width + 1; x += step) {
          if (is_position_available(layout, x, y, object_width, object_height, padding)) return { x: x, y: y }
        }
      }
      // 左上区域
      for (let y = cy; y < container_height - object_height + 1; y += step) {
        for (let x = cx - object_width; x > -1; x -= step) {
          if (is_position_available(layout, x, y, object_width, object_height, padding)) return { x: x, y: y }
        }
      }
      return false
    }

    function most_dense_packing(container_width, container_height, objects, step) {
      // 将物体按照面积从大到小排序
      // sorted_objects = sorted(objects, key=lambda obj: obj['width'] * obj['height'], reverse=True)
      const sorted_objects = objects.sort((a, b) => b.width * b.height - a.width * a.height)

      // 创建空的容器布局
      let layout = {
        width: container_width,
        height: container_height,
        objects: [],
      }

      // 尝试将每个物体放入容器
      sorted_objects.forEach(obj => {
        let position = find_best_position(layout, obj, step)
        if (position) {
          layout.objects.push({
            width: obj.width,
            height: obj.height,
            x: position.x,
            y: position.y,
          })
        }
      })

      return layout
    }

    // 计算晶圆最大放置数量
    function cacl_max_number(radius, rect) {
      let res = Math.ceil((2 * radius * 2 * radius) / (rect.width * rect.height))
      return res
    }

    // 计算最大公约数
    function gcd(a, b) {
      // 辗转相除法
      while (b !== 0) {
        let temp = b
        b = a % b
        a = temp
      }
      return a
    }

    // 计算放置步长，找到最优步长，加快速度
    function cacl_step(objects, space) {
      let width_arr = []
      let height_arr = []

      objects.forEach(element => {
        width_arr.push(element.width)
        height_arr.push(element.height)
      })

      let min_wh = 0
      if (width_arr) min_wh = Math.min(Math.min(...width_arr), Math.min(...height_arr))
      // 最大公约数作为步长
      // let step = gcd(parseInt(space), parseInt(min_wh))
      // step = step < 1 ? 1 : step
      return Math.floor(min_wh)
    }

    // 计算cell的偏移量
    // function cacl_cell_offset(cell_box) {
    //   return res
    // }

    this.conf = conf
    let cell = this.conf.cell.object

    // 计算最大数量
    let box = cell.bounding_box()
    let rect = {
      width: box[1][0] - box[0][0],
      height: box[1][1] - box[0][1],
    }

    if (rect.width === -Infinity || rect.height === -Infinity) {
      throw 'cell错误，cell的宽或高异常~'
    }
    // 计算cell需要平移到中心的偏移量
    // let cell_tx = -(box[0][0] + (box[1][0] - box[0][0]) / 2)
    // let cell_ty = -(box[1][0] + (box[1][1] - box[0][1]) / 2)
    let cell_tx = -box[0][0]
    let cell_ty = -box[0][1]

    let max_cnt = cacl_max_number(conf.wafer.radius, rect)

    // 创建objects
    let objects = []
    let space = conf.cell.space

    for (let i = 0; i < max_cnt; i++) {
      objects.push({ width: rect.width + space, height: rect.height + space })
    }

    let step = cacl_step(objects, space)

    // 容器宽高
    let container_height = conf.wafer.radius * 2
    let container_width = conf.wafer.radius * 2
    let wafer_tx = conf.wafer.center.x - conf.wafer.radius
    let wafer_ty = conf.wafer.center.y - conf.wafer.radius
    // 最密堆积
    let res = most_dense_packing(container_width, container_height, objects, step)
    res.objects.forEach(obj => {
      obj.x = obj.x + space / 2
      obj.y = obj.y + space / 2
      obj.width = obj.width - space
      obj.height = obj.height - space
    })

    res.objects.forEach(obj => {
      obj.x = obj.x + wafer_tx + cell_tx
      obj.y = obj.y + wafer_ty + cell_ty
      obj.width = obj.width - space
      obj.height = obj.height - space
    })

    return this.placeCell(res.objects)
  }

  placeCell(objects) {
    let cell = this.conf.cell.object
    let wafer_cell = new window.Kernel.Cell(cell.name + '_WaferLayout')
    for (let i = 0; i < objects.length; i++) {
      let obj = objects[i]
      let ref = new window.Kernel.Reference()
      ref.cell = cell
      ref.origin = [obj.x, obj.y]
      wafer_cell.add_reference(ref)
    }
    let circle = new window.Kernel.GdsEllipse([this.conf.wafer.center.x, this.conf.wafer.center.y], this.conf.wafer.radius, this.conf.wafer.radius, 200)
    circle.layer = this.conf.fileLayerDict[this.conf.wafer.layer].layerNumber
    circle.id = this.conf.fileLayerDict[this.conf.wafer.layer].id

    wafer_cell.add_polygon(circle)
    let data = {
      add_cells: [wafer_cell],
    }
    return data
  }
}
