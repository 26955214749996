import store from '@/store/index'
import bus from '@/components/common/bus'
import { getLayerInfo, addLayerInfo, editLayerInfo } from '../fileList/function/fileListApiFn'
import { LayerState } from '@/utils/graphic_cell/utils/layer-util.js'
import i18n from '../../../common/lang/i18n'
import { disposeGraphics } from '../../../utils/graphic_cell/utils/graphics-util'
export class Layer {
  constructor(id, color, shape, border, borderColor, layerName, layerNumber) {
    this.id = id
    this.color = color
    this.shape = shape
    this.border = border
    this.borderColor = borderColor
    this.image = ''
    this.layerName = layerName
    this.layerNumber = layerNumber
    this.hide = false
    this.lock = false
    this.isExport = true
    this.showNameInput = false
    this.showNumInput = false
    this.GDSType = 0
  }
}

const defaultNewLayerDatas = [
  {
    color: '#F96CAB',
    shape: 'F1',
    border: 'B1',
    borderColor: '#F96CAB',
    image: '',
  },
  {
    color: '#BA70F8',
    shape: 'F3',
    border: 'B1',
    borderColor: '#BA70F8',
    image: '',
  },
  {
    color: '#7292F7',
    shape: 'F1',
    border: 'B1',
    borderColor: '#7292F7',
    image: '',
  },
  {
    color: '#72D9F7',
    shape: 'F3',
    border: 'B1',
    borderColor: '#72D9F7',
    image: '',
  },
  {
    color: '#63E4B5',
    shape: 'F1',
    border: 'B1',
    borderColor: '#63E4B5',
    image: '',
  },
  {
    color: '#86DF72',
    shape: 'F3',
    border: 'B1',
    borderColor: '#86DF72',
    image: '',
  },
  {
    color: '#D6F656',
    shape: 'F1',
    border: 'B1',
    borderColor: '#D6F656',
    image: '',
  },
  {
    color: '#F3D85B',
    shape: 'F3',
    border: 'B1',
    borderColor: '#F3D85B',
    image: '',
  },
  {
    color: '#F09D4C',
    shape: 'F1',
    border: 'B1',
    borderColor: '#F09D4C',
    image: '',
  },
  {
    color: '#FF6E6E',
    shape: 'F3',
    border: 'B1',
    borderColor: '#FF6E6E',
    image: '',
  },
]

//设置文件图层列表
export const setFileLayerList = function (layerListObj) {
  store.commit('setFileLayerList', layerListObj)
}

export const getFileLayerListFromTable = function (fileId) {
  let index = store.state.fileLayerListTable.findIndex(item => item.fileId === fileId)
  if (index === -1) {
    return null
  } else {
    return store.state.fileLayerListTable[index]
  }
}

export const getDBFileLayerData = async function (fileId) {
  let fileLayerListObj = {}
  let fileLayerDataRes = await getLayerInfo(fileId)
  if (fileLayerDataRes.code === 200000) {
    let fileLayerDatas = []
    let size = fileLayerDataRes.data.data.length
    for (let i = 0; i < size; i++) {
      fileLayerDatas.push(
        new Layer(
          fileLayerDataRes.data.data[i].id,
          fileLayerDataRes.data.data[i].color,
          fileLayerDataRes.data.data[i].shape,
          fileLayerDataRes.data.data[i].border,
          fileLayerDataRes.data.data[i].borderColor,
          fileLayerDataRes.data.data[i].layerName,
          fileLayerDataRes.data.data[i].layerNumber
        )
      )
    }

    fileLayerDatas = sortLayerByNumber(fileLayerDatas)

    let layerState = new LayerState(fileLayerDatas)
    layerState.updateLayerMats(fileLayerDatas)
    fileLayerListObj = {
      fileId,
      fileLayerDatas,
      layerState,
    }
    // setFileLayerList(fileLayerListObj)
  }
  return fileLayerListObj
}

export const getFileLayerListFn = async function (fileId) {
  let fileLayerListObj = getFileLayerListFromTable(fileId)

  if (fileLayerListObj === null) {
    fileLayerListObj = await getDBFileLayerData(fileId)
    setFileLayerList(fileLayerListObj)
  }

  return fileLayerListObj
}

//新建默认文件图层列表
export const createNewFileLayerList = function () {
  let randomIdList = []
  for (let i = 0; i < 13; i++) {
    let id = Math.random().toString(36).substr(2, 5)
    if (!randomIdList.some(item => item.snow_id === id)) {
      randomIdList.push(id)
    }
  }
  let layer_infos = [
    {
      id: randomIdList[0],
      hide: false,
      lock: false,
      color: '#FF0014',
      shape: 'F0',
      border: 'B1',
      isExport: true,
      layerName: 'KeyPoInt',
      borderColor: '#9D0000',
      layerNumber: 0,
      GDSType: '0',
    },
    {
      id: randomIdList[1],
      hide: false,
      lock: false,
      color: '#1955FF',
      shape: 'F1',
      border: 'B1',
      isExport: true,
      layerName: 'Metal1',
      borderColor: '#1955FF',
      layerNumber: 1,
      GDSType: '0',
    },
    {
      id: randomIdList[2],
      hide: false,
      lock: false,
      color: '#7DAEF7',
      shape: 'F6',
      border: 'B1',
      isExport: true,
      layerName: 'Metal2',
      borderColor: '#7DAEF7',
      layerNumber: 2,
      GDSType: '0',
    },
    {
      id: randomIdList[3],
      hide: false,
      lock: false,
      color: '#9B51EE',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal3',
      borderColor: '#9B51EE',
      layerNumber: 3,
      GDSType: '0',
    },
    {
      id: randomIdList[4],
      hide: false,
      lock: false,
      color: '#C660F1',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal3-Flip',
      borderColor: '#C660F1',
      layerNumber: 4,
      GDSType: '0',
    },
    {
      id: randomIdList[5],
      hide: false,
      lock: false,
      color: '#A6B65A',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal4',
      borderColor: '#A6B65A',
      layerNumber: 5,
      GDSType: '0',
    },
    {
      id: randomIdList[6],
      hide: false,
      lock: false,
      color: '#7FB65A',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal4-Flip',
      borderColor: '#7FB65A',
      layerNumber: 6,
      GDSType: '0',
    },
    {
      id: randomIdList[7],
      hide: false,
      lock: false,
      color: '#E8CE3B',
      shape: 'F1',
      border: 'B1',
      isExport: true,
      layerName: 'Indium',
      borderColor: '#E8CE3B',
      layerNumber: 7,
      GDSType: '0',
    },
    {
      id: randomIdList[8],
      hide: false,
      lock: false,
      color: '#DEB22B',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Indium-Flip',
      borderColor: '#DEB22B',
      layerNumber: 8,
      GDSType: '0',
    },
    {
      id: randomIdList[9],
      hide: false,
      lock: false,
      color: '#B069E7',
      shape: 'F1',
      border: 'B1',
      isExport: true,
      layerName: 'TiN',
      borderColor: '#B069E7',
      layerNumber: 9,
      GDSType: '0',
    },
    {
      id: randomIdList[10],
      hide: false,
      lock: false,
      color: '#D469E7',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'TiN-Flip',
      borderColor: '#D469E7',
      layerNumber: 10,
      GDSType: '0',
    },
    {
      id: randomIdList[11],
      hide: false,
      lock: false,
      color: '#E60012',
      shape: 'F4',
      border: 'B1',
      isExport: true,
      layerName: 'Junction',
      borderColor: '#E60012',
      layerNumber: 11,
      GDSType: '0',
    },
    {
      id: randomIdList[12],
      hide: false,
      lock: false,
      color: '',
      shape: '',
      border: 'B1',
      isExport: true,
      layerName: 'Label',
      borderColor: '#00E2DA',
      layerNumber: 12,
      GDSType: '0',
    },
  ]

  return layer_infos
}

//保存新图层
export const saveNewLayerFn = async function (newLayerArr, file_gns) {
  let saveLayerList = []

  for (let i = 0; i < newLayerArr.length; i++) {
    let saveNewLayerData = {
      hide: newLayerArr[i].hide,
      lock: newLayerArr[i].lock,
      color: newLayerArr[i].color,
      shape: newLayerArr[i].shape,
      border: newLayerArr[i].border,
      isExport: newLayerArr[i].isExport,
      layerName: newLayerArr[i].layerName,
      borderColor: newLayerArr[i].borderColor,
      id: newLayerArr[i].id,
      layerNumber: newLayerArr[i].layerNumber,
      GDSType: newLayerArr[i].GDSType,
    }
    saveLayerList.push(saveNewLayerData)
  }

  let res = await addLayerInfo(file_gns, saveLayerList)
  return res
}

function recGetId(newId, layerDatas) {
  newId = Math.random().toString(36).substr(2, 5)
  if (layerDatas.some(item => item.id === newId)) {
    recGetId(newId, layerDatas)
  }

  return newId
}

export const addNewLayerFn = function (layerDatas, layerPointer) {
  let newLayer = {}
  // let index = store.state.layerDataTable.findIndex(
  //     (item) => item.snow_id === snow_id
  // );
  let newId = ''
  newId = recGetId(newId, layerDatas)

  let maxLayerNumber = Math.max.apply(
    Math,
    layerDatas.map(item => item.layerNumber)
  ) //获取图层编号最大值
  if (maxLayerNumber < 4294967295) {
    if (layerDatas.length > 0 && layerDatas.length < 256) {
      //限制个数为256
      newLayer = new Layer(
        newId,
        defaultNewLayerDatas[layerPointer].color,
        defaultNewLayerDatas[layerPointer].shape,
        defaultNewLayerDatas[layerPointer].border,
        defaultNewLayerDatas[layerPointer].borderColor,
        `layer${maxLayerNumber + 1}`,
        maxLayerNumber + 1
      )
    } else if (layerDatas.length == 0) {
      newLayer = new Layer(newId, defaultNewLayerDatas[0].color, defaultNewLayerDatas[0].shape, defaultNewLayerDatas[0].border, defaultNewLayerDatas[0].borderColor, `layer0`, 0)
    } else {
      bus.$emit('errDialog', i18n.t('messages.layerNumberLimit2'))
      return false
    }
  } else {
    bus.$emit('errDialog', i18n.t('messages.fileTip13'))
    return false
  }

  return newLayer
}

export const addSpecifyNewLayerFn = function (layerDatas, newLayerData) {
  let newLayer = {}
  let newId = ''
  newId = recGetId(newId, layerDatas)
  if (layerDatas.length >= 0 && layerDatas.length < 256) {
    //限制个数为256
    newLayer = new Layer(newId, newLayerData.color, newLayerData.shape, newLayerData.border, newLayerData.borderColor, newLayerData.layerName, newLayerData.layerNumber)
  } else {
    bus.$emit('errDialog', i18n.t('messages.layerNumberLimit2'))
    return
  }

  return newLayer
}

export const layerNumLimit = function (numberArr, fileId) {
  let fileLayerListObj = getFileLayerListFromTable(fileId)
  let fileLayerList = fileLayerListObj.fileLayerDatas
  let fileLayerNumberArr = fileLayerList.map(item => item.layerNumber)
  let newLayerNumberlist = []
  for (let j = 0; j < fileLayerNumberArr.length; j++) {
    if (!numberArr.some(item => item === fileLayerNumberArr[j])) {
      newLayerNumberlist.push(fileLayerNumberArr[j])
    }
  }
  if (newLayerNumberlist.length + fileLayerNumberArr.length > 256) {
    bus.$emit('errDialog', i18n.t('messages.layerNumberLimit2'))
    return false
  } else {
    return true
  }
}

// export const importLayerList = function (layerNumberList) {
//     let layerPointer = 0
//     let newLayer = {}
//     let newLayerList = []
//     let newId = ''

//     if (layerNumberList.length > 0) {
//         for (let i = 0; i < layerNumberList.length; i++) {
//             newId = Math.random().toString(36).substr(2, 5)
//             newLayer = new Layer(
//                 newId,
//                 defaultNewLayerDatas[layerPointer].color,
//                 defaultNewLayerDatas[layerPointer].shape,
//                 defaultNewLayerDatas[layerPointer].border,
//                 defaultNewLayerDatas[layerPointer].borderColor,
//                 `layer${layerNumberList[i]}`,
//                 layerNumberList[i]
//             )
//             newLayerList.push(newLayer)

//             if (layerPointer < 9) {
//                 layerPointer++;
//             } else {
//                 layerPointer = 0;
//             }
//         }
//     }

//     return newLayerList
// }

//图层编号排序
const sortLayerNumber = function (layerNumberList) {
  layerNumberList.sort((a, b) => {
    return a - b
  })

  return layerNumberList
}

//图层按编号排序
export const sortLayerByNumber = function (layerDatas) {
  layerDatas.sort((a, b) => {
    return a.layerNumber - b.layerNumber
  })

  return layerDatas
}

//更新图层需要的数据
export const updateFileLayers = function (importLayerNumberArr, fileLayerDatas) {
  let pointer = 0
  let newLayerList = []

  let oldLayerNumberArr = fileLayerDatas.map(item => item.layerNumber)
  let mergeLyaerNumberArr = [...new Set([...importLayerNumberArr, ...oldLayerNumberArr])]

  if (mergeLyaerNumberArr.length <= 256) {
    if (importLayerNumberArr.length > 0) {
      importLayerNumberArr = sortLayerNumber(importLayerNumberArr)

      let size = importLayerNumberArr.length
      for (let i = 0; i < size; i++) {
        if (!fileLayerDatas.some(item => item.layerNumber === importLayerNumberArr[i])) {
          let newLayer = addNewLayerFn(fileLayerDatas, pointer)
          newLayer.layerNumber = importLayerNumberArr[i]
          newLayerList.push(newLayer)
          fileLayerDatas.push(newLayer)
          if (pointer < 9) {
            pointer++
          } else {
            pointer = 0
          }
        }
      }
    }

    return newLayerList
  } else {
    bus.$emit('errDialog', i18n.t('messages.layerNumberLimit2'))
    return false
  }
}

export const editLayerDataFn = function (file_gns, layerNumber, type, data) {
  let edit_data = {}
  edit_data[type] = data
  let layer_infos = [
    {
      layerNumber,
      edit_data,
    },
  ]

  editLayerInfo(file_gns, layer_infos)
}

//批量修改单个图层数据
export const editLayerDatasFn = function (file_gns, layerNumber, edit_data) {
  let layer_infos = [
    {
      layerNumber,
      edit_data,
    },
  ]

  editLayerInfo(file_gns, layer_infos)
}

//删除器件数据 图层编号 layers=>[0,1,2,3]
export const deleteCellDataByLayers = function (cell, layers) {
  if (!cell) return
  const QUAD_TREE = cell.js_obj.QUAD_TREE //四叉树
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    if (layers.indexOf(path.layers[0]) !== -1) {
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(path)
      }
      disposeGraphics(path.js_obj.graphics)
      cell.remove_flexpath(path)
      cell.js_obj.isSave = false
    }
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    if (layers.indexOf(polygon.layer) !== -1) {
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(polygon)
      }
      disposeGraphics(polygon.js_obj.graphics)
      cell.remove_polygon(polygon)
      cell.js_obj.isSave = false
    }
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    if (layers.indexOf(label.layer) !== -1) {
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(label)
      }
      disposeGraphics(label.js_obj.graphics)
      cell.remove_label(label)
      cell.js_obj.isSave = false
    }
  }
  return cell
}

//合并图层
export const mergeCellLayer = function (cell, layerNums, toNum) {
  if (!cell) return
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    let toLayers = []
    let toIds = []
    let needChange = false
    path.layers.forEach(layer => {
      if (layerNums[layer]) {
        needChange = true
        toLayers.push(toNum.num)
        toIds.push(toNum.id)
      }
    })
    if (needChange) {
      path.layers = toLayers
      path.toIds = toIds
      cell.js_obj.isSave = false
    }
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    if (layerNums[polygon.layer]) {
      polygon.layer = toNum.num
      polygon.id = toNum.id
      cell.js_obj.isSave = false
    }
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    if (layerNums[label.layer]) {
      label.layer = toNum.num
      label.id = toNum.id
      cell.js_obj.isSave = false
    }
  }
}

//根据图层数据重构器件数据，包括删除不存在的图层，图层id重命名 layerMap=>{id1:num1,id2:num2}
export const convertCellDataByLayerId = function (cell, layerMap) {
  if (!cell) return
  const QUAD_TREE = cell.js_obj.QUAD_TREE //四叉树
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length

  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    if (path.id.length > 1) {
      const id = path.id[0]
      path.id = [id, id]
    }
    if (layerMap[path.id[0]] == undefined) {
      //不存在图层id说明图层被删除
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(path)
      }
      cell.remove_flexpath(path)
      disposeGraphics(path.js_obj.graphics)
      cell.js_obj.isSave = false
      continue
    }
    const layers = []
    path.id.forEach(id => {
      layers.push(layerMap[id])
    })
    path.layers = layers
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    if (layerMap[polygon.id] == undefined) {
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(polygon)
      }
      cell.remove_polygon(polygon)
      disposeGraphics(polygon.js_obj.graphics)
      cell.js_obj.isSave = false
      continue
    }

    polygon.layer = layerMap[polygon.id]
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    if (layerMap[label.id] == undefined) {
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(label)
      }
      cell.remove_label(label)
      disposeGraphics(label.js_obj.graphics)
      cell.js_obj.isSave = false
      continue
    }
    label.layer = layerMap[label.id]
  }

  return cell
}

// layerMap=>{num1:id,num2:id2}
export const convertCellDataByLayerNumber = function (cell, layerMap) {
  if (!cell) return
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    const id = []
    path.layers.forEach(layer => {
      id.push(layerMap[layer])
    })
    path.id = id
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    polygon.id = layerMap[polygon.layer]
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    label.id = layerMap[label.layer]
  }
  return cell
}

//复制去另一个文件,先和自己的图层列表比对,通过id将单元layerNumber改正,再和目标文件图层number比较,修改单元中的图层id

//图层id数据对比,修改单元中的图层number数据
export const contrastCellDataByLayerId = async function (fileId, cellList) {
  let layerMap = {}
  let fileLayerListObj = await getFileLayerListFn(fileId)

  let fileLayerList = fileLayerListObj.fileLayerDatas
  fileLayerList.forEach(item => {
    layerMap[item.id] = item.layerNumber
  })

  let size = cellList.length
  let newCellList = []
  for (let i = 0; i < size; i++) {
    let newCell = convertCellDataByLayerId(cellList[i], layerMap)
    newCellList.push(newCell)
  }

  return newCellList
}
