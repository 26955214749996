var QGdstk = null

;(function () {
  function AutoToolWaveGuide(library, cell) {
    QGdstk = window.QGdstk
    this.library = library
    this.cell = cell
    this.conf = {
      out_width: 8,
      inner_width: 4,
      radius: 20,
    }
  }

  AutoToolWaveGuide.prototype.setConf = function (conf) {
    this.conf = conf
  }

  function dfs(ref, path, idx) {
    path += ref['name'] + idx + '->'
    if (!ref['ref']) {
      paths.push(path)
      return
    }
    for (let i = 0; i < ref['ref']; i++) {
      dfs(ref['ref'][i], path, i)
    }
  }

  AutoToolWaveGuide.prototype.run = function (conf) {
    this.conf = conf

    let genPaths = []
    let layerData = conf.layerData
    for (let i = 0; i < layerData.length; i++) {
      this.cell.flexpaths.forEach(element => {
        if (element.layers[0] == layerData[i].layerNumber && element.width[0] === 0) {
          genPaths.push({
            path: element,
            gen_id: conf.fileLayerDict[layerData[i].genLayerNumber].id,
            gen_layer: layerData[i].genLayerNumber,
            gen_datatype: layerData[i].datatype,
          })
        }
      })
    }

    let add_paths = []
    let remove_paths = []

    for (let i = 0, len = genPaths.length; i < len; i++) {
      let item = genPaths[i]
      let path = genPaths[i].path
      let offset = (this.conf.inner_width + (this.conf.out_width - this.conf.inner_width) / 2) / 2
      let width = (this.conf.out_width - this.conf.inner_width) / 2
      let wave_guide_path = path.copy()
      wave_guide_path.id = [item.gen_id, item.gen_id]
      wave_guide_path.layers = [item.gen_layer, item.gen_layer]
      wave_guide_path.simple_path = true
      wave_guide_path.offset = [offset, -offset]
      wave_guide_path.width = [width, width]
      wave_guide_path.radius = [this.conf.radius, this.conf.radius]

      add_paths.push(wave_guide_path)
      if (conf.fileLayerDict[1003]) {
        let drc_layer = conf.fileLayerDict[1003]
        let wave_guide_path_drc = new window.Kernel.GdsFlexpath(path.points, this.conf.out_width, 0, 'round', 'flush', this.conf.radius, 1e-2, true, true, drc_layer.layerNumber, 0)
        wave_guide_path_drc.id = [drc_layer.id]
        add_paths.push(wave_guide_path_drc)
      }
      remove_paths.push(path)
    }

    return {
      remove_paths: remove_paths,
      add_paths: add_paths,
    }
  }
  AutoToolWaveGuide.prototype.waveGuide = function (points = [], out_width = 8, inner_width = 4, radius = 20, layer = 0, datatype = 0) {
    function add_line(p1, p2) {
      let dx = p2[0] - p1[0]
      let dy = p2[1] - p1[1]
      let x_step = dx / 2
      let y_step = dy / 2
      return [p2[0] + x_step, p2[1] + y_step]
    }
    let len = points.length
    if (len < 2) {
      return []
    }
    let outer = new QGdstk.FlexPath(
      (points = points),
      (width = out_width),
      (offset = 0),
      (joins = 'round'),
      (ends = 'flush'),
      (bend_radius = radius),
      (bend_function = null),
      (tolerance = 1e-2),
      (simple_path = false),
      (scale_width = true),
      (layer = layer),
      (datatype = datatype)
    )

    // 延长起点和终点，以免误差
    points[0] = add_line(points[1], points[0])
    points[len - 1] = add_line(points[len - 2], points[len - 1])

    let inner = new QGdstk.FlexPath(
      (points = points),
      (width = inner_width),
      (offset = 0),
      (joins = 'round'),
      (ends = 'flush'),
      (bend_radius = radius),
      (bend_function = null),
      (tolerance = 1e-2),
      (simple_path = false),
      (scale_width = true),
      (layer = layer),
      (datatype = datatype)
    )
    let res = QGdstk.boolean((operand1 = outer), (operand2 = inner), (operation = 'not'), (precision = 1e-3), (layer = layer), (datatype = datatype))
    return res
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolWaveGuide
  } else {
    window.AutoToolWaveGuide = AutoToolWaveGuide
  }
})()
