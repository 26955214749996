var QGdstk = null
var KDTreeFast = null
var AutoToolLibs = require('./libs')
var QGeosJs = require('./geos')
var CanvasFill = require('./canvas_fill')

// 像素法绘制

;(function () {
  function AutoToolTiNPadInpillar(library, cell) {
    QGdstk = window.QGdstk
    KDTreeFast = window.KDTreeFast
    this.library = library
    this.cell = cell
    this.libs = new AutoToolLibs()
    this.rules = []
    this.kd_rules = []
    this.fill_layers = {}
    this.conf = {
      avoid: [],
      size: {
        A: 20,
        A1: 10,
        indium_shape: 'Round',
        indium_tolerance: 0.01,
        indium_layer: 7,
        indium_flip_layer: 8,
        tin_shape: 'Round',
        tin_tolerance: 0.01,
        tin_layer: 9,
        tin_flip_layer: 10,
      },
      place_polygon: [
        [3000, 3000],
        [30000, 3000],
        [30000, 30000],
        [3000, 30000],
      ],
      dig_polygons: [
        [
          [10000, 10000],
          [20000, 10000],
          [20000, 20000],
          [10000, 20000],
        ],
      ],
      grid_step: 40,
      min_distance: 50,
    }
    this.console_name = '[TiNPad]:'
  }

  AutoToolTiNPadInpillar.prototype.setConf = function (conf) {
    this.conf = conf
  }

  AutoToolTiNPadInpillar.prototype.run = function (data) {
    this.conf = data

    let place_pos = this.offScreenPx(this.cell, data)
    return this.placeCell(place_pos)
  }

  AutoToolTiNPadInpillar.prototype.offScreenPx = function (cell, conf) {
    function bbox(points) {
      let x_min = Infinity
      let y_min = Infinity
      let x_max = -Infinity
      let y_max = -Infinity
      points.forEach(p => {
        if (p[0] < x_min) x_min = p[0]
        if (p[1] < y_min) y_min = p[1]
        if (p[0] > x_max) x_max = p[0]
        if (p[1] > y_max) y_max = p[1]
      })
      return [
        [x_min, y_min],
        [x_max, y_max],
      ]
    }

    function getPolygonPath2D(polygon, tran_x, tran_y) {
      let p = new Path2D()
      let points = polygon.points
      if (points === undefined) return p
      p.moveTo(points[0][0], points[0][1])
      for (let i = 1; i < points.length; i++) {
        p.lineTo(points[i][0], points[i][1])
      }
      p.closePath()
      return p
    }
    function getPolygonsPath2D(polygons, tran_x, tran_y) {
      let path2d_set = new Path2D()
      polygons.forEach(element => {
        if (element !== undefined) {
          let p = getPolygonPath2D(element, tran_x, tran_y)
          path2d_set.addPath(p)
        }
      })
      return path2d_set
    }

    function getPathPath2D(path, tranX, tranY, scale) {
      // let points = path.get_points()
      let points = path.points
      let p = new Path2D()
      p.moveTo(points[0][0], points[0][1])
      for (let i = 1; i < points.length; i++) {
        p.lineTo(points[i][0], points[i][1])
      }
      // p.closePath()
      return p
    }
    function getPathsPath2D(paths, tranX, tranY, scale) {
      let path2d_set = new Path2D()
      paths.forEach(element => {
        let p = getPathPath2D(element, tranX, tranY, scale)
        path2d_set.addPath(p)
      })
      return path2d_set
    }

    function ctxDrawCircle(ctx, points, r, color) {
      // # 绘制放置区域
      ctx.save()
      ctx.lineWidth = r
      ctx.strokeStyle = color
      ctx.fillStyle = color
      points.forEach(p => {
        ctx.arc(p[0], p[1], r, 0, 2 * Math.PI)
        ctx.fill()
      })
      ctx.restore()
    }

    function ctxDrawPolygon(ctx, path2d, line_width, color) {
      // # 绘制放置区域
      // ctx.save()
      ctx.lineWidth = line_width
      ctx.strokeStyle = color
      ctx.fillStyle = color
      ctx.stroke(path2d)
      ctx.fill(path2d)
      // ctx.restore()
    }

    function ctxDrawPath(ctx, path2d, line_width, color) {
      // # 绘制放置区域
      // ctx.save()
      ctx.lineWidth = line_width
      ctx.strokeStyle = color
      ctx.fillStyle = color
      ctx.stroke(path2d)
      // ctx.restore()
    }

    function pathsToPolygons(paths) {
      let res = []
      let process_len = paths.length,
        process_n = Math.ceil(process_len / 20),
        process_i = 0
      for (let i = 0; i < paths.length; i++) {
        if (process_i % process_n == 0) {
        }
        if (process_i == process_len - 1) {
        }
        process_i += 1
        try {
          let element = paths[i]
          let p = new QGdstk.FlexPath(element.get_points(), 1, 0, 'natural', 'flush', 0, null, 1e-2, false, true, 0, 0)
          res.push.apply(p.to_polygons())
        } catch (error) {}
      }
      return res
    }

    function offsetPaths(paths, d) {
      let offset_paths = []
      let process_len = paths.length,
        process_n = Math.ceil(process_len / 20),
        process_i = 0
      for (let i = 0; i < paths.length; i++) {
        if (process_i % process_n == 0) {
        }
        if (process_i == process_len - 1) {
        }
        process_i += 1
        try {
          let element = paths[i]
          // let p = new QGdstk.FlexPath(element.get_points(), 1, 0, 'natural', 'flush', 0, null, 1e-2, false, true, 0, 0)
          let p = new QGdstk.FlexPath(element.points, 1, 0, 'natural', 'flush', 0, null, 1e-2, false, true, 0, 0)
          let offsets = QGdstk.offset(p, d, 'bevel', 2, 1e-1, false, 0, 0)
          offset_paths.push.apply(offset_paths, offsets)
        } catch (error) {}
      }
      return offset_paths
    }

    function offsetPolygons(polygons, d) {
      let offset_polygons = []
      let process_len = polygons.length,
        process_n = Math.ceil(process_len / 20),
        process_i = 0
      for (let i = 0; i < polygons.length; i++) {
        if (process_i % process_n == 0) {
        }
        if (process_i == process_len - 1) {
        }
        process_i += 1
        try {
          let element = polygons[i]
          let poly = new QGdstk.Polygon(element.points, element.layer, element.datatype)
          let offsets = QGdstk.offset(poly, d, 'bevel', 2, 1e-1, false, 0, 0)
          offset_polygons.push.apply(offset_polygons, offsets)
        } catch (error) {}
      }
      return offset_polygons
    }

    function getFillPoints(ctx, width, height, tranX, tranY, scale) {
      let img = ctx.getImageData(0, 0, width, height)

      // 获取 填充像素点
      let fill_points = []
      let data = img.data

      let row = 0,
        col = 0
      for (let i = 0; i < data.length; i++) {
        if (i > 0 && i % width == 0) {
          row += 1
        }
        col = i - row * width

        let r = data[4 * i]
        let g = data[4 * i + 1]
        let b = data[4 * i + 2]
        let a = data[4 * i + 3]

        if (b > 0) {
          fill_points.push([col * scale + tranX, row * scale + tranY])
          // fill_points.push([col * scale, row * scale])
        }
      }

      return fill_points
    }

    function inside_circle(center, tile, radius) {
      //"""判断在半径内, 采用平方和判断，减少开根"""
      let dx = center.x - tile.x
      let dy = center.y - tile.y
      let distance_squared = dx * dx + dy * dy
      return distance_squared <= radius * radius
    }

    function circle_range(center, radius) {
      // 半径内的网格, 速度快
      let top = Math.ceil(center.y - radius)
      let bottom = Math.floor(center.y + radius)
      let left = Math.ceil(center.x - radius)
      let right = Math.floor(center.x + radius)
      let pos = []
      for (let y = top; y <= bottom; y++) {
        for (let x = left; x <= right; x++) {
          if (inside_circle(center, { x: x, y: y }, radius)) {
            pos.push([x, y])
          }
        }
      }
      return pos
    }

    function circle_outline_range(center, radius) {
      let top = Math.ceil(center.y - radius)
      let bottom = Math.ceil(center.y + radius)
      let pos = []
      for (let y = top; y <= bottom; y++) {
        let dy = y - center.y
        let dx = Math.floor(Math.sqrt(radius * radius - dy * dy))
        let left = center.x - dx
        let right = center.x + dx
        pos.push([left, y])
        pos.push([right, y])
      }
      for (let r = 0; r <= Math.floor(radius * Math.sqrt(0.5)); r++) {
        d = Math.floor(Math.sqrt(radius * radius - r * r))
        pos.push([center.x - d, center.y + r])
        pos.push([center.x + d, center.y + r])
        pos.push([center.x - d, center.y - r])
        pos.push([center.x + d, center.y - r])
        pos.push([center.x + r, center.y - d])
        pos.push([center.x + r, center.y + d])
        pos.push([center.x - r, center.y - d])
        pos.push([center.x - r, center.y + d])
      }
      return pos
    }

    function bounding_box_range(center, radius) {
      // 采用包围盒子来查找半径内的网格, 速度快
      // 参考连接: https://www.redblobgames.com/grids/circle-drawing/
      let top = Math.ceil(center.y - radius)
      let bottom = Math.floor(center.y + radius)
      let left = Math.ceil(center.x - radius)
      let right = Math.floor(center.x + radius)
      let pos = []
      for (let y = top; y <= bottom; y++) {
        for (let x = left; x <= right; x++) {
          pos.push([x, y])
        }
      }
      return pos
    }

    function bounding_box_45_range(center, radius) {
      // # 获取45度包围盒子来查找半径内的网格
      // # 参考连接: https://www.redblobgames.com/grids/circle-drawing/
      let l = Math.ceil(Math.sqrt(radius * radius + radius * radius))
      let top = Math.ceil(center.y - l)
      let bottom = Math.floor(center.y + l)
      let pos = []
      for (let y = top; y <= bottom; y++) {
        let dy = y - center.y
        let dx = l - Math.abs(dy)
        let left = Math.ceil(center.x - dx)
        let right = Math.floor(center.x + dx)
        for (let x = left; x <= right; x++) {
          pos.push([x, y])
        }
      }
      return pos
    }

    function genCellLayerQuadTree(box, polygons) {
      let qcbox = new QGdstk.QuadtreeBox(box[0][0], box[0][1], box[1][0] - box[0][0], box[1][1] - box[0][1])
      let quadtree = new QGdstk.Quadtree(qcbox)
      for (let i = 0; i < polygons.length; i++) {
        let item = polygons[i]
        if (!item) continue
        let b = item.bounding_box()
        if (!b) continue
        let node = new QGdstk.QuadtreeNode(i, b[0][0], b[0][1], b[1][0] - b[0][0], b[1][1] - b[0][1])
        quadtree.add(node)
      }
      return quadtree
    }

    function genPathsQuadTree(box, paths) {
      let qcbox = new QGdstk.QuadtreeBox(box[0][0], box[0][1], box[1][0] - box[0][0], box[1][1] - box[0][1])
      let quadtree = new QGdstk.Quadtree(qcbox)
      for (let i = 0; i < paths.length; i++) {
        let b = bbox(paths[i].get_points())
        let node = new QGdstk.QuadtreeNode(i, b[0][0], b[0][1], b[1][0] - b[0][0], b[1][1] - b[0][1])
        quadtree.add(node)
      }
      return quadtree
    }

    function genPolygonsQuadTree(box, polygons) {
      let qcbox = new QGdstk.QuadtreeBox(box[0][0], box[0][1], box[1][0] - box[0][0], box[1][1] - box[0][1])
      let quadtree = new QGdstk.Quadtree(qcbox)
      for (let i = 0; i < polygons.length; i++) {
        let item = polygons[i]
        if (!item) continue
        let b = item.bounding_box()
        if (!b) continue
        let node = new QGdstk.QuadtreeNode(i, b[0][0], b[0][1], b[1][0] - b[0][0], b[1][1] - b[0][1])
        quadtree.add(node)
      }
      return quadtree
    }

    function getPlacePosRectangle(data, width, height, grid_tranX, grid_tranY, scale, scan_radius, range_0, range_45) {
      let px = 0,
        py = 0,
        axis_x = 0,
        axis_y = 0,
        idx = 0,
        angle = 0
      function scan_surround(range) {
        for (let i = 0; i < range.length; i++) {
          px = axis_x + range[i][0]
          py = axis_y + range[i][1]
          if (px > 0 && py > 0) {
            // 存在跳出
            if (is_place[px][py] == 1) {
              return true
            }
          }
        }
      }
      for (let row = 0; row < height; row++) {
        for (let col = 0; col < width; col++) {
          idx = (row * width + col) * 4
          if (data[idx] > 0) {
            // 转换canvas像素点为x,y坐标位置
            axis_x = col + grid_tranX
            axis_y = row + grid_tranY
            // fill.push([axis_x * scale + tranX, (axis_y + 1) * scale + tranY])
            if (is_place[axis_x][axis_y] == 1) continue
            // 处理扫描区域
            angle = 0
            // 方形
            let isExist = false
            arround_pos = []
            // 0度处理
            isExist = scan_surround(range_0)
            // for (let i = 0; i < range_0.length; i++) {
            //   px = axis_x + range_0[i][0]
            //   py = axis_y + range_0[i][1]
            //   if (px > 0 && py > 0) {
            //     // 存在跳出
            //     if (is_place[px][py] == 1) {
            //       isExist = true
            //       break
            //     }
            //   }
            // }
            if (isExist) {
              // 45度处理
              angle = Math.PI / 4
              isExist = scan_surround(range_45)
              // for (let i = 0; i < range_45.length; i++) {
              //   px = axis_x + range_45[i][0]
              //   py = axis_y + range_45[i][1]
              //   if (px > 0 && py > 0) {
              //     // 存在跳出
              //     if (is_place[px][py] == 1) {
              //       isExist = true
              //       break
              //     }
              //   }
              // }
            }
            // 四周不存在，放置一个，标记四周，跨步
            if (!isExist) {
              if (angle == 0) {
                for (let i = 0; i < range_0.length; i++) {
                  px = axis_x + range_0[i][0]
                  py = axis_y + range_0[i][1]
                  if (px > 0 && py > 0) {
                    is_place[px][py] = 1
                  }
                }
              } else {
                for (let i = 0; i < range_45.length; i++) {
                  px = axis_x + range_45[i][0]
                  py = axis_y + range_45[i][1]
                  if (px > 0 && py > 0) {
                    is_place[px][py] = 1
                  }
                }
              }
              // 跨一步, 加快速度
              col += scan_radius
              place_pos.push({ pos: [axis_x * scale + tranX, axis_y * scale + tranY], angle: angle })
            }
          }
        }
      }
    }

    function getPlacePos(data, width, height, grid_tranX, grid_tranY, scale, scan_radius, scan_range) {
      let px, py, axis_x, axis_y, idx
      for (let row = 0; row < height; row++) {
        for (let col = 0; col < width; col++) {
          idx = (row * width + col) * 4
          if (data[idx] > 0) {
            // 转换canvas像素点为x,y坐标位置
            axis_x = col + grid_tranX
            axis_y = row + grid_tranY
            // fill.push([axis_x * scale + tranX, (axis_y + 1) * scale + tranY])
            if (is_place[axis_x][axis_y] == 1) continue
            // 处理扫描区域
            isExist = false
            // let scan_pos = []
            for (let scan_i = 0; scan_i < scan_range.length; scan_i++) {
              px = axis_x + scan_range[scan_i][0]
              py = axis_y + scan_range[scan_i][1]
              if (px > 0 && py > 0) {
                // 存在跳出
                if (is_place[px][py] == 1) {
                  isExist = true
                  break
                }
                // scan_pos.push([place_x, place_y])
              }
            }
            if (isExist) {
              continue
            } else {
              for (let scan_i = 0; scan_i < scan_range.length; scan_i++) {
                px = axis_x + scan_range[scan_i][0]
                py = axis_y + scan_range[scan_i][1]
                if (px > 0 && py > 0) {
                  is_place[px][py] = 1
                }
              }
              // 跨一步
              col += scan_radius
              place_pos.push({ pos: [axis_x * scale + tranX, axis_y * scale + tranY], angle: 0 })
            }
          }
        }
      }
    }

    if (!cell) return []

    // 放置区域
    if (conf.place_polygon.length <= 2) {
      return
    }
    let place_polygon = new QGdstk.Polygon(conf.place_polygon, 0, 0)
    let box = place_polygon.bounding_box()
    //  缩放
    let scale = conf.grid_step
    // 宽度、高度 取整
    let width = Math.ceil((box[1][0] - box[0][0]) / scale)
    let height = Math.ceil((box[1][1] - box[0][1]) / scale)
    // 平移
    let tranX = box[0][0]
    let tranY = box[0][1]
    // pad形状
    let shape_is_rectangle = conf.size.tin_shape === 'Rectangle' ? true : false

    let quad_box = [
      [-10e9, -10e9],
      [10e9, 10e9],
    ]
    // let quad_box = box

    // 放置区域
    place_polygon.translate(-tranX, -tranY)
    place_polygon.scale(1 / scale, 1 / scale, [0, 0])
    // 挖取区域
    let dig_polygons = []
    conf.dig_polygons.forEach(element => {
      if (element.length > 2) {
        let dig = new QGdstk.Polygon(element, 0, 0)
        dig.translate(-tranX, -tranY)
        dig.scale(1 / scale, 1 / scale, [0, 0])
        dig_polygons.push(dig)
      }
    })
    // 避开区域, 四叉树
    let avoid = []
    // let avoid_polygons = []
    conf.avoid.forEach(item => {
      // 距离计算
      let d = 0
      if (shape_is_rectangle) {
        d = (item.distance + conf.size.A / 2 / Math.sin(Math.PI / 4)) * 1
      } else {
        d = (item.distance + conf.size.A / 2) * 1
      }

      // 获取图层paths和polygons
      let paths = cell.get_paths(true, -1, item.layer, item.datatype)
      let polygons = cell.get_polygons(true, false, -1, item.layer, item.datatype)

      // 转换为有宽度的线, 用于后面膨胀, 处理
      d = Math.ceil(d)
      let offset_paths = offsetPaths(paths, d)
      let offset_polygons = offsetPolygons(polygons, d)
      // 组合所有多边形，并转换
      let avoid_polygons = []
      offset_paths.forEach(p => {
        p.translate(-tranX, -tranY)
        p.scale(1 / scale, 1 / scale, [0, 0])
        avoid_polygons.push(p)
      })
      offset_polygons.forEach(p => {
        p.translate(-tranX, -tranY)
        p.scale(1 / scale, 1 / scale, [0, 0])
        avoid_polygons.push(p)
      })
      let quadtree = genCellLayerQuadTree(quad_box, avoid_polygons)
      avoid.push({
        quadtree: quadtree,
        polygons: avoid_polygons,
      })
    })

    // 生成四叉树，用于后续网格检测快速查询
    // let avoid_quadtree = genCellLayerQuadTree(quad_box, avoid_polygons)

    // tinpad避开自身
    let scan_radius = Math.ceil(conf.min_distance / 2 / scale)
    let scan_range_circle = circle_range({ x: 0, y: 0 }, scan_radius)
    // let scan_range_circle = bounding_box_range({ x: 0, y: 0 }, scan_radius)
    let scan_range_rect_0 = bounding_box_range({ x: 0, y: 0 }, scan_radius)
    let scan_range_rect_45 = bounding_box_45_range({ x: 0, y: 0 }, scan_radius)

    // 网格切割放置
    let max_wh = width > height ? width : height
    let n = Math.ceil(max_wh / 3000)
    let grid_cell_width = Math.ceil(width / n)
    let grid_cell_height = Math.ceil(height / n)
    // let grid_cell_width = 5000
    // let grid_cell_height = 5000

    // 创造一个放置二维数组，用于存储放置位置标记
    let is_place = new Array(max_wh + 100)
    for (let i = 0; i < is_place.length; i++) {
      is_place[i] = new Uint8Array(max_wh + 100)
    }

    let place_pos = []
    let process_len = Math.ceil(n * n),
      process_n = Math.ceil(process_len / 20),
      process_i = 0
    let start_time = new Date(),
      end_time = new Date(),
      all_start_time = new Date()
    // let canvas_off = new OffscreenCanvas(grid_cell_width, grid_cell_height)
    // let ctx = canvas_off.getContext('2d', { willReadFrequently: true })
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        try {
          let grid_cell_tranX = i * grid_cell_width
          let grid_cell_tranY = j * grid_cell_height
          // 网格单元包围盒子
          let grid_cell_box = [
            [i * grid_cell_width, j * grid_cell_height],
            [(i + 1) * grid_cell_width, (j + 1) * grid_cell_height],
          ]
          // 放置区域
          let grid_cell_polygon = new QGdstk.Polygon(
            [
              [grid_cell_box[0][0], grid_cell_box[0][1]],
              [grid_cell_box[0][0], grid_cell_box[1][1]],
              [grid_cell_box[1][0], grid_cell_box[1][1]],
              [grid_cell_box[1][0], grid_cell_box[0][1]],
            ],
            0,
            0
          )
          let place_polygon_t = QGdstk.boolean(place_polygon, grid_cell_polygon, 'and', 1e-3, 0, 0)
          if (place_polygon_t.length == 0) {
            continue
          }
          // 挖取区域
          let dig_polygon_t = QGdstk.boolean(dig_polygons, grid_cell_polygon, 'and', 1e-3, 0, 0)

          // 避开区域
          let querybox = new QGdstk.QuadtreeBox(grid_cell_tranX, grid_cell_tranY, grid_cell_width, grid_cell_height)

          // canvas 绘制填充点
          let canvas_off = new OffscreenCanvas(grid_cell_width, grid_cell_height)
          let ctx = canvas_off.getContext('2d')
          ctx.clearRect(0, 0, grid_cell_width, grid_cell_height)
          ctx.translate(-grid_cell_tranX, -grid_cell_tranY)
          let place_polygons_path2d = getPolygonsPath2D(place_polygon_t, 0, 0)
          ctxDrawPolygon(ctx, place_polygons_path2d, 1, 'rgb(1,0,0)')
          let dig_polygons_path2d = getPolygonsPath2D(dig_polygon_t, 0, 0)
          ctxDrawPolygon(ctx, dig_polygons_path2d, 1, 'rgb(0,1,0)')

          avoid.forEach(element => {
            let avoid_query_polygons = []
            let avoid_query_res = element.quadtree.query(querybox)
            avoid_query_res.forEach(node => {
              avoid_query_polygons.push(element.polygons[node.id])
            })
            let avoid_polygons_path2d = getPolygonsPath2D(avoid_query_polygons, 0, 0)
            ctxDrawPolygon(ctx, avoid_polygons_path2d, 1, 'rgb(0,0,1)')
          })

          // 提取像素点
          let img = ctx.getImageData(0, 0, grid_cell_width, grid_cell_height)

          // 处理像素
          if (shape_is_rectangle) {
            getPlacePosRectangle(img.data, grid_cell_width, grid_cell_height, grid_cell_tranX, grid_cell_tranY, scale, scan_radius, scan_range_rect_0, scan_range_rect_45)
          } else {
            getPlacePos(img.data, grid_cell_width, grid_cell_height, grid_cell_tranX, grid_cell_tranY, scale, scan_radius, scan_range_circle)
          }
        } catch (error) {}

        if (process_i % process_n == 0) {
          end_time = new Date()

          start_time = new Date()
        }
        if (process_i == process_len - 1) {
          end_time = new Date()
        }
        process_i += 1
      }
    }

    return place_pos
  }

  AutoToolTiNPadInpillar.prototype.checkRule = function (pos, shape, radius, rules) {
    function rect_tran(center = [0, 0], w = 10, angle = 0) {
      /*用gdspy库获取2D 方形8个关键点位经过旋转平移后的点位信息
            3 - 2 - 1
            |       |
            4       0
            |       |
            5 - 6 - 7
        */
      points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      p = new QGdstk.Polygon(points, 0, 0)
      p.rotate(angle)
      p.translate(center[0], center[1])
      return p.points
    }
    if (shape == 'Round') {
      for (let i = 0; i < rules.length; i++) {
        let rule = rules[i]
        let path_indices = rule.path_kdtree.neighborhood_indices_pos(pos[0], pos[1], rule.path_distance + radius)
        if (path_indices.length > 0) return -1
        let polygon_indices = rule.polygon_kdtree.neighborhood_indices_pos(pos[0], pos[1], rule.polygon_distance + radius)
        if (polygon_indices.length > 0) return -1

        // let indices = rule.kdtree.neighborhood_indices_pos(pos[0], pos[1], rule.distance + radius)
        // if (indices.length > 0) {
        //   return -1
        // }
      }
      return 0
    }

    if (shape == 'Rectangle') {
      let angle_list = [0, Math.PI / 4]
      for (let j = 0; j < angle_list.length; j++) {
        let is_ok = true
        let angle = angle_list[j]
        for (let i = 0; i < rules.length; i++) {
          let rule = rules[i]
          // let r = rule.distance
          let bound_points = rect_tran(pos, radius, angle)
          for (let k = 0; k < bound_points.length; k++) {
            let p = bound_points[k]
            let path_indices = rule.path_kdtree.neighborhood_indices_pos(p[0], p[1], rule.path_distance)
            if (path_indices.length > 0) {
              is_ok = false
              break
            }
            let polygon_indices = rule.polygon_kdtree.neighborhood_indices_pos(p[0], p[1], rule.polygon_distance)
            if (polygon_indices.length > 0) {
              is_ok = false
              break
            }
            // let indices = rule.kdtree.neighborhood_indices_pos(p[0], p[1], r)
            // if (indices.length > 0) {
            //   is_ok = false
            //   break
            // }
          }
        }
        if (is_ok) {
          return angle
        }
      }
      return -1
    }
  }
  AutoToolTiNPadInpillar.prototype.printProxy = function (points) {
    let p = []
    for (let i = 0; i < points.length; i++) {
      p.push([points[i][0], points[i][1]])
    }
  }

  AutoToolTiNPadInpillar.prototype.proxyToArray = function (points) {
    let p = []
    for (let i = 0; i < points.length; i++) {
      p.push([points[i][0], points[i][1]])
    }
    return p
  }

  AutoToolTiNPadInpillar.prototype.scanLineFillPolygon = function (box, polygons, step) {
    let x_min = box[0][0] / step
    let y_min = box[0][1] / step
    let x_max = box[1][0] / step
    let y_max = box[1][1] / step
    let x_len = Math.floor(x_max - x_min)
    let y_len = Math.floor(y_max - y_min)

    let lines = []
    for (let i = 0; i < x_len; i++) {
      lines.push([
        [x_min + i, y_min],
        [x_min + i, y_max],
      ])
    }

    for (let i = 0; i < polygons.length; i++) {
      for (let j = 0; j < polygons[i].length; j++) {
        polygons[i][j] = [polygons[i][j][0] / step, polygons[i][j][1] / step]
      }
    }

    let multiLineString = new QGeosJs('MultiLineString', lines)
    let multiPolygon = new QGeosJs('MultiPolygon', [polygons])
    let inter = multiLineString.intersection(multiPolygon)
    let col_lines = []
    if (!inter) return []
    if (inter.type == 'GeometryCollection') {
      let geos_arr = inter.coordinates
      for (let i = 0; i < geos_arr.length; i++) {
        let tmp_geo = geos_arr[i]
        if (tmp_geo.type == 'LineString') {
          col_lines.push(tmp_geo.coordinates)
        }
      }
    }
    if (inter.type == 'MultiLineString') {
      col_lines = inter.coordinates
    }
    if (inter.type == 'LineString') {
      col_lines.push(inter.coordinates)
    }
    return col_lines
  }

  AutoToolTiNPadInpillar.prototype.getPlacePos = function () {
    let place_polygon_op = new QGdstk.Polygon(this.conf.place_polygon, 0, 0)
    let box = place_polygon_op.bounding_box()
    let x_min = box[0][0]
    let y_min = box[0][1]
    let x_max = box[1][0]
    let y_max = box[1][1]

    // let x_offset = x_min < 0 ? -x_min : 0
    // let y_offset = y_min < 0 ? -y_min : 0
    let x_offset = -x_min
    let y_offset = -y_min

    place_polygon_op.translate(x_offset, y_offset)
    box = place_polygon_op.bounding_box()

    // 获取roi区域
    let roi = null
    let dig_polygons = this.conf.dig_polygons
    if (dig_polygons) {
      let dig_polygons_op = []
      for (let i = 0; i < dig_polygons.length; i++) {
        if (dig_polygons[i].length < 3) {
          continue
        }
        let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
        p2.translate(x_offset, y_offset)
        dig_polygons_op.push(p2)
      }
      roi = QGdstk.boolean(place_polygon_op, dig_polygons_op, 'not')
    } else {
      roi = [place_polygon_op]
    }

    // 计算步长
    let step_grid = this.conf.grid_step
    // let step = Math.floor(this.conf.min_distance / step_grid)

    let polygons = []
    for (let p_idx = 0; p_idx < roi.length; p_idx++) {
      let ppp = roi[p_idx].points
      ppp.push(ppp[0])
      polygons.push(this.proxyToArray(ppp))
    }

    // 自身kdtree
    let self_min_distance = this.conf.min_distance * this.conf.min_distance - 0.01
    let kdtree_self = new KDTreeFast.KdTree(2)
    let res = []

    // 获取线列线
    let col_lines = this.scanLineFillPolygon(box, polygons, step_grid)
    // 进度
    let length = col_lines.length
    let process_n = Math.ceil(length / 10)
    // let n = 0
    for (let i = 0; i < col_lines.length; i++) {
      if (i % process_n == 0) {
      } else if (i == length - 1) {
      }

      let line = col_lines[i]
      let p1 = line[0]
      let p2 = line[1]
      // let dx = p2[0] - p1[0]
      let dy = p2[1] - p1[1]
      let pos_arr = []
      // 散点
      if (dy > 0) {
        for (let k = 0; k < dy; k++) {
          pos_arr.push([p1[0] * step_grid - x_offset, (p1[1] + k) * step_grid - y_offset])
        }
      }
      if (dy < 0) {
        for (let k = 0; k > dy; k--) {
          pos_arr.push([p1[0] * step_grid - x_offset, (p1[1] + k) * step_grid - y_offset])
        }
      }
      if (pos_arr.length < 1) continue

      for (let k = 0; k < pos_arr.length; k++) {
        let pos = pos_arr[k]
        let inds = kdtree_self.within(pos, self_min_distance)
        if (inds.length > 0) {
          continue
        }
        let angle = this.checkRule(pos, this.conf.size.tin_shape, this.conf.size.A / 2, this.kd_rules)
        if (angle === -1) {
          continue
        }
        res.push({ pos: pos, angle: angle })
        kdtree_self.add(pos, k)
      }
    }
    return res
  }

  AutoToolTiNPadInpillar.prototype.getPlacePosBK = function () {
    let place_polygon_op = new QGdstk.Polygon(this.conf.place_polygon, 0, 0)
    let box = place_polygon_op.bounding_box()
    let x_min = box[0][0]
    let y_min = box[0][1]
    let x_max = box[1][0]
    let y_max = box[1][1]
    // let x_offset = x_min < 0 ? -x_min : 0
    // let y_offset = y_min < 0 ? -y_min : 0
    let x_offset = -x_min
    let y_offset = -y_min
    place_polygon_op.translate(x_offset, y_offset)

    let dig_polygons_op = []
    let dig_polygons = this.conf.dig_polygons
    for (let i = 0; i < dig_polygons.length; i++) {
      let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
      p2.translate(x_offset, y_offset)
      dig_polygons_op.push(p2)
    }

    let roi = QGdstk.boolean(place_polygon_op, dig_polygons_op[0], 'not')

    // 计算步长
    let step_grid = this.conf.grid_step
    let step = Math.floor(this.conf.min_distance / step_grid)
    let x_offset_step = Math.floor(x_offset / step_grid)
    let y_offset_step = Math.floor(y_offset / step_grid)

    // 计算grid行列数量
    let row_num = Math.floor((y_max - y_min) / step_grid + step * 2)
    let col_num = Math.floor((x_max - x_min) / step_grid + step * 2)
    let grid_one = new ArrayBuffer(row_num)
    for (let n = 0; n < row_num; n++) {
      grid_one[n] = new Uint8Array(col_num)
    }

    let res = []
    for (let p_idx = 0; p_idx < roi.length; p_idx++) {
      // 遍历需要放置的多边形区域
      let poly = roi[p_idx]
      // 将多边形的首尾点连接起来
      poly.points.push(poly.points[0])

      this.printProxy(poly.points)
      // 线性填充多边形
      // let fill = this.libs.fillPolygonRC(this.proxyToArray(poly.points), step_grid, 1)
      let rr = fill.cc
      let cc = fill.rr

      // 进度变量
      let process = Math.ceil(rr.length / 20)
      let len = rr.length
      for (let idx = 0; idx < len; idx++) {
        // 进度显示
        if (idx % process == 0) {
        } else if (idx == len - 1) {
        }
        let r = parseInt(rr[idx])
        let c = parseInt(cc[idx])
        // 获取真实位置为: 行数*网格步长-偏移量
        let pos = [r * step_grid - x_offset, c * step_grid - y_offset]
        let has_other = false
        // 快速扫描周边有没有其他tinpad
        for (let i = r - step; i < r + step; i++) {
          for (let j = c - step; j < c + step; j++) {
            if (grid_one[i + step][j + step] === 1) {
              has_other = true
              break
            }
          }
          if (has_other) {
            break
          }
        }
        if (has_other) {
          continue
        }
        let angle = this.checkRule(pos, this.conf.size.tin_shape, this.conf.size.A / 2, this.kd_rules)
        if (angle === -1) {
          continue
        }
        try {
          grid_one[r + step][c + step] = 1
        } catch (error) {}
        res.push({ pos: pos, angle: angle })
      }
    }

    return res
  }

  AutoToolTiNPadInpillar.prototype.pathsTranLines = function (paths) {
    let lines = []
    for (let i = 0; i < paths.length; i++) {
      lines.push(paths[i].spine())
    }
    return lines
  }

  AutoToolTiNPadInpillar.prototype.placeCell = function (pos) {
    let cell_tin_dium = this.TiNIndium(this.conf.size)
    let cell_tin_dium_k = this.libs.GdstkCell2KernelCell(cell_tin_dium, this.conf.fileLayerList)

    let references = []

    let process_len = pos.length,
      process_n = Math.ceil(process_len / 20),
      process_i = 0
    pos.forEach(t => {
      if (process_i % process_n == 0) {
      }
      if (process_i == process_len - 1) {
      }
      process_i += 1
      if (t.pos[0] !== undefined && t.pos[1] !== undefined && t.angle !== undefined) {
        // let ref = new window.Kernel.Reference(cell_tin_dium_k, t.pos, t.angle, 1, false, 1, 1, [0, 0])
        let ref = new window.Kernel.Reference()
        ref.cell = cell_tin_dium_k
        ref.origin = t.pos
        ref.rotation = t.angle
        references.push(ref)
      }
    })

    let log = this.total(references.length)
    let data = {
      add_cells: [cell_tin_dium_k],
      add_references: references,
      log: log,
    }

    return data
  }

  AutoToolTiNPadInpillar.prototype.getRoiArea = function (place_polygon, dig_polygons) {
    let place_poly = new QGdstk.Polygon(place_polygon)
    let dig_polygons_op = []
    for (let i = 0; i < dig_polygons.length; i++) {
      let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
      dig_polygons_op.push(p2)
    }
    let roi = QGdstk.boolean(place_poly, dig_polygons_op, 'not')
    return roi
  }

  // 统计报告
  AutoToolTiNPadInpillar.prototype.total = function (inpillar_num) {
    let radius = this.conf.size.A1 / 2
    let shape = this.conf.size.indium_shape
    let inpillar_area = 0
    if (shape == 'Round') {
      // inpillar_area = inpillar_num * Math.PI * radius * radius
      let Indium = QGdstk.ellipse([0, 0], this.conf.size.A1 / 2, null, 0, 0, this.conf.size.indium_tolerance, this.conf.size.indium_layer, this.conf.size.indium_datatype)
      inpillar_area = inpillar_num * Indium.area()
    }
    if (shape == 'Rectangle') {
      inpillar_area = inpillar_num * (2 * radius) * (2 * radius)
    }
    let place = this.conf.place_polygon
    let place_poly = new QGdstk.Polygon(place)

    let dig_polygons_op = []
    let dig_polygons = this.conf.dig_polygons
    for (let i = 0; i < dig_polygons.length; i++) {
      if (dig_polygons[i].length == 0) {
        continue
      }
      let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
      dig_polygons_op.push(p2)
    }
    let roi = QGdstk.boolean(place_poly, dig_polygons_op, 'not', 0.01, 0, 0)

    let roi_area = 0
    for (let i = 0; i < roi.length; i++) {
      roi_area += roi[i].area()
    }

    let account = 0
    if (roi_area > 0) account = (100 * inpillar_area) / roi_area

    return {
      inpillar_number: inpillar_num,
      inpillar_radius: radius,
      inpillar_shape: shape,
      inpillar_total_area: inpillar_area,
      roi_area: roi_area,
      account: account,
    }
  }

  // indium_shape, indium_layer = 7, indium_datatype = 0, indium_flip_layer = 8, indium_flip_datatype = 0, tin_shape, tin_layer = 9, tin_datatype = 0, tin_flip_layer = 10, tin_flip_datatype = 0, A = 26, A1 = 16, tolerance = 0.01
  AutoToolTiNPadInpillar.prototype.TiNIndium = function (data) {
    let size = data || {
      A: 26,
      indium_tolerance: 0.01,
      indium_shape: 'Round',
      indium_layer: 7,
      indium_datatype: 0,
      indium_flip_layer: 8,
      indium_flip_datatype: 0,

      A1: 16,
      tin_tolerance: 0.01,
      tin_shape: 'Round',
      tin_layer: 9,
      tin_datatype: 0,
      tin_flip_layer: 10,
      tin_flip_datatype: 0,
    }
    let TiN = null
    let TiN_Flip = null
    if (size.tin_shape === 'Round') {
      TiN = QGdstk.ellipse([0, 0], size.A / 2, null, 0, 0, size.tin_tolerance, size.tin_layer, size.tin_datatype)
      TiN_Flip = QGdstk.ellipse([0, 0], size.A / 2, null, 0, 0, size.tin_tolerance, size.tin_flip_layer, size.tin_flip_datatype)
    }
    if (size.tin_shape === 'Rectangle') {
      let w = size.A / 2
      let points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      TiN = new QGdstk.Polygon(points, size.tin_layer, size.tin_datatype)
      TiN_Flip = new QGdstk.Polygon(points, size.tin_flip_layer, size.tin_flip_datatype)
    }
    let Indium = null
    let Indium_Flip = null
    if (size.indium_shape === 'Round') {
      Indium = QGdstk.ellipse([0, 0], size.A1 / 2, null, 0, 0, size.indium_tolerance, size.indium_layer, size.indium_datatype)
      Indium_Flip = QGdstk.ellipse([0, 0], size.A1 / 2, null, 0, 0, size.indium_tolerance, size.indium_flip_layer, size.indium_flip_datatype)
    }
    if (size.indium_shape === 'Rectangle') {
      let w = size.A1 / 2
      let points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      Indium = new QGdstk.Polygon(points, size.indium_layer, size.indium_datatype)
      Indium_Flip = new QGdstk.Polygon(points, size.indium_flip_layer, size.indium_flip_datatype)
    }

    let cell = new QGdstk.Cell('TiN-Induim')
    cell.add([TiN, TiN_Flip, Indium, Indium_Flip])
    return cell
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolTiNPadInpillar
  } else {
    window.AutoToolTiNPadInpillar = AutoToolTiNPadInpillar
  }
})()
