export class UndoRedo {
  constructor() {
    this.max = 49
    this.undoStack = []
    this.redoStack = []
    this.onChange = null
  }
  record(data) {
    if (this.onChange) {
      this.onChange()
    }
    //超过最大值
    if (this.undoStack.length > this.max) {
      let remove = this.undoStack.shift()
      if (remove.action === 'delete') {
        //释放canvaskit path内存
        // remove.objs.forEach(obj => {
        //   obj?.js_obj?.path?.delete()
        // })
      }
    }
    this.redoStack = []
    this.undoStack.push(data)
  }
  undo() {
    if (this.undoStack.length) {
      if (this.onChange) {
        this.onChange()
      }
      let last = this.undoStack.pop()
      this.updateRedoStack(last)
      return last
    }
    return []
  }
  redo() {
    if (this.redoStack.length) {
      if (this.onChange) {
        this.onChange()
      }
      let last = this.redoStack.pop()
      this.updateUndoStack(last)
      return last
    }
    return []
  }
  updateUndoStack(data) {
    if (this.undoStack.length > this.max) {
      let remove = this.undoStack.shift()
      if (remove.action === 'delete') {
        //释放canvaskit path内存
        // remove.objs.forEach(obj => {
        //   obj?.js_obj?.path?.delete()
        // })
      }
    }
    this.undoStack.push(data)
  }
  updateRedoStack(data) {
    if (this.redoStack.length > this.max) {
      this.redoStack.shift()
    }
    this.redoStack.push(data)
  }
  clear() {
    this.undoStack = []
    this.redoStack = []
  }
}
