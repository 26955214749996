import { BOARDMODE } from '../const/board-status'
/**
    图形校验逻辑
 */

export function validDrawLinePoints(posNow, posLast, currentPoints = []) {
  if (isEqualPos(posNow, posLast)) return false
  // for (let i = 0; i < currentPoints.length; i++) {
  //   const p = currentPoints[i]
  //   if (isEqualPos(posNow, p)) return false
  // }
  return true
}

export function validDrawCirclePoints(start, end) {
  if (isEqualPos(start, end)) return false
  if (isHVLine(start, end)) return false
  return true
}

export function isEqualPos(p1, p2) {
  if (p1[0] == p2[0] && p1[1] == p2[1]) return true
  return false
}

//两点连线为水平或垂直
export function isHVLine(p1, p2) {
  if (p1[0] == p2[0] || p1[1] == p2[1]) return true
  return false
}

//判断是否在绘制图形
export function isDrawMode(MODE) {
  if (
    MODE == BOARDMODE.DRAW_LINE ||
    MODE == BOARDMODE.DRAW_CIRCLE ||
    MODE == BOARDMODE.DRAW_RECT ||
    MODE == BOARDMODE.DRAW_POLYGON ||
    MODE == BOARDMODE.DRAW_LABEL ||
    MODE == BOARDMODE.DRAW_KP ||
    MODE == BOARDMODE.DRAW_RULER ||
    MODE == BOARDMODE.DRAW_3D_CUT_AREA ||
    MODE == BOARDMODE.DRAW_3D_RENDER_AREA
  ) {
    return true
  }
  return false
}
//判断是否能结束绘制
export function validCompleteDraw(LAYOUT) {
  let l_p = LAYOUT.STATUS.left_press
  let m_p = LAYOUT.STATUS.middle_press
  let r_p = LAYOUT.STATUS.right_press
  if (LAYOUT.MODE == BOARDMODE.OVER_SEL || LAYOUT.MODE == BOARDMODE.PART_SEL) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_LINE && r_p) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_CIRCLE && (l_p || r_p)) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_RECT && (l_p || r_p)) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_POLYGON && r_p) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_LABEL && l_p) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_KP && l_p) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_RULER && (l_p || r_p)) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_3D_CUT_AREA) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_3D_RENDER_AREA && r_p) {
    return true
  }
}

//判断绘制的图形是否合法
export function validDrawGraphic(LAYOUT) {
  let drawingObj = LAYOUT.STATUS.drawingObj
  if (LAYOUT.MODE == BOARDMODE.DRAW_LINE) {
    return drawingObj?.js_obj.points.length > 1
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_CIRCLE) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_RECT) {
    return true
  }
  if (LAYOUT.MODE == BOARDMODE.DRAW_POLYGON) {
    return drawingObj?.js_obj.points.length > 2
  }
  return true
}

//判断是否在绘制图形或工具
export function checkDrawing(LAYOUT) {
  if (LAYOUT.STATUS.drawingObj) return true
  if (LAYOUT.STAGE.tools.isDrawingTool()) return true
  return false
}

export function isBasicGraphic(mode) {
  return mode == BOARDMODE.DRAW_LINE || mode == BOARDMODE.DRAW_CIRCLE || mode == BOARDMODE.DRAW_RECT || mode == BOARDMODE.DRAW_POLYGON || mode == BOARDMODE.DRAW_3D_CUT_AREA || mode == BOARDMODE.DRAW_3D_RENDER_AREA
}

export function checkCanTrigger() {
  let flag = true
  let objs = document.querySelectorAll('div[aria-label]') //''对象编辑
  //判断弹窗是否打开
  objs.forEach(obj => {
    if (obj.getAttribute('role') == 'dialog') {
      if (obj.parentNode.style.display !== 'none') {
        flag = false
      }
    }
  })
  return flag
}

//是否可以切割
export function canCutObj(obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  return TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*'
}

//是否可以合并
export function canMerge(obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  return (TYPE == 'GdsFlexpath*' && obj.width[0] > 0) || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*'
}

//是否可以区域切除
export function canCutArea(obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  return TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*'
}

//是否可以布尔运算
export function canBoolean(obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  return (TYPE == 'GdsFlexpath*' && obj.width[0]) || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*'
}

//是否是合法包围盒
export function validAABB(aabb) {
  if (!aabb) return false
  if (aabb[0][0] > aabb[1][0]) return false
  return true
}

export function cantrigger(e) {
  //判断鼠标聚焦在画板以外的文本框
  if (e.target?.tagName == 'INPUT' && e.target?.type !== 'checkbox') return false //e.target.tagName == 'INPUT'

  if (document.getElementById('textInputBox').style.display == 'block') return false

  if (document.getElementById('drc-editor')?.getBoundingClientRect()?.width) {
    return false //drc编辑框显示
  }
  // if (document.getElementById('drc-editor').style.display !== 'none') {

  //   return false //drc编辑框
  // }
  //判断弹窗是否打开
  document.querySelectorAll('div[aria-label]').forEach(obj => {
    if (obj.getAttribute('role') == 'dialog' && obj.parentNode.style.display !== 'none') return false
  })

  return true
}

//可以进入下层的器件
export function canEnterRef(obj) {
  if (obj?.$$?.ptrType.name == 'Reference*') {
    return true
  }
  return false
}

//需要强制吸附的模式
export function needForceAdsorpMode(mode) {
  if (mode == BOARDMODE.ALIGN_PICK_POINT || mode == BOARDMODE.ALIGN_PICK_LINE) {
    return true
  }
  return false
}
