var AutoToolLibs = require('./libs')
export class ToolJJ {
  constructor() {
    this.libs = new AutoToolLibs()
  }

  // 识别jj位置，以jj图层进行识别，取包围盒子中心
  getPositions(cell, conf) {
    this.cell = cell

    function getBoxCenter(b) {
      let cx = b[0][0] + (b[1][0] - b[0][0]) / 2
      let cy = b[0][1] + (b[1][1] - b[0][1]) / 2
      return { x: cx, y: cy }
    }
    let res = {}
    for (let i = 0; i < conf.length; i++) {
      let item = conf[i]
      let center_pos = []

      let polygons = cell.get_polygons(true, false, -1, item.layer, 0)
      let paths = cell.get_paths(true, -1, item.layer, 0)
      for (let j = 0; j < paths.length; j++) {
        if (paths[j]) center_pos.push(getBoxCenter(this.libs.bounding_box(paths[j].points)))
      }
      for (let j = 0; j < polygons.length; j++) {
        if (polygons[j]) {
          let b = polygons[j].bounding_box()
          center_pos.push(getBoxCenter(b))
        }
      }
      if (res[item.type]) {
        res[item.type].push.apply(res[item.type], center_pos)
      } else {
        res[item.type] = center_pos
      }
    }
    return res
  }

  // 识别chip分区，取包围盒子中心
  getChipPositions(cell, jj_positions) {
    function getBoxCenter(b) {
      let cx = b[0][0] + (b[1][0] - b[0][0]) / 2
      let cy = b[0][1] + (b[1][1] - b[0][1]) / 2
      return { x: cx, y: cy }
    }
    let references = cell.referens
    if (references.length == 0) {
      throw window.i18n.t('messages.toolJJerrorCell')
    }
    let positions = []
    for (let j = 0; j < references.length; j++) {
      if (references[j]) {
        let b = references[j].bounding_box()
        if (b[1][0] - b[0][0] === -Infinity || b[1][1] - b[0][1] === -Infinity) {
          throw window.i18n.t('messages.toolJJerrorCell')
        }
        let center = getBoxCenter(b)
        positions.push([center.x, center.y, b])
      }
    }

    let rc = this.getRowCol(positions, 20)

    // 生成四叉树
    let box = cell.bounding_box()
    if (!box) return
    let qcbox = new QGdstk.QuadtreeBox(box[0][0], box[0][1], box[1][0] - box[0][0], box[1][1] - box[0][1])
    let quadtree = new QGdstk.Quadtree(qcbox)
    for (let i = 0, len = rc.length; i < len; i++) {
      let b = rc[i].obj[2]
      if (!b) continue
      let node = new QGdstk.QuadtreeNode(i, b[0][0], b[0][1], b[1][0] - b[0][0], b[1][1] - b[0][1])
      quadtree.add(node)
    }

    // 遍历jj位置，通过jj位置查询四叉树，确定所在chip区域
    for (let i = 0, len = jj_positions.length; i < len; i++) {
      let nbox = new QGdstk.QuadtreeBox(jj_positions[i][0] - 0.001, jj_positions[i][1] - 0.001, 0.002, 0.002)
      let nodes = quadtree.query(nbox)
      if (nodes) {
        jj_positions[i][2].chip = rc[nodes[0].id].chip
      }
    }
    return jj_positions
  }

  // 对坐标点进行行列区分, th: 行列偏差阈值
  getRowCol(objects, thresh) {
    // 求平均数
    function average(arr) {
      if (!arr) return
      let sum = 0
      arr.forEach(element => {
        sum += element
      })
      return sum / arr.length
    }
    // 根据X轴顺序获取各列的中心位置
    function getTagsX(objs) {
      let th = thresh
      let res = []
      let group = []
      let start = objs[0]
      for (let i = 0; i < objs.length; i++) {
        let err = Math.abs(objs[i][0] - start[0])
        if (err > th && i > 0) {
          start = objs[i]
          res.push(average(group))
          group = []
        }
        group.push(objs[i][0])
      }
      res.push(average(group))
      return res
    }
    // 根据Y轴顺序获取各行中心位置
    function getTagsY(objs) {
      let th = thresh
      let res = []
      let group = []
      let start = objs[0]
      for (let i = 0; i < objs.length; i++) {
        let err = Math.abs(objs[i][1] - start[1])
        if (err > th && i > 0) {
          start = objs[i]
          res.push(average(group))
          group = []
        }
        group.push(objs[i][1])
      }
      res.push(average(group))
      return res
    }

    // 获取excel的列英文表示，A、B、C...
    function getExcelColumnLabel(columnNumber) {
      let label = ''
      while (columnNumber > 0) {
        // 将数值转换为 ASCII 码表示的字母
        label = String.fromCharCode(((columnNumber - 1) % 26) + 65) + label
        columnNumber = Math.floor((columnNumber - 1) / 26)
      }
      return label
    }

    // 根据行列对应范围，获取行列
    function getRC(objs, tags_x, tags_y) {
      let th = thresh / 2
      let res = []
      objs.forEach(obj => {
        let x = obj[0]
        let y = obj[1]
        let r = 0
        let c = 0
        for (let i = 0; i < tags_x.length; i++) {
          if (x > tags_x[i] - th && x < tags_x[i] + th) c = i
          for (let j = 0; j < tags_y.length; j++) {
            if (y > tags_y[j] - th && y < tags_y[j] + th) r = j
          }
        }

        res.push({
          obj: obj,
          chip: `${getExcelColumnLabel(c + 1)}${r + 1}`,
        })
      })
      return res
    }

    let sorted_objects_x = JSON.parse(JSON.stringify(objects)).sort((a, b) => a[0] - b[0])
    let sorted_objects_y = JSON.parse(JSON.stringify(objects)).sort((a, b) => b[1] - a[1])

    let tags_x = getTagsX(sorted_objects_x)
    let tags_y = getTagsY(sorted_objects_y)

    let rc = getRC(objects, tags_x, tags_y)

    return rc
  }
}

// var test_jj = new ToolJJ()
// test_jj.getRowCol(
//   [
//     [0, 0],
//     [10, 0],
//     [20, 0],

//     [10, 10],
//     [20, 10],

//     [0, 10],
//     [10, 20],
//     [0, 20],
//     [20, 20],
//   ],
//   // [
//   //   [11754, -7703],
//   //   [6754, -2703],
//   //   [1754, 2297],
//   //   [16754, 7297],
//   //   [6754, 17297],
//   //   [11754, 12297],
//   //   [21754, 22297],
//   //   [16754, 27297],
//   //   [11754, 32297],
//   // ],
//   2
// )
