import { array } from 'js-md5'
import { validAABB } from '../validator/validator'
import { transInRefPos, transOutRefPos } from './collision'
import { generateLabelMesh } from './font-util'
import { addCellInstanceMap, buildCellData, getDirectionByAnchor, initCellInstanceMap, isParamsDevice, updateCellInsRefs, updateRefGraphic } from './graphics-util'
import { rotatePoint } from './layout-function'

//计算引用cell的所有引用矩阵
export function computeRefs(originRefs, cellsInstanceMap, befoewRefs = []) {
  let refs = expanRefs(originRefs) //展开阵列属性
  let len = refs.length
  for (let i = 0; i < len; i++) {
    let ref = refs[i]

    let cell = ref.cell
    if (cell) {
      let all_refs = [...befoewRefs, ref]
      let subRefs = cell.referens
      let cellInstanceMap = cellsInstanceMap[cell.name]
      cellInstanceMap.refs.push(all_refs)
      if (subRefs.length) {
        computeRefs(subRefs, cellsInstanceMap, all_refs)
      }
    }
  }
}

//计算引用cell的所有引用矩阵
export function computeTargetRefs(originRefs, cellsInstanceMap, cellsPtrs, befoewRefs = []) {
  let refs = expanRefs(originRefs) //展开阵列属性
  // let refs = originRefs
  let len = refs.length
  for (let i = 0; i < len; i++) {
    let ref = refs[i]
    let cell = ref.cell
    if (cell) {
      let all_refs = [...befoewRefs, ref]
      let subRefs = cell.referens
      let cellInstanceMapItem = cellsInstanceMap[cell.name]
      if (!cellInstanceMapItem) continue
      if (cellsPtrs.indexOf(cellInstanceMapItem.cell.$$.ptr) !== -1) {
        cellInstanceMapItem.refs.push(all_refs)
      }
      if (subRefs.length) {
        computeTargetRefs(subRefs, cellsInstanceMap, cellsPtrs, all_refs)
      }
    }
  }
}

//引用用网格区域归纳
export function computeRefByAreas(cellsMatMap, divideAreas, targetCellsPtrs) {
  for (const key in cellsMatMap) {
    let obj = cellsMatMap[key]
    obj.groups = []
    // if (!targetCellsPtrs || targetCellsPtrs.indexOf(obj.cell.$$.ptr) !== -1) {
    //   if (obj.refs.length > 1000000) {
    //     obj.groups = generateRefsGroup(obj.refs, divideAreas)
    //   }
    // }
  }
}

//根据网格分组
function generateRefsGroup(refs, divideAreas) {
  let result = []
  for (let i = 0; i < divideAreas.length; i++) {
    result.push({ refs: [], area: divideAreas[i], instanceObj: { path: [], polygons: [] } })
  }
  let l = refs.length

  for (let i = 0; i < l; i++) {
    const pos = refs[i][0].origin //取第一个position
    for (let j = 0; j < divideAreas.length; j++) {
      const area = divideAreas[j]
      if (pos[0] > area.min[0] && pos[1] > area.min[1] && pos[0] < area.max[0] && pos[1] < area.max[1]) {
        result[j].refs.push(refs[i])
        break
      }
    }
  }

  return result
}

//计算引用的矩阵
export function computeRefMats(cellsInstanceMap, targetCellsPtrs) {
  for (const key in cellsInstanceMap) {
    let obj = cellsInstanceMap[key]
    if (!targetCellsPtrs || targetCellsPtrs.indexOf(obj.cell.$$.ptr) !== -1)
      if (obj.groups?.length) {
        obj.matsArray = computeMatArrays(obj.groups) //多个batch
      } else {
        obj.matsArray = computeMatArray(obj.refs) //一个batch
      }
  }
}

//移动选择后更新器件矩阵
export function updateRefMats(cellsInstanceMap, cells_ptr) {
  for (const key in cellsInstanceMap) {
    let obj = cellsInstanceMap[key]
    if (containCell(cells_ptr, obj.cell)) {
      if (obj.groups?.length) {
        obj.matsArray = computeMatArrays(obj.groups) //多个batch
      } else {
        obj.matsArray = computeMatArray(obj.refs) //一个batch
      }
    }
  }
}

export function containCell(cells_ptr, targetCell) {
  for (let i = 0; i < cells_ptr.length; i++) {
    if (cells_ptr[i] == targetCell.$$.ptr) {
      return true
    }
  }
  return false
}
//计算[ref,ref,ref] =>矩阵
export function computeMatArray(refs) {
  let instanceCount = refs.length
  if (instanceCount) {
    let mats = []
    let translateArray = new Float32Array(instanceCount * 4 * 4)
    for (let i = 0, start = 0; i < instanceCount; i++, start += 16) {
      let matrix4 = new THREE.Matrix4()
      let obj = refs[i]
      mats.push(matrix4)
      for (let j = 0; j < obj.length; j++) {
        let refmMat = computeRefsMat(obj[j])
        matrix4 = matrix4.multiply(refmMat)
      }
      let mat = matrix4.elements
      translateArray[start + 0] = mat[0]
      translateArray[start + 1] = mat[1]
      translateArray[start + 2] = mat[2]
      translateArray[start + 3] = mat[3]
      translateArray[start + 4] = mat[4]
      translateArray[start + 5] = mat[5]
      translateArray[start + 6] = mat[6]
      translateArray[start + 7] = mat[7]
      translateArray[start + 8] = mat[8]
      translateArray[start + 9] = mat[9]
      translateArray[start + 10] = mat[10]
      translateArray[start + 11] = mat[11]
      translateArray[start + 12] = mat[12]
      translateArray[start + 13] = mat[13]
      translateArray[start + 14] = mat[14]
      translateArray[start + 15] = mat[15]
    }
    return translateArray
  } else {
    let translateArray = new Float32Array([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])
    return translateArray
  }
}

//计算group引用=>矩阵
export function computeMatArrays(groups) {
  groups.forEach(group => {
    let refs = group.refs
    let instanceCount = refs.length
    let translateArray = new Float32Array(instanceCount * 4 * 4)
    group.matsArray = []
    for (let i = 0, start = 0; i < instanceCount; i++, start += 16) {
      let mats = []
      let matrix4 = new THREE.Matrix4()
      let obj = refs[i]
      mats.push(matrix4)
      for (let j = 0; j < obj.length; j++) {
        let refmMat = computeRefsMat(obj[j])
        matrix4 = matrix4.multiply(refmMat)
      }
      let mat = matrix4.elements
      translateArray[start + 0] = mat[0]
      translateArray[start + 1] = mat[1]
      translateArray[start + 2] = mat[2]
      translateArray[start + 3] = mat[3]
      translateArray[start + 4] = mat[4]
      translateArray[start + 5] = mat[5]
      translateArray[start + 6] = mat[6]
      translateArray[start + 7] = mat[7]
      translateArray[start + 8] = mat[8]
      translateArray[start + 9] = mat[9]
      translateArray[start + 10] = mat[10]
      translateArray[start + 11] = mat[11]
      translateArray[start + 12] = mat[12]
      translateArray[start + 13] = mat[13]
      translateArray[start + 14] = mat[14]
      translateArray[start + 15] = mat[15]
    }
    group.matsArray = translateArray
    group.matBufferAttr = new THREE.InstancedBufferAttribute(translateArray, 16)
  })
}

//计算ref 4*4矩阵
export function computeRefsMat(ref) {
  let trans = new THREE.Object3D()
  let x_mirror = ref.x_reflection ? -1 : 1
  trans.scale.set(ref.magnification, ref.magnification * x_mirror, 1)
  trans.rotation.set(0, 0, ref.rotation)
  trans.position.set(ref.origin[0], ref.origin[1], 0)
  trans.updateMatrix()
  return trans.matrix
}

//划分区域
export function divideRectangle(aabb, size) {
  if (!validAABB(aabb)) return []
  let min_x = aabb[0][0]
  let min_y = aabb[0][1]
  let max_x = aabb[1][0]
  let max_y = aabb[1][1]
  let w = Math.ceil(max_x) - min_x
  let h = Math.ceil(max_y) - min_y
  let divide_w = parseInt(Math.ceil(w / size))
  let divide_h = parseInt(Math.ceil(h / size))
  let divide_w_half = divide_w / 2
  let divide_h_half = divide_h / 2
  let radius = Math.sqrt(divide_w * divide_w + divide_h * divide_h) / 2
  let result = []
  let total = size * size

  for (let i = 0; i < size; i++) {
    //行
    let x = min_x + i * divide_w
    let center_x = x + divide_w_half
    for (let j = 0; j < size; j++) {
      //列
      let y = min_y + j * divide_h
      let center_y = y + divide_h_half
      let aabb = [
        [x, y],
        [x + divide_w, y + divide_h],
      ]
      result.push({
        min: aabb[0],
        max: aabb[1],
        aabb,
        boundingSphere: new THREE.Sphere(new THREE.Vector3(center_x, center_y, 0), radius),
      })
    }
  }
  return result
}

//计算引用中存在的器件ptr
export function getRefsCellsPtr(cells, refs) {
  let ref_cells_ptrs = noRepeatArray(refs.map(ref => ref.cell?.$$.ptr))
  const deepCells = cells.filter(cell => ref_cells_ptrs.indexOf(cell.$$.ptr) !== -1)
  for (let i = 0; i < deepCells.length; i++) {
    ref_cells_ptrs.push(...deepCells[i].depend_cells(-1).map(cell => cell.$$.ptr))
  }
  ref_cells_ptrs = noRepeatArray(ref_cells_ptrs)
  return ref_cells_ptrs
}

//计算器件包括所引用cell的ptrs
export function getCellPtrs(cell) {
  let cell_arr = [cell]
  cell_arr.concat(cell.depend_cells(-1))
  return cell_arr.map(cell => cell.$$.ptr)
}

//计算存在阵列属性的的ptr
export function getRepetitionCellsPtr(refs) {
  let cellPtrs = []
  for (let i = 0; i < refs.length; i++) {
    const ref = refs[i]
    if (ref.js_obj.expanRefs?.length) {
      cellPtrs.push(ref.cell?.$$.ptr)
    }
  }
  return noRepeatArray(cellPtrs)
}
//获取引用的多边形
export function getRefPolygons(ref, depth, keep_params_device) {
  let objs = getCellPolygons(ref.cell, depth, keep_params_device)
  let len = objs.length
  let result = []
  for (let i = 0; i < len; i++) {
    result.push(copyRefPolygonsToGlobal(objs[i], ref))
  }
  return result
}

//获取器件的多边形
export function getCellPolygons(cell, depth, keep_params_device) {
  let items = []
  if (!depth) {
    items = [...cell.flexpaths, ...cell.polygons, ...cell.labels, ...expanRefs(cell.referens)]
  } else {
    let refItems = []
    const refs = cell.referens
    refs.forEach(ref => {
      if (isParamsDevice(ref.cell) && keep_params_device) {
        refItems.push(ref)
      } else {
        refItems.push(...getRefPolygons(ref, true, keep_params_device))
      }
    })
    items = [...cell.flexpaths, ...cell.polygons, ...cell.labels, ...refItems]
  }

  return items
}

//复制引用下的图形到全局
export function copyRefPolygonsToGlobal(data, ref) {
  let copy
  const TYPE = data.$$?.ptrType.name //data.constructor.name
  if (TYPE == 'Reference*') {
    copy = data.copy()
    copy.origin = transOutRefPos([copy.origin], ref)[0]
    copy.rotation += ref.rotation
    const x_reflection = (copy.x_reflection ? -1 : 1) * (ref.x_reflection ? -1 : 1) == -1 ? true : false
    copy.x_reflection = x_reflection
  } else if (TYPE == 'GdsFlexpath*') {
    copy = data.copy()
    let points = transOutRefPos(data.points, ref)

    copy.points = points
  } else if (TYPE == 'GdsPolygon*') {
    copy = data.copy()
    copy.points = transOutRefPos(data.points, ref)
  } else if (TYPE == 'GdsRectangle*') {
    copy = data.copy()
    copy.center = transOutRefPos([data.center], ref)[0]
  } else if (TYPE == 'GdsEllipse*') {
    copy = data.copy()
    copy.center = transOutRefPos([data.center], ref)[0]
  } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
    copy = data.copy()
    copy.origin = transOutRefPos([copy.origin], ref)[0]
    copy.angle += ref.rotation
    copy.layer = data.layer
  }
  return copy
}

//展开引用
export function expanRefs(refs) {
  const res = []
  const length = refs.length
  for (let i = 0; i < length; i++) {
    const ref = refs[i]

    let expanRefs = ref.js_obj.expanRefs //.apply_repetition()
    if (!expanRefs) {
      ref.js_obj.expanRefs = ref.apply_repetition()
      expanRefs = ref.js_obj.expanRefs
    }

    if (expanRefs?.length) {
      expanRefs.forEach(ref => (ref.js_obj.isExpan = true)) //记录引用是扩展的
      //存在阵列
      res.push(ref)
      res.push(...expanRefs)
    } else {
      res.push(ref)
    }
  }
  return res
}

export function getExpandRefs(ref) {
  let expanRefs = ref.apply_repetition()

  if (expanRefs.length) {
    expanRefs.forEach(ref => (ref.js_obj.isExpan = true)) //记录引用是扩展的
  }

  return expanRefs
}

//判断是否存在引用实例 并添加引用实例
export function addCellInsToCurrentCell(LAYOU, ref) {
  const CELL = LAYOU.CELL //当前编辑的器件
  const cellInsMaps = CELL.js_obj.cellInsMaps
  const refCells = [ref.cell].concat(ref.cell.depend_cells(-1))

  const currentDependCellsPtr = []
  for (const cellName in cellInsMaps) {
    currentDependCellsPtr.push(cellInsMaps[cellName].cell.$$.ptr)
  }
  const addNewCells = refCells.filter(refCell => currentDependCellsPtr.indexOf(refCell.$$.ptr) == -1) //过滤当前cell不存在的引用

  if (addNewCells.length) {
    addCellInstanceMap(CELL, addNewCells, ref)
  }
  addNewCells.forEach(newCell => {
    if (!newCell.js_obj.QUAD_TREE) {
      //说明没有构建过的cell数据
      buildCellData(LAYOU, newCell)
    } else {
      //说明已经打开并且构建过数据
      // updateCellData(LAYOU, newCell) //更新cell相关图形数据
    }
  })

  LAYOU.QUAD_TREE = CELL.js_obj.QUAD_TREE
  return addNewCells
}

export function getOriginExRefs(ref) {
  const copyRef = ref.copy()

  copyRef.repetition.v1 = rotatePoint(copyRef.repetition.v1, [0, 0], -copyRef.rotation)
  copyRef.repetition.v2 = rotatePoint(copyRef.repetition.v2, [0, 0], -copyRef.rotation)
  copyRef.rotation = 0
  if (copyRef.x_reflection) {
    let v1 = copyRef.repetition.v1
    let v2 = copyRef.repetition.v2
    v1[1] = -v1[1]
    v2[1] = -v2[1]
    copyRef.repetition.v1 = v1
    copyRef.repetition.v2 = v2
  }
  return [copyRef, ...copyRef.apply_repetition()]
}
//计算原始
export function getOriginRepeationRefBoundingBox(cellBox, refs) {
  let points = []
  const originRef = refs[0]
  for (let i = 0; i < refs.length; i++) {
    const ref = refs[i]
    // const polygon = new QGdstk.Polygon(cellBox)
    // polygon.translate(ref.origin)
    // points.push(...polygon.get_points())
    let newPos = [
      [cellBox[0][0] + ref.origin[0], cellBox[0][1] + ref.origin[1]],
      [cellBox[1][0] + ref.origin[0], cellBox[1][1] + ref.origin[1]],
    ]
    points.push(...newPos)
  }
  const res = new QGdstk.Polygon(points)
  // const v1 = originRef.repetition.v1
  // const v2 = originRef.repetition.v2
  res.translate([-originRef.origin[0], -originRef.origin[1]])

  return res.bounding_box()
}

//获取引用的所有展开图形数据，用于临时调用绘制
export function getRefLinesArrayBuffer(ref, extrePolygons) {
  const points_array = []
  const paths = ref.cell.get_paths(true, -1)

  const polygons = ref.cell.get_polygons(true, true, -1)

  if (extrePolygons) {
    polygons.push(...extrePolygons)
  }
  paths.forEach(path => {
    const points = path.points
    for (let j = 0; j < points.length; j++) {
      const p = points[j]
      points_array.push(p[0], p[1], 0)
    }
    points_array.push(NaN, NaN, NaN)
  })

  polygons.forEach(polygon => {
    const points = polygon.points
    for (let j = 0; j < points.length; j++) {
      const p = points[j]
      points_array.push(p[0], p[1], 0)
    }
    points_array.push(points[0][0], points[0][1], 0)
    points_array.push(NaN, NaN, NaN)
  })
  return new THREE.Float32BufferAttribute(points_array, 3)
}

export function getRefFontMesh(ref, fontMat, labels) {
  if (!labels) {
    labels = ref.cell.get_labels(true, -1)
  }
  const fontMeshes = []
  labels.forEach(label => {
    if (label.$$?.ptrType.name == 'GdsKeyPoint*' && label.need_be_dumped) {
      const direction = getDirectionByAnchor(label.anchor)
      fontMeshes.push(generateLabelMesh(label.text, label.origin, label.angle, direction, fontMat, label.font_size, 2, true))
    } else {
      const direction = getDirectionByAnchor(label.anchor)
      fontMeshes.push(generateLabelMesh(label.text, label.origin, label.angle, direction, fontMat, label.font_size, 2))
    }
  })
  return fontMeshes
}
export function getRefPointsArrayBuffer(ref, labels) {
  if (!labels) {
    labels = ref.cell.get_labels(true, -1)
  }
  const points_array = []
  const keypoints = labels.filter(label => label.$$?.ptrType.name == 'GdsKeyPoint*')
  keypoints.forEach(kp => points_array.push(kp.origin[0], kp.origin[1], 0))
  return new THREE.Float32BufferAttribute(points_array, 3)
}

//向画板添加引用
export function addRefToLayout(LAYOUT, refs) {
  let ref = refs
  if (Array.isArray(refs)) {
    //多个重复器件
    ref = refs[0]
  }
  const addNewCells = addCellInsToCurrentCell(LAYOUT, ref) //判断是否有新器件调用，构建相关数据
  if (addNewCells.length) {
    const cellInsMaps = LAYOUT.CELL.js_obj.cellInsMaps

    const cellsPtrs = addNewCells.map(cell => cell.$$.ptr)
    for (const cell in cellInsMaps) {
      const obj = cellInsMaps[cell]
      if (cellsPtrs.indexOf(obj.cell.$$.ptr) !== -1) {
        const insCount = obj.refs.length
        const matsArray = obj.matsArray
        LAYOUT.STAGE.addCellInst(LAYOUT.STAGE.refsGroup, obj.cell, insCount, matsArray, LAYOUT.LAYER_STATE) //向引用渲染组添加图形数据
      }
    }
  }

  if (Array.isArray(refs)) {
    for (let i = 0; i < refs.length; i++) {
      LAYOUT.addGdsObj(refs[i])
    }
  } else {
    LAYOUT.addObj(LAYOUT.CELL, ref)
  }

  const affectCellPtrs = getRefsCellsPtr(LAYOUT.CELL.depend_cells(-1), [ref])

  updateCellInsRefs(LAYOUT.CELL, affectCellPtrs)
  LAYOUT.STAGE.updateRefArray(LAYOUT, null, affectCellPtrs)
  // LAYOUT.STATUS.addNewCells = addNewCells
  // LAYOUT.STATUS.affectCellPtrs = affectCellPtrs
  // LAYOUT.STATUS.repetitionCellsPtrs = getRepetitionCellsPtr([ref])
}

//重构cell引用数据和图形
export function ReBuildCellRefs(LAYOUT) {
  let cell = LAYOUT.CELL
  let dependCells = cell.depend_cells(-1)
  dependCells.forEach(cell => {
    cell.js_obj.bounding_box = cell.bounding_box()
  })
  //更新器件引用包围盒
  const QUAD_TREE = cell.js_obj.QUAD_TREE
  const refs = cell.referens
  for (let i = 0; i < refs.length; i++) {
    const ref = refs[i]
    updateRefGraphic(ref)
    QUAD_TREE.updateNode(ref)
  }
  cell.js_obj.cellInsMaps = initCellInstanceMap(cell) //重新构建器件引用树关系
  // LAYOUT.STAGE.clearRefsGraphic() //清空引用图形
  // LAYOUT.STAGE.addCellInstMap(cell)
}

//重构指定cell引用数据和图形
export function ReBuildTargetCellRefs(cell, affectCellPtrs) {
  let dependCells = cell.depend_cells(-1)
  dependCells.forEach(cell => {
    cell.js_obj.bounding_box = cell.bounding_box()
  })
  //更新器件引用包围盒
  const QUAD_TREE = cell.js_obj.QUAD_TREE
  const refs = cell.referens
  for (let i = 0; i < refs.length; i++) {
    const ref = refs[i]
    updateRefGraphic(ref)
    QUAD_TREE.updateNode(ref)
  }
}

//将全局坐标转换至引用内部
export function transToRef(LAYOUT, pos) {
  if (!pos) return pos
  let ref = LAYOUT.EDIT_REF
  if (ref) {
    const transPos = transInRefPos([pos], ref)[0]
    return transPos
  }
  return pos
}
//将内部坐标转换至全局
export function transOutRef(LAYOUT, pos) {
  if (!pos) return pos
  let ref = LAYOUT.EDIT_REF
  if (ref) {
    const transPos = transOutRefPos([pos], ref)[0]
    return transPos
  }
  return pos
}

//将全局坐标点转换至引用内部
export function transToRefPoints(LAYOUT, points) {
  if (!points || !points.length) return points
  let ref = LAYOUT.EDIT_REF
  if (ref) {
    return transInRefPos(points, ref)
  }
  return points
}

//将内部坐标转换至全局
export function transOutRefPoints(LAYOUT, points) {
  if (!points || !points.length) return points
  let ref = LAYOUT.EDIT_REF
  if (ref) {
    return transOutRefPos(points, ref)
  }
  return points
}

export function claRefsPointsArray(refs, arr) {
  refs.forEach(ref => {
    const points = ref.js_obj.borderBox.points
    for (let j = 0; j < points.length; j++) {
      const p = points[j]
      arr.push(p[0], p[1], 0)
    }
    arr.push(points[0][0], points[0][1], 0)
    arr.push(NaN, NaN, NaN)
  })
}

export function existCell(targetCell, cell) {
  let dep_cells = targetCell.depend_cells(-1)
  dep_cells.forEach(cell => {
    if (cell.$$.ptr == cell?.$$.ptr) {
      return true
    }
  })
  return false
}

//器件内容替换,清空内容，添加内容
export function replaceCellData(cell, datas) {
  // cell
  if (cell) {
    clearCell(cell)
    for (let i = 0; i < datas.length; i++) {
      const obj = datas[i]
      const TYPE = obj.$$?.ptrType.name
      if (TYPE == 'GdsFlexpath*') {
        cell.add_flexpath(obj)
      } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
        cell.add_polygon(obj)
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        cell.add_label(obj)
      } else if (TYPE == 'Reference*') {
        cell.add_reference(obj)
      }
    }
  }
}

//清除cell数据
export function clearCell(cell) {
  const QUAD_TREE = cell.js_obj.QUAD_TREE
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  const referens = cell.referens
  const ref_size = referens.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    cell.remove_flexpath(path)
    if (QUAD_TREE) {
      QUAD_TREE.removeNode(path)
    }
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    cell.remove_polygon(polygon)
    if (QUAD_TREE) {
      QUAD_TREE.removeNode(polygon)
    }
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    cell.remove_label(label)
    if (QUAD_TREE) {
      QUAD_TREE.removeNode(label)
    }
  }
  for (let i = 0; i < ref_size; i++) {
    const ref = referens[i]
    cell.remove_reference(ref)
    if (QUAD_TREE) {
      QUAD_TREE.removeNode(ref)
    }
  }
}

export function updateRefsName(refs) {
  refs.forEach(ref => {
    let cell = ref.cell
    if (cell) {
      ref.name = cell.name
      updateRefsName(cell.referens)
    }
  })
}
