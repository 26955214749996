import { BOARDMODE } from '../const/board-status'
import {
  alignmentObjs,
  flip,
  arrayObjs,
  route3DView,
  prePasteData,
  mergePolygons,
  boolCheckedObjs,
  breakRef,
  addDevice,
  copyOP,
  undo,
  redo,
  enterlowerLevel,
  backUpperLevel,
  saveAsCell,
  editLowerLevel,
  adaptScreen,
  adaptObj,
} from '../utils/layout-function'
import { selectLayer, newLayer, layerChange, delLayer, mergeLayer, changeLayerNumber, hideLayer, allHide, lockLayer, allLock, batchChangeLayers } from '../utils/layer-util'
import { addRefToLayout, getRefsCellsPtr, updateRefsName } from '../utils/reference-util'
import { DirectionMapCH, generateUniqueCellName, initCharacterRef, reBuildGDSGraphic, updateCellInsRefs, updateObjMat } from '../utils/graphics-util'
// import { BoardMode, directionMap } from '../../graphic_schema/layout/layout-util'
import { Message } from 'element-ui'
import { currentDrawBoard_deepCopy_remane_cell } from '../../../components/homes/fileList/function/fileListPublicFn'

// var AutoTool = require('../../../utils/tools/index')
import { AutoTool } from '../../../utils/tools/index'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
// import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
//画板添加监听事件
export function addEventListener(LAYOUT, bus) {
  LAYOUT.resize()
  //页面缩放
  bus.$on('resizeLayer', data => {
    LAYOUT.resize()
  })

  /******************************** 键鼠事件 ********************************/
  document.addEventListener('keydown', LAYOUT.keyBoardListener)
  document.addEventListener('keyup', LAYOUT.keyUpBoardListener)
  LAYOUT.STAGE.renderer.domElement.addEventListener('mousemove', LAYOUT.mouseMoveListener)
  LAYOUT.STAGE.renderer.domElement.addEventListener('dblclick', LAYOUT.dbClickListener)
  // LAYOUT.STAGE.renderer.domElement.addEventListener('mouseup', LAYOUT.mouseUpListener)
  document.body.addEventListener('mouseup', LAYOUT.mouseUpListener)
  LAYOUT.STAGE.renderer.domElement.addEventListener('mousedown', LAYOUT.mouseDownListener)
  LAYOUT.STAGE.renderer.domElement.addEventListener('mousewheel', LAYOUT.mouseWheelListener)

  /******************************** 画板设计 ********************************/
  bus.$on('boardOption', data => {
    let obj = {
      backgroundColor: LAYOUT.STAGE.config.colors.axisBg,
      gridColor: LAYOUT.STAGE.config.colors.axisGrid,
      showgrid: LAYOUT.STAGE.config.show.axisGrid,
      adsorption: LAYOUT.AXIS.adsorp,
      adsorp_precision: LAYOUT.AXIS.adsorp_precision,
    }
    bus.$emit('openBoardOption', obj)
  })

  //接收画板设计参数
  bus.$on('boardDesign', data => {
    LAYOUT.STAGE.config.colors.axisBg = data[0]
    LAYOUT.STAGE.config.colors.axisGrid = data[1]
    LAYOUT.STAGE.config.show.axisGrid = data[2]
    LAYOUT.AXIS.adsorp = data[3]
    let p = parseFloat(data[4])
    if (p == 0 || !p) p = 0.01
    LAYOUT.AXIS.adsorp_precision = p
    LAYOUT.STAGE.updateAxisShaderUniforms(LAYOUT.AXIS)
  })

  /******************************** 视图功能 ********************************/
  //画板放大
  bus.$on('boardAmplification', data => {
    let e = { deltaY: -1 }
    LAYOUT.mouseWheelListener(e, true)
  })
  //画板缩小
  bus.$on('boardReduction', data => {
    let e = { deltaY: 1 }
    LAYOUT.mouseWheelListener(e, true)
  })
  //画板自适应
  bus.$on('boardAdaptation', data => {
    adaptObj(LAYOUT)
  })
  //自适应
  bus.$on('objectAdaptation', data => {
    adaptObj(LAYOUT)
  })
  //区域放大
  bus.$on('regionalAmplification', data => {
    LAYOUT.switchMode(BOARDMODE.DRAW_ADAPT_RECT)
  })

  /******************************** 吸附设置 ********************************/
  //吸附
  bus.$on('adsorption', data => {
    LAYOUT.AXIS.adsorpPoint = data
  })
  //任意角度吸附
  bus.$on('anyAngle', data => {
    LAYOUT.AXIS.angledAdsorption = false
  })
  //斜角吸附
  bus.$on('angledAdsorption', data => {
    LAYOUT.AXIS.angledAdsorption = true
  })
  //角度锁定
  bus.$on('lockStretch', data => {
    LAYOUT.STATUS.isStretch = true
  })
  //任意拉伸
  bus.$on('anyStretch', data => {
    LAYOUT.STATUS.isStretch = false
  })
  //获取吸附精度
  bus.$on('getPrecision', data => {
    LAYOUT.AXIS.adsorp_precision = parseFloat(data)
  })

  /******************************** 选择状态 ********************************/
  //整体选择
  bus.$on('overallSelection', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.MODE == BOARDMODE.OVER_SEL) {
      return
    }
    LAYOUT.switchMode(BOARDMODE.OVER_SEL)
  })
  //部分选择
  bus.$on('partialSelection', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.MODE == BOARDMODE.PART_SEL) {
      return
    }
    LAYOUT.switchMode(BOARDMODE.PART_SEL)
  })

  /******************************** 绘制对象 ********************************/
  bus.$on('func', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.LAYER_STATE.currentLayerIsHide()) return
    if (data == 'dogleg') {
      LAYOUT.switchMode(BOARDMODE.DRAW_LINE)
    } else if (data == 'circle') {
      LAYOUT.switchMode(BOARDMODE.DRAW_CIRCLE)
    } else if (data == 'rectangle') {
      LAYOUT.switchMode(BOARDMODE.DRAW_RECT)
    } else if (data == 'polygon') {
      LAYOUT.switchMode(BOARDMODE.DRAW_POLYGON)
    }
  })

  //尺子
  bus.$on('ruler', data => {
    if (LAYOUT.STATUS.viewMode) return
    LAYOUT.switchMode(BOARDMODE.DRAW_RULER)
  })

  //标签
  bus.$on('labelInfo', data => {
    if (LAYOUT.STATUS.viewMode) return

    let layer = data.layerNumber
    let direction = DirectionMapCH[data.position]
    let text = data.text
    let fontSize = parseFloat(data.fontSize)
    if (layer == null) return
    LAYOUT.switchMode(BOARDMODE.DRAW_LABEL, { layer, text, fontSize, direction })
  })

  //关键点
  bus.$on('keyPointInfo', data => {
    if (LAYOUT.STATUS.viewMode) return

    let layer = data.layerNumber
    let text = data.keyPointName
    let useLabel = data.isCreate
    let fontSize = data.keyPointSize ? parseFloat(data.keyPointSize) : 30
    //   let input = 'Key Point 1,Key Point 2,Key Point 3'
    //   let params = { layer: 1, text: input, fontSize: 16, direction: 'o', useLabel: true }
    //   this.switchMode(BOARDMODE.DRAW_KP, params)
    if (layer == null) return
    LAYOUT.switchMode(BOARDMODE.DRAW_KP, { layer, text, fontSize, direction: 'o', useLabel })
  })

  //文本图形
  bus.$on('textGraphicsInfo', data => {
    if (LAYOUT.STATUS.viewMode) return
    let text = data.text
    let layer = data.layerNumber //this.getLayerNumByName(data[1])
    let direction = data.position
    let fontSize = parseFloat(data.size)
    let rotation = (parseFloat(data.rotate) * Math.PI) / 180
    if (fontSize <= 0) {
      return
    }
    LAYOUT.switchMode(BOARDMODE.DRAW_TEXT_GRAPHIC, { layer, text, fontSize, direction, rotation })
    // let ref_box = this.drawCharacter(true, txt, layer, direction, font_size, rotation)
    // this.copyCharacterData = QedaGraphics.copy(ref_box, ref_box.position)
    // LAYOUT.switchMode(BOARDMODE.DRAW_TEXT_GRAPHIC, { layer, text, font_size, direction: 's', createLabel })
  })

  /******************************** 图层操作 ********************************/
  //选择新图层
  bus.$on('changeLayerNum', data => {
    if (LAYOUT.STATUS.drawingObj) {
      const obj = LAYOUT.STATUS.drawingObj
      const TYPE = obj.$$?.ptrType.name //obj.constructor.name
      LAYOUT.LAYER_STATE.layer = data.layerNumber
      LAYOUT.LAYER_STATE.layerId = data.id
      if (TYPE == 'GdsFlexpath*') {
        LAYOUT.STATUS.drawingObj.id = [data.id]
        LAYOUT.STATUS.drawingObj.layers = [data.layerNumber]
      } else {
        LAYOUT.STATUS.drawingObj.id = data.id
        LAYOUT.STATUS.drawingObj.layer = data.layerNumber
      }
      updateObjMat(obj, LAYOUT, true)
    }

    // selectLayer(LAYOUT, data)
    // this.stage.currentLayer = data
    // Object.values(this.stage.qedaLib.cells).forEach(cell => {
    //   cell.showHideCell(this.stage.layerDatas)
    // })
  })

  //图层数据修改
  bus.$on('layerChange', data => {
    // layerChange(LAYOUT, data)
    // this.stage.setLayerPaint(data)
    // this.generateFillImg(data.layerNumber)
    // this.stage.currentLayer = data
    // this.showHideLayer()
    // this.fileChange()
  })
  //图层编号修改
  bus.$on('changeLayerNumber', data => {
    changeLayerNumber(LAYOUT, data)

    // this.stage.currentLayer = data.layerData
    // this.stage.setPaints()
    // this.stage.qedaLib.changeLayerNumber([data.oldNumber], data.newNumber)
  })
  //新建
  bus.$on('newLayer', data => {
    // newLayer(LAYOUT, data)
    // this.stage.setPaints()
    // this.stage.setLayerPaint(data)
    // this.generateFillImg(data.layerNumber)
    // this.fileChange()
  })
  //删除图层
  bus.$on('delLayer', data => {
    delLayer(LAYOUT, data)
    // this.deleteLayerData(data)
    // this.updateCellRefBox(this.stage.cell)
    // this.stage.setPaints()
    // this.stage.render()
    // let layerDatas = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas
    // if (!layerDatas?.length) {
    //   this.stage.currentLayer = null
    //   return
    // }
    // this.generateFillImg()
    // this.fileChange()
  })
  //隐藏
  bus.$on('hideClick', data => {
    hideLayer(LAYOUT, data)
    // this.stage.currentLayer = data
    // this.showHideLayer()
    // this.lockLayer()
    // this.fileChange()
  })
  //锁定
  bus.$on('lockClick', data => {
    lockLayer(LAYOUT, data)
    // this.stage.currentLayer = data
    // this.lockLayer()
    // this.fileChange()
  })
  //隐藏全部
  bus.$on('allHide', data => {
    allHide(LAYOUT, data)
    // this.hideAllLayer(data)
    // // this.lockAllLayer(data)
    // this.fileChange()
  })
  //锁定全部
  bus.$on('allLock', data => {
    allLock(LAYOUT, data)
    // this.lockAllLayer(data)
    // this.fileChange()
  })
  //合并图层
  bus.$on('mergeLayer', data => {
    mergeLayer(LAYOUT, data)
    // this.mergeLayers(data[0], data[1])

    // this.stage.setPaints()
    // // this.generateFillImg()
    // this.stage.render()
    // this.fileChange()
  })
  // //批量修改图层
  // bus.$on('batchChangeLayers', data => {
  //   batchChangeLayers(LAYOUT, data)
  //   // //data --> {targets, layerNum} targets修改对象 layerNum修改到的图层
  //   // this.batchChangeLayers(data.targets, data.layerNum)
  // })

  /******************************** 编辑操作 ********************************/
  //剪切
  bus.$on('cut', data => {
    if (LAYOUT.STATUS.viewMode) return
    copyOP(LAYOUT, true)
  })
  //复制
  bus.$on('copy', data => {
    if (LAYOUT.STATUS.viewMode) return
    copyOP(LAYOUT)
  })
  //粘贴
  bus.$on('paste', data => {
    if (LAYOUT.STATUS.viewMode) return
    prePasteData(LAYOUT)
  })
  //撤销
  bus.$on('undo', data => {
    if (LAYOUT.STATUS.viewMode) return
    undo(LAYOUT)
  })
  //恢复
  bus.$on('redo', data => {
    if (LAYOUT.STATUS.viewMode) return
    redo(LAYOUT)
  })
  //移动
  bus.$on('move', data => {
    if (LAYOUT.STATUS.viewMode) return
    LAYOUT.switchMode(BOARDMODE.MOVE)
  })
  //旋转
  bus.$on('rotate', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.STATUS.checked_objs.length) {
      LAYOUT.switchMode(BOARDMODE.ROTATE)
    }
  })
  //拉伸
  bus.$on('stretch', data => {
    if (LAYOUT.STATUS.viewMode) return
    LAYOUT.STATUS.isStretch = false
    LAYOUT.switchMode(BOARDMODE.OVER_SEL)
  })
  //水平翻转
  bus.$on('horizontalFlip', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.MODE == BOARDMODE.PART_SEL) {
      Message.error(`${i18n.t('messages.cellBoardMsg.error.partCheckBanFunc')}`)
      return
    }

    flip(LAYOUT, 'X', parseFloat(data))
  })
  //垂直翻转
  bus.$on('verticalFlip', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.MODE == BOARDMODE.PART_SEL) {
      Message.error(`${i18n.t('messages.cellBoardMsg.error.partCheckBanFunc')}`)
      return
    }

    flip(LAYOUT, 'Y', parseFloat(data))
  })
  //点对齐
  bus.$on('pointAlignment', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (!LAYOUT.STATUS.checked_objs.length) return
    if (LAYOUT.MODE != BOARDMODE.ALIGN_PICK_POINT) {
      LAYOUT.switchMode(BOARDMODE.ALIGN_PICK_POINT)
    }
    LAYOUT.STATUS.pointOffset = data.map(d => parseFloat(d))
    return
  })
  //线对齐
  bus.$on('lineAlignment', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (!LAYOUT.STATUS.checked_objs.length) return
    if (LAYOUT.MODE != BOARDMODE.ALIGN_PICK_LINE) {
      LAYOUT.switchMode(BOARDMODE.ALIGN_PICK_LINE)
    }
    LAYOUT.STATUS.pointOffset = data.map(d => parseFloat(d))
  })

  //阵列
  bus.$on('matrix', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.MODE == BOARDMODE.PART_SEL) {
      Message.error(`${i18n.t('messages.cellBoardMsg.error.partCheckBanFunc')}`)
      return
    }

    bus.$emit('loading', true)
    arrayObjs(LAYOUT, data)
    bus.$emit('ending', true)
  })

  //对齐
  bus.$on('alignment', data => {
    if (LAYOUT.STATUS.viewMode) return

    alignmentObjs(LAYOUT, data[0])
  })
  //切割
  bus.$on('cutting', data => {
    if (LAYOUT.STATUS.viewMode) return

    LAYOUT.switchMode(BOARDMODE.CUT_X)
  })
  //区域切除
  bus.$on('areaExcision', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (!LAYOUT.STATUS.checked_objs.length) return
    LAYOUT.switchMode(BOARDMODE.CUT_AREA)
  })
  //合并
  bus.$on('merge', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.MODE == BOARDMODE.PART_SEL) {
      Message.error(`${i18n.t('messages.cellBoardMsg.error.partCheckBanFunc')}`)
      return
    }
    mergePolygons(LAYOUT)
  })
  //布尔运算
  bus.$on('boolean', data => {
    if (LAYOUT.STATUS.viewMode) return
    if (LAYOUT.MODE == BOARDMODE.PART_SEL) {
      Message.error(`${i18n.t('messages.cellBoardMsg.error.partCheckBanFunc')}`)
      return
    }
    // data = [1, 'not', '2']
    boolCheckedObjs(LAYOUT, data)
  })
  //打撒
  bus.$on('breakUp', data => {
    if (LAYOUT.STATUS.viewMode) return

    breakRef(LAYOUT, data)
  })
  //添加自身文件的器件
  bus.$on('addSelfDevice', data => {
    if (LAYOUT.STATUS.viewMode) return
    addDevice(LAYOUT, data, false)
  })
  //添加自身文件的器件
  bus.$on('addNewDevice', data => {
    if (LAYOUT.STATUS.viewMode) return
    addDevice(LAYOUT, data, true)
  })
  //进入下层
  bus.$on('enterlowerLevel', data => {
    if (LAYOUT.STATUS.viewMode) return
    enterlowerLevel(LAYOUT)
  })
  //返回上层
  bus.$on('backUpperLevel', data => {
    if (LAYOUT.STATUS.viewMode) return
    backUpperLevel(LAYOUT)
  })

  //返回最上层
  bus.$on('backTop', data => {
    if (LAYOUT.STATUS.viewMode) return
    backUpperLevel(LAYOUT, true)
  })

  //编辑下层
  bus.$on('editLowerLevel', data => {
    if (LAYOUT.STATUS.viewMode) return
    editLowerLevel(LAYOUT)
  })

  //另存为单元
  bus.$on('saveAsCell', data => {
    if (LAYOUT.STATUS.viewMode) return

    bus.$emit('getBoxData', saveAsCell(LAYOUT, data[1]))
  })

  /******************************** 版图3D ********************************/
  //3D剖面区域
  bus.$on('draw3DCutBox', data => {
    if (LAYOUT.STATUS.viewMode) return
    LAYOUT.switchMode(BOARDMODE.DRAW_3D_CUT_AREA)
  })
  //3D区域
  bus.$on('draw3DArea', data => {
    if (LAYOUT.STATUS.viewMode) return
    LAYOUT.switchMode(BOARDMODE.DRAW_3D_RENDER_AREA)
  })
  //生成3D模型
  bus.$on('3DCellView', data => {
    route3DView(LAYOUT)
  })

  // 按图层拆分版图
  bus.$on('TOOL_SPLIT_CELL_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    // tool.loadKernelCell(LAYOUT.RENDER_CELL)
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.gdsSplit(data).then(res => {
      bus.$emit('toolSplitCell', res)
      // Message.success('版图拆分成功~')
      Message.success(`${i18n.t('messages.layoutSplitSuccess')}~`)
    })
    // this.autotool
    //   .gdsSplit(data)
    //   .then(res => {
    //     // Message.success('版图拆分成功~')
    //     bus.$emit('splitCell', res)
    //   })
    //   .catch(err => {
    //     Message.error(`${i18n.t('messages.layoutSplitSuccess')}:${err}~`)
    //   })
  })

  // 共面波导
  bus.$on('TOOL_WAVEGUIDE_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.waveGuide(data).then(res => {
      if (res) {
        const history = {
          func: 'TOOL_WAVEGUIDE_RUN',
          tool: 'TOOL_WAVEGUIDE_RUN',
          actions: [
            { action: 'delete', cell: LAYOUT.CELL, objs: res.remove_paths },
            { action: 'add', cell: LAYOUT.CELL, objs: res.add_paths },
          ],
        }
        LAYOUT.HISTORY.record(history)
        LAYOUT.sendMessage(history)
        LAYOUT.deleteObj(LAYOUT.CELL, res.remove_paths)
        res.add_paths.forEach(element => {
          LAYOUT.addGdsObj(element)
        })
      }
      LAYOUT.reRender()
      bus.$emit('updateCellLayerList', null)
      Message.success(`${i18n.t('messages.cwGeneratedSuccess')}`)
    })

    // this.autotool
    //   .waveGuide(data)
    //   .then(res => {
    //     let addObjs = this.stage.cell.addGdsData(res.add_polygons)
    //     let deleteObjs = this.stage.cell.removeGdsData(res.remove_paths)
    //     let action1 = { action: 'add', cellName: this.stage.cell.name, objs: addObjs }
    //     let action2 = { action: 'delete', cellName: this.stage.cell.name, objs: deleteObjs }
    //     this.stage.editHistory.record([action1, action2])
    //     this.compareFileLayers(this.stage.qedaLib.QLIB.cells)
    //     this.stage.render()
    //     Message.success(`${i18n.t('messages.cwGeneratedSuccess')}`)
    //   })
    //   .catch(err => {
    //     Message.error(`${i18n.t('messages.cwdGeneratedFaile')}:${err}~`)
    //   })
  })
  // WBPad
  bus.$on('TOOL_WBPAD_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    // tool.loadKernelCell(LAYOUT.RENDER_CELL)
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.wbpad(data).then(res => {
      const addCell = res.add_cells[0]
      const needAddCells = []
      for (let i = 0; i < res.add_cells.length; i++) {
        const newCell = res.add_cells[i]
        currentDrawBoard_deepCopy_remane_cell(newCell, false, false)
        needAddCells.push(newCell)
      }

      const addRefs = res.add_references
      updateRefsName(addRefs)
      for (let i = 0; i < res.add_texts.length; i++) {
        let text = res.add_texts[i]
        let layerId = LAYOUT.getLayerIdByNum(text.layer)
        let ref = initCharacterRef(`${text.text}`, text.layer, text.anchor, text.fontSize, (text.rotation * Math.PI) / 180, layerId)
        ref.origin = text.origin
        res.add_cells.push(ref.cell)
        res.add_references.push(ref)
        // ref.cell.name = generateUniqueCellName(ref.cell.name)
        needAddCells.push(ref.cell)
      }
      bus.$emit('updateFileTree', needAddCells)
      if (addCell && addRefs.length) {
        const history = {
          func: 'ADD_DEVICE',
          tool: 'TOOL_AIRBRIDGE',
          actions: [
            { action: 'add', cell: LAYOUT.CELL, objs: addRefs },
            { action: 'add', cell: LAYOUT.CELL, objs: res.add_paths },
            { action: 'delete', cell: LAYOUT.CELL, objs: res.remove_paths },
          ],
        }
        LAYOUT.HISTORY.record(history)
        // LAYOUT.sendMessage(history)

        // let cellRefsMap = {} //对引用根据器件类型进行分组
        // addRefs.forEach(ref => {
        //   if (cellRefsMap[ref.cell.name]) {
        //     cellRefsMap[ref.cell.name].push(ref)
        //   } else {
        //     cellRefsMap[ref.cell.name] = [ref]
        //   }
        // })

        // for (const cell in cellRefsMap) {
        //   //按器件类别添加
        //   addRefToLayout(LAYOUT, cellRefsMap[cell])
        // }

        addRefs.forEach(ref => {
          LAYOUT.addGdsObj(ref)
        })
        res.add_paths.forEach(element => {
          LAYOUT.addGdsObj(element)
        })
        LAYOUT.deleteObj(LAYOUT.CELL, res.remove_paths)

        // ReBuildCellRefs(LAYOUT)
        // LAYOUT.enterCell(LAYOUT.RENDER_CELL)
        LAYOUT.updateByMessageServe() //重渲染
      }
      bus.$emit('updateCellLayerList', null)

      bus.$emit('TOOL_WBPAD_LOG', { numbers: res.add_references.length })
      Message.success(`${i18n.t('messages.wbPadPlacedSuccess')}`)
    })
    // .catch(err => {
    //   Message.error(`${i18n.t('messages.wbPadPlacedFaile')}:${err}~`)
    // })

    // this.autotool.loadKernel(LAYOUT.RENDER_CELL)
    // this.autotool.wbpad(data).then(res => {

    //   for (let i = 0; i < res.add_texts.length; i++) {
    //     let text = res.add_texts[i]
    //     let cell = this.drawCharacter(false, `${text.text}`, text.layer, text.anchor, text.fontSize, text.rotation)
    //     let ref = new QGdstk.Reference(cell, text.origin, (text.rotation * Math.PI) / 180, 1, false, 1, 1, null)
    //     res.add_cells.push(cell)
    //     res.add_references.push(ref)
    //   }
    //   res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
    //   this.compareFileLayers(res.add_cells)
    //   bus.$emit(
    //     'updateFileTree',
    //     res.add_cells.map(cell => cell.name)
    //   )
    //   let deleteObjs = this.stage.cell.removeGdsData(res.remove_paths)
    //   let addObjs = this.stage.cell.addGdsData(res.add_paths)
    //   let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
    //   let action1 = { action: 'addCell', objs: [...res.add_cells] }
    //   let action3 = { action: 'delete', cellName: this.stage.cell.name, objs: deleteObjs }
    //   let action2 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs, ...addObjs] }
    //   this.stage.editHistory.record([action1, action2, action3])
    //   this.stage.render()
    //   bus.$emit('TOOL_WBPAD_LOG', { numbers: res.add_references.length })
    //   Message.success(`${i18n.t('messages.wbPadPlacedSuccess')}`)
    // })
    // .catch(err => {
    //   Message.error(`${i18n.t('messages.wbPadPlacedFaile')}:${err}~`)
    // })
  })
  // InLinePad
  bus.$on('TOOL_INLINEPAD_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.inlinepad(data).then(res => {
      const addCell = res.add_cells[0]
      for (let i = 0; i < res.add_cells.length; i++) {
        const newCell = res.add_cells[i]
        currentDrawBoard_deepCopy_remane_cell(newCell, false, false)
      }
      const addRefs = res.add_references
      updateRefsName(addRefs)

      bus.$emit('updateFileTree', res.add_cells)
      if (addCell && addRefs.length) {
        const history = {
          func: 'TOOL_INLINEPAD_RUN',
          tool: 'TOOL_INLINEPAD',
          actions: [
            { action: 'add', cell: LAYOUT.CELL, objs: addRefs },
            { action: 'add', cell: LAYOUT.CELL, objs: res.add_paths },
            { action: 'delete', cell: LAYOUT.CELL, objs: res.remove_paths },
          ],
        }
        LAYOUT.HISTORY.record(history)
        LAYOUT.sendMessage(history)
        let cellRefsMap = {} //对引用根据器件类型进行分组
        addRefs.forEach(ref => {
          if (cellRefsMap[ref.cell.name]) {
            cellRefsMap[ref.cell.name].push(ref)
          } else {
            cellRefsMap[ref.cell.name] = [ref]
          }
        })

        for (const cell in cellRefsMap) {
          //按器件类别添加
          addRefToLayout(LAYOUT, cellRefsMap[cell])
        }

        res.add_paths.forEach(element => {
          LAYOUT.addGdsObj(element)
        })
        LAYOUT.deleteObj(LAYOUT.CELL, res.remove_paths)
      }
      bus.$emit('updateCellLayerList', null)
      bus.$emit('TOOL_INLINEPAD_LOG', { numbers: res.add_references.length })
      bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
      Message.success(`${i18n.t('messages.inLinePadPlacedSuccess')}`)
    })
    // .catch(err => {
    //   Message.error(`${i18n.t('messages.inLinePadPlacedFaile')}:${err}~`)
    // })

    // this.autotool
    //   .inlinepad(data)
    //   .then(res => {
    //     res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
    //     this.compareFileLayers(res.add_cells)
    //     bus.$emit(
    //       'updateFileTree',
    //       res.add_cells.map(cell => cell.name)
    //     )
    //     let deleteObjs = this.stage.cell.removeGdsData(res.remove_paths)
    //     let addObjs = this.stage.cell.addGdsData(res.add_paths)
    //     let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
    //     let action1 = { action: 'addCell', objs: [...res.add_cells] }
    //     let action2 = { action: 'delete', cellName: this.stage.cell.name, objs: deleteObjs }
    //     let action3 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs, ...addObjs] }
    //     this.stage.editHistory.record([action1, action2, action3])
    //     this.stage.render()
    //     bus.$emit('TOOL_INLINEPAD_LOG', { numbers: res.add_references.length })
    //     Message.success(`${i18n.t('messages.inLinePadPlacedSuccess')}`)
    //   })
    //   .catch(err => {
    //     Message.error(`${i18n.t('messages.inLinePadPlacedFaile')}:${err}~`)
    //   })
  })
  // AirBridge
  bus.$on('TOOL_AIRBRIDGE_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.airBridge(data).then(res => {
      const addCell = res.add_cells[0]
      currentDrawBoard_deepCopy_remane_cell(addCell, false, false)
      const addRefs = res.add_references
      updateRefsName(addRefs)
      if (addCell && addRefs.length) {
        bus.$emit('updateFileTree', addCell)
        const history = {
          func: 'ADD_DEVICE',
          tool: 'TOOL_AIRBRIDGE',
          actions: [{ action: 'add', cell: LAYOUT.CELL, objs: addRefs }],
        }
        LAYOUT.HISTORY.record(history)
        addRefToLayout(LAYOUT, addRefs)
        bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
      }
      bus.$emit('updateCellLayerList', null)
      bus.$emit('TOOL_AIRBRIDGE_LOG', { numbers: res.add_references.length })
      Message.success(`${i18n.t('messages.airBridgePlacedSuccess')}`)
    })
    // .catch(err => {
    //   Message.error(`${i18n.t('messages.airBridgePlacedFaile')}:${err}~`)
    // })
  })
  // TiNPad&InPillar
  bus.$on('TOOL_TINPAD_INPILLAR_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    // tool.loadKernelCell(LAYOUT.RENDER_CELL)
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.tinpad(data).then(res => {
      const addCell = res.add_cells[0]
      currentDrawBoard_deepCopy_remane_cell(addCell, false, false)
      const addRefs = res.add_references
      updateRefsName(addRefs)
      if (addCell && addRefs.length) {
        bus.$emit('updateFileTree', addCell)
        const history = {
          func: 'ADD_DEVICE',
          tool: 'TOOL_TINPAD_INPILLAR_RUN',
          actions: [{ action: 'add', cell: LAYOUT.CELL, objs: addRefs }],
        }
        LAYOUT.HISTORY.record(history)
        addRefToLayout(LAYOUT, addRefs)
        bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
      }
      bus.$emit('updateCellLayerList', null)
      bus.$emit('TOOL_TINPAD_INPILLAR_LOG', res.log)
      Message.success(`${i18n.t('messages.tpPlacedSuccess')}`)
    })
    // .catch(err => {
    //   Message.error(`${i18n.t('messages.tpPlacedFaile')}:${err}~`)
    // })
  })

  // Squid Zline
  bus.$on('TOOL_SQUID_ZLINE_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let res = data

    const addCell = res.add_cells[0]
    res.add_cells.forEach(element => {
      currentDrawBoard_deepCopy_remane_cell(element, false, false)
    })
    const addRefs = res.add_references
    updateRefsName(addRefs)

    if (addCell && addRefs.length) {
      function nextStep() {
        const history = {
          func: 'TOOL_SQUID_ZLINE_RUN',
          tool: 'TOOL_SQUID_ZLINE_RUN',
          actions: [{ action: 'add', cell: LAYOUT.CELL, objs: addRefs }],
        }
        LAYOUT.HISTORY.record(history)
        LAYOUT.sendMessage(history)
        addRefToLayout(LAYOUT, addRefs)
        bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
      }
      bus.$emit('updateFileTree', { cell: addCell, nextStep })
    }
    bus.$emit('updateCellLayerList', null)
    Message.success(`${i18n.t('messages.squidZPlacedSuccess')}`)
  })

  //进入TOOL_SQUID_ZLINE选择线模式
  bus.$on('TOOL_SQUID_ZLINE', data => {
    if (LAYOUT.STATUS.viewMode) return
    // this.stage.checkMode_bk = this.stage.checkMode
    LAYOUT.switchMode(BOARDMODE.Z_LINE)
    bus.$emit('updateCellLayerList', null)
    Message.success(`${i18n.t('messages.plsSelectSquidEdge')}`)
  })
  // Squid Zline
  bus.$on('TOOL_CAVITY_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let res = data
    if (res) {
      const addCell = res.add_cells[0]
      currentDrawBoard_deepCopy_remane_cell(addCell, false, false)
      const addRefs = res.add_references
      updateRefsName(addRefs)
      if (addCell && addRefs.length) {
        // bus.$emit('updateFileTree', addCell)
        // const history = {
        //   func: 'ADD_DEVICE',
        //   tool: 'TOOL_CAVITY_RUN',
        //   actions: [{ action: 'add', cell: LAYOUT.CELL, objs: addRefs }],
        // }
        // LAYOUT.HISTORY.record(history)
        // // LAYOUT.sendMessage(history)
        // addRefToLayout(LAYOUT, addRefs)
        function nextStep() {
          const history = {
            func: 'TOOL_CAVITY_RUN',
            tool: 'TOOL_CAVITY_RUN',
            actions: [{ action: 'add', cell: LAYOUT.CELL, objs: addRefs }],
          }
          LAYOUT.HISTORY.record(history)
          LAYOUT.sendMessage(history)
          addRefToLayout(LAYOUT, addRefs)
          bus.$emit('refreshCellRef', LAYOUT.CELL.snow_id)
        }
        bus.$emit('updateFileTree', { cell: addCell, nextStep })
      }
      bus.$emit('updateCellLayerList', null)
    }
  })

  // Check Line
  bus.$on('TOOL_CHECK_LINE_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.checkLine(data).then(res => {
      bus.$emit('TOOL_CHECK_LINE_LOG', res)
      bus.$emit('updateCellLayerList', null)
      Message.success(`${i18n.t('messages.testLineSuccess')}`)
    })
  })

  // Connect Line
  bus.$on('TOOL_CONNECT_LINE_RUN', data => {
    if (LAYOUT.STATUS.viewMode) return
    let tool = new AutoTool()
    tool.loadCell(LAYOUT.RENDER_CELL)
    tool.connectLine(data).then(res => {
      if (res) {
        const history = {
          func: 'CONNECT_LINE',
          tool: 'CONNECT_LINE',
          actions: [
            { action: 'delete', cell: LAYOUT.CELL, objs: res.remove_paths },
            { action: 'add', cell: LAYOUT.CELL, objs: res.add_paths },
          ],
        }
        LAYOUT.HISTORY.record(history)
        LAYOUT.sendMessage(history)

        LAYOUT.deleteObj(LAYOUT.CELL, res.remove_paths)
        res.add_paths.forEach(element => {
          LAYOUT.addGdsObj(element)
        })
      }
      bus.$emit('updateCellLayerList', null)
      Message.success(`${i18n.t('messages.theConnectionLineSuccess')}`)
    })
    //   .catch(err => {
    //     console.error(err)
    //     Message.error(`${i18n.t('messages.theConnectionLineFailed')}:${err}~`)
    //   })
  })

  //触发谐振腔功能
  bus.$on('TOOL_CAVITY', data => {
    if (LAYOUT.STATUS.viewMode) return
    let checkedObjs = LAYOUT.STATUS.checked_objs
    if (checkedObjs.length === 3) {
      let onePolygon = checkedObjs.filter(obj => {
        const T = obj.$$?.ptrType.name
        return T === 'GdsPolygon*' || T === 'GdsRectangle*' || T === 'GdsEllipse*'
      })
      let towKeyPoints = checkedObjs.filter(obj => {
        const T = obj.$$?.ptrType.name
        return T === 'GdsKeyPoint*'
      })

      if (onePolygon?.length === 1 && towKeyPoints?.length === 2) {
        let points = []
        towKeyPoints.forEach(kp => {
          points.push(kp.origin)
        })
        let polygon = onePolygon[0].points
        let result = { polygon: polygon, points: points }
        bus.$emit('TOOL_CAVITY_SELECT', result)
      } else {
        Message.warning(`${i18n.t('messages.plsSelect1P2K')}`)
      }
    } else {
      Message.warning(`${i18n.t('messages.plsSelect1P2K')}`)
    }
  })

  // 晶圆布局
  bus.$on('TOOL_WAFER_LAYOUT_RUN', res => {
    if (LAYOUT.STATUS.viewMode) return

    res.add_cells.forEach(element => {
      currentDrawBoard_deepCopy_remane_cell(element, false, false)
    })
    bus.$emit('updateFileTree', res.add_cells)
    Message.success(`${i18n.t('messages.toolWaferPlaceSuccess')}`)
  })

  //drc检测图形
  bus.$on('drcClicked', data => {
    let aabb = null
    let temp
    // let data = {
    //   type: 'polygons',
    //   // data: [100, 100],
    //   data: [
    //     [0, 0],
    //     [0, 100],
    //     [100, 100],
    //     [100, 0],
    //     [0, 0],
    //   ],
    // }
    if (!data) return
    let matLine = LAYOUT.CONFIG.toolsMat.drcLineMat
    if (data.type == 'polygons') {
      data.graphics = [pathGenerate(data.data, matLine)]
      temp = new QGdstk.Polygon(data.data)
    } else if (data.type == 'edge_pairs') {
      data.graphics = [pathGenerate(data.data[0], matLine), pathGenerate(data.data[1], matLine)]
      temp = new QGdstk.Polygon([...data.data[0], ...data.data[1]])
    } else if (data.type == 'edges') {
      data.graphics = [pathGenerate(data.data, matLine)]
      temp = new QGdstk.Polygon([...data.data])
    } else if (data.type == 'points') {
      let box = boxGenerate(data.data, matLine)
      data.graphics = [box.line]
      temp = new QGdstk.Polygon(box.points)
    }
    aabb = temp?.bounding_box()

    if (aabb) {
      adaptScreen(LAYOUT, aabb)
    }

    LAYOUT.STAGE.updateDrcGraphics(data.graphics)
  })

  //刷新画板
  bus.$on('reRenderReplaceBoard', data => {
    LAYOUT.updateByMessageServe()
  })

  //下层调用器件重新渲染
  bus.$on('notSaveCellReRender', data => {
    const cells = LAYOUT.RENDER_CELL.depend_cells(-1) //可能新调用的cell没有重新构建图形数据
    //画板存在进入下层状态
    if (LAYOUT.CellHistory.length) {
      cells.push(LAYOUT.RENDER_CELL)
    }
    for (let i = 0; i < cells.length; i++) {
      const cell = cells[i]
      if (cell.snow_id == data) {
        LAYOUT.updateByMessageServe()
      }
    }
  })

  bus.$on('TOOL_JJ_SELECT_POS', data => {
    if (LAYOUT.STATUS.viewMode) return
    LAYOUT.switchMode(BOARDMODE.JJSELECT)
  })

  //下层调用器件重新渲染
  bus.$on('notSaveCellReRender', data => {
    const cells = LAYOUT.RENDER_CELL.depend_cells(-1) //可能新调用的cell没有重新构建图形数据
    //画板存在进入下层状态
    if (LAYOUT.CellHistory.length) {
      cells.push(LAYOUT.RENDER_CELL)
    }
    for (let i = 0; i < cells.length; i++) {
      const cell = cells[i]
      if (cell.snow_id == data) {
        LAYOUT.updateByMessageServe()
      }
    }
  })
}

export function boxGenerate(point, matLine) {
  let p1, p2, p3, p4
  let w = 0.5
  p1 = [point[0] - w, point[1] - w]
  p2 = [point[0] - w, point[1] + w]
  p3 = [point[0] + w, point[1] + w]
  p4 = [point[0] + w, point[1] - w]
  let points = [p1, p2, p3, p4, p1]
  return { points, line: pathGenerate(points, matLine) }
}
export function pathGenerate(points, matLine) {
  let positions = []
  for (let i = 0; i < points.length; i++) {
    let p = points[i]
    positions.push(p[0], p[1], -1)
  }
  const geometry = new LineGeometry()
  geometry.setPositions(positions)
  matLine.resolution.set(window.innerWidth, window.innerHeight)
  let line = new Line2(geometry, matLine)
  line.computeLineDistances()
  line.scale.set(1, 1, 1)
  return line
}
//取消监听
export function removeEventListeners(LAYOUT, bus) {
  /******************************** 键鼠事件 ********************************/
  document.removeEventListener('keydown', LAYOUT.keyBoardListener)
  document.removeEventListener('keyup', LAYOUT.keyUpBoardListener)
  LAYOUT.STAGE.renderer.domElement.removeEventListener('mousemove', LAYOUT.mouseMoveListener)
  // LAYOUT.STAGE.renderer.domElement.removeEventListener('mouseup', LAYOUT.mouseUpListener)
  LAYOUT.STAGE.renderer.domElement.removeEventListener('dblclick', LAYOUT.dbClickListener)
  document.body.removeEventListener('mouseup', LAYOUT.mouseUpListener)
  LAYOUT.STAGE.renderer.domElement.removeEventListener('mousedown', LAYOUT.mouseDownListener)
  LAYOUT.STAGE.renderer.domElement.removeEventListener('mousewheel', LAYOUT.mouseWheelListener)
  bus.$off('resizeLayer')
  bus.$off('boardOption')
  bus.$off('boardDesign')
  bus.$off('boardAmplification')
  bus.$off('boardReduction')

  bus.$off('boardAdaptation')
  bus.$off('objectAdaptation')
  bus.$off('regionalAmplification')
  bus.$off('adsorption')
  bus.$off('anyAngle')
  bus.$off('angledAdsorption')
  bus.$off('lockStretch')
  bus.$off('anyStretch')
  bus.$off('getPrecision')
  bus.$off('overallSelection')
  bus.$off('partialSelection')
  bus.$off('func')
  bus.$off('ruler')
  bus.$off('labelInfo')
  bus.$off('keyPointInfo')
  bus.$off('textGraphicsInfo')
  bus.$off('changeLayerNum')
  bus.$off('layerChange')
  bus.$off('changeLayerNumber')
  bus.$off('newLayer')
  bus.$off('delLayer')
  bus.$off('hideClick')
  bus.$off('lockClick')
  bus.$off('allHide')
  bus.$off('allLock')
  bus.$off('mergeLayer')

  // bus.$off('batchChangeLayers')
  bus.$off('cut')
  bus.$off('copy')
  bus.$off('paste')
  bus.$off('undo')
  bus.$off('redo')
  bus.$off('move')
  bus.$off('rotate')
  bus.$off('stretch')
  bus.$off('horizontalFlip')
  bus.$off('verticalFlip')
  bus.$off('pointAlignment')
  bus.$off('lineAlignment')
  bus.$off('matrix')
  bus.$off('alignment')
  bus.$off('cutting')
  bus.$off('areaExcision')
  bus.$off('merge')
  bus.$off('boolean')
  bus.$off('breakUp')
  bus.$off('addSelfDevice')
  bus.$off('addNewDevice')
  bus.$off('enterlowerLevel')
  bus.$off('editLowerLevel')
  bus.$off('saveAsCell')
  bus.$off('backUpperLevel')
  bus.$off('backTop')
  bus.$off('draw3DCutBox')
  bus.$off('draw3DArea')
  bus.$off('3DCellView')

  bus.$off('TOOL_SPLIT_CELL_RUN')
  bus.$off('TOOL_WAVEGUIDE_RUN')
  bus.$off('TOOL_WBPAD_RUN')
  bus.$off('TOOL_INLINEPAD_RUN')
  bus.$off('TOOL_AIRBRIDGE_RUN')
  bus.$off('TOOL_TINPAD_INPILLAR_RUN')
  bus.$off('TOOL_SQUID_ZLINE')
  bus.$off('TOOL_SQUID_ZLINE_RUN')
  bus.$off('TOOL_CAVITY_RUN')
  bus.$off('TOOL_CHECK_LINE_RUN')
  bus.$off('TOOL_CONNECT_LINE_RUN')
  bus.$off('TOOL_CAVITY')
  bus.$off('TOOL_WAFER_LAYOUT_RUN')
  bus.$off('drcClicked')
  bus.$off('reRenderReplaceBoard')
  bus.$off('TOOL_JJ_SELECT_POS')
  bus.$off('notSaveCellReRender')
}
