import {
  lockCell_api, //锁文件
  selectLockCell_api, //判断被锁
  unLockCell_api,
} from '@/api/file/file.js'
import { QedaCellStage } from './qeda-cell-stage'
import QedaCellAxis from './axis/qeda-cell-axis'
import { addEventListener, removeEventListeners } from './event/layout-events'
import { KEY_DOWN, KEY_UP, MOUSE_MOVE, MOUSE_DOWN, MOUSE_UP, DBCLICK, MOUSE_WHEEL, RESIZE, completeDraw } from './event/layout-control'
import { BOARDMODE, BOARDMODEMAP, SPECIALGRAPHIC, Status } from './const/board-status'
import { CellDisplayConfig, getCellLayers } from './utils/render-util'
import { generateLabelMesh } from './utils/font-util'
import { getAdsorpPoint } from './utils/adsorption'
import {
  initPath,
  initRect,
  initPolygon,
  Ruler,
  getBoundingBox,
  initEllipse,
  initLabel,
  initKeyPoint,
  updateObjMat,
  updateMouseOffSet,
  updateNowAngle,
  reBuildGdsData,
  buildLib,
  updateCellInsRefs,
  initCharacterRef,
  updateLineInsAttr,
  updateFillAttr,
  disposeGraphics,
  updatePointInsAttr,
  updateGDSGraphic,
  initCellInstanceMap,
  buildCellData,
  buildCell,
  rebuildCellQuadTree,
  clearCellExtData,
  mouseInsideCanvas,
  forceBuildCell,
} from './utils/graphics-util'
import {
  adaptScreen,
  selectObjByClick,
  selectObjsByBox,
  selectObjPartByClick,
  selectObjsjPartByBox,
  selectAlignPointByClick,
  selectAlignLineByClick,
  updatePreCheckObj,
  updatePartPreCheckObj,
  updatePreCheckAlignPoint,
  updatePreCheckAlignLine,
  updatePrePasteData,
  confirmPaste,
  moveObj,
  recordHistory,
  recordObjsProperty,
  cutObj,
  cutAreaObjs,
  undo,
  adaptObj,
  removeCheckedObjs,
  addDevice,
} from './utils/layout-function'
import { applyLayerIdToCell, getLayerIdByNum, LayerState } from './utils/layer-util'
import bus from '@/components/common/bus'
import { UndoRedo } from './redo_undo/redo-undo'
import { CellQuadTree, checkInsideCheckedObjs } from './utils/quad-tree'
import { isDrawMode } from './validator/validator'
import { addRefToCell, addRefToLayout, ReBuildCellRefs, transOutRef, transToRef } from './utils/reference-util'
import { addCellMessageServe, transAddMsg, transDelAddMsg, transMoveMsg, transPropertyMsg, transTransformMsg } from '../message_serve/cell-message'
import { getFileLayerListFromTable } from '../../components/homes/rightList/layerListFn'
import store from '@/store/index'
import { currentDrawBoard_deepCopy_remane_cell } from '../../components/homes/fileList/function/fileListPublicFn'
import { Message } from 'element-ui'
import { getPermission } from '../../components/homes/fileList/function/fileListApiFn'
export class QedaCellLayout {
  constructor(topInfo, proInfo, fileInfo, cellInfo, CELL, LayerState) {
    this.topInfo = topInfo
    this.proInfo = proInfo
    this.fileInfo = fileInfo
    this.cellInfo = cellInfo

    this.file_snow_id = fileInfo.fileId
    //容器
    this.containerId = fileInfo.fileId + cellInfo.cellId
    //画布
    this.canvasId = this.containerId + '_canvas'
    // this.canvasId = 'cell_canvas'
    this.canvasRulerX
    this.canvasRulerY
    this.MODE = BOARDMODE.OVER_SEL //画板模式
    this.STATUS = new Status() //状态容器
    this.STATUS.viewMode = this.cellInfo.isReadOnly //只读模式
    this.CONFIG = new CellDisplayConfig() //显示设置
    this.STAGE = new QedaCellStage(this.canvasId, this.CONFIG) //渲染系统
    this.canvas = this.STAGE.renderer.domElement
    this.CellHistory = [] //器件层级编辑记录

    this.LAYER_STATE = LayerState
    if (this.LAYER_STATE) {
      this.LAYER_STATE.updateCanvas(this.canvas)
    }
    // this.LAYER_STATE = new LayerState(this.CONFIG, this.canvas) //图层数据管理
    // this.LAYER_STATE.updateLayerMats([
    //   { layerNum: 0, border: 'B1', fill: 'F1' },
    //   { layerNum: 1, border: 'B1', fill: 'F1' },
    //   { layerNum: 2, border: 'B2', fill: 'F2' },
    // ])
    // this.LAYER_STATE.updateLayerMats([
    //   { layerNum: 1, border: 'B1', fill: 'F1' },
    //   { layerNum: 2, border: 'B2', fill: 'F2' },
    // ])
    this.AXIS = new QedaCellAxis(800, 800, 1.0e-6, 1.0e-9) //坐标系统
    this.LIB = new Kernel.Library('lib') //整个版图文件
    this.TOP_CELL = CELL //顶层器件
    this.RENDER_CELL = CELL //当前渲染的器件
    this.EDIT_REF = null //当前编辑下层的引用
    this.CELL = CELL //编辑的器件
    clearCellExtData(this.CELL)
    // addCellMessageServe(this.topInfo, this.proInfo, this.fileInfo, this.cellInfo, this.CELL)
    // this.LIB.add_cell(this.CELL)
    this.CELL.depend_cells(-1).forEach(childCell => {
      this.LIB.add_cell(childCell)
      // addCellMessageServe(this.topInfo, this.proInfo, this.fileInfo, this.cellInfo, childCell)
    })
    //初始化Lib文件
    forceBuildCell(this, this.CELL)
    buildLib(this, this.LIB)
    this.HISTORY = this.CELL.js_obj.HISTORY //撤销恢复
    let that = this
    this.HISTORY.onChange = function () {
      if (that.STATUS.active) {
        bus.$emit('fileChange', that.fileInfo.fileId)
        that.CELL.js_obj.isSave = false
      }
    }
    this.QUAD_TREE = this.CELL.js_obj.QUAD_TREE //当前编辑器件的四叉树
    this.STAGE.reRenderCell(this.CELL, this.LAYER_STATE)
    this.STAGE.updateGlobalKP(this)
    this.initAxis()
    this.setRulerCanvas()
    this.resize = RESIZE.bind(this)
    this.keyBoardListener = KEY_DOWN.bind(this)
    this.keyUpBoardListener = KEY_UP.bind(this)
    this.mouseMoveListener = MOUSE_MOVE.bind(this)
    this.mouseDownListener = MOUSE_DOWN.bind(this)
    this.mouseUpListener = MOUSE_UP.bind(this)
    this.dbClickListener = DBCLICK.bind(this)
    this.mouseWheelListener = MOUSE_WHEEL.bind(this)
    addEventListener(this, bus)
    adaptObj(this)

    this.setToolState()
    bus.$emit('ending', true)
    // setTimeout(() => {
    bus.$emit('cellHistoryUpdate', { cell: this.RENDER_CELL, active: true })
    // }, 1000)

    if (this.fileInfo.teamRule !== undefined && !this.cellInfo.isReadOnly) {
      // alert(123)
      if (!this.lockFileTimer) {
        let that = this
        this.lockFileTimer = setInterval(() => {
          that.lockFile(that.CELL.snow_id)
        }, 30000) //每60秒一次
        this.lockFile(that.CELL.snow_id)
      }
    }
  }
  // 初始化坐标系统，舞台
  initAxis() {
    this.AXIS.rulerX.height = 22
    this.AXIS.rulerY.width = 22
    var canvas = document.getElementById('canvas_container')
    var stage = document.getElementById('stage-container')

    this.AXIS.width = stage.offsetWidth - 22
    this.AXIS.height = stage.offsetHeight - 22
    this.AXIS.dx = this.AXIS.width / 2
    this.AXIS.dy = this.AXIS.height / 2
    this.AXIS.rulerX.width = this.AXIS.width
    this.AXIS.rulerY.height = this.AXIS.height

    this.AXIS.rulerX.height = 22
    this.AXIS.rulerY.width = 22
    this.AXIS.render()
    canvas.width = this.AXIS.width
    canvas.height = this.AXIS.height
  }

  setRulerCanvas() {
    this.canvasRulerX = document.getElementById(this.containerId + 'ruler-x')
    this.canvasRulerY = document.getElementById(this.containerId + 'ruler-y')

    this.STAGE.renderRuler(this.canvasRulerX, this.canvasRulerY, this.AXIS)
  }

  //删除画板
  async deleteBoard() {
    window.CELLBOARD.copyData = null
    bus.$emit('cellHistoryUpdate', { cell: this.RENDER_CELL, avtive: false })
    this.STATUS.active = false
    this.STAGE.active = false
    if (this.lockFileTimer) {
      window.clearInterval(this.lockFileTimer)
      this.lockFileTimer = null
    }
    window.removeEventListener('resize', this.resize)
    document.removeEventListener('keydown', this.keyBoardListener)
    document.removeEventListener('keyup', this.keyUpBoardListener)
    this.STAGE.renderer.domElement.removeEventListener('mousemove', this.mouseMoveListener)
    // this.STAGE.renderer.domElement.removeEventListener('mouseup', this.mouseUpListener)
    document.body.removeEventListener('mouseup', this.mouseUpListener)
    this.STAGE.renderer.domElement.removeEventListener('mousedown', this.mouseDownListener)
    this.STAGE.renderer.domElement.removeEventListener('mousewheel', this.mouseWheelListener)
    this.STAGE.delete()
    // await this.unLockFile()
    // const cells = this.RENDER_CELL.depend_cells(-1)
    // cells.push(this.RENDER_CELL)
    // cells.forEach(cell => {
    //   const flexpaths = cell.flexpaths
    //   const flexpaths_size = flexpaths.length
    //   const polygons = cell.polygons
    //   const polygon_size = polygons.length
    //   const labels = cell.labels
    //   const labels_size = labels.length
    //   for (let i = 0; i < flexpaths_size; i++) {
    //     disposeGraphics(flexpaths[i].js_obj?.graphics)
    //     flexpaths[i].js_obj = {}
    //   }
    //   for (let i = 0; i < polygon_size; i++) {
    //     disposeGraphics(polygons[i].js_obj?.graphics)
    //     polygons[i].js_obj = {}
    //   }
    //   for (let i = 0; i < labels_size; i++) {
    //     disposeGraphics(labels[i].js_obj?.graphics)
    //     labels[i].js_obj = {}
    //   }
    // })
  }

  //设置文件和渲染版图
  setFileAndCell(lib, cellName) {
    this.LIB = lib
    this.CELL = this.LIB.cells.filter(cell => cell.name == cellName)[0]
    this.STAGE.setAntialias()
    this.STAGE.clearScence()
    this.STAGE.scaleLabel()
    let layerData = getCellLayers(this.CELL)

    // this.LAYER_STATE.updateLayerMats(layerData)
    this.CONFIG.updateLayerMats(getCellLayers(this.CELL).map(ly => ly.layerNumber))
    const layerMats = this.CONFIG.layerMats
    // const layerMats = this.LAYER_STATE.layerMats

    this.STAGE.renderCell(this.CELL, layerMats)
  }

  //设置lib和cell
  setLibAndCell(lib, cell) {
    this.LIB = lib
    this.CELL = cell
    //...
  }

  //激活
  active() {
    const cell = this.RENDER_CELL
    const cells = cell.depend_cells(-1) //可能新调用的cell没有重新构建图形数据
    cells.push(cell)
    cells.forEach(newCell => {
      buildCell(this, newCell)
    })
    ReBuildCellRefs(this) //切换画板回来，引用内容可能修改需要重构
    this.enterCell(this.RENDER_CELL) //重新进入器件 更新场景图形数据
    this.HISTORY = this.CELL.js_obj.HISTORY
    this.QUAD_TREE = this.CELL.js_obj.QUAD_TREE
    addEventListener(this, bus)
    this.STATUS.active = true
    this.STAGE.active = true
    bus.$emit('cellHistoryUpdate', { cell: this.RENDER_CELL, avtive: true })
    if (!this.cellInfo?.isReadOnly && this.fileInfo?.teamRule !== undefined) {
      this.lockFile(this.CELL.snow_id)
    }
    this.setToolState()
  }
  //待命
  inActive() {
    if (!this.STATUS.active) return
    this.resetLayout(false, false, false)
    removeEventListeners(this, bus)
    this.STATUS.active = false
    this.STAGE.active = false
    bus.$emit('cellHistoryUpdate', { cell: this.RENDER_CELL, avtive: false })
  }

  //获取鼠标位置
  getMousePos() {
    return getAdsorpPoint(this)
  }

  resetLayout(toMode, layerDataChange = false, clearCopyData = true) {
    const fromMode = this.MODE
    let clearCKObjs = true
    let clearPreCutObjs = true
    //一些模式需要基于选中对象进行操作
    if (toMode == BOARDMODE.MOVE || toMode == BOARDMODE.ROTATE || toMode == BOARDMODE.CUT_X || toMode == BOARDMODE.CUT_Y || toMode == BOARDMODE.ALIGN_PICK_POINT || toMode == BOARDMODE.ALIGN_PICK_LINE || toMode == BOARDMODE.CUT_AREA) {
      clearCKObjs = false
    }
    if (toMode == BOARDMODE.PRE_PASTE) {
      clearPreCutObjs = false
    }
    //切换模式后不需要清除选中对象
    if (fromMode == BOARDMODE.PRE_PASTE) {
      clearCKObjs = false
    }
    if (layerDataChange) {
      clearCKObjs = true
      clearPreCutObjs = true
    }
    // if (toMode !== BOARDMODE.PRE_PASTE) {
    //   if (clearCopyData) {
    //     window.CELLBOARD.copyData = null
    //   }
    // }
    if ((fromMode == BOARDMODE.OVER_SEL || fromMode == BOARDMODE.PART_SEL || fromMode == BOARDMODE.MOVE || fromMode == BOARDMODE.ROTATE) && this.STATUS.drag_count) {
      //移动旋转拖动过程中escape
      undo(this)
    }
    // alert(clearCKObjs)
    // alert(clearPreCutObjs)
    this.STATUS.reset(this, clearCKObjs, clearPreCutObjs)
    this.STAGE.tools.reset()
    this.STAGE.updateMouseCross()
    this.STAGE.updateEditRefBorder(this)
    this.STAGE.updateDrcGraphics([])
    this.STAGE.clearPreCopyData()
    completeDraw(this, true)
    this.updateEditRefBox()

    if (store.state.boardPropertyState[2].func === 'overallSelection') {
      this.MODE = BOARDMODE.OVER_SEL
    } else {
      this.MODE = BOARDMODE.PART_SEL
    }
    if (toMode == undefined) {
      bus.$emit('cancelSidebarSelect')
    }
  }

  getCurrentLayerData() {}

  //切换画板模式
  switchMode(mode = BOARDMODE.OVER_SEL, labelParams) {
    //部分选择下无法执行某些功能
    if (this.MODE == BOARDMODE.PART_SEL) {
      if (mode == BOARDMODE.ROTATE || mode == BOARDMODE.CUT_AREA || mode == BOARDMODE.CUT_X || mode == BOARDMODE.CUT_Y) {
        Message.error(`${i18n.t('messages.cellBoardMsg.error.partCheckBanFunc')}`)
        return
      }
    }
    this.resetLayout(mode)
    this.MODE = mode
    if (mode == BOARDMODE.CUT_X) {
      this.STAGE.updateMouseCross(true, false)
    } else if (mode == BOARDMODE.ROTATE) {
      this.STATUS.updateCheckedObjsAABB()
      let center = this.STATUS.checked_objs_center
      if (this.STATUS.checked_objs.length == 1 && this.STATUS.checked_objs[0].$$?.ptrType.name == 'Reference*' && !this.STATUS.checked_objs[0].js_obj.expanRefs?.length) {
        center = this.STATUS.checked_objs[0].origin
      }
      center = transOutRef(this, center)
      this.STAGE.tools.updateAngleText(`0°`, center)
    } else if (mode == BOARDMODE.DRAW_LABEL) {
      this.STATUS.setLoopText(labelParams.text)
      this.STATUS.textLayer = labelParams.layer
      this.STATUS.font_size = labelParams.fontSize
      this.STATUS.direction = labelParams.direction
      this.addLabel(this.STATUS.getCtText())
    } else if (mode == BOARDMODE.DRAW_KP) {
      this.STATUS.setLoopText(labelParams.text)
      this.STATUS.useLabel = labelParams.useLabel
      this.STATUS.font_size = labelParams.fontSize
      this.addKP(this.STATUS.getCtText())
    } else if (mode == BOARDMODE.DRAW_TEXT_GRAPHIC) {
      this.STATUS.setLoopText(labelParams.text)
      this.STATUS.textLayer = labelParams.layer
      this.STATUS.characterRotation = labelParams.rotation
      this.STATUS.font_size = labelParams.fontSize
      this.STATUS.direction = labelParams.direction
      this.addCharacter(this.STATUS.getCtText())
    }
  }

  /**********执行画板功能**********/
  //鼠标点击功能
  runMouseDownFunc(e, globalPos) {
    // this.STAGE.updateDebugLines(this.RENDER_CELL.labels)
    if (this.STATUS.viewMode) return
    let l_click = e.button == 0 //左键点击
    let m_click = e.button == 1 //中键点击
    let r_click = e.button == 2 //右键点击
    let pos = transToRef(this, globalPos)
    if (l_click) {
      if (this.MODE === BOARDMODE.JJSELECT) {
        bus.$emit('TOOL_JJ_SELECT_POS_OK', { x: globalPos[0], y: globalPos[1] })
        this.resetLayout()
        return
      }
      //整体选择拖动
      if (this.MODE === BOARDMODE.OVER_SEL) {
        const hited = checkInsideCheckedObjs(this, pos) //拖动前置条件
        this.STATUS.checked_objs_hited = hited
        if (hited) {
          this.STATUS.movePos = []
          this.STATUS.setMovePos(pos)
          updateMouseOffSet(this.STATUS.checked_objs, pos)
          this.STATUS.updateLastMousePos(pos)
        }
        return
      }

      //部分选择拖动
      if (this.MODE === BOARDMODE.PART_SEL) {
        const hited = checkInsideCheckedObjs(this, pos) //拖动前置条件
        this.STATUS.checked_objs_hited = hited
        if (hited) {
          this.STATUS.movePos = []
          this.STATUS.setMovePos(pos)
          updateMouseOffSet(this.STATUS.checked_objs, pos)
          this.STATUS.updateLastMousePos(pos)
        }
        return
      }

      //点击移动
      if (this.MODE === BOARDMODE.MOVE) {
        updateMouseOffSet(this.STATUS.checked_objs, pos)
        this.STATUS.updateLastMousePos(pos)
        return
      }
      //旋转
      if (this.MODE === BOARDMODE.ROTATE) {
        updateNowAngle(this.STATUS.checked_objs)
        this.STATUS.updateLastMousePos(pos)
        return
      }
    }

    if (this.MODE == BOARDMODE.DRAW_LABEL && l_click) {
      let text = this.STATUS.getCtText()
      this.addLabel(text)
    }
    if (this.MODE == BOARDMODE.DRAW_KP && l_click) {
      let text = this.STATUS.getCtText()
      this.addKP(text)
    }
    if (this.MODE == BOARDMODE.CUT_X && l_click) {
      cutObj(this)
      this.resetLayout()
    }
    if (this.MODE == BOARDMODE.CUT_Y && l_click) {
      cutObj(this)
      this.resetLayout()
    }
    if ((this.MODE == BOARDMODE.PRE_ADD_CELL || this.MODE == BOARDMODE.DRAW_TEXT_GRAPHIC) && l_click) {
      if (this.STATUS.preAddRef) {
        //通知更新树状结构
        // if (this.STATUS.fromExternalFile) {
        const afterAddLayers = noRepeatArray([...this.RENDER_CELL.layers, ...this.RENDER_CELL.depend_layers()].concat([...this.STATUS.preAddRef.cell.layers, ...this.STATUS.preAddRef.cell.depend_layers()])) //noRepeatArray(this.RENDER_CELL.depend_layers(-1).concat(this.STATUS.preAddRef.cell.depend_layers(-1)))

        bus.$emit('updateFileLayers', afterAddLayers)
        const layerData = getFileLayerListFromTable(this.fileInfo.fileId)

        applyLayerIdToCell(this.STATUS.preAddRef.cell, layerData.fileLayerDatas)

        // }
        let that = this
        let nextStep = function () {
          const history = {
            func: 'ADD_DEVICE',
            actions: [{ action: 'add', cell: that.CELL, objs: [that.STATUS.preAddRef] }],
          }
          that.HISTORY.record(history)
          that.sendMessage(history)
          addRefToLayout(that, that.STATUS.preAddRef)
          that.STATUS.preAddRef = null
          that.clearPreAddRef()
          if (that.MODE == BOARDMODE.DRAW_TEXT_GRAPHIC) {
            that.addCharacter()
            return
          }
          that.resetLayout()
        }

        bus.$emit('loading', true)
        bus.$emit('updateFileTree', { cell: this.STATUS.preAddRef.cell, nextStep })
        // const history = {
        //   func: 'ADD_DEVICE',
        //   actions: [{ action: 'add', cell: this.CELL, objs: [this.STATUS.preAddRef] }],
        // }
        // this.HISTORY.record(history)
        // this.sendMessage(history)
        // addRefToLayout(this, this.STATUS.preAddRef)
        // this.STATUS.preAddRef = null
        // this.clearPreAddRef()
        // if (this.MODE == BOARDMODE.DRAW_TEXT_GRAPHIC) {
        //   this.addCharacter()
        //   return
        // }
      }
    }
  }
  //鼠标移到功能
  runMouseMoveFunc(e, globalPos) {
    if (this.STATUS.viewMode) return
    let pos = transToRef(this, globalPos)
    //整体选择预选中对象
    if (this.MODE === BOARDMODE.OVER_SEL) {
      //左键拖动
      if (this.STATUS.canDrag()) {
        //记录拖动鼠标起始状态
        if (this.STATUS.drag_count === 0) {
          recordHistory(this, 'transform', 'MOVE')
        }
        this.STATUS.drag_count++
        this.STATUS.setMovePos(pos)
        let objs = this.STATUS.checked_objs
        for (let i = 0; i < objs.length; i++) {
          const obj = objs[i]
          moveObj(this, obj, pos)
        }
        this.STAGE.updateGlobalKP(this)
        this.STAGE.updateCheckedRefs(this)
        this.STAGE.updateRefArray(this)
        this.STAGE.updateEditRefBorder(this)
        return
      }
      if (!this.STAGE.tools?.selectBox?.points?.length) {
        //没有绘制选择框
        updatePreCheckObj(this, pos)
      }

      return
    }
    //部分选择预选中对象
    if (this.MODE === BOARDMODE.PART_SEL) {
      //左键拖动
      if (this.STATUS.canPartDrag()) {
        //记录拖动鼠标起始状态
        if (this.STATUS.drag_count === 0) {
          recordHistory(this, 'shape', 'STRETCH')
        }
        this.STATUS.drag_count++
        this.STATUS.setMovePos(pos)
        let objs = this.STATUS.checked_objs
        for (let i = 0; i < objs.length; i++) {
          const obj = objs[i]
          moveObj(this, obj, pos)
        }
        this.STATUS.updateCheckedPointsAndLines(this)
        this.STAGE.updatePreCheckObj(null, this)
        this.STAGE.updatePreCheckPoint(null)
        this.STAGE.updatePreCheckLine(null)
        this.STAGE.updateGlobalKP(this)
        this.STAGE.updateCheckedRefs(this)
        this.STAGE.updateRefArray(this)
        this.STAGE.updateEditRefBorder(this)
        return
      }

      if (!this.STAGE.tools?.selectBox?.points?.length) {
        //没有绘制选择框
        updatePartPreCheckObj(this, pos)
      }
      return
    }
    //点对齐选择点
    if (this.MODE === BOARDMODE.ALIGN_PICK_POINT) {
      if (!this.STAGE.tools?.selectBox?.points?.length) {
        //没有绘制选择框
        updatePreCheckAlignPoint(this, pos)
      }
      return
    }
    //线对齐选择线
    if (this.MODE === BOARDMODE.ALIGN_PICK_LINE) {
      if (!this.STAGE.tools?.selectBox?.points?.length) {
        //没有绘制选择框
        updatePreCheckAlignLine(this, pos)
      }
      return
    }
    //z线选择线模式
    if (this.MODE === BOARDMODE.Z_LINE) {
      if (!this.STAGE.tools?.selectBox?.points?.length) {
        //没有绘制选择框
        updatePreCheckAlignLine(this, pos, 'ZLine')
      }
      return
    }
    //预粘贴
    if (this.MODE === BOARDMODE.PRE_PASTE) {
      updatePrePasteData(this, globalPos, pos)
      let start = window.CELLBOARD.copyData.center
      this.STAGE.preAddGroup.position.set(pos[0] - start[0], pos[1] - start[1], 5)
      return
    }
    //预添加cell
    if (this.MODE === BOARDMODE.PRE_ADD_CELL || this.MODE === BOARDMODE.DRAW_TEXT_GRAPHIC) {
      const preAddRef = this.STATUS.preAddRef

      if (preAddRef) {
        preAddRef.js_obj.mouse_offset = [0, 0]
        moveObj(this, preAddRef, pos, false, true)
        // if (this.STATUS.fromExternalFile || this.STATUS.preAddRefExist) {
        //   //来自外部文件还未添加

        this.STAGE.preAddRefGroup.position.set(pos[0], pos[1])
        // } else {
        //   //已经添加
        //   this.STAGE.updateRefArray(this, null, this.STATUS.affectCellPtrs)
        // }
        return
      }
    }
  }

  //鼠标释放功能
  runMouseUpFunc(e) {
    if (this.STATUS.viewMode) return
    let l_release = e.button == 0 //左键释放
    let m_release = e.button == 1 //中键释放
    let r_release = e.button == 2 //右键释放

    if (l_release) {
      if (!isDrawMode(this.MODE) && this.MODE !== BOARDMODE.MOVE) {
        this.STATUS.updateLastMousePos(null)
      }

      if (this.STAGE.tools.selectBox.points?.length) {
        if (this.MODE == BOARDMODE.OVER_SEL) {
          if (this.STAGE.tools.selectBox.points.length == 1) {
            selectObjByClick(this)
            return
          } else if (this.STAGE.tools.selectBox.points.length > 1) {
            selectObjsByBox(this)
            return
          }
        }
        if (this.MODE == BOARDMODE.PART_SEL) {
          if (this.STAGE.tools.selectBox.points.length == 1) {
            selectObjPartByClick(this)
            return
          } else if (this.STAGE.tools.selectBox.points.length > 1) {
            selectObjsjPartByBox(this)
            return
          }
        }
      }
      if (this.MODE == BOARDMODE.ALIGN_PICK_POINT) {
        selectAlignPointByClick(this)
        return
      }
      if (this.MODE == BOARDMODE.ALIGN_PICK_LINE) {
        selectAlignLineByClick(this)
        return
      }
      if (this.MODE === BOARDMODE.Z_LINE) {
        selectAlignLineByClick(this, 'ZLine')
      }
    }

    if (this.STAGE.tools.adaptBox.points?.length) {
      let aabb = getBoundingBox(this.STAGE.tools.adaptBox.points)
      adaptScreen(this, aabb)
      if (this.MODE == BOARDMODE.DRAW_ADAPT_RECT) {
        this.switchMode()
      }
      return
    }
    if (this.STAGE.tools.cutBox2D.points?.length) {
      cutAreaObjs(this, this.STAGE.tools.cutBox2D.points)
      this.resetLayout()
      return
    }
    if (this.MODE == BOARDMODE.ROTATE && l_release) {
      this.resetLayout()
      return
    }
    if (this.MODE == BOARDMODE.MOVE && l_release && this.STATUS.movePos.length == 2) {
      this.resetLayout()
      return
    }
    if (this.MODE == BOARDMODE.ALIGN_PICK_POINT && this.STATUS.pickPoints.length == 2 && l_release) {
      this.resetLayout()
      return
    }
    if (this.MODE == BOARDMODE.ALIGN_PICK_LINE && this.STATUS.pickLines.length == 2 && l_release) {
      this.resetLayout()
      return
    }

    if (this.MODE == BOARDMODE.PRE_PASTE && l_release && mouseInsideCanvas(this.canvas, e)) {
      let that = this
      function nextStep() {
        confirmPaste(that)
        that.resetLayout()
      }
      if (this.STATUS.crossCopyData) {
        if (this.STATUS.crossCopyData) {
          bus.$emit('updateFileLayers', this.STATUS.crossCopyData.layers)
        }
        const layerData = getFileLayerListFromTable(this.fileInfo.fileId)

        applyLayerIdToCell(this.STATUS.crossCopyData.copyCell, layerData.fileLayerDatas)
        currentDrawBoard_deepCopy_remane_cell(this.STATUS.crossCopyData.copyCell)
        if (this.STATUS.crossCopyData.cells.length) {
          bus.$emit('loading', true)
          bus.$emit('updateFileTree', { cell: this.STATUS.crossCopyData.cells, nextStep })
        } else {
          nextStep()
        }
      } else {
        nextStep()
      }
      return
    }
  }

  //添加线
  addPath(pos) {
    let path = initPath(pos, this)
    this.STATUS.drawingObj = path
    this.STATUS.updateLastMousePos(pos)
    this.addObj(this.CELL, path)
  }

  //添加矩形
  addRect(pos) {
    let rect = initRect(pos, this)
    this.STATUS.drawingObj = rect
    this.STATUS.updateLastMousePos(pos)
    this.addObj(this.CELL, rect)
  }

  //添加椭圆
  addEllipse(pos) {
    let ellipse = initEllipse(pos, this)
    this.STATUS.drawingObj = ellipse
    this.STATUS.updateLastMousePos(pos)
    this.addObj(this.CELL, ellipse)
  }

  //添加多边形
  addPolygon(pos) {
    let polygon = initPolygon(pos, this)
    this.STATUS.drawingObj = polygon
    this.STATUS.updateLastMousePos(pos)
    this.addObj(this.CELL, polygon)
  }

  //放置标签
  addLabel(text) {
    let globalPos = this.getMousePos()
    let pos = transToRef(this, globalPos)
    let label = initLabel(pos, text, this)
    this.STATUS.drawingObj = label
    this.addObj(this.CELL, label)
    updateGDSGraphic(label, pos, this)
  }

  //放置关键点
  addKP(text) {
    let globalPos = this.getMousePos()
    let pos = transToRef(this, globalPos)
    let keyPoint = initKeyPoint(pos, text, this)
    this.STATUS.drawingObj = keyPoint
    this.addObj(this.CELL, keyPoint)
    updateGDSGraphic(keyPoint, pos, this)
  }

  //预放置文本图形
  addCharacter(text) {
    let pos = this.getMousePos()
    let ref
    if (text) {
      //新生成文本图形
      let layerId = this.getLayerIdByNum(this.STATUS.textLayer)
      ref = initCharacterRef(text, this.STATUS.textLayer, this.STATUS.direction, this.STATUS.font_size, this.STATUS.characterRotation, layerId)
      this.STATUS.textCell = ref.cell
    } else {
      //连续放置图形
      ref = new Kernel.Reference()
      ref.rotation = this.STATUS.characterRotation
      ref.cell = this.STATUS.textCell
      ref.origin = this.getMousePos()
    }

    addDevice(this, ref, true, true)
    // addDevice(LAYOUT, ref, true)
    // this.STATUS.drawingObj = ref
    // this.addObj(this.CELL, keyPoint)
  }
  //添加尺子
  addRuler(pos) {
    let ruler = new Ruler(this.CONFIG.toolsMat.rulerLine, this.CONFIG.toolsMat.rulerText)
    ruler.points = [pos]
    this.STATUS.drawingObj = ruler
    this.STATUS.updateLastMousePos(pos)
    this.addObj(this.CELL, ruler)
  }

  //添加3D剖面区域
  add3DCutArea(pos) {
    // let line_geometry = new THREE.BufferGeometry()
    // line_geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
    // let line = new THREE.LineLoop(line_geometry, this.STAGE.config.toolsMat.cutBox3D)
    // line.points = [pos]
    // this.STATUS.drawingObj = line
    // this.STATUS.updateLastMousePos(pos)
    // this.STAGE.tools.cutBox3DGroup.add(line)
    // // this.CELL.js_obj.cutBox3DGroup.push(line)

    let rect = initRect(pos, this, SPECIALGRAPHIC.CUTAREA3D, this.CONFIG.toolsMat.cutBox3D)
    this.STATUS.drawingObj = rect
    this.STATUS.updateLastMousePos(pos)
    this.addObj(this.CELL, rect)
  }

  //添加3D剖面渲染区域
  add3DRenderArea(pos) {
    // let line_geometry = new THREE.BufferGeometry()
    // line_geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
    // let line = new THREE.LineLoop(line_geometry, this.STAGE.config.toolsMat.renderArea3D)
    // line.points = [pos]
    // this.STATUS.drawingObj = line
    // this.STATUS.updateLastMousePos(pos)
    // this.STAGE.tools.renderArea3DGroup.add(line)
    let polygon = initPolygon(pos, this, SPECIALGRAPHIC.RENDERAREA, this.CONFIG.toolsMat.renderArea3D)
    this.STATUS.drawingObj = polygon
    this.STATUS.updateLastMousePos(pos)
    this.addObj(this.CELL, polygon)
  }
  //添加未构建的gds对象
  addGdsObj(obj) {
    reBuildGdsData(this, obj)
    this.addObj(this.CELL, obj)
  }
  //添加对象
  addObj(cell, obj, needRefresh = false) {
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    const SPEC_TYPE = obj.js_obj.type
    const cellGroup = this.STAGE.cellGroup
    const refsGroup = this.STAGE.refsGroup
    if (this.EDIT_REF) {
      //编辑下层
      const cellInsMapsObj = this.RENDER_CELL.js_obj.cellInsMaps[this.EDIT_REF.cell.name]
      const matsArray = cellInsMapsObj.matsArray
      const insCount = cellInsMapsObj.refs.length
      if (SPEC_TYPE) {
        //特殊图形
        if (SPEC_TYPE == SPECIALGRAPHIC.CUTAREA3D) {
          cell.js_obj.cutArea3D.push(obj)
        } else if (SPEC_TYPE == SPECIALGRAPHIC.RENDERAREA) {
          cell.js_obj.render3DArea.push(obj)
        }
      } else if (TYPE == 'GdsFlexpath*') {
        updateLineInsAttr(obj.js_obj.graphics.lineIns, insCount, matsArray)
        refsGroup.add(obj.js_obj.graphics.lineIns)
        if (obj.width[0]) {
          updateLineInsAttr(obj.js_obj.graphics.lineLoopIns, insCount, matsArray)
          updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray)
          refsGroup.add(obj.js_obj.graphics.lineLoopIns)
          if (this.CONFIG.showFill) {
            refsGroup.add(obj.js_obj.graphics.fillIns)
          }
        }
        cell.add_flexpath(obj)
      } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
        updateLineInsAttr(obj.js_obj.graphics.lineLoopIns, insCount, matsArray)
        updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray)
        refsGroup.add(obj.js_obj.graphics.lineLoopIns)
        if (this.CONFIG.showFill) {
          refsGroup.add(obj.js_obj.graphics.fillIns)
        }
        cell.add_polygon(obj)
      } else if (TYPE == 'GdsLabel*') {
        updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray)
        refsGroup.add(obj.js_obj.graphics.fillIns)
        cell.add_label(obj)
      } else if (TYPE == 'GdsKeyPoint*') {
        refsGroup.add(obj.js_obj.graphics.pointIns)
        updatePointInsAttr(obj.js_obj.graphics.pointIns, insCount, matsArray)
        if (obj.js_obj.graphics?.fillIns) {
          updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray)
          refsGroup.add(obj.js_obj.graphics.fillIns)
        }
        cell.add_label(obj)
      } else if (TYPE == 'Reference*') {
        cell.add_reference(obj)
      } else if (TYPE == 'Ruler' || obj.constructor.name == 'Ruler') {
        cell.js_obj.rulers.push(obj)
      }
      this.QUAD_TREE.addNode(obj)
    } else {
      //编辑当前器件
      if (SPEC_TYPE) {
        //特殊图形
        if (SPEC_TYPE == SPECIALGRAPHIC.CUTAREA3D) {
          cellGroup.add(obj.js_obj.graphics.lineLoop)
          cell.js_obj.cutArea3D.push(obj)
        } else if (SPEC_TYPE == SPECIALGRAPHIC.RENDERAREA) {
          cellGroup.add(obj.js_obj.graphics.lineLoop)
          cell.js_obj.render3DArea.push(obj)
        }
      } else if (TYPE == 'GdsFlexpath*') {
        cellGroup.add(obj.js_obj.graphics.line)
        if (obj.width[0]) {
          cellGroup.add(obj.js_obj.graphics.lineLoop)
          if (this.CONFIG.showFill) {
            cellGroup.add(obj.js_obj.graphics.fill)
          }
        }
        cell.add_flexpath(obj)
      } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
        cellGroup.add(obj.js_obj.graphics.lineLoop)
        if (this.CONFIG.showFill) {
          cellGroup.add(obj.js_obj.graphics.fill)
        }
        cell.add_polygon(obj)
      } else if (TYPE == 'GdsLabel*') {
        if (obj.js_obj.graphics?.fill) {
          cellGroup.add(obj.js_obj.graphics.fill)
        }
        cell.add_label(obj)
      } else if (TYPE == 'GdsKeyPoint*') {
        cellGroup.add(obj.js_obj.graphics.point)
        if (obj.js_obj.graphics?.fill) {
          cellGroup.add(obj.js_obj.graphics.fill)
        }
        cell.add_label(obj)
      } else if (TYPE == 'Reference*') {
        cell.add_reference(obj)
      } else if (TYPE == 'Ruler' || obj.constructor.name == 'Ruler') {
        this.STAGE.tools.rulerGroup.add(obj.line)
        this.STAGE.tools.rulerGroup.add(obj.text)
        cell.js_obj.rulers.push(obj)
      }
      this.QUAD_TREE.addNode(obj)
    }
    this.updateEditRefBox()
    if (needRefresh) {
      bus.$emit('refreshCellRef', this.CELL.snow_id)
    }
  }

  //删除对象
  deleteObj(CELL, deleteObjs, dispose = false) {
    let objs = deleteObjs
    if (!Array.isArray(deleteObjs)) {
      objs = [deleteObjs]
    }
    let QUAD_TREE = this.QUAD_TREE
    let GROUP = this.STAGE.cellGroup
    let RULER_GROUP = this.STAGE.tools.rulerGroup
    let REF_Group = this.STAGE.refsGroup
    if (this.EDIT_REF) {
      //编辑下层
      for (let i = 0; i < objs.length; i++) {
        const obj = objs[i]
        const TYPE = obj.$$?.ptrType.name //obj.constructor.name
        const SPEC_TYPE = obj.js_obj.type
        if (SPEC_TYPE) {
          //特殊图形
          if (SPEC_TYPE == SPECIALGRAPHIC.CUTAREA3D) {
            removeArrayItem(CELL.js_obj.cutArea3D, obj)
          } else if (SPEC_TYPE == SPECIALGRAPHIC.RENDERAREA) {
            removeArrayItem(CELL.js_obj.render3DArea, obj)
          }
        } else if (TYPE == 'GdsFlexpath*') {
          REF_Group.remove(obj.js_obj.graphics.lineIns)
          REF_Group.remove(obj.js_obj.graphics.lineLoopIns)
          REF_Group.remove(obj.js_obj.graphics.fillIns)
          CELL.remove_flexpath(obj)
        } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
          REF_Group.remove(obj.js_obj.graphics.lineLoopIns)
          REF_Group.remove(obj.js_obj.graphics.fillIns)
          CELL.remove_polygon(obj)
        } else if (TYPE == 'GdsLabel*') {
          REF_Group.remove(obj.js_obj.graphics.fillIns)
          CELL.remove_label(obj)
        } else if (TYPE == 'GdsKeyPoint*') {
          REF_Group.remove(obj.js_obj.graphics.pointIns)
          if (obj.js_obj.graphics?.fill) {
            REF_Group.remove(obj.js_obj.graphics.fillIns)
          }
          CELL.remove_label(obj)
        } else if (TYPE == 'Reference*') {
          CELL.remove_reference(obj)
        } else if (TYPE == 'Ruler' || obj.constructor.name == 'Ruler') {
          removeArrayItem(CELL.js_obj.rulers, obj)
        }
        if (dispose) {
          disposeGraphics(obj.js_obj.graphics)
        }
        obj.js_obj.STATE.reset()
        updateObjMat(obj, this)
        QUAD_TREE.removeNode(obj)
      }
      this.STATUS.resetCheckStatus(this)
      this.STATUS.updateCheckedPointsAndLines(this)
    } else {
      //编辑当前层
      for (let i = 0; i < objs.length; i++) {
        const obj = objs[i]
        const TYPE = obj.$$?.ptrType.name //obj.constructor.name
        const SPEC_TYPE = obj.js_obj.type
        if (SPEC_TYPE) {
          //特殊图形
          if (SPEC_TYPE == SPECIALGRAPHIC.CUTAREA3D) {
            GROUP.remove(obj.js_obj.graphics.lineLoop)
            removeArrayItem(CELL.js_obj.cutArea3D, obj)
          } else if (SPEC_TYPE == SPECIALGRAPHIC.RENDERAREA) {
            GROUP.remove(obj.js_obj.graphics.lineLoop)
            removeArrayItem(CELL.js_obj.render3DArea, obj)
          }
        } else if (TYPE == 'GdsFlexpath*') {
          let line = obj.js_obj.graphics.line
          let lineLoop = obj.js_obj.graphics.lineLoop
          let fill = obj.js_obj.graphics.fill
          GROUP.remove(line)
          GROUP.remove(lineLoop)
          GROUP.remove(fill)
          CELL.remove_flexpath(obj)
        } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
          let lineLoop = obj.js_obj.graphics.lineLoop
          let fill = obj.js_obj.graphics.fill
          GROUP.remove(lineLoop)
          GROUP.remove(fill)
          CELL.remove_polygon(obj)
        } else if (TYPE == 'GdsLabel*') {
          let fill = obj.js_obj.graphics?.fill
          if (fill) {
            GROUP.remove(fill)
          }
          CELL.remove_label(obj)
        } else if (TYPE == 'GdsKeyPoint*') {
          GROUP.remove(obj.js_obj.graphics.point)
          if (obj.js_obj.graphics?.fill) {
            GROUP.remove(obj.js_obj.graphics.fill)
          }
          CELL.remove_label(obj)
        } else if (TYPE == 'Reference*') {
          CELL.remove_reference(obj)
        } else if (TYPE == 'Ruler' || obj.constructor.name == 'Ruler') {
          removeArrayItem(CELL.js_obj.rulers, obj)
          RULER_GROUP.remove(obj.line)
          RULER_GROUP.remove(obj.text)
        }
        if (dispose) {
          disposeGraphics(obj.js_obj.graphics)
        }
        obj.js_obj.STATE.reset()
        updateObjMat(obj, this)
        QUAD_TREE.removeNode(obj)
      }
    }
    this.updateEditRefBox(true)
    bus.$emit('refreshCellRef', this.CELL.snow_id)
  }
  // //移除对象
  // removeObj(obj) {
  //   let CELL_GROUP = this.STAGE.cellGroup
  //   let RULER_GROUP = this.STAGE.tools.rulerGroup
  //   let REF_Group = this.STAGE.refsGroup
  //   const TYPE = obj.constructor.name
  //   if (obj instanceof Ruler) {
  //     removeArrayItem(this.CELL.js_obj.rulers, obj)
  //     this.STAGE.tools.rulerGroup.remove(obj.line)
  //     this.STAGE.tools.rulerGroup.remove(obj.text)
  //     obj.clear()
  //   } else if (TYPE == 'GdsFlexpath*') {
  //     let line = obj.js_obj.graphics.line
  //     let lineLoop = obj.js_obj.graphics.lineLoop
  //     let fill = obj.js_obj.graphics.fill
  //     CELL_GROUP.remove(line)
  //     CELL_GROUP.remove(lineLoop)
  //     CELL_GROUP.remove(fill)
  //     line.geometry.dispose()
  //     lineLoop?.geometry.dispose()
  //     fill?.geometry.dispose()
  //     this.CELL.remove_flexpath(obj)
  //   } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
  //     let lineLoop = obj.js_obj.graphics.lineLoop
  //     let fill = obj.js_obj.graphics.fill
  //     this.STAGE.cellGroup.remove(lineLoop)
  //     this.STAGE.cellGroup.remove(fill)
  //     lineLoop.geometry.dispose()
  //     fill?.geometry.dispose()
  //     this.CELL.remove_polygon(obj)
  //   } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
  //     let fill = obj.js_obj.graphics?.fill
  //     this.STAGE.cellGroup.remove(fill)
  //     fill?.geometry.dispose()
  //     this.CELL.remove_label(obj)
  //     // this.STAGE.updateGlobalAnchor(labelList)
  //     if (TYPE == 'GdsKeyPoint*') {
  //       this.STAGE.updateGlobalKP(this)
  //     }
  //   } else {
  //     obj.geometry.dispose()
  //     this.STAGE.cellGroup.remove(obj)
  //     this.STAGE.tools.cutBox3DGroup.remove(obj)
  //     this.STAGE.tools.renderArea3DGroup.remove(obj)
  //   }
  //   this.QUAD_TREE.removeNode(obj)

  // }

  //删除画板选中对象
  deleteCheckedObjs() {
    removeCheckedObjs(this)
  }
  //记录属性变化
  recordProperty(type, data, remark) {
    recordObjsProperty(this, type, data, remark)
  }

  //新增和删除器件时，更新对应器件引用矩形
  updateTargetCellInsRefs(cellPtrs) {
    if (cellPtrs.length) {
      updateCellInsRefs(this.RENDER_CELL, cellPtrs)
      this.STAGE.updateRefArray(this, null, cellPtrs)
    }
  }

  //中途取消清除预添加器件 清除引用数为0的图形数据
  clearPreAddRef() {
    // if (this.STATUS.fromExternalFile) {
    //调用来自外部文件的器件
    this.STAGE.clearPreAddRef()
    // } else {
    //   this.deleteObj(this.CEll, this.STATUS.preAddRef)
    //   const preAddNewCells = this.STATUS.addNewCells
    //   if (preAddNewCells && preAddNewCells.length) {
    //     const cellInsMaps = this.CELL.js_obj.cellInsMaps

    //     const cellsPtrs = preAddNewCells.map(cell => cell.$$.ptr)
    //     for (const cell in cellInsMaps) {
    //       const obj = cellInsMaps[cell]
    //       if (cellsPtrs.indexOf(obj.cell.$$.ptr) !== -1) {
    //         this.STAGE.removeCellInst(this.STAGE.refsGroup, obj.cell, true) //删除引用相关数据
    //       }
    //     }
    //   }
    // }
  }

  //重新渲染
  reRender(rebuildQuadTree = false) {
    // if(rebuildQuadTree){
    //   this.RENDER_CELL.js_obj.QUAD_TREE =
    // }mmouse
    this.STAGE.reRenderCell(this.RENDER_CELL, this.LAYER_STATE)
  }
  //进入器件
  enterCell(cell) {
    this.STAGE.reRenderCell(cell, this.LAYER_STATE)
    this.STAGE.lodCellInsGraphics(this.RENDER_CELL.js_obj.cellInsMaps)
  }

  updateEditRefBox(forceUpdate = false) {
    if (this.STATUS.drawingObj || forceUpdate) {
      this.STAGE.updateEditRefBorder(this)
    }
  }
  //编辑下层选中文本跟新位置
  updateEditRefCheckedLable() {
    this.STAGE.editRefCheckedObjs.children.forEach(obj => {
      if (obj.geometry.constructor.name == 'TextGeometry') {
        obj.position.copy(obj.cloneFrom.position)
        obj.rotation.copy(obj.cloneFrom.rotation)
        obj.scale.copy(obj.cloneFrom.scale)
      }
    })
  }

  //发送消息
  async sendMessage(history, isUndo = false, isRedo = false) {
    //团队项目下才发送数据

    if (this.STATUS.viewMode) return
    const FUNC = history.func.toUpperCase()
    if (this.cellInfo.teamRule && this.CELL.js_obj.WS) {
      const proinfo = { pid: this.proInfo.projectId, fid: this.fileInfo.fileId }
      let msg
      if (FUNC.indexOf('DRAW') !== -1) {
        //绘制

        msg = transAddMsg(proinfo, history, this.CELL, isUndo, isRedo)
      } else if (FUNC === 'MOVE' || FUNC === 'ALIGN_PICK_POINT' || FUNC === 'ALIGN_PICK_LINE' || FUNC === 'ALIGN') {
        //移动
        msg = transMoveMsg(proinfo, history, this.CELL, isUndo, isRedo)
      } else if (FUNC === 'ROTATE' || FUNC === 'MIRROR' || FUNC === 'MIRRORX' || FUNC === 'MIRRORY' || FUNC === 'STRETCH') {
        //旋转
        msg = transTransformMsg(proinfo, history, this.CELL, isUndo, isRedo)
      } else if (
        FUNC == 'DELETE' ||
        FUNC == 'ADD_DEVICE' ||
        FUNC == 'PASTE' ||
        FUNC == 'CUT' ||
        FUNC === 'MERGE' ||
        FUNC === 'CUT_AREA' ||
        FUNC === 'SLICE' ||
        FUNC === 'ARRAY' ||
        FUNC === 'BREAK' ||
        FUNC === 'BOOLEAN' ||
        FUNC === 'CONNECT_LINE' ||
        FUNC === 'TOOL_SQUID_ZLINE_RUN' ||
        FUNC === 'TOOL_WAVEGUIDE_RUN' ||
        FUNC === 'TOOL_CAVITY_RUN'
      ) {
        //增删对象
        msg = transDelAddMsg(proinfo, history, this.CELL, isUndo, isRedo)
      } else if (FUNC == 'PROPERTY') {
        //属性修改
        // return
        msg = transPropertyMsg(proinfo, history, this.CELL, isUndo, isRedo)
      } else if (FUNC == 'CLOSE') {
        msg = {
          func: FUNC,
        }
      }

      await this.CELL.js_obj.WS.sendMsg(msg)
    }
  }
  //消息服务更新当前画板器件
  updateByMessageServe() {
    // cell.js_obj.QUAD_TREE = new CellQuadTree(cell) //构建四叉树
    // LAYOUT.QUAD_TREE = cell.js_obj.QUAD_TREE
    const cell = this.RENDER_CELL
    const cells = cell.depend_cells(-1) //可能新调用的cell没有重新构建图形数据
    cells.push(cell)
    cells.forEach(newCell => {
      buildCell(this, newCell)
    })
    this.updateMat()
    this.QUAD_TREE = this.CELL.js_obj.QUAD_TREE
    this.HISTORY = this.CELL.js_obj.HISTORY
    ReBuildCellRefs(this) //切换画板回来，引用内容可能修改需要重构
    this.enterCell(this.CELL) //重新进入器件 更新场景图形数据
    // const flexpaths = cell.flexpaths
    // const polygons = cell.polygons
    // const labels = cell.labels
    // const referens = cell.referens
    // for (let i = 0; i < flexpaths.length; i++) {
    //   const path = flexpaths[i]
    //   reBuildGdsData(this, path, false)
    // }
    // for (let i = 0; i < polygons.length; i++) {
    //   const polygon = polygons[i]
    //   reBuildGdsData(this, polygon, false)
    // }
    // for (let i = 0; i < labels.length; i++) {
    //   const label = labels[i]
    //   reBuildGdsData(this, label, false)
    // }
    // cell.js_obj.cellInsMaps = initCellInstanceMap(cell)
    // for (let i = 0; i < referens.length; i++) {
    //   const ref = referens[i]
    //   reBuildGdsData(this, ref, false)
    // }

    // this.STAGE.reRenderCell(this.CELL)
  }

  //存在编辑权限
  async hasCellPermission(cellId) {
    if (this.cellInfo.teamRule && this.CELL.js_obj.WS) {
      let gns = `gns://${this.topInfo.teamId}/${this.proInfo.projectId}/${this.fileInfo.fileId}/${cellId}`
      const res = await getPermission(gns)
      if (res.data?.permission?.indexOf('编辑')) {
        return true
      }
      Message.error(`${i18n.t('messages.userCellPermission')}`)
      return false
    }
    return true
  }
  //是否可以进入器件
  async canAccessFile(id) {
    let data = await selectLockCell_api(id)
    if (data.code === 200000) {
      if (data.message == 'not lock') {
        return true
      } else if (data.message == 'locked') {
        Message.error(`${i18n.t('messages.code_405025')}`)
        return false
      }
    }
    return false
  }

  //判断执行完旋转或缩放
  transfromObjs(s, e) {
    if (this.MODE == BOARDMODE.ROTATE) {
      const history = {
        func: 'ROTATE',
        params: this.STATUS.radian,
        actions: [{ action: 'transform', objs: this.STATUS.checked_objs }],
      }
      this.sendMessage(history)
    } else if (this.MODE === BOARDMODE.MOVE || this.STATUS.drag_count) {
      if (this.STATUS.movePos.length != 2) return
      const start = this.STATUS.movePos[0]
      const end = this.STATUS.movePos[1]
      let func = 'MOVE'
      if (this.STATUS.checked_objs.filter(obj => obj.js_obj.STATE.partChecked).length) {
        func = 'STRETCH'
      }
      const history = {
        func,
        params: [end[0] - start[0], end[1] - start[1]],
        actions: [{ action: 'move', objs: this.STATUS.checked_objs }],
      }
      this.sendMessage(history)
    } else if (this.MODE === BOARDMODE.ALIGN_PICK_POINT || this.MODE === BOARDMODE.ALIGN_PICK_LINE) {
      if (s && e) {
        const history = {
          func: BOARDMODEMAP[this.MODE],
          params: [e[0] - s[0], e[1] - s[1]],
          actions: [{ action: 'move', objs: this.STATUS.checked_objs }],
        }
        this.sendMessage(history)
      }
    }
  }

  //更新当前器件的图层材质
  updateMat() {
    const cells = [this.RENDER_CELL, ...this.RENDER_CELL.depend_cells(-1)]
    for (let i = 0; i < cells.length; i++) {
      const cell = cells[i]
      if (!cell) continue
      const flexpaths = cell.flexpaths
      const flexpaths_size = flexpaths.length
      const polygons = cell.polygons
      const polygon_size = polygons.length
      const labels = cell.labels
      const labels_size = labels.length
      for (let i = 0; i < flexpaths_size; i++) {
        updateObjMat(flexpaths[i], this, true)
      }
      for (let i = 0; i < polygon_size; i++) {
        updateObjMat(polygons[i], this, true)
      }
      for (let i = 0; i < labels_size; i++) {
        updateObjMat(labels[i], this, true)
      }
    }
  }

  getLayerIdByNum(num) {
    const layerData = getFileLayerListFromTable(this.fileInfo.fileId).fileLayerDatas
    return getLayerIdByNum(num, layerData)
  }
  async lockFile(cellId) {
    if (this.fileInfo?.teamRule !== undefined) {
      let lock = lockCell_api({ file_snow_id: cellId, snow_team_id: this.topInfo.teamId })
      lock.then(data => {
        if (data.code === 200000) {
          this.STATUS.viewMode = false
        } else {
          // Message.warning(data.message)
          this.STATUS.viewMode = true
        }
      })
    }
  }

  async unLockFile(cells) {
    // await this.sendMessage({ func: 'CLOSE' })
    if (cells && Array.isArray(cells) && cells.length) {
      for (let i = 0; i < cells.length; i++) {
        await unLockCell_api({ file_snow_id: cells[i].snow_id })
      }
    } else {
      await unLockCell_api({ file_snow_id: this.CELL.snow_id })
    }
  }

  rebuildCellsQuadTree() {
    let cells = [this.TOP_CELL]
    this.TOP_CELL.depend_cells(-1).forEach(childCell => {
      cells.push(childCell)
    })
    cells.forEach(cell => (cell.js_obj.bounding_box = cell.bounding_box()))
    cells.forEach(cell => rebuildCellQuadTree(cell, this))
  }

  //同步工具栏
  setToolState() {
    this.AXIS.adsorpPoint = store.state.adsorptionState
    this.AXIS.adsorp_precision = parseFloat(store.state.precision)
    this.AXIS.angledAdsorption = store.state.boardPropertyState[0].func === 'angledAdsorption'
    this.STATUS.isStretch = store.state.boardPropertyState[1].func === 'lockStretch'
    if (store.state.boardPropertyState[2].func === 'overallSelection') {
      bus.$emit('overallSelection', true)
    } else {
      bus.$emit('partialSelection', true)
    }
  }
}

//获取当前激活的画板
export function getCurrentLayout() {
  if (!window.QLayouts) return 'INIT'
  for (let i = 0; i < window.QLayouts.length; i++) {
    const QLayout = window.QLayouts[i]
    if (QLayout.constructor.name == 'QedaCellLayout') {
      if (QLayout.STATUS.active) {
        return QLayout
      }
    }
  }
}

//重构四叉树
export function rebuildCellsQuadTree() {
  let cells = [this.TOP_CELL]
  this.TOP_CELL.depend_cells(-1).forEach(childCell => {
    cells.push(childCell)
  })
  cells.forEach(cell => (cell.js_obj.bounding_box = cell.bounding_box()))
  cells.forEach(cell => rebuildCellQuadTree(cell))
}
