// Kernel Cell对象转换Gdstk Library对象
export function KernelCell2GdstkLibrary(cell) {
  // cell构建转换lib
  let cell_refs = cell.depend_cells(-1)
  let lib = new Kernel.Library(cell.name)
  lib.add_cell(cell)
  for (let i = 0; i < cell_refs.length; i++) {
    lib.add_cell(cell_refs[i])
  }
  lib.write_gds(lib.name)
  let data_u8 = Kernel.FS.readFile(lib.name)
  // 转换gdstk对象=
  window.QGdstk.FS.writeFile(lib.name, data_u8)
  let library = window.QGdstk.read_gds(lib.name)
  return library
}

// Kernel Cell对象转换Gdstk Cell对象
export function KernelCell2GdstkCell(cell) {
  // cell构建转换lib
  let cell_refs = cell.depend_cells(-1)
  let lib = new Kernel.Library(cell.name)
  lib.add_cell(cell)
  for (let i = 0; i < cell_refs.length; i++) {
    lib.add_cell(cell_refs[i])
  }
  lib.write_gds(lib.name)
  let data_u8 = Kernel.FS.readFile(lib.name)
  // 转换gdstk对象=
  window.QGdstk.FS.writeFile(lib.name, data_u8)
  let library = window.QGdstk.read_gds(lib.name)
  let gds_cell = null
  for (let i = 0; i < library.cells.length; i++) {
    if (library.cells[i].name == cell.name) {
      return library.cells[i]
    }
  }
}

// Gdstk Cell转Kernel Cell
export function GdstkCell2KernelCell(cell, fileLayerList) {
  let kernel_cell = new window.Kernel.Cell(cell.name)
  cell.paths.forEach(element => {
    let path = new Kernel.GdsFlexpath(element.points, inner_width, 0, 'round', 'flush', radius, 1e-2, false, true, layer, datatype)
    kernel_cell.add(path)
  })
  cell.polygons.forEach(element => {
    let points = []
    for (let i = 0; i < element.points.length; i++) {
      let p = element.points[i]
      points.push([p[0], p[1]])
    }
    let p = new Kernel.GdsPolygon(points)
    let layer = fileLayerList[parseInt(element.layer)]
    if (fileLayerList[parseInt(element.layer)]) p.id = layer.id
    else p.id = String(parseInt(element.layer))
    p.layer = element.layer
    p.datatype = element.datatype
    kernel_cell.add_polygon(p)
  })
  return kernel_cell
}

// Gdstk Cell转Kernel Cell
export function KernelFlexPath2GdstkFlexPath(paths) {
  let new_paths = []
  paths.forEach(p => {
    new_paths.push()
  })
}
