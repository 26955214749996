const LINEVS = `
flat out vec3 p0;
out vec3 p;
uniform bool isDash;
// attribute float preCheck;
// attribute float checked;

// varying float preCheck_v;
// varying float checked_v;
void main()
{
    // preCheck_v = preCheck;
    // checked_v = checked;
    vec4 pos = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    gl_Position = pos;
    if(isDash){
        p0 = pos.xyz / pos.w;
        p  = p0;
    }

}
`
const LINEFS = `
precision lowp float;
flat in vec3 p0;
in vec3 p;
uniform vec4 color;
// uniform vec3 preCheckColor;
// uniform vec3 checkedColor;

uniform bool isDash;
uniform vec2  canvas;
uniform float dash;
uniform float gap;

// varying float preCheck_v;
// varying float checked_v;

void main()
{
    if(isDash){ //虚线
    	vec2 dir = (p.xy - p0.xy) * canvas.xy * 2.0;
    	float dist = length(dir);
    	if (fract(dist / (dash + gap)) > dash / (dash + gap))
    		discard;
    }
    //vec4 finall_color = color;
    // if(bool(checked_v)){
    //   finall_color = checkedColor;
    // }else if(bool(preCheck_v)){
    //   finall_color = preCheckColor;
    // }
    gl_FragColor = color;
}
`

const LINEIN_VS = `
flat out vec3 p0;
out vec3 p;
uniform bool isDash;
attribute mat4 globalMatrix;
attribute float preCheck;
attribute float checked;

varying float preCheck_v;
varying float checked_v;
void main()
{
    preCheck_v = preCheck;
    checked_v = checked;
    vec4 pos = projectionMatrix * viewMatrix * globalMatrix * vec4(position, 1.0);
    gl_Position = pos;
    if(isDash){
        p0 = pos.xyz / pos.w;
        p  = p0;
    }

}
`
const LINEIN_FS = `
precision lowp float;
flat in vec3 p0;
in vec3 p;
uniform vec4 color;
uniform vec4 preCheckColor;
uniform vec4 checkedColor;

uniform bool isDash;
uniform vec2  canvas;
uniform float dash;
uniform float gap;

varying float preCheck_v;
varying float checked_v;

void main()
{
    if(isDash){ //虚线
    	vec2 dir = (p.xy - p0.xy) * canvas.xy * 2.0;
    	float dist = length(dir);
    	if (fract(dist / (dash + gap)) > dash / (dash + gap))
    		discard;
    }
    // vec4 finall_color = color;
    // if(bool(checked_v)){
    //   finall_color = checkedColor;
    // }else if(bool(preCheck_v)){
    //   finall_color = preCheckColor;
    // }
    gl_FragColor = color;
}
`
const FILLVS = `
// attribute float preCheck;
// attribute float checked;
// varying float preCheck_v;
// varying float checked_v;
void main()
{
    // preCheck_v = preCheck;
    // checked_v = checked;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

const FILLFS = `
precision highp float;
uniform vec4 color;
// uniform vec3 preCheckColor;
// uniform vec3 checkedColor;
// varying float preCheck_v;
// varying float checked_v;
uniform bool useTexture; //使用纹理
uniform float width; //线宽
uniform float space; //网格间距
uniform float line_width; //线段长度
uniform float dot; //线段空隙间距
uniform float angle; //旋转角度
uniform float scale; //缩放
uniform float only_side; //只绘制一边 0 || 1 默认0
uniform float use_point_grid; //使用点阵 0 || 1 默认0

// float grid2(float value, float space, float width, float grid_size) {
//     return mod(value + width, space * grid_size) - width;
// }
float grid(float value, float width, float space, float vertical, float line_width, float dot) {
  float total = line_width + dot; //线长加间距 -----  ----- -----
  float pos = mod(vertical, total);
  float dot_weight = step(1.,dot)*step(pos,line_width)*( width + 1.);
  // if(pos <= line_width){
  //     dot_weight = width + 1.; //计算点距权重
  // }
  return mod(value + width * 0.5, space) - width + dot_weight;
}

void main()
{
  vec4 finall_color = color;
  if(useTexture){
    mat3 matrix = mat3(
        vec3(cos(angle) * scale,sin(angle),0),
        vec3(-sin(angle),cos(angle) * scale,0),
        vec3(0,0,1)
    );
    vec2 pp = (matrix * vec3(gl_FragCoord.x,gl_FragCoord.y,0)).xy;
    float mx = 1.-smoothstep(0., 1., grid(pp.x, width, space, pp.y - line_width/2., line_width, dot));
    float my = step(1.,only_side)*(1.-smoothstep(0., 1., grid(pp.y, width, space, pp.x - line_width/2., line_width, dot)));
    finall_color = mix(vec4(0.), color, clamp(mx + my - use_point_grid, 0.0, color.a));//点阵
    // if(only_side){
    //     my = 1.-smoothstep(0., 1., grid(pp.y, width, space, pp.x - line_width/2., line_width, dot));
    // }
    // if(use_point_grid){
    //   finall_color = mix(vec4(0.), color, clamp(mx + my - 1., 0.0, color.a));//点阵
    // }else{
    //   finall_color = mix(vec4(0.), color, clamp(mx + my, 0.0, color.a));//网格
    // }
  }

    // vec4 finall_color = color;
    // if(bool(checked_v)){
    //   finall_color = checkedColor;
    // }else if(bool(preCheck_v)){
    //   finall_color = preCheckColor;
    // }
    gl_FragColor = finall_color;
    
}
`

const FILLIN_VS = `
attribute float preCheck;
attribute float checked;

varying float preCheck_v;
varying float checked_v;
void main()
{
    preCheck_v = preCheck;
    checked_v = checked;
    gl_Position = projectionMatrix * viewMatrix * instanceMatrix * vec4(position, 1.0);
}
`

const FILLIN_FS = `
precision highp float;
uniform vec4 color;
uniform vec4 preCheckColor;
uniform vec4 checkedColor;
varying float preCheck_v;
varying float checked_v;
uniform bool useTexture; //使用纹理
uniform float width; //线宽
uniform float space; //网格间距
uniform float line_width; //线段长度
uniform float dot; //线段空隙间距
uniform float angle; //旋转角度
uniform float scale; //缩放
uniform float only_side; //只绘制一边 0 || 1 默认0
uniform float use_point_grid; //使用点阵 0 || 1 默认0

float grid(float value, float width, float space, float vertical, float line_width, float dot) {
  float total = line_width + dot; //线长加间距 -----  ----- -----
  float pos = mod(vertical, total);
  float dot_weight = step(1.,dot)*step(pos,line_width)*( width + 1.);
  return mod(value + width * 0.5, space) - width + dot_weight;
}

void main()
{
  vec4 finall_color = color;
  if(useTexture){
    mat3 matrix = mat3(
        vec3(cos(angle) * scale,sin(angle),0),
        vec3(-sin(angle),cos(angle) * scale,0),
        vec3(0,0,1)
    );
    vec2 pp = (matrix * vec3(gl_FragCoord.x,gl_FragCoord.y,0)).xy;
    float mx = 1.-smoothstep(0., 1., grid(pp.x, width, space, pp.y - line_width/2., line_width, dot));
    float my = step(1.,only_side)*(1.-smoothstep(0., 1., grid(pp.y, width, space, pp.x - line_width/2., line_width, dot)));
    finall_color = mix(vec4(0.), color, clamp(mx + my - use_point_grid, 0.0, color.a));//点阵
  }
    // vec4 finall_color = color;
    // if(bool(checked_v)){
    //   finall_color = checkedColor;
    // }else if(bool(preCheck_v)){
    //   finall_color = preCheckColor;
    // }
    gl_FragColor = finall_color;
    
}
`
const FONTVS = `
  uniform float scale;
  // attribute float preCheck;
  // attribute float checked;
  // varying float preCheck_v;
  // varying float checked_v;
  void main()
  {
      // preCheck_v = preCheck_v;
      // checked_v = checked_v;
      //绝对渲染
      mat4 absPos = mat4(
        vec4(scale,0.,0.,0.),
        vec4(0.,scale,0.,0.),
        vec4(0.,0.,1,0.),
        vec4(0.,0.,0.,1.));
      gl_Position = projectionMatrix * modelViewMatrix * absPos * vec4(position, 1.0);
  }
`
const FONTFS = `
  uniform vec4 color;
  // uniform vec3 preCheckColor;
  // uniform vec3 checkedColor;
  // varying float preCheck_v;
  // varying float checked_v;
  void main()
  {
    // vec3 finall_color = color;
    // if(bool(checked_v)){
    //   finall_color = checkedColor;
    // }else if(bool(preCheck_v)){
    //   finall_color = preCheckColor;
    // }
      gl_FragColor = color;
  }
`

const FONTIN_VS = `
  uniform float scale;
  attribute float preCheck;
  attribute float checked;

  varying float preCheck_v;
  varying float checked_v;
  void main()
  {
      preCheck_v = preCheck;
      checked_v = checked;
      //绝对渲染
      mat4 absPos = mat4(
        vec4(scale,0.,0.,0.),
        vec4(0.,scale,0.,0.),
        vec4(0.,0.,1,0.),
        vec4(0.,0.,0.,1.));
      gl_Position = projectionMatrix *  viewMatrix * instanceMatrix * modelMatrix * absPos * vec4(position, 1.0); //投影矩阵-相机视图矩阵-器件引用矩阵-标签矩阵-视图缩放矩阵
  }
`

const FONTIN_FS = `
  uniform vec4 color;
  uniform vec4 preCheckColor;
  uniform vec4 checkedColor;
  varying float preCheck_v;
  varying float checked_v;
  void main()
  {
    // vec4 finall_color = color;
    // if(bool(checked_v)){
    //   finall_color = checkedColor;
    // }else if(bool(preCheck_v)){
    //   finall_color = preCheckColor;
    // }
      gl_FragColor = color;
  }
`

const KEYPOINTVS = `
varying vec4 vPos;
uniform float pointsSize;
void main()
{
    vPos = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    gl_Position = vPos;
    gl_PointSize = pointsSize;
}
`
const KEYPOINTIN_VS = `
varying vec4 vPos;
attribute mat4 globalMatrix;
uniform float pointsSize;
void main()
{
    vPos = projectionMatrix * viewMatrix * globalMatrix * vec4(position, 1.0);
    gl_Position = vPos;
    gl_PointSize = pointsSize;
}
`

const KEYPOINTFS = `
  varying vec4 vPos;
  uniform vec4 color;
  void main()
  {
      // 计算方形区域每个片元距离方形几何中心的距离
      // gl.POINTS模式点渲染的方形区域,方形中心是0.5,0.5,左上角是坐标原点,右下角是1.0,1.0，
      float r = distance(gl_PointCoord, vec2(0.5, 0.5));

      //根据距离设置片元
      if(r < 0.5){
          // 方形区域片元距离几何中心半径小于0.5，像素颜色设置红色
          gl_FragColor = color;
      }else {
          // 方形区域距离几何中心半径大于等于0.5的片元剪裁舍弃掉：
          discard;
      }
  }
`

const ANCHORVS = `
  varying vec4 vPos;
  uniform float pointsSize;
  void main()
  {
      vPos = projectionMatrix * viewMatrix * vec4(position, 1.0);
      gl_Position = vPos;
      gl_PointSize = pointsSize;
  }
`
const ANCHORFS = `
  varying vec4 vPos;
  uniform vec3 color;
  void main()
  {
      // 计算方形区域每个片元距离方形几何中心的距离
      // gl.POINTS模式点渲染的方形区域,方形中心是0.5,0.5,左上角是坐标原点,右下角是1.0,1.0，
      float r = distance(gl_PointCoord, vec2(0.5, 0.5));

      float d_x = abs(gl_PointCoord.x - 0.5);
      float d_y = abs(gl_PointCoord.y - 0.5);
      float w = 0.05;

      if(d_x < w || d_y < w){
        // 方形区域片元距离几何中心半径小于0.5，像素颜色设置红色
        gl_FragColor = vec4(1.0,0.0,0.0,1.0);
      }else {
        // 方形区域距离几何中心半径不小于0.5的片元剪裁舍弃掉：
        discard;
      }
  }
`
export function generateLineMat(color_v4, canvas, color_pre, color_c) {
  let mat = new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
      canvas: { type: 'v2', value: { x: canvas.width, y: canvas.height } },
      isDash: { type: 'b', value: false },
      dash: { type: 'f', value: 0 },
      gap: { type: 'f', value: 0 },
      color: { type: 'v4', value: color_v4 },
      preCheckColor: { type: 'v4', value: color_pre },
      checkedColor: { type: 'v4', value: color_c },
    },
    vertexShader: LINEVS,
    fragmentShader: LINEFS,
  })
  return mat
}
export function generateFillMat(color_v4, color_pre, color_c) {
  let mat = new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
      color: { type: 'v4', value: color_v4 },
      preCheckColor: { type: 'v4', value: color_pre },
      checkedColor: { type: 'v4', value: color_c },
      useTexture: { type: 'b', value: true },
      width: { type: 'f', value: 2 },
      space: { type: 'f', value: 15 },
      line_width: { type: 'f', value: 0 },
      dot: { type: 'f', value: 0 },
      angle: { type: 'f', value: 45 },
      only_side: { type: 'f', value: 0 },
      use_point_grid: { type: 'f', value: 0 },
      scale: { type: 'f', value: 1 },
    },
    vertexShader: FILLVS,
    fragmentShader: FILLFS,
  })
  return mat
}

export function generateLineInsMat(color_v4, canvas, color_pre, color_c) {
  let mat = new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
      canvas: { type: 'v2', value: { x: canvas.width, y: canvas.height } },
      isDash: { type: 'b', value: false },
      dash: { type: 'f', value: 0 },
      gap: { type: 'f', value: 0 },
      color: { type: 'v4', value: color_v4 },
      preCheckColor: { type: 'v4', value: color_pre },
      checkedColor: { type: 'v4', value: color_c },
    },
    vertexShader: LINEIN_VS,
    fragmentShader: LINEIN_FS,
  })
  return mat
}

export function generateFillInsMat(color_v4, color_pre, color_c, fill = true) {
  if (!fill) {
    color_v4 = color_v4.clone()
    color_v4.w = 0
  }
  let mat = new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
      color: { type: 'v4', value: color_v4 },
      preCheckColor: { type: 'v4', value: color_pre },
      checkedColor: { type: 'v4', value: color_c },
      useTexture: { type: 'b', value: true },
      width: { type: 'f', value: 2 },
      space: { type: 'f', value: 20 },
      line_width: { type: 'f', value: 5 },
      dot: { type: 'f', value: 10 },
      angle: { type: 'f', value: 45 },
      only_side: { type: 'f', value: 0 },
      use_point_grid: { type: 'f', value: 0 },
      scale: { type: 'f', value: 1 },
    },
    vertexShader: FILLIN_VS,
    fragmentShader: FILLIN_FS,
  })
  return mat
}

export function generateFontMat(color_v4, color_pre, color_c) {
  let mat = new THREE.ShaderMaterial({
    depthTest: false,
    // depthWrite: true,
    transparent: true,
    wireframe: false, //文本顶点debug
    uniforms: {
      scale: { type: 'float', value: 1 },
      color: { type: 'v4', value: color_v4 },
      preCheckColor: { type: 'v4', value: color_pre },
      checkedColor: { type: 'v4', value: color_c },
    },
    vertexShader: FONTVS,
    fragmentShader: FONTFS,
  })
  return mat
}

export function generateFontInsMat(color_v4, color_pre, color_c) {
  let mat = new THREE.ShaderMaterial({
    depthTest: false,
    // depthWrite: true,
    transparent: true,
    wireframe: false, //文本顶点debug
    uniforms: {
      scale: { type: 'float', value: 1 },
      color: { type: 'v4', value: color_v4 },
      preCheckColor: { type: 'v4', value: color_pre },
      checkedColor: { type: 'v4', value: color_c },
    },
    vertexShader: FONTIN_VS,
    fragmentShader: FONTIN_FS,
  })
  return mat
}
export function generateKeyPointMat(color_v4) {
  let mat = new THREE.ShaderMaterial({
    depthTest: false,
    // depthWrite: false,
    transparent: true,
    uniforms: {
      pointsSize: { type: 'float', value: 8 },
      color: { type: 'v4', value: color_v4 },
    },
    vertexShader: KEYPOINTVS,
    fragmentShader: KEYPOINTFS,
  })
  return mat
}

export function generateKeyPointInsMat(color_v4) {
  let mat = new THREE.ShaderMaterial({
    depthTest: false,
    // depthWrite: false,
    transparent: true,
    uniforms: {
      pointsSize: { type: 'float', value: 8 },
      color: { type: 'v4', value: color_v4 },
    },
    vertexShader: KEYPOINTIN_VS,
    fragmentShader: KEYPOINTFS,
  })
  return mat
}

export function generateAnchorMat(color_v3) {
  let mat = new THREE.ShaderMaterial({
    depthTest: false,
    // depthWrite: false,
    transparent: true,
    uniforms: {
      pointsSize: { type: 'float', value: 20 },
      color: { type: 'v3', value: new THREE.Vector3(0, 1, 0) },
    },
    vertexShader: ANCHORVS,
    fragmentShader: ANCHORFS,
  })
  return mat
}

export function generateAxisMat() {
  return new THREE.ShaderMaterial({
    uniforms: {
      step_v: { type: 'float', value: 0 }, //小网格像素宽度
      transform_p: { type: 'v2', value: new THREE.Vector2(0, 0) }, //位移变换
      loop_p: { type: 'v2', value: new THREE.Vector2(0, 0) }, //循环坐标系 由CPU计算避免GPU在大于10^7浮点计算丢失精度
      bg_color: { type: 'v4', value: new THREE.Vector4(0, 0, 0, 1) }, //背景颜色
      axis_color: { type: 'v4', value: new THREE.Vector4(1, 1, 1, 1) }, //坐标轴颜色
      line_color: { type: 'v4', value: new THREE.Vector4(1, 1, 1, 1) }, //grid颜色
      width: { type: 'float', value: 1800 }, //屏幕宽
      height: { type: 'float', value: 900 }, //屏幕高
      show_grid: { type: 'b', value: true }, //显示grid
      device_ratio: { type: 'float', value: 1 }, //像素比例
    },
    vertexShader: `
          varying vec2 vUv;
          void main() {
              vUv = uv;
              gl_Position = vec4( position, 1.0 );    
          }
        `,
    fragmentShader: `
  
          varying vec2 vUv;
  
          uniform float step_v;
          uniform vec2  transform_p;
          uniform vec2  loop_p;
          uniform vec4  bg_color;
          uniform vec4  axis_color;
          uniform vec4  line_color;
          uniform float  width;
          uniform float  height;
          uniform bool  show_grid;
          uniform float  device_ratio;

          float grid(float value, float space, float width, float grid_size) {
              return mod(value + width, space * grid_size) - width;
          }
  
          void main() {
              vec2 p = vec2(gl_FragCoord.x,-gl_FragCoord.y + height)*device_ratio;//vec2(vUv.x*width, (1.0-vUv.y)*height );
              vec2 pp = p; //拷贝像素坐标
              pp.x -= loop_p.x; //位移变换
              pp.y -= loop_p.y;
              float axis_w = 1.0; //坐标轴宽度
              float w = 1.0; //网格宽度
              float w_2 = 1.0; //网格宽度
              float space = step_v; //点间距，方格宽度
              vec2 pos;
              vec4 col;
        
              pos.x = grid(pp.x, space, w, 5.0);
              pos.y = grid(pp.y, space, w, 5.0);
              col = bg_color;
              if(show_grid){
                if (pos.x<=0. || pos.y<=0.) { //计算大网格
                    col = line_color;
                } else {
                    pos.x = grid(pp.x, space, w, 1.0); //计算小网格
                    pos.y = grid(pp.y, space, w, 1.0);
                    if (pos.x<=0. || pos.y<=0.) {
                      col = line_color*0.5;
                    }
                }
              }
              if(abs(p.x - transform_p.x) <= axis_w || abs(p.y - transform_p.y) <= axis_w){ //计算坐标轴
                col = axis_color;
              }
              gl_FragColor = col;
          }
  
        `,
  })
}

export function generateMouseCrossMat() {
  return new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
      color: { type: 'v4', value: new THREE.Vector4(0, 1, 0, 1) }, //背景颜色
      mouse_pos: { type: 'v2', value: new THREE.Vector2(300.0, 300.0) }, //鼠标像素位置
      width: { type: 'float', value: 0 }, //屏幕宽
      height: { type: 'float', value: 0 }, //屏幕高
      show_x: { type: 'b', value: true }, //显示X
      show_y: { type: 'b', value: true }, //显示y
      device_ratio: { type: 'float', value: 1 }, //像素比例
    },
    vertexShader: `
              void main() {
                  gl_Position = vec4( position, 1.0 );    
              }
            `,
    fragmentShader: `
              uniform vec4  color;
              uniform vec2  mouse_pos;
              uniform float  width;
              uniform float  height;
              uniform bool  show_x;
              uniform bool  show_y;
              uniform float  device_ratio;
              void main() {
                float w = 1.0;
                float w_hf = w/2.;
                vec2 p = vec2(gl_FragCoord.x,gl_FragCoord.y)*device_ratio;
                vec4 col;
                if(show_x && abs(p.x - mouse_pos.x) <= w_hf){
                    col = color;
                }
                if(show_y && abs(p.y - mouse_pos.y) <= w_hf){
                    col = color;
                }
                gl_FragColor = col;
              }
      
            `,
  })
}

export function generateRenderArea3DMat(color, w, h, alpha = 1) {
  return new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
      color: { type: 'v4', value: new THREE.Vector4(color.r, color.g, color.b, alpha) }, //背景颜色
      canvas: { type: 'v2', value: { x: w, y: h } }, //画布大小
    },
    vertexShader: `
              flat out vec3 p0;
              out vec3 p;
              void main() {
                vec4 pos = projectionMatrix * viewMatrix * vec4(position, 1.0);
                gl_Position = pos;
                p0 = pos.xyz / pos.w;
                p  = p0;
              }
            `,
    fragmentShader: `
              flat in vec3 p0;
              in vec3 p;
              uniform vec4  color;
              uniform vec2  canvas;
              void main() {
                float dash = 40.;
                float gap = 40.;
                vec2 dir = (p.xy - p0.xy) * canvas.xy * 2.0;
                float dist = length(dir);
                if (fract(dist / (dash + gap)) > dash / (dash + gap))
                  discard;
                gl_FragColor = color;
              }
            `,
  })
}
