import Stats from 'three/examples/jsm/libs/stats.module'
import {
  Tools,
  computeRefs,
  computeRefMats,
  computeCellMesh,
  computeCellInstance,
  divideRectangle,
  generateShapeGeometry,
  computeRefByAreas,
  updateGroupsPosition,
  hexToRgb,
  axisLoopOffset,
  drawRuler,
  clearChildern,
  clearGroup,
} from './utils/render-util'
import { initPoint, initLine, initLineLoop, updateLineInsAttr, updateFillAttr, disposeGraphics, updateCellInsRefs, updateCellBoundingBox, updateRefGraphic, cloneThreeObj, applyNewMat, updatePointInsAttr } from './utils/graphics-util'
import { generateTextMesh, generateLabelMesh } from './utils/font-util.js'
import { douglasPeucker } from './utils/douglas-peucker.js'
import { EffectComposer } from 'three/examples/js/postprocessing/EffectComposer'
import { CopyShader } from 'three/examples/js/shaders/CopyShader'
import { RenderPass } from 'three/examples/js/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/js/postprocessing/ShaderPass'
import { SMAAPass } from 'three/examples/js/postprocessing/SMAAPass'
import { FXAAShader } from 'three/examples/js/shaders/FXAAShader'
import { SMAAShader } from 'three/examples/js/shaders/SMAAShader'
import { BufferGeometryUtils } from 'three/examples/js/utils/BufferGeometryUtils'
import { claRefsPointsArray, computeRefsMat, computeTargetRefs, containCell, getRefFontMesh, getRefLinesArrayBuffer, getRefPointsArrayBuffer, getRefsCellsPtr, getRepetitionCellsPtr, updateRefMats } from './utils/reference-util'
import { Matrix4 } from 'three'
import { layerIsHide } from './utils/layer-util'
import { cutObj } from './utils/layout-function'
export class QedaCellStage {
  constructor(canvasId, config) {
    this.canvasId = canvasId //画布id
    this.scene = new THREE.Scene() //场景
    this.renderer = null //渲染引擎
    this.composer = null //后处理
    this.camera = null //正交相机
    this.stats = null //帧数显示
    this.config = config //显示设置
    this.cell = null
    this.cellsMatMap = {} //cell 图形数据
    this.mergeInfo = null //合并图形数据
    this.cellGroup = new THREE.Group() //版图图形数据
    this.refsGroup = new THREE.Group() //版图引用实例数据
    this.textGroup = new THREE.Group()
    this.labelGroup = new THREE.Group() //标签
    this.anchorGroup = new THREE.Group() //标签锚点
    this.axisLineGroup = new THREE.Group() //背景网格
    this.mouseLineGroup = new THREE.Group() //鼠标十字线
    this.drawingGroup = new THREE.Group() //正在绘制的对象
    this.toolsGroup = new THREE.Group() //工具对象
    this.tools = new Tools(this.toolsGroup, this.config) //绘制工具
    this.preCheckGroup = new THREE.Group() //预选中对象
    this.preCheckPoint = initPoint(this.config.toolsMat.preCheckPoint) //预选中点
    this.preCheckLine = initLine(this.config.toolsMat.preCheckLine) //预选中线
    this.checkedPoints = initPoint(this.config.toolsMat.checkedPoints) //选中点
    this.checkedLines = initLine(this.config.toolsMat.checkedLines) //选中线
    this.checkedAlignPoint = initPoint(this.config.toolsMat.checkedAlignPoint) //点对齐选中点
    this.checkedAlignLine = initLine(this.config.toolsMat.checkedAlignLine) //选中线
    this.preCheckedRefBox = initLineLoop(this.config.toolsMat.preCheckLine) //预选器件
    this.checkedRefBoxs = initLine(this.config.toolsMat.checkedLines) //选中器件
    this.preCutRefBoxs = initLine(this.config.toolsMat.preCutLines) //剪切器件
    this.preAddGroup = new THREE.Group() //预添加对象
    this.preAddRulerGroup = new THREE.Group() //预添加尺子对象
    this.preAddRefGroup = new THREE.Group() //预添加外部器件
    this.keyPointsGroup = new THREE.Group() //关键点对象 initPoint(this.config.keyPointMatTest) //关键点集合
    this.editRefBorder = initLine(this.config.toolsMat.editRefBorder) //编辑下层的边框
    this.editRefCheckedObjs = new THREE.Group() //编辑下层选中对象
    this.editRefPreCheckedRefBoxs = initLineLoop(this.config.toolsMat.checkedLines) //编辑下层预选中器件
    this.editRefCheckedRefBoxs = initLine(this.config.toolsMat.checkedLines) //编辑下层选中器件
    this.editRefPreCutRefBoxs = initLine(this.config.toolsMat.preCutLines) //编辑下层剪切器件
    this.drcGraphics = new THREE.Group() //drc图形
    this.renderLod = false
    this.active = true //激活状态
    this.debugLines = initLine(this.config.toolsMat.checkedLines) //debug
    // this.initStats()
    this.initRenderer()
    this.test()
    this.animate = this.drawFrame.bind(this) //绘制循环
    this.requestID = requestAnimationFrame(this.animate)
  }

  delete() {
    this.active = false
    // clearChildern(this.scene)
    this.scene.clear()
    cancelAnimationFrame(this.requestID)
  }

  initStats() {
    //实例化
    if (window.Stats) {
      this.stats = window.Stats
    } else {
      this.stats = new Stats()
    }
    //setMode参数如果是0，监测的是FPS信息，如果是1，监测的是渲染时间
    this.stats.setMode(0)
    //把统计面板放到左上角
    this.stats.domElement.style.position = 'absolute'
    this.stats.domElement.style.top = '0px'
    this.stats.domElement.style.left = '0px'
    //添加到body里
    document.body.appendChild(this.stats.domElement)
  }

  //初始化渲染器
  initRenderer() {
    let ratio = 1 //window.devicePixelRatio //默认为1
    let container = document.getElementById('canvas_container')
    let canvasElm = document.getElementById(this.canvasId)

    let width = 2319 //canvasElm.width //window.innerWidth
    let height = 781 //canvasElm.height //window.innerHeight

    // 初始化渲染器
    this.renderer = new THREE.WebGLRenderer({ canvas: canvasElm, antialias: true, depth: true, alpha: true, logarithmicDepthBuffer: false, devicePixelRatio: ratio, powerPreference: 'high-performance' }) //
    // 渲染器的大小
    this.renderer.setPixelRatio(ratio)
    this.renderer.setSize(container.clientWidth, container.clientHeight)
    this.renderer.setClearColor(0x1e1e1e, 1)
    // 正交相机
    this.camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 0, 1000)
    this.camera.position.z = 10

    const renderPass = new THREE.RenderPass(this.scene, this.camera)
    // renderPass.clearColor = new THREE.Color(0, 0, 0)
    renderPass.clearAlpha = 0
    //抗锯齿后处理
    this.composer = new THREE.EffectComposer(this.renderer)
    this.composer.addPass(renderPass)

    //SMAA抗锯齿
    this.config.antialias.smaaPass = new THREE.SMAAPass(width * this.renderer.getPixelRatio(), height * this.renderer.getPixelRatio())
    // this.composer.addPass(smaa_pass)

    // FXAA抗锯齿
    var fxaaPass = new THREE.ShaderPass(THREE.FXAAShader)

    const pixelRatio = this.renderer.getPixelRatio()
    fxaaPass.material.uniforms['resolution'].value.x = 1 / (canvasElm.offsetWidth * pixelRatio)
    fxaaPass.material.uniforms['resolution'].value.y = 1 / (canvasElm.offsetHeight * pixelRatio)
    fxaaPass.renderToScreen = true
    this.config.antialias.fxaaPass = fxaaPass
    // this.composer.addPass(fxaaPass)
    this.labelGroup.renderOrder = 2
    this.anchorGroup.renderOrder = 3

    this.scene.add(this.labelGroup)
    this.scene.add(this.anchorGroup)
    this.preCheckLine.position.z = 1
    this.preCheckLine.frustumCulled = false
    this.preCheckPoint.position.z = 2
    this.preCheckPoint.frustumCulled = false
    this.checkedLines.position.z = 3
    this.checkedLines.frustumCulled = false
    this.checkedPoints.position.z = 4
    this.checkedPoints.frustumCulled = false
    this.checkedAlignPoint.position.z = 4.1
    this.checkedAlignPoint.frustumCulled = false
    this.checkedAlignLine.position.z = 3.1
    this.checkedAlignLine.frustumCulled = false
    this.checkedRefBoxs.position.z = 3.1
    this.checkedRefBoxs.frustumCulled = false
    this.preCutRefBoxs.position.z = 3.1
    this.preCutRefBoxs.frustumCulled = false

    //*****编辑下层相关图形****//
    this.editRefBorder.position.z = 5
    this.editRefBorder.frustumCulled = false
    this.editRefCheckedObjs.position.z = 5
    this.editRefPreCheckedRefBoxs.position.z = 5.1
    this.editRefPreCheckedRefBoxs.frustumCulled = false
    this.editRefCheckedRefBoxs.position.z = 5.1
    this.editRefCheckedRefBoxs.frustumCulled = false
    this.editRefPreCutRefBoxs.position.z = 5.1
    this.editRefPreCutRefBoxs.frustumCulled = false
    this.scene.add(this.preCheckPoint)
    this.scene.add(this.preCheckLine)
    this.scene.add(this.checkedPoints)
    this.scene.add(this.checkedLines)
    this.scene.add(this.checkedAlignPoint)
    this.scene.add(this.checkedAlignLine)
    this.scene.add(this.checkedRefBoxs)
    this.scene.add(this.preCutRefBoxs)
    this.scene.add(this.editRefBorder)
    this.scene.add(this.editRefCheckedObjs)
    this.scene.add(this.editRefPreCheckedRefBoxs)
    this.scene.add(this.editRefCheckedRefBoxs)
    this.scene.add(this.editRefPreCutRefBoxs)

    this.scene.add(this.debugLines)
  }
  resize(LAYOUT) {
    let ratio = window.devicePixelRatio
    let w = LAYOUT.AXIS.width //container.clientWidth //container.clientWidth //canvasElm.width//window.innerWidth //
    let h = LAYOUT.AXIS.height //container.clientWidth //container.clientHeight //canvasElm.height//window.innerHeight //
    //更新坐标网格十字线全屏后处理
    this.axisLineGroup.children[0].geometry = new THREE.PlaneGeometry(w, h, 1, 1)
    this.mouseLineGroup.children[0].geometry = new THREE.PlaneGeometry(w, h, 1, 1)

    // this.camera.aspect = w / h
    this.camera.left = w / -2
    this.camera.right = w / 2
    this.camera.top = h / 2
    this.camera.bottom = h / -2
    this.renderer.setSize(w, h)
    this.renderer.setViewport(0, 0, w, h)
    //this.renderer.setPixelRatio(ratio) //更新后需要向全屏后处理着色器传入像素比例
    this.composer.setSize(w, h)
    this.updateAxisShaderUniforms(LAYOUT.AXIS)
    this.updateCameraMatrix(LAYOUT.AXIS)
    //更新依赖画布大小的材质
    this.config.resize(w, h)

    //重绘尺子
    setTimeout(() => {
      this.renderRuler(LAYOUT.canvasRulerX, LAYOUT.canvasRulerY, LAYOUT.AXIS)
    })
    //自适应屏幕重新渲染
    this.lodGraphics()
    this.lodCellInsGraphics(LAYOUT.RENDER_CELL.js_obj.cellInsMaps)
    // this.frustumCullMergeData()
    this.scaleLabel()
    this.reCalDividRuler(LAYOUT)
  }

  test() {
    // const box_geometry = new THREE.BoxGeometry(1, 1, 1)
    // const material = new THREE.MeshBasicMaterial({ color: 0x4caf50 })
    // const cube = new THREE.Mesh(box_geometry, material)
    // this.scene.add(cube)

    // let textMesh = generateTextMesh('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', null, 100, '#ffbbff')
    // this.textGroup.add(textMesh)
    // this.scene.add(this.textGroup)

    let w = 2
    const AxisQuad = new THREE.Mesh(new THREE.PlaneGeometry(w, w, 1, 1), this.config.axisMaterial)
    AxisQuad.frustumCulled = false
    this.axisLineGroup.add(AxisQuad)
    this.scene.add(this.axisLineGroup)

    this.scene.add(this.cellGroup)
    this.scene.add(this.refsGroup)
    const MouseCrossQuad = new THREE.Mesh(new THREE.PlaneGeometry(w, w, 1, 1), this.config.mouseCrossMat)
    MouseCrossQuad.frustumCulled = false
    MouseCrossQuad.material.depthTest = false
    this.mouseLineGroup.add(MouseCrossQuad)
    this.mouseLineGroup.renderOrder = 999
    this.scene.add(this.mouseLineGroup)
    this.scene.add(this.drawingGroup)
    this.scene.add(this.toolsGroup)
    this.keyPointsGroup.position.z = 5
    this.scene.add(this.keyPointsGroup)

    this.scene.add(this.preCheckGroup)
    this.scene.add(this.preAddGroup)
    this.preAddRulerGroup.position.z = 5
    this.scene.add(this.preAddRulerGroup)
    this.scene.add(this.drcGraphics)
  }

  updateCameraMatrix(axis) {
    this.camera.position.x = (-axis.dx + axis.width / 2) / axis.scale
    this.camera.position.y = (axis.dy - axis.height / 2) / axis.scale
    this.camera.zoom = axis.scale
    this.camera.updateMatrix()
    this.camera.updateProjectionMatrix()
    this.camera.updateMatrixWorld(true)
  }

  //绘制循环
  drawFrame() {
    this.requestID = requestAnimationFrame(this.animate)
    if (this.active) {
      this.composer.render()
      // this.renderer.render(this.scene, this.camera)
      // this.stats.update()
    }
  }

  setAntialias() {
    this.renderer.antialias = this.config.antialias.useAntialias
    // this.composer.removePass(this.config.antialias.smaaPass)
    this.composer.removePass(this.config.antialias.fxaaPass)
    // if (this.config.antialias.useSmaaPass) {
    //   this.composer.addPass(this.config.antialias.smaaPass)
    // }
    if (this.config.antialias.useFxaaPass) {
      this.composer.addPass(this.config.antialias.fxaaPass)
    }
  }

  //生成版图图形数据
  renderCell(cell, layerMats) {
    console.time('渲染cell')
    this.cell = cell
    let divideAreas = divideRectangle(cell.bounding_box(), 15)

    // this.config.updateLayerMats(getCellLayers(cell))

    let cells = cell.dependencies(true)
    cells.push(cell)
    this.cellsMatMap = {}
    cells.forEach(cell => {
      this.cellsMatMap[cell.name] = { cell, cellGraphics: { paths: [], polygons: [], labels: [] }, cellInstance: { paths: [], polygons: [], labels: [] }, refs: [], matsArray: [] }
    })
    let refs = cell.references

    console.time('解构版图引用为cell和矩阵列表')
    computeRefs(refs, this.cellsMatMap)
    console.timeEnd('解构版图引用为cell和矩阵列表')

    console.time('引用用网格区域归纳')
    computeRefByAreas(this.cellsMatMap, divideAreas)
    console.timeEnd('引用用网格区域归纳')

    console.time('计算引用矩阵数据')
    computeRefMats(this.cellsMatMap)
    console.timeEnd('计算引用矩阵数据')

    console.time('生成cell所有多边形instence')
    this.mergeInfo = {
      lineGeometryArray: [],
      lineMaterialArray: [],
      lineLoopGeometryArray: [],
      lineLoopMaterialArray: [],
      meshGeometryArray: [],
      meshMaterialArray: [],
      singleLineMergeData: new THREE.Line(),
      singleLineLoopMergeData: new THREE.LineLoop(),
      singleFillMergeData: new THREE.Mesh(),
      // singleLineMergeData: new THREE.Line(new THREE.InstancedBufferGeometry()),
      // singleLineLoopMergeData: new THREE.LineLoop(new THREE.InstancedBufferGeometry()),
      // singleFillMergeData: new THREE.InstancedMesh(),
    }
    for (const key in this.cellsMatMap) {
      let obj = this.cellsMatMap[key]
      if (this.cell.name == obj.cell.name) {
        computeCellMesh(this.cellGroup, this.config, layerMats, obj, this.mergeInfo)
        // computeCellInstance(this.cellGroup, this.config, layerMats,obj, this.mergeInfo)
      } else {
        //computeCellInstance(this.cellGroup, this.config, layerMats, obj)
        computeCellInstance(this.refsGroup, this.config, layerMats, obj)
      }
    }

    //合并cell图形数据
    if (this.config.useMergeBufferGeometries) {
      this.cellGroup.add(this.mergeInfo.singleLineMergeData)
      this.cellGroup.add(this.mergeInfo.singleLineLoopMergeData)
      this.cellGroup.add(this.mergeInfo.singleFillMergeData)
      this.frustumCullMergeData()
      // if (this.mergeInfo.lineGeometryArray.length) {
      //   const mergedLineGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(this.mergeInfo.lineGeometryArray, true)
      //   this.mergeInfo.singleLineMergeData = new THREE.Line(mergedLineGeometries, this.mergeInfo.lineMaterialArray)
      //   this.cellGroup.add(this.mergeInfo.singleLineMergeData)
      // }
      // if (this.mergeInfo.lineLoopGeometryArray.length) {
      //   const mergedLineLoopGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(this.mergeInfo.lineLoopGeometryArray, true)
      //   this.mergeInfo.singleLineLoopMergeData = new THREE.LineLoop(mergedLineLoopGeometries, this.mergeInfo.lineLoopMaterialArray)
      //   this.cellGroup.add(this.mergeInfo.singleLineLoopMergeData)
      // }
      // if (this.mergeInfo.meshGeometryArray.length) {
      //   const mergedMeshGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(this.mergeInfo.meshGeometryArray, true)
      //   this.mergeInfo.singleFillMergeData = new THREE.Mesh(mergedMeshGeometries, this.mergeInfo.meshMaterialArray)
      //   this.cellGroup.add(this.mergeInfo.singleFillMergeData)
      // }
    }

    //绘制标签锚点
    let labels = this.cell.get_labels()
    if (labels.length) {
      var dotGeometry = new THREE.BufferGeometry()
      let positionArray = []
      labels.forEach(label => {
        positionArray.push(label.origin[0], label.origin[1], 0)
      })
      var dotGeometry = new THREE.BufferGeometry()
      dotGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positionArray, 3))
      var dot = new THREE.Points(dotGeometry, this.config.anchorMaterial)
      clearChildern(this.anchorGroup)
      this.anchorGroup.add(dot)
    }

    console.timeEnd('生成cell所有多边形instence')
    console.timeEnd('渲染cell')
  }
  mouseMove(LAYOUT, forceMove) {
    this.updateAxisShaderUniforms(LAYOUT.AXIS)
    this.renderRuler(LAYOUT.canvasRulerX, LAYOUT.canvasRulerY, LAYOUT.AXIS)
    if (LAYOUT.STATUS.left_press || LAYOUT.STATUS.middle_press || forceMove) {
      this.updateCameraMatrix(LAYOUT.AXIS)
      // this.frustumCullMergeData()
      this.reCalDividRuler(LAYOUT)
    }
  }

  mouseWheel(e, LAYOUT) {
    this.updateCameraMatrix(LAYOUT.AXIS)
    this.updateAxisShaderUniforms(LAYOUT.AXIS)
    this.renderRuler(LAYOUT.canvasRulerX, LAYOUT.canvasRulerY, LAYOUT.AXIS)
    this.lodGraphics()
    this.lodCellInsGraphics(LAYOUT.RENDER_CELL.js_obj.cellInsMaps)
    // this.frustumCullMergeData()
    this.scaleLabel()
    this.reCalDividRuler(LAYOUT)
    if (LAYOUT.STATUS.preCheckObj && LAYOUT.STATUS.preCheckObj.constructor.name == 'Ruler') {
      this.updatePreCheckObj(LAYOUT.STATUS.preCheckObj, LAYOUT)
    }
  }

  updateAxisShaderUniforms(axis) {
    let ratio_scale = 1 / window.devicePixelRatio
    let bg = hexToRgb(this.config.colors.axisBg).map(v => v / 255)
    let gridColor = hexToRgb(this.config.colors.axisGrid).map(v => v / 255)
    let dx = axis.dx
    let dy = axis.dy
    let step = axis.step
    this.config.axisMaterial.uniforms.step_v.value = axis.step //小网格像素宽度
    this.config.axisMaterial.uniforms.transform_p.value = new THREE.Vector2(dx, dy) //位移变换
    this.config.axisMaterial.uniforms.loop_p.value = new THREE.Vector2(axisLoopOffset(dx, step), axisLoopOffset(dy, step)) //循环坐标系 由CPU计算避免GPU在大于10^7浮点计算丢失精度
    this.config.axisMaterial.uniforms.bg_color.value = new THREE.Vector4(bg[0], bg[1], bg[1], 1) //背景颜色
    let gridCol = new THREE.Vector4(gridColor[0], gridColor[1], gridColor[1], 1)
    this.config.axisMaterial.uniforms.axis_color.value = gridCol //网格颜色
    this.config.axisMaterial.uniforms.line_color.value = gridCol //网格颜色
    this.config.axisMaterial.uniforms.width.value = axis.width
    this.config.axisMaterial.uniforms.height.value = axis.height
    this.config.axisMaterial.uniforms.show_grid.value = this.config.show.axisGrid
    //this.config.axisMaterial.uniforms.device_ratio.value = ratio_scale //设置缩放比例 同时需要设置-> this.renderer.setPixelRatio(ratio)

    let x = axis.mouse_pixel_x
    let y = -axis.mouse_pixel_y + axis.height
    this.config.mouseCrossMat.uniforms.mouse_pos.value = new THREE.Vector2(x, y)
    this.config.mouseCrossMat.uniforms.width.value = axis.width
    this.config.mouseCrossMat.uniforms.height.value = axis.height
    this.config.mouseCrossMat.uniforms.show_x.value = this.config.show.mouseCrossX
    this.config.mouseCrossMat.uniforms.show_y.value = this.config.show.mouseCrossY
    //this.config.mouseCrossMat.uniforms.device_ratio.value = ratio_scale
  }

  //更新十字光标位置渲染
  updateMouseCrossPos(axis) {
    let x = axis.mouse_pixel_x
    let y = -axis.mouse_pixel_y + axis.height
    this.config.mouseCrossMat.uniforms.mouse_pos.value = new THREE.Vector2(x, y)
  }

  //合并模式下对合并对象手动剔除
  frustumCullMergeData() {
    // if (this.camera.zoom < 0.02) return
    if (this.config.useMergeBufferGeometries) {
      // console.time('cull data')
      if (!this.cell) return
      let map = this.cellsMatMap[this.cell.name]
      if (!map) return

      let paths = map.cellGraphics.paths
      let paths_size = paths.length
      let polygons = map.cellGraphics.polygons
      let pologons_size = polygons.length
      let lineArray = []
      let lineArrayMats = []
      let lineLoopArry = []
      let lineloopMats = []
      let fillArry = []
      let fillMats = []

      var frustum = new THREE.Frustum()
      frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse))
      let showFill = this.config.showFill
      for (let i = 0; i < paths_size; i++) {
        const obj = paths[i]
        let line = obj.line
        // if (frustum.intersectsSphere(line.geometry.boundingSphere)) {
        lineArray.push(line.geometry)
        lineArrayMats.push(line.material)
        // }
      }
      for (let i = 0; i < pologons_size; i++) {
        const obj = polygons[i]
        let line = obj.line
        // if (frustum.intersectsSphere(line.geometry.boundingSphere)) {
        lineLoopArry.push(line.geometry)
        lineloopMats.push(line.material)
        if (showFill) {
          let fill = obj.fill
          fillArry.push(fill.geometry)
          fillMats.push(fill.material)
          // }
        }
      }
      // let currentRenderPathsSize = this.mergeInfo.singleLineMergeData?.material.length
      // if (lineArray.length !== currentRenderPathsSize || this.mergeInfo.singleLineMergeData.material.length !== this.cell.paths.length) {
      if (lineArray.length) {
        const mergedLineGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(lineArray, true)
        this.mergeInfo.singleLineMergeData.frustumCulled = false
        this.mergeInfo.singleLineMergeData.geometry.attributes.position = mergedLineGeometries.attributes.position
        // this.mergeInfo.singleLineMergeData.geometry.attributes.position = mergedLineGeometries.attributes.globalMatrix
        // this.mergeInfo.singleLineMergeData.geometry.attributes.position = mergedLineGeometries.attributes.preCheck
        // this.mergeInfo.singleLineMergeData.geometry.attributes.position = mergedLineGeometries.attributes.checked
        this.mergeInfo.singleLineMergeData.geometry.groups = mergedLineGeometries.groups
        this.mergeInfo.singleLineMergeData.material = lineArrayMats
      }
      // }

      // let currentRenderPolygonsSize = this.mergeInfo.singleLineLoopMergeData?.material.length
      // if (lineLoopArry.length !== currentRenderPolygonsSize || currentRenderPolygonsSize !== this.cell.polygons.length) {
      if (lineLoopArry.length) {
        const mergedLineLoopGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(lineLoopArry, true)

        this.mergeInfo.singleLineLoopMergeData.frustumCulled = false
        this.mergeInfo.singleLineLoopMergeData.geometry.attributes.position = mergedLineLoopGeometries.attributes.position
        // this.mergeInfo.singleLineLoopMergeData.geometry.attributes.globalMatrix = mergedLineLoopGeometries.attributes.globalMatrix
        // this.mergeInfo.singleLineLoopMergeData.geometry.attributes.preCheck = mergedLineLoopGeometries.attributes.preCheck
        // this.mergeInfo.singleLineLoopMergeData.geometry.attributes.checked = mergedLineLoopGeometries.attributes.checked
        this.mergeInfo.singleLineLoopMergeData.geometry.groups = mergedLineLoopGeometries.groups
        this.mergeInfo.singleLineLoopMergeData.material = lineloopMats
      }

      // if (fillArry.length) {
      //   const mergedFillGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(fillArry, true)
      //   this.mergeInfo.singleFillMergeData.frustumCulled = false
      //   this.mergeInfo.singleFillMergeData.geometry.attributes.position = mergedFillGeometries.attributes.position
      //   // this.mergeInfo.singleFillMergeData.geometry.attributes.preCheck = mergedFillGeometries.attributes.preCheck
      //   // this.mergeInfo.singleFillMergeData.geometry.attributes.checked = mergedFillGeometries.attributes.checked
      //   this.mergeInfo.singleFillMergeData.geometry.groups = mergedFillGeometries.groups
      //   this.mergeInfo.singleFillMergeData.geometry.index = mergedFillGeometries.index
      //   this.mergeInfo.singleFillMergeData.material = fillMats
      // }

      // }
      // console.timeEnd('cull data')
    }
  }

  //动态LOD
  lodGraphics() {
    return

    let showFill = this.config.showFill
    let objs = this.scene.children
    let length = objs.length

    let scale = this.camera.zoom
    let area_scale = scale * scale
    let epsilon = 1 / scale //(1 - scale) * 4

    let pointsCount = 0
    if (scale < 1) {
      //拓扑图形
      //   if (!this.renderLod) {
      for (const key in this.cellsMatMap) {
        let obj = this.cellsMatMap[key]

        let cellPolygonsInst = obj.cellInstance.polygons

        let size = cellPolygonsInst.length
        for (let i = 0; i < size; i++) {
          let inst = cellPolygonsInst[i]
          let lb = inst.linesBatch
          let topology_points = douglasPeucker(lb.lod.originPoints, epsilon)
          let newSize = topology_points.length
          if (newSize > 2) {
            if (newSize !== lb.geometry.attributes.position.count) {
              let topology_lod = generateShapeGeometry(topology_points)
              lb.geometry.attributes.position = topology_lod.attributes.position
              if (showFill) {
                let fb = inst.fillBatch
                fb.geometry.attributes.position = topology_lod.attributes.position
                fb.geometry.index = topology_lod.index
              }
              updateGroupsPosition(obj, showFill, topology_lod.attributes.position, topology_lod.index, i)
              topology_lod.dispose()
              topology_lod = null
            }
          }
          pointsCount += lb.geometry.attributes.position.count
        }
      }
    } else {
      //复原图形
      for (const key in this.cellsMatMap) {
        let obj = this.cellsMatMap[key]
        let cellPolygonsInst = obj.cellInstance.polygons
        let size = cellPolygonsInst.length
        for (let i = 0; i < size; i++) {
          let inst = cellPolygonsInst[i]
          let lb = inst.linesBatch
          let lod = inst.linesBatch.lod
          if (showFill) {
            lod = inst.fillBatch.lod
          }

          if (lb.geometry.attributes.position.count !== lod.originPosition.count) {
            let lb = inst.linesBatch
            lb.geometry.attributes.position = lod.originPosition
            if (showFill) {
              let fb = inst.fillBatch
              fb.geometry.attributes.position = lod.originPosition
              fb.geometry.index = lod.originIndex
            }
            updateGroupsPosition(obj, showFill, lod.originPosition, lod.originIndex, i)
          }
          pointsCount += lb.geometry.attributes.position.count
        }
      }
    }
  }

  //动态LOD
  lodCellInsGraphics(cellInsMaps) {
    return

    let scale = this.camera.zoom
    let area_scale = scale * scale
    let epsilon = 1 / scale //(1 - scale) * 4

    let pointsCount = 0
    if (scale < 1) {
      //拓扑图形
      //   if (!this.renderLod) {
      for (const cell in cellInsMaps) {
        const obj = cellInsMaps[cell]

        const polygons = obj.cell.polygons

        const size = polygons.length
        for (let i = 0; i < size; i++) {
          const polygon = polygons[i]
          const graphics = polygon.js_obj.graphics
          if (!graphics) continue
          const lb = graphics.lineLoopIns
          const realEpsilon = Math.min(20, epsilon) //避免拓扑穿帮
          const topology_points = douglasPeucker(polygon.points, realEpsilon)
          const newSize = topology_points.length
          if (newSize > 2) {
            if (newSize !== lb.geometry.attributes.position.count) {
              let topology_lod = generateShapeGeometry(topology_points)
              lb.geometry.attributes.position = topology_lod.attributes.position
              // if (showFill) {
              const fb = graphics.fillIns
              fb.geometry.attributes.position = topology_lod.attributes.position
              fb.geometry.index = topology_lod.index
              // }
              // this.updateGroupsPosition(obj, showFill, topology_lod.attributes.position, topology_lod.index, i)
              topology_lod.dispose()
              topology_lod = null
            }
          }
        }
      }
    } else {
      //复原图形
      for (const cell in cellInsMaps) {
        const obj = cellInsMaps[cell]
        const polygons = obj.cell.polygons
        const size = polygons.length
        for (let i = 0; i < size; i++) {
          // let inst = cellPolygonsInst[i]
          // let lb = inst.linesBatch
          // let lod = inst.linesBatch.lod
          const polygon = polygons[i]
          const graphics = polygon.js_obj.graphics
          if (!graphics) continue
          const lb = graphics.lineLoopIns
          let lod = lb.lod
          // const orgCount = polygon.points

          if (lb.geometry.attributes.position.count !== lod.originPosition.count) {
            lb.geometry.attributes.position = lod.originPosition
            // if (showFill) {
            let fb = graphics.fillIns
            lod = fb.lod
            fb.geometry.attributes.position = lod.originPosition
            fb.geometry.index = lod.originIndex
            // }
            // this.updateGroupsPosition(obj, showFill, lod.originPosition, lod.originIndex, i)
          }
        }
      }
    }
  }

  updateGroupsPosition(obj, showFill, position, fillIndexs, index) {
    let size = obj.groups.length
    if (size) {
      for (let i = 0; i < size; i++) {
        const group = obj.groups[i]
        let inst = group.instanceObj.polygons[index]

        let lb = inst.linesBatch
        lb.geometry.attributes.position = position
        if (showFill) {
          let fb = inst.fillBatch
          fb.geometry.attributes.position = position
          fb.geometry.index = fillIndexs
        }
      }
    }
  }
  renderRuler(canvasRulerX, canvasRulerY, axis) {
    drawRuler(canvasRulerX, canvasRulerY, axis)
  }

  clearScence() {
    clearChildern(this.cellGroup)
    clearChildern(this.refsGroup)
    clearChildern(this.labelGroup)
    clearChildern(this.anchorGroup)
  }

  //更新标签大小
  scaleLabel() {
    //标签大小绝对像素显示
    let scale = 1 / this.camera.zoom
    if (this.config.renderConfig.labelAbsoulate) {
      for (const layer in this.config.layerMats) {
        this.config.layerMats[layer].fontMat.uniforms.scale.value = scale
        this.config.layerMats[layer].fontInsMat.uniforms.scale.value = scale
      }
    }
    this.config.toolsMat.angleText.uniforms.scale.value = scale
  }

  //更新鼠标十字光标
  updateMouseCross(showX = true, showY = true) {
    this.config.show.mouseCrossX = showX
    this.config.show.mouseCrossY = showY
    this.config.mouseCrossMat.uniforms.show_x.value = this.config.show.mouseCrossX
    this.config.mouseCrossMat.uniforms.show_y.value = this.config.show.mouseCrossY
  }

  //重新计算刻度尺
  reCalDividRuler(LAYOUT) {
    var frustum = new THREE.Frustum()
    frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse))
    const rulers = LAYOUT.RENDER_CELL.js_obj?.rulers
    if (rulers) {
      for (let i = 0; i < rulers.length; i++) {
        const ruler = rulers[i]
        let boundingSphere = ruler.line.geometry.boundingSphere
        if (boundingSphere && frustum.intersectsSphere(boundingSphere)) {
          ruler.update(LAYOUT.AXIS)
        }
      }
    }
    if (LAYOUT.STATUS.prePasteData) {
      let prePasteRulers = LAYOUT.STATUS.prePasteData.filter(obj => obj.constructor.name == 'Ruler')
      if (prePasteRulers.length) {
        for (let i = 0; i < prePasteRulers.length; i++) {
          const ruler = prePasteRulers[i]
          let boundingSphere = ruler.line.geometry.boundingSphere
          if (boundingSphere && frustum.intersectsSphere(boundingSphere)) {
            ruler.update(LAYOUT.AXIS)
          }
        }
      }
    }
  }

  //重新计算关键点
  updateGlobalKP(LAYOUT) {
    // clearChildern(this.keyPointsGroup)
    // let CELL = LAYOUT.RENDER_CELL
    // const keyPoints = CELL.labels.filter(label => label.constructor.name == 'GdsKeyPoint*')
    // // const labels = CELL.get_labels(true, -1)
    // // const keyPoints = labels.filter(label => label.constructor.name == 'GdsKeyPoint*')
    // // let cellKpData = {}
    // const layerMats = LAYOUT.LAYER_STATE.layerMats
    // for (const layerNum in layerMats) {
    //   const layerMat = layerMats[layerNum]
    //   let layerKps = keyPoints.filter(kp => kp.layer == layerNum)
    //   let kps = layerKps.map(kp => [kp.origin[0], kp.origin[1], 0])
    //   let preCheckKps = layerKps.filter(kp => kp.js_obj.STATE.preCheck).map(kp => [kp.origin[0], kp.origin[1], 0])
    //   let checkedKps = layerKps.filter(kp => kp.js_obj.STATE.checked).map(kp => [kp.origin[0], kp.origin[1], 0])
    //   let preCutKps = layerKps.filter(kp => kp.js_obj.STATE.preCut).map(kp => [kp.origin[0], kp.origin[1], 0])
    //   if (kps.length) {
    //     this.keyPointsGroup.add(initPoint(layerMat.keyPointMat, kps))
    //   }
    //   if (preCheckKps.length) {
    //     this.keyPointsGroup.add(initPoint(layerMat.keyPointMat_pre_check, preCheckKps))
    //   }
    //   if (checkedKps.length) {
    //     this.keyPointsGroup.add(initPoint(layerMat.keyPointMat_checked, checkedKps))
    //   }
    //   if (preCutKps.length) {
    //     this.keyPointsGroup.add(initPoint(layerMat.keyPointMat_pre_cut, preCutKps))
    //   }
    // }
    // // this.keyPointsGroup.geometry.attributes.position = new THREE.Float32BufferAttribute(positionArray, 3)
  }

  //渲染选中引用对象
  updateCheckedRefs(LAYOUT, preCutObjs) {
    let checked_refs = []
    let pre_cut_refs = []
    if (LAYOUT) {
      for (let i = 0; i < LAYOUT.STATUS.checked_objs.length; i++) {
        const obj = LAYOUT.STATUS.checked_objs[i]
        if (obj.$$?.ptrType.name == 'Reference*') {
          // if (obj.js_obj.STATE.preCut) {
          //   pre_cut_refs.push(obj)
          // } else
          if (obj.js_obj.STATE.checked) {
            checked_refs.push(obj)
          }
        }
      }
      if (preCutObjs) {
        pre_cut_refs = preCutObjs.filter(cutObj => cutObj.$$?.ptrType.name == 'Reference*')
      }
    }

    const checked_array = []
    claRefsPointsArray(checked_refs, checked_array)
    const pre_cut_array = []
    claRefsPointsArray(pre_cut_refs, pre_cut_array)

    if (LAYOUT?.EDIT_REF) {
      const refMat = computeRefsMat(LAYOUT.EDIT_REF)
      applyNewMat(this.editRefCheckedRefBoxs, refMat)
      applyNewMat(this.editRefPreCutRefBoxs, refMat)
      this.editRefCheckedRefBoxs.position.z = 5.1
      this.editRefPreCutRefBoxs.position.z = 5.1
      this.editRefCheckedRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute(checked_array, 3)
      this.editRefPreCutRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute(pre_cut_array, 3)
      this.checkedRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute([], 3)
      this.preCutRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute([], 3)
    } else {
      this.checkedRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute(checked_array, 3)
      this.preCutRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute(pre_cut_array, 3)
      this.editRefCheckedRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute([], 3)
      this.editRefPreCutRefBoxs.geometry.attributes.position = new THREE.Float32BufferAttribute([], 3)
    }
  }
  //删除图层图形数据
  deletLayer(layerMats) {
    if (!layerMats?.length) return
    let deleteObjs = []
    for (let i = 0; i < this.cellGroup.children.length; i++) {
      const el = this.cellGroup.children[i]
      if (layerMats.indexOf(el.material) != -1) {
        deleteObjs.push(el)
      }
    }

    this.cellGroup.remove(...deleteObjs)
  }

  //更新预选中对象
  updatePreCheckObj(obj, LAYOUT) {
    clearChildern(this.preCheckGroup)
    this.preCheckGroup.position.z = 2
    if (!obj) return
    let refMat = null //引用矩阵
    if (LAYOUT.EDIT_REF) {
      refMat = computeRefsMat(LAYOUT.EDIT_REF)
    }
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    if (TYPE == 'GdsFlexpath*') {
      this.addToPreCheckObj(obj.js_obj.graphics.line, refMat)
      if (obj.width[0]) {
        this.addToPreCheckObj(obj.js_obj.graphics.lineLoop, refMat)
        if (this.config.showFill) {
          this.addToPreCheckObj(obj.js_obj.graphics.fill, refMat)
        }
      }
    } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
      this.addToPreCheckObj(obj.js_obj.graphics.lineLoop, refMat)
      if (this.config.showFill) {
        this.addToPreCheckObj(obj.js_obj.graphics.fill, refMat)
      }
    } else if (TYPE == 'GdsLabel*') {
      this.addToPreCheckObj(obj.js_obj.graphics?.fill, refMat)
    } else if (TYPE == 'GdsKeyPoint*') {
      this.addToPreCheckObj(obj.js_obj.graphics.point, refMat)
      let font_mesh = obj.js_obj.graphics?.fill
      if (font_mesh) {
        this.addToPreCheckObj(font_mesh, refMat)
      }
    } else if (TYPE == 'Ruler' || obj.constructor.name == 'Ruler') {
      this.addToPreCheckObj(obj.line, refMat)
      this.addToPreCheckObj(obj.text, refMat)
    } else if (TYPE == 'Reference*') {
      const aabb = obj.js_obj.bounding_box
      const points = obj.js_obj.borderBox.points //obj.js_obj.borderBox.points
      let array = []
      for (let i = 0; i < points.length; i++) {
        const p = points[i]
        array.push(p[0], p[1], 0)
      }
      this.preCheckedRefBox.geometry.attributes.position = new THREE.Float32BufferAttribute(array, 3)
      this.preCheckedRefBox.frustumCulled = false
      this.addToPreCheckObj(this.preCheckedRefBox, refMat)
    }
  }

  //更新预选中点
  updatePreCheckPoint(point) {
    let buffer = new THREE.Float32BufferAttribute([], 3)
    if (point) {
      buffer = new THREE.Float32BufferAttribute([point[0], point[1], 0], 3)
    }
    this.preCheckPoint.geometry.attributes.position = buffer
  }

  //更新预选中线
  updatePreCheckLine(line) {
    let buffer = new THREE.Float32BufferAttribute([], 3)
    if (line) {
      buffer = new THREE.Float32BufferAttribute([line[0][0], line[0][1], 0, line[1][0], line[1][1], 0], 3)
    }
    this.preCheckLine.geometry.attributes.position = buffer
  }
  //更新选中点和线
  updateCheckedPointsAndLines(points, lines) {
    points = points.filter(p => p) //过滤部分undefined顶点
    let buffer_p = new THREE.Float32BufferAttribute([], 3)
    if (points) {
      let array = []
      for (let i = 0; i < points.length; i++) {
        const p = points[i]
        array.push(p[0], p[1], 0)
      }
      buffer_p = new THREE.Float32BufferAttribute(array, 3)
    }
    this.checkedPoints.geometry.attributes.position = buffer_p

    let buffer_l = new THREE.Float32BufferAttribute([], 3)
    if (lines) {
      let array = []
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        let l_p_s = line.length
        if (l_p_s > 1) {
          for (let j = 0; j < l_p_s; j++) {
            const p = line[j]
            if (p) {
              array.push(p[0], p[1], 0)
            }
          }
        }
        array.push(NaN, NaN, NaN)
      }
      buffer_l = new THREE.Float32BufferAttribute(array, 3)
    }

    this.checkedLines.geometry.attributes.position = buffer_l
  }

  //渲染点对齐选择的第一个点
  updateAlignPoints(points) {
    let buffer_p = new THREE.Float32BufferAttribute([], 3)
    if (points.length) {
      const start = points[0]
      buffer_p = new THREE.Float32BufferAttribute([start[0], start[1], 0], 3)
    }
    this.checkedAlignPoint.geometry.attributes.position = buffer_p
  }

  //渲染点=线对齐选择的第一条线
  updateAlignLines(lines) {
    let buffer_l = new THREE.Float32BufferAttribute([], 3)
    if (lines.length) {
      const line = lines[0]
      const array = []
      for (let j = 0; j < line.length; j++) {
        const p = line[j]
        array.push(p[0], p[1], 0)
      }
      buffer_l = new THREE.Float32BufferAttribute(array, 3)
    }
    this.checkedAlignLine.geometry.attributes.position = buffer_l
  }

  addToPreCheckObj(obj, refMat) {
    if (obj) {
      const preCheckObj = cloneThreeObj(obj)
      preCheckObj.frustumCulled = false
      if (refMat) {
        // preCheckObj.geometry.applyMatrix4(refMat) //选择器器件后标签文本预选择显示预问题
        preCheckObj.applyMatrix4(refMat)
      }
      this.preCheckGroup.add(preCheckObj)
    }
  }

  //渲染新构建的cell
  reRenderCell(cell, layerState) {
    const cellGroup = this.cellGroup
    const refsGroup = this.refsGroup
    const rulerGroup = this.tools.rulerGroup
    clearGroup(cellGroup)
    clearGroup(refsGroup)
    clearGroup(rulerGroup)
    // cellGroup.remove(...cellGroup.children)
    // refsGroup.remove(...refsGroup.children)
    // rulerGroup.remove(...rulerGroup.children)
    const flexpaths = cell.flexpaths
    const polygons = cell.polygons
    const labels = cell.labels
    if (!cell.js_obj.rulers) {
      cell.js_obj.rulers = []
    }
    if (!cell.js_obj.cutArea3D) {
      cell.js_obj.cutArea3D = []
    }
    if (!cell.js_obj.render3DArea) {
      cell.js_obj.render3DArea = []
    }
    const rulers = cell.js_obj.rulers
    const cutArea3D = cell.js_obj.cutArea3D
    const render3DArea = cell.js_obj.render3DArea
    for (let i = 0; i < flexpaths.length; i++) {
      const obj = flexpaths[i]
      if (layerIsHide(obj.layers[0], layerState)) continue
      cellGroup.add(obj.js_obj.graphics.line)
      if (obj.width[0]) {
        cellGroup.add(obj.js_obj.graphics.lineLoop)
        if (this.config.showFill) {
          cellGroup.add(obj.js_obj.graphics.fill)
        }
      }
    }
    for (let i = 0; i < polygons.length; i++) {
      const obj = polygons[i]
      if (layerIsHide(obj.layer, layerState)) continue
      cellGroup.add(obj.js_obj.graphics.lineLoop)
      if (this.config.showFill) {
        cellGroup.add(obj.js_obj.graphics.fill)
      }
    }
    for (let i = 0; i < labels.length; i++) {
      const obj = labels[i]
      if (layerIsHide(obj.layer, layerState)) continue
      if (obj.js_obj.graphics?.fill) {
        cellGroup.add(obj.js_obj.graphics.fill)
      }
      if (obj.js_obj.graphics?.point) {
        cellGroup.add(obj.js_obj.graphics.point)
      }
    }
    for (let i = 0; i < rulers.length; i++) {
      const obj = rulers[i]
      rulerGroup.add(obj.line)
      rulerGroup.add(obj.text)
    }
    for (let i = 0; i < cutArea3D.length; i++) {
      const obj = cutArea3D[i]
      cellGroup.add(obj.js_obj.graphics.lineLoop)
    }
    for (let i = 0; i < render3DArea.length; i++) {
      const obj = render3DArea[i]
      cellGroup.add(obj.js_obj.graphics.lineLoop)
    }
    this.addCellInstMap(cell, layerState)
  }

  addCellInstMap(cell, layerState) {
    const refsGroup = this.refsGroup
    const cellInsMaps = cell.js_obj.cellInsMaps
    for (const cell in cellInsMaps) {
      const obj = cellInsMaps[cell]
      const insCount = obj.refs.length //obj.matsArray.length/16
      const matsArray = obj.matsArray
      obj.recordInsCount = insCount //记录实例数
      this.addCellInst(refsGroup, obj.cell, insCount, matsArray, layerState)
    }
  }
  //添加器件的引用实例
  addCellInst(group, cell, insCount, matsArray, layerState) {
    const flexpaths = cell.flexpaths
    const polygons = cell.polygons
    const labels = cell.labels

    for (let i = 0; i < flexpaths.length; i++) {
      const obj = flexpaths[i]
      if (layerIsHide(obj.layers[0], layerState)) continue
      updateLineInsAttr(obj.js_obj.graphics.lineIns, insCount, matsArray)
      group.add(obj.js_obj.graphics.lineIns)
      if (obj.width[0]) {
        updateLineInsAttr(obj.js_obj.graphics.lineLoopIns, insCount, matsArray, obj.js_obj.boundingSphere)
        group.add(obj.js_obj.graphics.lineLoopIns)
        if (this.config.showFill) {
          updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray, true)
          group.add(obj.js_obj.graphics.fillIns)
        }
      }
    }
    for (let i = 0; i < polygons.length; i++) {
      const obj = polygons[i]
      if (layerIsHide(obj.layer, layerState)) continue

      updateLineInsAttr(obj.js_obj.graphics.lineLoopIns, insCount, matsArray)
      group.add(obj.js_obj.graphics.lineLoopIns)
      if (this.config.showFill) {
        updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray, false)
        group.add(obj.js_obj.graphics.fillIns)
      }
    }
    for (let i = 0; i < labels.length; i++) {
      const obj = labels[i]
      if (layerIsHide(obj.layer, layerState)) continue
      if (obj.js_obj.graphics?.fillIns) {
        updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray, true)
        group.add(obj.js_obj.graphics.fillIns)
      }
      if (obj.js_obj.graphics?.pointIns) {
        updatePointInsAttr(obj.js_obj.graphics.pointIns, insCount, matsArray)
        group.add(obj.js_obj.graphics.pointIns)
      }
    }
  }

  //清除器件的引用实例
  removeCellInst(group, cell, dispose = false) {
    const flexpaths = cell.flexpaths
    const polygons = cell.polygons
    const labels = cell.labels
    for (let i = 0; i < flexpaths.length; i++) {
      const obj = flexpaths[i]
      group.remove(obj.js_obj.graphics.lineIns)
      group.remove(obj.js_obj.graphics.lineLoopIns)
      group.remove(obj.js_obj.graphics.fillIns)
      if (dispose) {
        disposeGraphics(obj.js_obj.graphics)
      }
    }
    for (let i = 0; i < polygons.length; i++) {
      const obj = polygons[i]
      group.remove(obj.js_obj.graphics.lineLoopIns)
      group.remove(obj.js_obj.graphics.fillIns)
      if (dispose) {
        disposeGraphics(obj.js_obj.graphics)
      }
    }
    for (let i = 0; i < labels.length; i++) {
      const obj = labels[i]
      group.remove(obj.js_obj.graphics.fillIns)
      if (dispose) {
        disposeGraphics(obj.js_obj.graphics)
      }
    }
  }

  //跟新器件的引用实例
  updateCellInst(cell, insCount, matsArray) {
    const refsGroup = this.refsGroup
    const flexpaths = cell.flexpaths
    const polygons = cell.polygons
    const labels = cell.labels
    for (let i = 0; i < flexpaths.length; i++) {
      const obj = flexpaths[i]
      updateLineInsAttr(obj.js_obj.graphics.lineIns, insCount, matsArray)
      updateLineInsAttr(obj.js_obj.graphics.lineLoopIns, insCount, matsArray, obj.js_obj.boundingSphere)
      updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray, true)
    }
    for (let i = 0; i < polygons.length; i++) {
      const obj = polygons[i]
      updateLineInsAttr(obj.js_obj.graphics.lineLoopIns, insCount, matsArray)
      updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray, false)
    }
    for (let i = 0; i < labels.length; i++) {
      const obj = labels[i]
      if (obj.js_obj.graphics?.fillIns) {
        updateFillAttr(obj.js_obj.graphics.fillIns, insCount, matsArray, true)
      }
      if (obj.js_obj.graphics?.pointIns) {
        updatePointInsAttr(obj.js_obj.graphics.pointIns, insCount, matsArray)
      }
    }
  }

  //清除器件引用实例
  clearRefsGraphic() {
    // this.refsGroup.remove(...this.refsGroup.children) //清空画板引用图形
    this.refsGroup.clear()
  }

  //移动引用实例后跟新矩形
  updateRefArray(LAYOUT, targetRefs, affect_refs_cells_ptr, repetitionCellsPtrs = []) {
    let refs_cells_ptr //需要更新的器件
    if (affect_refs_cells_ptr) {
      refs_cells_ptr = affect_refs_cells_ptr
    } else {
      const cells = [LAYOUT.RENDER_CELL, ...LAYOUT.RENDER_CELL.depend_cells(-1)] //LAYOUT.LIB.cells
      let checkedRefs = []
      if (targetRefs) {
        checkedRefs = targetRefs
      } else {
        checkedRefs = LAYOUT.STATUS.checked_objs.filter(obj => obj.$$?.ptrType.name == 'Reference*')
      }
      // if (LAYOUT.EDIT_REF) {
      //   checkedRefs.push(LAYOUT.EDIT_REF)
      // }

      refs_cells_ptr = getRefsCellsPtr(cells, checkedRefs)
      repetitionCellsPtrs = getRepetitionCellsPtr(checkedRefs)
    }

    const cellInsMaps = LAYOUT.RENDER_CELL.js_obj.cellInsMaps

    if (repetitionCellsPtrs.length || refs_cells_ptr.length) {
      //移动存在阵列的引用
      updateCellInsRefs(LAYOUT.RENDER_CELL, refs_cells_ptr)
    }

    updateRefMats(cellInsMaps, refs_cells_ptr)
    for (const cell in cellInsMaps) {
      const obj = cellInsMaps[cell]
      if (containCell(refs_cells_ptr, obj.cell)) {
        const countOld = obj.recordInsCount
        const insCount = obj.refs.length //obj.matsArray.length/16
        const matsArray = obj.matsArray
        obj.recordInsCount = insCount

        this.updateCellInst(obj.cell, insCount, matsArray)
        if (!insCount) {
          //数量为0时从渲染系统删除
          this.removeCellInst(this.refsGroup, obj.cell)
        } else if (countOld === 0 && insCount) {
          //数量为0变为1时添加至从引用渲染系统
          this.addCellInst(this.refsGroup, obj.cell, insCount, matsArray, LAYOUT.LAYER_STATE)
        }
      }
    }
  }

  //预复制数据
  updatePrePasteData(ref) {
    this.clearPreCopyData()
    const extrePolygons = []
    if (ref.cell.js_obj.cutArea3D) {
      extrePolygons.push(...ref.cell.js_obj.cutArea3D)
    }
    if (ref.cell.js_obj.render3DArea) {
      extrePolygons.push(...ref.cell.js_obj.render3DArea)
    }
    if (ref.cell.js_obj.rulers) {
      for (let i = 0; i < ref.cell.js_obj.rulers.length; i++) {
        const ruler = ref.cell.js_obj.rulers[i]
        this.preAddRulerGroup.add(ruler.line)
        this.preAddRulerGroup.add(ruler.text)
      }
    }
    const preAddLine = initLine(this.config.toolsMat.preAddRefLine) //调用外部器件的线
    const preAddPoint = initPoint(this.config.toolsMat.preAddRefPoint) //调用外部器件的点
    let linesArray = getRefLinesArrayBuffer(ref, extrePolygons)
    let labels = ref.cell.get_labels(true, -1)
    let fontMeshes = getRefFontMesh(ref, this.config.toolsMat.preAddRefFill, labels) //调用外部器件的点
    let pointsArray = getRefPointsArrayBuffer(ref, labels)

    preAddLine.geometry.attributes.position = linesArray
    preAddLine.frustumCulled = false
    preAddPoint.geometry.attributes.position = pointsArray
    preAddPoint.frustumCulled = false
    this.preAddGroup.add(preAddLine)
    fontMeshes.forEach(mesh => {
      mesh.frustumCulled = false
      this.preAddGroup.add(mesh)
    })

    this.preAddGroup.add(preAddPoint)
    this.preAddGroup.position.set(0, 0, 5)
    // this.preAddGroup.rotation.z = ref.rotation
  }
  //清除外部复制数据
  clearPreCopyData() {
    clearChildern(this.preAddGroup)
    clearChildern(this.preAddRulerGroup)
    // this.scene.remove(this.preAddGroup)
  }

  //外部复制的数据
  addPreAddRef(ref) {
    this.clearPreAddRef()
    const preAddRefLine = initLine(this.config.toolsMat.preAddRefLine) //调用外部器件的线
    const preAddRefPoint = initPoint(this.config.toolsMat.preAddRefPoint) //调用外部器件的点
    let linesArray = getRefLinesArrayBuffer(ref)
    let labels = ref.cell.get_labels(true, -1)
    let fontMeshes = getRefFontMesh(ref, this.config.toolsMat.preAddRefFill, labels) //调用外部器件的点
    let pointsArray = getRefPointsArrayBuffer(ref, labels)

    preAddRefLine.geometry.attributes.position = linesArray
    preAddRefLine.frustumCulled = false
    preAddRefPoint.geometry.attributes.position = pointsArray
    preAddRefPoint.frustumCulled = false
    this.preAddRefGroup.add(preAddRefLine)
    fontMeshes.forEach(mesh => {
      mesh.frustumCulled = false
      this.preAddRefGroup.add(mesh)
    })

    this.preAddRefGroup.add(preAddRefPoint)
    this.preAddRefGroup.position.set(ref.origin[0], ref.origin[1], 5)
    this.preAddRefGroup.rotation.z = ref.rotation
    this.scene.add(this.preAddRefGroup)
  }

  //清除外部复制数据
  clearPreAddRef() {
    clearChildern(this.preAddRefGroup)
    this.scene.remove(this.preAddRefGroup)
  }

  //渲染编辑中的引用边框边框
  updateEditRefBorder(LAYOUT) {
    let ref = LAYOUT.EDIT_REF
    if (ref) {
      const arr = []
      updateCellBoundingBox(ref.cell)
      updateRefGraphic(ref)
      const points = ref.js_obj.borderBox.points
      points.forEach(p => arr.push(p[0], p[1], 0))
      arr.push(points[0][0], points[0][1], 0)

      const buffer_ref_border = new THREE.Float32BufferAttribute(arr, 3)
      this.editRefBorder.geometry.attributes.position = buffer_ref_border
    } else {
      this.editRefBorder.geometry.attributes.position = new THREE.Float32BufferAttribute([], 3)
    }
  }

  //编辑下层时选中对象渲染
  updateCheckedEditRefCheckedObjs(LAYOUT) {
    clearChildern(this.editRefCheckedObjs)
    let ref = LAYOUT.EDIT_REF
    if (ref) {
      const refMat = computeRefsMat(ref)
      applyNewMat(this.editRefCheckedObjs, refMat)
      const objs = LAYOUT.STATUS.checked_objs
      for (let i = 0; i < objs.length; i++) {
        const obj = objs[i]
        const TYPE = obj.$$?.ptrType.name //obj.constructor.name
        if (TYPE == 'GdsFlexpath*') {
          this.addToEditRefCheckedObj(obj.js_obj.graphics.line, refMat)
          if (obj.width[0]) {
            this.addToEditRefCheckedObj(obj.js_obj.graphics.lineLoop, refMat)
            if (this.config.showFill) {
              this.addToEditRefCheckedObj(obj.js_obj.graphics.fill, refMat)
            }
          }
        } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
          this.addToEditRefCheckedObj(obj.js_obj.graphics.lineLoop, refMat)
          if (this.config.showFill) {
            this.addToEditRefCheckedObj(obj.js_obj.graphics.fill, refMat)
          }
        } else if (TYPE == 'GdsLabel*') {
          this.addToEditRefCheckedObj(obj.js_obj.graphics.fill)
        } else if (TYPE == 'GdsKeyPoint*') {
          this.addToEditRefCheckedObj(obj.js_obj.graphics.point)
          let font_mesh = obj.js_obj.graphics?.fill
          if (font_mesh) {
            this.addToEditRefCheckedObj(font_mesh)
          }
        }
        //  else if (TYPE == 'Reference*') {
        //   const points = obj.js_obj.borderBox.points //obj.js_obj.borderBox.points
        //   let array = []
        //   for (let i = 0; i < points.length; i++) {
        //     const p = points[i]
        //     array.push(p[0], p[1], 0)
        //   }
        //   this.preCheckedRefBox.geometry.attributes.position = new THREE.Float32BufferAttribute(array, 3)
        //   this.preCheckedRefBox.frustumCulled = false
        //   this.addToEditRefCheckedObj(this.preCheckedRefBox)
        // }
      }
    }
  }

  addToEditRefCheckedObj(obj) {
    let checkObj = obj.clone()
    if (obj.geometry.constructor.name == 'TextGeometry') {
      checkObj.cloneFrom = obj
    }
    this.editRefCheckedObjs.add(checkObj)
  }

  updateDrcGraphics(drcGraphics) {
    clearChildern(this.drcGraphics)
    drcGraphics.forEach(g => this.drcGraphics.add(g))
  }

  updateDebugLines(objs) {
    if (objs.length) {
      for (let i = 0; i < objs.length; i++) {
        const obj = objs[i]
        const arr = []
        const points = obj.js_obj.borderBox.points
        points.forEach(p => arr.push(p[0], p[1], 0))
        arr.push(points[0][0], points[0][1], 0)

        const buffer_ref_border = new THREE.Float32BufferAttribute(arr, 3)
        this.debugLines.geometry.attributes.position = buffer_ref_border
      }
    } else {
      this.debugLines.geometry.attributes.position = new THREE.Float32BufferAttribute([], 3)
    }
  }
}
