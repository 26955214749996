var QGdstk = null
var AutoToolLibs = require('./libs')

;(function () {
  function AutoToolGdsSplit(library, cell) {
    QGdstk = window.QGdstk
    this.library = library
    this.cell = cell
    this.conf = {
      library: {
        name: 'lib_split',
        unit: 1e-6,
        percision: 1e-9,
      },
      splits: [
        {
          name: 'lib_split_cell1',
          layer_datatypes: [
            { layer: 2, datatype: 0, texttype: 0 },
            { layer: 3, datatype: 0, texttype: 0 },
          ],
        },
        {
          name: 'lib_split_cell2',
          layer_datatypes: [{ layer: 4, datatype: 0, texttype: 0 }],
        },
      ],
    }
  }

  AutoToolGdsSplit.prototype.setConf = function (conf) {
    this.conf = conf
  }
  AutoToolGdsSplit.prototype.GdstkCell2KernelCell = function (cell, fileLayerList) {
    let kernel_cell = new window.Kernel.Cell(cell.name)
    cell.paths.forEach(element => {
      let path = new Kernel.GdsFlexpath(element.points, inner_width, 0, 'round', 'flush', radius, 1e-2, false, true, layer, datatype)
      kernel_cell.add(path)
    })
    cell.polygons.forEach(element => {
      let points = []
      for (let i = 0; i < element.points.length; i++) {
        let p = element.points[i]
        points.push([p[0], p[1]])
      }
      let p = new Kernel.GdsPolygon(points)
      let layer = fileLayerList[parseInt(element.layer)]
      if (fileLayerList[parseInt(element.layer)]) p.id = layer.id
      else p.id = String(parseInt(element.layer))
      p.layer = element.layer
      p.datatype = element.datatype
      kernel_cell.add_polygon(p)
    })
    return kernel_cell
  }

  // 运行
  AutoToolGdsSplit.prototype.run = function (data) {
    this.conf = data
    let new_library = new window.Kernel.Library(this.conf.library.name, this.conf.library.unit, this.conf.library.percision)

    this.conf.splits.forEach(sp => {
      let cell = new window.Kernel.Cell(sp.name)
      sp.layer_datatypes.forEach(ld => {
        let paths = this.cell.get_paths(true, -1, ld.layer, ld.datatype)
        let polygons = this.cell.get_polygons(true, false, -1, ld.layer, ld.datatype)
        let labels = this.cell.get_labels(true, -1, ld.layer, ld.texttype)
        paths.forEach(e => {
          cell.add_flexpath(e)
        })
        polygons.forEach(e => {
          cell.add_polygon(e)
        })
        labels.forEach(e => {
          if (e.$$.ptrType.name !== 'GdsKeyPoint*') cell.add_label(e)
        })
      })
      if (cell.flexpaths.length > 0 || cell.polygons.length > 0 || cell.labels.length > 0) new_library.add_cell(cell)
    })

    return new_library
  }

  AutoToolGdsSplit.prototype.exportGds = function (library, filename) {
    library.write_gds(filename)
    let mime = 'application/octet-stream'
    let content = QGdstk.FS.readFile(filename)

    var download_link = document.getElementById('download_gds')
    if (download_link == null) {
      download_link = document.createElement('a')
      download_link.id = 'download_gds'
      download_link.style.display = 'none'
      document.body.appendChild(download_link)
    }
    download_link.download = filename
    download_link.href = URL.createObjectURL(
      new Blob([content], {
        type: mime,
      })
    )
    download_link.click()
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolGdsSplit
  } else {
    window.AutoToolGdsSplit = AutoToolGdsSplit
  }
})()
