var QGdstk = null
var AutoToolLibs = require('./libs')

;(function () {
  function AutoToolCheckLine(cell) {
    QGdstk = window.QGdstk
    this.cell = cell
    this.libs = new AutoToolLibs()
    this.conf = [
      {
        layer: 2,
        datatype: 0,
        distance: 10,
      },
      {
        layer: 3,
        datatype: 0,
        distance: 10,
      },
    ]
  }

  AutoToolCheckLine.prototype.setConf = function (conf) {
    this.conf = conf
  }

  // 运行
  AutoToolCheckLine.prototype.run = function (data) {
    this.conf = data
    let result = []
    for (let i = 0; i < this.conf.length; i++) {
      let c = this.conf[i]

      // let paths = this.cell.get_paths((apply_repetitions = true), (depth = null), (layer = c.layer), (datatype = c.datatype))
      let paths = this.getLayerPaths(this.cell.flexpaths, c.layer)
      let lines = this.libs.pathsTranLines_k(paths)

      // let distance = Math.floor(c.distance / Math.cos(Math.PI / 6))
      // let lines_maps = this.getLinesMap(lines, distance)

      this.check(lines, c.distance).then(err => {
        result.push({
          layer: c.layer,
          datatype: c.datatype,
          err: err,
        })
      })
    }

    return result
  }

  // 近距离点位
  AutoToolCheckLine.prototype.getClosetPos = function (points, distance) {
    let KD = new QGdstk.KDTree(points)
    let indices = KD.neighborhood_indices(points, distance)

    let re_idxs = {}
    for (let i = 0, len = indices.length; i < len; i++) {
      // 去除自身检查结果
      let idx = indices[i]
      if (indices[i].length < 2) continue

      // 其他两两组合
      let t = []
      for (let j = 0; j < idx.length; j++) {
        t.push(idx[j])
      }
      // 去重
      if (re_idxs[[t[0], t[1]]] || re_idxs[[t[1], t[0]]]) {
        continue
      }
      re_idxs[t] = [t[0], t[1]]
    }
    return re_idxs
  }

  // 按照步长获取骨架线映射关系
  // AutoToolCheckLine.prototype.getLinesMap = function (lines, distance) {
  //   let step = distance
  //   // let distance = x * 1.414

  //   let points = []
  //   // 线对应idx的映射集合
  //   let lines_idx_map = []
  //   // 进度
  //   let length = lines.length
  //   let process_n = Math.ceil(length / 5)
  //   let n = 0
  //   let self_err_paths = []
  //   // // 骨架线轮廓点位标注
  //   for (let idx = 0; idx < length; idx++) {
  //     let line = lines[idx]
  //     for (let i = 1, leni = line.length; i < leni; i++) {
  //       let s = [line[i - 1], line[i]]
  //       // let sp = this.libs.split_line(s, step)
  //       let pp_distance = this.libs.pointDistance(s[0], s[1])
  //       if (pp_distance < distance) {
  //         self_err_paths.push(s)
  //       }
  //       let sp = this.libs.split_line(s, step)

  //       points.push.apply(points, sp)

  //       let idxs = []
  //       for (let j = 0, lenj = sp.length; j < lenj; j++) {
  //         idxs.push(idx)
  //       }
  //       lines_idx_map.push.apply(lines_idx_map, idxs)
  //     }
  //     n += 1
  //     if (n % process_n == 0) {

  //     } else if (n == length) {

  //     }
  //   }

  //   return {
  //     line_idxs: lines_idx_map,
  //     points: points,
  //   }
  // }

  // 检测线算法, 有漏洞，还有自身长度端点之间距离小的情况
  AutoToolCheckLine.prototype.check = async function (lines, x) {
    let step = x / 2
    // let step = Math.floor(x / Math.cos(Math.PI / 6))
    // let distance = x * 1.414

    let points = []
    // 线对应idx的映射集合
    let lines_idx_map = []
    // 进度
    let length = lines.length
    let process_n = Math.ceil(length / 5)
    let n = 0
    let self_err_paths = []
    // // 骨架线轮廓点位标注
    for (let idx = 0, len = lines.length; idx < len; idx++) {
      let line = lines[idx]
      for (let i = 1, leni = line.length; i < leni; i++) {
        let s = [line[i - 1], line[i]]
        // 自身检测
        // let pp_distance = this.libs.pointDistance(s[0], s[1])
        // if (pp_distance < distance) {
        //   self_err_paths.push(s)
        // }

        let sp = this.libs.split_line(s, step)
        points.push.apply(points, sp)

        let idxs = []
        for (let j = 0, lenj = sp.length; j < lenj; j++) {
          idxs.push(idx)
        }
        lines_idx_map.push.apply(lines_idx_map, idxs)
      }
      n += 1
      if (n % process_n == 0) {
      } else if (n == length) {
      }
    }

    let tree = new QGdstk.KDTree(points)
    let indices = tree.neighborhood_indices(points, x)

    // 根据查找到的点的索引值来获取线的id
    // let err_points = []
    let err_points = {}
    let err_idxs = []

    for (let i = 0, len = indices.length; i < len; i++) {
      // 去除自身检查结果
      let idx_arr = indices[i]
      if (idx_arr.length < 2) continue
      let t = []
      for (let j = 0; j < idx_arr.length; j++) {
        t.push(lines_idx_map[idx_arr[j]])
      }
      t = Array.from(new Set(t))
      // 与其他线之间存在距离较近
      if (t.length > 1) {
        for (let k = 0; k < idx_arr.length; k++) {
          // err_points.push(points[idx_arr[k]])
          err_points[points[idx_arr[k]]] = points[idx_arr[k]]
        }
        err_idxs.push.apply(err_idxs, t)
      }
    }
    // 去除重复的路径id
    err_idxs = Array.from(new Set(err_idxs))

    err_paths = []
    for (let i = 0; i < err_idxs.length; i++) {
      err_paths.push(lines[err_idxs[i]])
    }

    err_paths.push.apply(err_paths, self_err_paths)

    return {
      paths: err_paths,
      // points: err_points,
    }
  }

  // 根据图层分离骨架线
  AutoToolCheckLine.prototype.getLayerPaths = function (paths, layer) {
    paths_layer = []
    for (let i = 0, len = paths.length; i < len; i++) {
      let item = paths[i]
      if (item.layers[0] == layer) {
        paths_layer.push(item)
      }
    }
    return paths_layer
  }

  // 根据图层分离骨架线
  AutoToolCheckLine.prototype.getPathsByLayer = function () {
    let paths = this.cell.paths
    let layer_paths = {}
    for (let i = 0, len = this.layers.length; i < len; i++) {
      layer_paths[i] = []
    }

    for (let i = 0, len = paths.length; i < len; i++) {
      let item = paths[i]
      for (let j = 0, len = this.layers.length; j < len; j++) {
        if (item.layers[0] == this.layers[j]) {
          layer_paths[j].push(item)
        }
      }
    }

    return layer_paths
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolCheckLine
  } else {
    window.AutoToolCheckLine = AutoToolCheckLine
  }
})()
