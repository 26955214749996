var QGdstk = null
var AutoToolLibs = require('./libs')
var Device = require('../qeda-component')

;(function () {
  function AutoToolInLinePad(library, cell) {
    QGdstk = window.QGdstk
    this.library = library
    this.cell = cell
    this.libs = new AutoToolLibs()
    this.qeda_device = new Device()
    // this.components = new AutoToolComponents()
    this.conf = {
      max_distance: 10,
      // spout: {
      //   A: 30,
      //   A1: 21,
      // },
      // pad: {
      //   w: 38,
      //   h: 38,
      //   inter_radius: 10,
      //   inter_layer: 0,
      //   inter_datatype: 0,
      //   outer_radius: 20,
      //   outer_layer: 1,
      //   outer_datatype: 0,
      // },
      connect: {
        top_layer: 2,
        top_datatype: 0,
        bottom_layer: 3,
        bottom_datatype: 0,
      },
    }
  }
  AutoToolInLinePad.prototype.setConf = function (conf) {
    this.conf = conf
  }

  AutoToolInLinePad.prototype.run = function (conf) {
    this.conf = conf
    let c = this.conf.connect
    let top_paths = this.getPathsByLayer(c.top_layer, c.top_datatype)
    let bottom_paths = this.getPathsByLayer(c.bottom_layer, c.bottom_datatype)
    let connect = this.connectPath(top_paths, bottom_paths)
    // let place = this.placeCell(connect.inlinepads)
    let place = this.placeCellKernel(connect.inlinepads)

    return {
      add_paths: connect.add_paths,
      remove_paths: connect.remove_paths,
      add_cells: place.add_cells,
      add_references: place.add_references,
    }
  }

  //获取两个点的中心点位
  AutoToolInLinePad.prototype.getCenter = function (p1, p2) {
    x = (p2[0] - p1[0]) / 2.0 + p1[0]
    y = (p2[1] - p1[1]) / 2.0 + p1[1]
    return [x, y]
  }

  AutoToolInLinePad.prototype.getClosetPos = function (points, distance) {
    let KD = new QGdstk.KDTree(points)
    let indices = KD.neighborhood_indices(points, distance)

    let re_idxs = {}
    for (let i = 0, len = indices.length; i < len; i++) {
      // 去除自身检查结果
      let idx = indices[i]
      if (indices[i].length < 2) continue

      // 其他两两组合
      let t = []
      for (let j = 0; j < idx.length; j++) {
        t.push(idx[j])
      }
      // 去重
      if (re_idxs[[t[0], t[1]]] || re_idxs[[t[1], t[0]]]) {
        continue
      }
      re_idxs[t] = [t[0], t[1]]
    }
    return re_idxs
  }

  AutoToolInLinePad.prototype.connectPath = function (top_paths, bottom_paths) {
    // 两层paths的中间idx
    let mid_idx = top_paths.length
    let add_paths = []
    let paths = []
    let points = []
    let path_idxs = []
    let point_idxs = []
    paths.push.apply(paths, top_paths)
    paths.push.apply(paths, bottom_paths)
    for (let i = 0; i < paths.length; i++) {
      // 创建路径copy
      let flexpath = paths[i]
      add_paths.push(flexpath.copy())
      // 获取点位
      // let ps = flexpath.spine()
      let ps = flexpath.points
      let len = ps.length
      // 起点
      points.push(ps[0])
      path_idxs.push(i)
      point_idxs.push(0)
      // 尾点
      points.push(ps[len - 1])
      path_idxs.push(i)
      point_idxs.push(len - 1)
      // for (let j = 0; j < ps.length; j++) {
      //   points.push(ps[j])
      //   path_idxs.push(i)
      //   point_idxs.push(j)
      // }
    }

    // 获取壶嘴长度
    let inlinepad_cell = this.getInlinepadSpoutCell((A = this.conf.size.B), (A1 = this.conf.size.B1), (layer = this.conf.connect.top_layer), (datatype = this.conf.connect.top_datatype))
    let spout_len = Math.abs(inlinepad_cell.key_points[0][0])

    // 获取距离相近的点位
    let idxs = this.getClosetPos(points, this.conf.max_distance)
    let inlinepads = []
    let remove_paths = paths
    for (key in idxs) {
      let idx = idxs[key]

      // 获取中心点
      let p_1 = points[idx[0]]
      let path_idx_1 = path_idxs[idx[0]]
      let path_1 = paths[path_idxs[idx[0]]]
      let point_idx_1 = point_idxs[idx[0]]

      let p_2 = points[idx[1]]
      let path_idx_2 = path_idxs[idx[1]]
      let path_2 = paths[path_idxs[idx[1]]]
      let point_idx_2 = point_idxs[idx[1]]
      if (path_1.layers[0] == path_2.layers[0]) {
        continue
      }
      // 计算中心位置
      let center = this.getCenter(p_1, p_2)
      // path1点位变化
      let p_idx_1 = point_idx_1 == 0 ? 1 : path_1.points.length - 2
      let p_next_1 = path_1.points[p_idx_1]
      let angle_1 = this.libs.angle_direction(center, p_next_1)
      let pos_x_1 = center[0] + spout_len * Math.cos(angle_1)
      let pos_y_1 = center[1] + spout_len * Math.sin(angle_1)
      // add_paths[path_idx_1].points[point_idx_1] = [pos_x_1, pos_y_1]
      add_paths[path_idx_1].points_vec.set(point_idx_1, [pos_x_1, pos_y_1])
      // path2点位变化
      let p_idx_2 = point_idx_2 == 0 ? 1 : path_2.points.length - 2
      let p_next_2 = path_2.points[p_idx_2]
      let angle_2 = this.libs.angle_direction(center, p_next_2)
      let pos_x_2 = center[0] + spout_len * Math.cos(angle_2)
      let pos_y_2 = center[1] + spout_len * Math.sin(angle_2)
      // add_paths[path_idx_2].points[point_idx_2] = [pos_x_2, pos_y_2]
      add_paths[path_idx_2].points_vec.set(point_idx_2, [pos_x_2, pos_y_2])
      // add_paths.push(path_1)
      // add_paths.push(path_2)
      // 放入结果
      inlinepads.push({
        pos: center,
        angle_1: angle_1,
        layer_1: path_1.layers[0],
        // datatype_1: path_1.datatypes[0],
        datatype_1: 0,
        angle_2: angle_2,
        layer_2: path_2.layers[0],
        // datatype_2: path_2.datatypes[0],
        datatype_2: 0,
      })
    }
    return {
      inlinepads: inlinepads,
      remove_paths: remove_paths,
      add_paths: add_paths,
    }
  }

  AutoToolInLinePad.prototype.getInlinepadPadCellX = function (size) {
    let cell = new QGdstk.Cell('Inlinepad_pad')
    let indium = this.qeda_device.Indium_1(
      (layer_indium = size.indium_layer),
      (datatype_indium = size.indium_datatype),
      (layer_indium_flip = size.indium_flip_layer),
      (datatype_indium_flip = size.indium_flip_datatype),
      (layer_tin = size.tin_layer),
      (datatype_tin = size.tin_datatype),
      (layer_tin_flip = size.tin_flip_layer),
      (datatype_tin_flip = size.tin_flip_datatype),
      (A = size.A),
      (A1 = size.A1),
      (tolerance = size.tin_tolerance)
    )

    cell.add(indium.get_polygons())
    let polygons_1 = indium.get_polygons()
    let polygons_2 = indium.get_polygons()
    let polygons_3 = indium.get_polygons()
    let polygons_4 = indium.get_polygons()
    for (let i = 0; i < polygons_1.length; i++) {
      polygons_1[i].translate(size.pad_w, size.pad_h)
      polygons_2[i].translate(-size.pad_w, size.pad_h)
      polygons_3[i].translate(size.pad_w, -size.pad_h)
      polygons_4[i].translate(-size.pad_w, -size.pad_h)
    }
    cell.add(polygons_1)
    cell.add(polygons_2)
    cell.add(polygons_3)
    cell.add(polygons_4)
    return cell
  }

  AutoToolInLinePad.prototype.getInlinepadSpoutCell = function (A = 30, A1 = 21, layer = 0, datatype = 0) {
    let A2 = 10
    let A3 = 5
    let A4 = 4
    let A5 = 2
    let A6 = 1.2169
    // 计算角度
    let a1 = Math.asin((A4 / 2 + A5 + A3) / (A + A3))
    let a2 = Math.asin((A4 / 2 + A3) / (A1 + A3))
    // 采用curve一次走完
    let curve = new QGdstk.Curve([0, A4 / 2], 1e-3)
    curve.segment([0, A5], true)
    curve.segment([A6, 0], true)
    curve.arc(A3, -Math.PI / 2, -a1)
    curve.arc(A, Math.PI - a1, -(Math.PI - a1))
    curve.arc(A3, a1, Math.PI / 2)
    curve.segment([-A6, 0], true)
    curve.segment([0, A5], true)
    curve.segment([A2, 0], true)
    curve.arc(A3, Math.PI / 2, a2)
    curve.arc(A1, -(Math.PI - a2), Math.PI - a2)
    curve.arc(A3, -a2, -Math.PI / 2)
    curve.segment([-A2, 0], true)
    // 通过curve生成多边形, 使壶嘴朝向右侧
    let spout = new QGdstk.Polygon(curve.points(), layer, datatype)
    spout.translate(-((A + A3) * Math.cos(a1) + A6), 0)
    spout.rotate(Math.PI, [0, 0])

    // let o1 = new QGdstk.Label(text='O1', origin=[-(A+A3)*cos(a1), 0], anchor='o', rotation=,1, )
    let cell = new QGdstk.Cell(`InLinePad_Spout_l${layer}d${datatype}`)
    cell.add([spout])
    return { cell: cell, key_points: [[(A + A3) * Math.cos(a1) + A6, 0]] }
  }

  AutoToolInLinePad.prototype.placeCell = function (pos) {
    let cell_pad = this.getInlinepadPadCellX(this.conf.size)
    let cell_spout_top = this.getInlinepadSpoutCell((A = this.conf.size.B), (A1 = this.conf.size.B1), (layer = this.conf.connect.top_layer), (datatype = this.conf.connect.top_datatype))
    let cell_spout_bottom = this.getInlinepadSpoutCell((A = this.conf.size.B), (A1 = this.conf.size.B1), (layer = this.conf.connect.bottom_layer), (datatype = this.conf.connect.bottom_datatype))
    let references = []
    for (let i = 0; i < pos.length; i++) {
      let t = pos[i]
      let ref_pad = new QGdstk.Reference(cell_pad, t.pos, t.angle_1, 1, false, 1, 1, null)
      references.push(ref_pad)
      if (t.layer_1 == this.conf.connect.top_layer) {
        let ref_spout = new QGdstk.Reference(cell_spout_top.cell, t.pos, t.angle_1, 1, false, 1, 1, null)
        references.push(ref_spout)
      }
      if (t.layer_1 == this.conf.connect.bottom_layer) {
        let ref_spout = new QGdstk.Reference(cell_spout_bottom.cell, t.pos, t.angle_1, 1, false, 1, 1, null)
        references.push(ref_spout)
      }
      if (t.layer_2 == this.conf.connect.top_layer) {
        let ref_spout = new QGdstk.Reference(cell_spout_top.cell, t.pos, t.angle_2, 1, false, 1, 1, null)
        references.push(ref_spout)
      }
      if (t.layer_2 == this.conf.connect.bottom_layer) {
        let ref_spout = new QGdstk.Reference(cell_spout_bottom.cell, t.pos, t.angle_2, 1, false, 1, 1, null)
        references.push(ref_spout)
      }
    }
    return {
      add_cells: [cell_pad, cell_spout_top.cell, cell_spout_bottom.cell],
      add_references: references,
    }
  }

  AutoToolInLinePad.prototype.InlinePadCell2D = function (pos, size, l1, l2, angle_1, angle_2) {
    let cell_pad = this.getInlinepadPadCellX(size)
    let cell_spout_top = this.getInlinepadSpoutCell((A = size.B), (A1 = size.B1), (layer = l1), (datatype = 0))
    let cell_spout_bottom = this.getInlinepadSpoutCell((A = size.B), (A1 = size.B1), (layer = l2), (datatype = 0))

    let cell_inlinepad = new QGdstk.Cell('Inlinepad')
    let references = []

    let ref_pad = new QGdstk.Reference(cell_pad, pos, angle_1, 1, false, 1, 1, null)
    references.push(ref_pad)
    let ref_spout_1 = new QGdstk.Reference(cell_spout_top.cell, pos, angle_1, 1, false, 1, 1, null)
    references.push(ref_spout_1)
    let ref_spout_2 = new QGdstk.Reference(cell_spout_bottom.cell, pos, angle_2, 1, false, 1, 1, null)
    references.push(ref_spout_2)
    cell_inlinepad.add(references)
    return cell_inlinepad
    // return {
    //   add_cells: [cell_pad, cell_spout_top.cell, cell_spout_bottom.cell],
    //   add_references: references,
    // }
  }

  AutoToolInLinePad.prototype.getPathsByLayer = function (layer, datatype) {
    let paths = this.cell.flexpaths
    let paths_layer = []
    for (let i = 0, len = paths.length; i < len; i++) {
      if (paths[i].layers[0] == layer && paths[i].width[0] === 0) {
        paths_layer.push(paths[i])
      }
    }
    return paths_layer
  }

  AutoToolInLinePad.prototype.placeCellKernel = function (pos) {
    let cell_pad_g = this.getInlinepadPadCellX(this.conf.size)
    let cell_spout_top_g = this.getInlinepadSpoutCell(this.conf.size.B, this.conf.size.B1, this.conf.connect.top_layer, this.conf.connect.top_datatype)
    let cell_spout_bottom_g = this.getInlinepadSpoutCell(this.conf.size.B, this.conf.size.B1, this.conf.connect.bottom_layer, this.conf.connect.bottom_datatype)
    let cell_pad = this.libs.GdstkCell2KernelCell(cell_pad_g, this.conf.fileLayerList)
    let cell_spout_top = this.libs.GdstkCell2KernelCell(cell_spout_top_g.cell, this.conf.fileLayerList)
    let cell_spout_bottom = this.libs.GdstkCell2KernelCell(cell_spout_bottom_g.cell, this.conf.fileLayerList)
    let references = []
    for (let i = 0; i < pos.length; i++) {
      let t = pos[i]
      // let ref_pad = new window.Kernel.Reference(cell_pad, t.pos, t.angle_1, 1, false, 1, 1, [0, 0])
      let ref_pad = new window.Kernel.Reference()
      ref_pad.cell = cell_pad
      ref_pad.origin = t.pos
      ref_pad.rotation = t.angle_1

      references.push(ref_pad)
      if (t.layer_1 == this.conf.connect.top_layer) {
        // let ref_spout = new window.Kernel.Reference(cell_spout_top, t.pos, t.angle_1, 1, false, 1, 1, [0, 0])
        let ref_spout = new window.Kernel.Reference()
        ref_spout.cell = cell_spout_top
        ref_spout.origin = t.pos
        ref_spout.rotation = t.angle_1
        references.push(ref_spout)
      }
      if (t.layer_1 == this.conf.connect.bottom_layer) {
        // let ref_spout = new window.Kernel.Reference(cell_spout_bottom, t.pos, t.angle_1, 1, false, 1, 1, [0, 0])
        let ref_spout = new window.Kernel.Reference()
        ref_spout.cell = cell_spout_bottom
        ref_spout.origin = t.pos
        ref_spout.rotation = t.angle_1
        references.push(ref_spout)
      }
      if (t.layer_2 == this.conf.connect.top_layer) {
        // let ref_spout = new window.Kernel.Reference(cell_spout_top, t.pos, t.angle_2, 1, false, 1, 1, [0, 0])
        let ref_spout = new window.Kernel.Reference()
        ref_spout.cell = cell_spout_top
        ref_spout.origin = t.pos
        ref_spout.rotation = t.angle_2
        references.push(ref_spout)
      }
      if (t.layer_2 == this.conf.connect.bottom_layer) {
        // let ref_spout = new window.Kernel.Reference(cell_spout_bottom, t.pos, t.angle_2, 1, false, 1, 1, [0, 0])
        let ref_spout = new window.Kernel.Reference()
        ref_spout.cell = cell_spout_bottom
        ref_spout.origin = t.pos
        ref_spout.rotation = t.angle_2
        references.push(ref_spout)
      }
    }
    return {
      add_cells: [cell_pad, cell_spout_top, cell_spout_bottom],
      add_references: references,
    }
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolInLinePad
  } else {
    window.AutoToolInLinePad = AutoToolInLinePad
  }
})()
