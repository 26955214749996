import { deepClone } from '../../utils'

//切割线
export function sliceLines(lines, val, axis, checkedGraphicsAABB) {
  let min_x = checkedGraphicsAABB[0][0]
  let min_y = checkedGraphicsAABB[0][1]
  let max_x = checkedGraphicsAABB[1][0]
  let max_y = checkedGraphicsAABB[1][1]
  min_y -= 10
  max_y += 10
  min_x -= 10
  max_x += 10
  let line_slice = []
  if (axis === 'x') {
    line_slice = [
      [val, min_y],
      [val, max_y],
    ]
  } else {
    line_slice = [
      [min_x, val],
      [max_x, val],
    ]
  }

  const result = []
  const len = lines.length
  for (let i = 0; i < len; i++) {
    result.push({ result: sliceLine(lines[i], line_slice), layers: deepClone(lines[i].layers), id: deepClone(lines[i].id), width: deepClone(lines[i].width), radius: deepClone(lines[i].radius), offset: deepClone(lines[i].offset) })
  }
  return result
}

function sliceLine(path, line_slice) {
  const result = []
  const points = path.points
  const len = points.length
  let path_new = []
  for (let i = 0; i < len - 1; i++) {
    const p1 = points[i]
    const p2 = points[i + 1]

    const p_intersection = segmentsIntr(p1, p2, line_slice[0], line_slice[1])
    path_new.push(p1)
    if (p_intersection) {
      //存在交点
      path_new.push(p_intersection)
      let path_new_copy = deepClone(path_new)
      if (curve_del_parallel(path_new_copy).length > 1) {
        result.push(deepClone(path_new_copy))
      }
      path_new = []
      path_new.push(p_intersection)
    }
  }
  path_new.push(points[len - 1])
  if (curve_del_parallel(path_new).length > 1) {
    result.push(path_new)
  }
  // path.deleted = true
  return result
}

function segmentsIntr(a, b, c, d) {
  // 三角形abc 面积的2倍
  var area_abc = (a[0] - c[0]) * (b[1] - c[1]) - (a[1] - c[1]) * (b[0] - c[0])

  // 三角形abd 面积的2倍
  var area_abd = (a[0] - d[0]) * (b[1] - d[1]) - (a[1] - d[1]) * (b[0] - d[0])

  // 面积符号相同则两点在线段同侧,不相交 (对点在线段上的情况,本例当作不相交处理);
  if (area_abc * area_abd > 0) {
    return false
  }

  // 三角形cda 面积的2倍
  var area_cda = (c[0] - a[0]) * (d[1] - a[1]) - (c[1] - a[1]) * (d[0] - a[0])
  // 三角形cdb 面积的2倍
  // 注意: 这里有一个小优化.不需要再用公式计算面积,而是通过已知的三个面积加减得出.
  var area_cdb = area_cda + area_abc - area_abd
  if (area_cda * area_cdb > 0) {
    return false
  }

  //计算交点坐标
  var t = area_cda / (area_abd - area_abc)
  var dx = t * (b[0] - a[0]),
    dy = t * (b[1] - a[1])
  return [a[0] + dx, a[1] + dy]
}

//"""简化曲线，去除平行点位"""
function curve_del_parallel(curve) {
  let new_curve = [curve[0]]
  for (let idx = 0; idx <= curve.length - 3; idx++) {
    let a = curve[idx]
    let b = curve[idx + 1]
    let c = curve[idx + 2]
    let ag_a_b = Math.atan2(b[1] - a[1], b[0] - a[0])
    let ag_b_c = Math.atan2(c[1] - b[1], c[0] - b[0])
    let err = Math.abs(ag_a_b - ag_b_c)
    if (err == 0) {
      continue
    } else {
      new_curve.push(b)
    }
  }
  new_curve.push(curve[curve.length - 1])
  if (new_curve.length == 2 && new_curve[0][0] == new_curve[1][0] && new_curve[0][1] == new_curve[1][1]) {
    new_curve = [new_curve[0]]
  }
  return new_curve
}
