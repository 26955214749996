var QGdstk = null
// import i18n from '../../common/lang/i18n'

var QGeosJs = require('./geos')
var AutoToolLibs = require('./libs')
const BigNumber = require('bignumber.js')

;(function () {
  function AutoToolCavity() {
    QGdstk = window.QGdstk
    i18n = window.i18n
    // this.library = library
    // this.cell = cell
    this.err = ''
    this.libs = new AutoToolLibs()
    this.conf = {
      polygon: [
        [0, 0],
        [500, 0],
        [500, 1000],
        [0, 1000],
        [0, 0],
      ],
      length: 7000,
      start_pos: [500, 800],
      end_pos: [250, 0],
      min_radius: 10,
      max_radius: 50,
      pipe_out_width: 10,
      pipe_inner_width: 5,
      layer: 1,
      datatype: 0,
    }
  }

  AutoToolCavity.prototype.setConf = function (conf) {
    this.conf = conf
  }

  // 运行
  AutoToolCavity.prototype.run = async function (conf) {
    this.conf = conf

    // try {
    let cavity = this.get()
    return {
      cavity: cavity,
      err: this.err,
    }
    // } catch (error) {
    //
    // }
  }

  AutoToolCavity.prototype.waveGuide = function (points = [], out_width = 8, inner_width = 4, radius = 20, layer = 0, datatype = 0) {
    let outer = new QGdstk.FlexPath(points, out_width, 0, 'round', 'flush', radius, null, 1e-2, false, true, layer, datatype)
    let inner = new QGdstk.FlexPath(points, inner_width, 0, 'round', 'flush', radius, null, 1e-2, false, true, layer, datatype)
    let res = QGdstk.boolean((operand1 = outer), (operand2 = inner), (operation = 'not'), (precision = 1e-3), (layer = layer), (datatype = datatype))
    return res
  }

  // 根据图层分离骨架线
  AutoToolCavity.prototype.get = function () {
    let path = this.generate()

    if (!path) return

    let cell = new QGdstk.Cell('tmp')
    let boom = this.waveGuide((points = path['points']), (out_width = this.conf.pipe_out_width), (inner_width = this.conf.pipe_inner_width), (radius = path['radius']), (layer = this.conf.layer), (datatype = this.conf.datatype))
    let drc_cavity = new QGdstk.FlexPath(path['points'], this.conf.pipe_out_width, 0, 'round', 'flush', path['radius'], null, 1e-2, false, true, 1006, 0)
    cell.add(boom)
    cell.add(drc_cavity.to_polygons())
    let box = cell.bounding_box()
    let center = [box[0][0] + (box[1][0] - box[0][0]) / 2, box[0][1] + (box[1][1] - box[0][1]) / 2]

    // 平移到原点
    for (let i = 0; i < boom.length; i++) {
      boom[i].translate(-center[0], -center[1])
    }
    let start_pos = [path['start_pos'][0] - center[0], path['start_pos'][1] - center[1]]
    let end_pos = [path['end_pos'][0] - center[0], path['end_pos'][1] - center[1]]

    let cell_cavity = new QGdstk.Cell(`ResonatorCavity_L${path['length'].toFixed(3)}`)
    var O = new QGdstk.Label('O', [0, 0])
    var O1 = new QGdstk.Label('O1', start_pos)
    var O2 = new QGdstk.Label('O2', end_pos)
    // 设置属性，关键点显示
    let keypoint_property = {
      create_label: true,
    }
    let kp_pro_str = JSON.stringify(keypoint_property)
    O.set_gds_property(1, kp_pro_str)
    O.set_gds_property(4, path['length'].toFixed(3))
    cell_cavity.add(boom)
    cell_cavity.add([O, O1, O2])

    let ref = new QGdstk.Reference(cell_cavity, center, 0, 1, false, 1, 1, null)

    // 构建2d显示cell
    let cell_2d = new QGdstk.Cell('Cavity2D')
    let poly = new QGdstk.Polygon(this.conf.polygon, 1, 0)
    poly.translate(-center[0], -center[1])
    cell_2d.add(boom)
    cell_2d.add([poly, O, O1, O2])

    let cell_cavity_k = this.libs.GdstkCell2KernelCell(cell_cavity, this.conf.fileLayerList)

    // 关键点
    let O_k = new window.Kernel.GdsKeyPoint()
    O_k.text = 'O'
    O_k.font_size = 15
    O_k.origin = [0, 0]
    O_k.need_be_dumped = true
    O_k.id = this.conf.fileLayerDict[this.conf.layer].id
    let O1_k = new window.Kernel.GdsKeyPoint()
    O1_k.text = 'O1'
    O1_k.font_size = 15
    O1_k.origin = start_pos
    O1_k.need_be_dumped = true
    O1_k.id = this.conf.fileLayerDict[this.conf.layer].id
    let O2_k = new window.Kernel.GdsKeyPoint()
    O2_k.text = 'O2'
    O2_k.font_size = 15
    O2_k.origin = end_pos
    O2_k.need_be_dumped = true
    O2_k.id = this.conf.fileLayerDict[this.conf.layer].id
    cell_cavity_k.add_label(O_k)
    cell_cavity_k.add_label(O1_k)
    cell_cavity_k.add_label(O2_k)

    // let ref_k = new window.Kernel.Reference(cell_cavity_k, center, 0, 1, false, 0, 0, [0, 0])
    let ref_k = new window.Kernel.Reference()
    ref_k.cell = cell_cavity_k
    ref_k.origin = center

    return {
      add_cells: [cell_cavity_k],
      add_references: [ref_k],
      cell: cell_2d,
      radius: path['radius'],
      length: path['length'],
      corner: path['n'],
    }
  }

  // 根据图层分离骨架线
  AutoToolCavity.prototype.get_kernel = function () {
    let path = this.generate()
    if (!path) return

    let cell = new window.Kernel.Cell('tmp')
    let offset = (this.conf.inner_width + (this.conf.out_width - this.conf.inner_width) / 2) / 2
    let width = (this.conf.out_width - this.conf.inner_width) / 2
    let wave_guide_path = path.copy()
    wave_guide_path.id = [item.gen_id, item.gen_id]
    wave_guide_path.layers = [item.gen_layer, item.gen_layer]
    wave_guide_path.simple_path = true
    wave_guide_path.offset = [offset, -offset]
    wave_guide_path.width = [width, width]
    wave_guide_path.radius = [this.conf.radius, this.conf.radius]
  }

  AutoToolCavity.prototype.getPathLength = function (points, r) {
    // """ 计算谐振腔长度
    // 示意图：
    // (p0) o-----------o
    //                  | (n1)
    //      o-----------o
    // (n2) |
    //      o----o (p5)
    //             ^
    //             |
    //             o (p6)
    // args:
    //     points: 谐振腔路径点位
    //     r: 拐角半径
    // return:
    //     谐振腔长度
    // """
    // 计算拐角个数
    let path_sum = 0
    let arc_err_sum = 0
    for (let i = 0; i < points.length - 1; i++) {
      let ln = [points[i], points[i + 1]]
      path_sum += Math.sqrt(Math.pow(Math.abs(ln[1][0] - ln[0][0]), 2) + Math.pow(Math.abs(ln[1][1] - ln[0][1]), 2))
      if (i > 0) {
        let pre_ln = [points[i], points[i - 1]]
        // 计算拐角转圆弧误差
        let ag_ln = this.libs.angle_direction(ln[0], ln[1])
        let ag_pre = this.libs.angle_direction(pre_ln[0], pre_ln[1])
        let ag = this.libs.angleModify(Math.abs(ag_ln - ag_pre) % Math.PI) / 2
        let half_arc_len = (ag / (2 * Math.PI)) * 2 * Math.PI * r
        let half_ln_len = r / Math.tan(ag)
        arc_err_sum += 2 * (half_ln_len - half_arc_len)
      }
    }
    length = path_sum - arc_err_sum
    return length
  }

  AutoToolCavity.prototype.tailBackRayPoint = function (end_pos, end_line, end_nv, path, radius, polygon) {
    //   """获取尾部回退点位, 射线方法求交点
    //          ^ nv1
    //   -------|-----
    //   |      |
    //   -------|------
    //          |      |
    //   -------|------
    //          |
    //   -------o(end_pos)-------> end_direct
    //   args:
    //       end_pos: 尾点
    //       end_line: 尾线
    //       end_nv: 尾线法向量方向角
    //       path: 谐振腔路径
    //       radius: 拐角半径
    //       polygon: 多边形区域
    //   """
    // 获取路径边框
    let path_ls = new QGeosJs('LineString', path)
    let bounds = path_ls.bounds()
    let xmin = bounds[0][0]
    let ymin = bounds[0][1]
    let xmax = bounds[1][0]
    let ymax = bounds[1][1]
    let max_width = Math.max(Math.abs(xmax - xmin), Math.abs(ymax - ymin))
    // 尾部法向量方向对应线段
    let line_nv = this.libs.pointExtendToLine(end_pos, end_nv, max_width * 2)
    let line_nv_ls = new QGeosJs('LineString', line_nv)
    if (path.length < 4) {
      return
    }
    while (path.length > 1) {
      line_tmp = [path[path.length - 2], path[path.length - 1]]
      // 剔除较短的线段
      let line_tmp_ls = new QGeosJs('LineString', line_tmp)
      if (line_tmp_ls.length() <= 2 * radius) {
        path.pop()
        continue
      }
      // 检测法向量与当前线段是否有交点
      let inter_pos = line_nv_ls.intersection(line_tmp_ls)
      if (!inter_pos) {
        path.pop()
        continue
      }
      let end_pos_p = new QGeosJs('Point', end_pos)
      // 存在交点, 检测交点与尾点距离
      let d = inter_pos.distance(end_pos_p)

      if (d > 2 * radius) {
        if (inter_pos.type == 'Point') {
          // path[path.length - 1] = [inter_pos.x, inter_pos.y]
          path[path.length - 1] = inter_pos.coordinates
          return path
        }
      }
      path.pop()
    }
  }

  AutoToolCavity.prototype.drawPath = function (radius) {
    // """
    // 绘制谐振腔路径
    // """

    let polygon = this.conf.polygon
    let start_pos = this.conf.start_pos
    let end_pos = this.conf.end_pos
    let pipe_out_width = this.conf.pipe_out_width
    if (polygon.length < 3) {
      this.err += i18n.t('messages.toolCavityThreePoint')
      return
    }

    // 多边形点array
    let poly = polygon
    // 获取边框
    let s_poly = new QGeosJs('Polygon', [poly])
    let bounds = s_poly.bounds()
    let x_min = bounds[0][0]
    let y_min = bounds[0][1]
    let x_max = bounds[1][0]
    let y_max = bounds[1][1]
    // x, y 总长度
    let x_length = x_max - x_min
    let y_length = y_max - y_min
    let max_width = Math.max(x_length, y_length)
    //
    // 多边形边集合
    let lines = []
    // for i in range(len(poly)-1):
    for (let i = 0; i < poly.length - 1; i++) {
      lines.push([poly[i], poly[i + 1]])
    }
    //
    // lines.push([poly[poly.length - 1], poly[0]])
    // 移动起点到多边形上面，用于用户起点在多边形附近情况，自动找最近点, 并获取点所在边
    let start = this.libs.movePointToPolygon(poly, start_pos, 10)
    start_pos = start.pos
    let start_line = start.line
    if (!start_pos) {
      // this.err += '起点离多边形过远, 最小范围: 10um~'
      this.err += i18n.t('messages.toolCavityStartPosTooFar')
      return
    }
    let end = this.libs.movePointToPolygon(poly, end_pos, 10)
    end_pos = end.pos
    let end_line = end.line
    if (!end_pos) {
      // this.err += '尾点离多边形过远, 最小范围: 10um~'
      this.err += i18n.t('messages.toolCavityEndPosTooFar')
      return
    }
    // )
    // )
    // )
    lines = new QGeosJs('MultiLineString', lines)
    // 延长结尾线段
    end_line = this.libs.extendLine([end_line[0], end_line[1]], 2 * max_width)
    end_line = this.libs.extendLine([end_line[1], end_line[0]], 2 * max_width)
    // 获取起点所在线段的两个法向量方向, 检测沿法向量移动的点是否在多边形区域内
    start = this.libs.lineNormalVector(start_line)
    let start_nv1 = start.nv1
    let start_nv2 = start.nv2

    let start_pos_nv1 = this.libs.movePoint(start_pos, start_nv1, 1)
    let p = new QGeosJs('Point', start_pos_nv1)
    let start_inter_nv1 = p.intersection(s_poly)

    let start_nv = start_inter_nv1 != null ? start_nv1 : start_nv2
    //
    //  / Math.PI)
    //  / Math.PI)
    //  / Math.PI)

    end = this.libs.lineNormalVector(end_line)
    // 获取尾点所在线段的两个法向量方向, 检测沿法向量移动的点是否在多边形区域内
    let end_nv1 = end.nv1
    let end_nv2 = end.nv2

    let end_pos_nv1 = this.libs.movePoint(end_pos, end_nv1, 1)
    p = new QGeosJs('Point', end_pos_nv1)
    let end_inter_nv1 = p.intersection(s_poly)
    let end_nv = end_inter_nv1 != null ? end_nv1 : end_nv2
    //
    //  / Math.PI)
    //  / Math.PI)
    //  / Math.PI)

    // 根据direct的两条法向量发出射线，检测是否与尾线有交点，有交点的方向为向下生长方向
    let x_direct = start_nv
    let direct_nv1 = this.libs.angleModify(x_direct - Math.PI / 2)
    let direct_nv2 = this.libs.angleModify(x_direct + Math.PI / 2)
    let line_down = this.libs.pointExtendToLine(start_pos, direct_nv1, 2 * max_width)
    let line_down_geo = new QGeosJs('LineString', line_down)
    let end_line_geo = new QGeosJs('LineString', end_line)
    let inter_down = line_down_geo.intersection(end_line_geo)

    if (inter_down) y_direct = direct_nv1
    else y_direct = direct_nv2
    // 求解路径, 确定起点位置
    let path = [[start_pos[0], start_pos[1]]]
    // y走步
    let y_walk_d = 0
    while (y_walk_d < 5 * y_length) {
      // 步距
      let y_step = 2 * radius
      let x_step = pipe_out_width / 2
      let pre = path[path.length - 1]
      // 朝向x_direct发出射线, 获取交点
      let pos_x = this.libs.ray(pre, lines, x_direct, max_width)
      if (!pos_x) break
      // 向下发出射线，检测是否存在交点
      let step_s = pipe_out_width
      let step_s_deta = pipe_out_width
      while (step_s < max_width) {
        // 获取回退点
        let back_pos = this.libs.movePoint(pos_x, x_direct - Math.PI, step_s)
        // 向下射线查询是否有交点
        let pos_y = this.libs.ray(back_pos, lines, y_direct, 2 * radius + pipe_out_width / 2)
        if (pos_y) {
          step_s += step_s_deta
        } else {
          // 向上射线查询是否有交点, 不能超出边框
          let pos_y_up = this.libs.ray(back_pos, lines, y_direct - Math.PI, 2 * radius + pipe_out_width / 2)
          if (!pos_y_up) break
          let d_y_up = this.libs.pointDistance(pos_y_up, back_pos)
          if (d_y_up > 2 * pipe_out_width) step_s += step_s_deta
          else break
        }
      }
      // 判断微调步距是否小于管道宽度, 得出x方向新步距
      x_step = step_s > pipe_out_width / 2 ? step_s : pipe_out_width / 2
      let p1 = this.libs.movePoint(pos_x, x_direct - Math.PI, x_step)
      let p2 = this.libs.movePoint(p1, y_direct, y_step)
      // 检测到结尾距离
      p2_p = new QGeosJs('Point', p2)
      let geo_end_line = new QGeosJs('LineString', end_line)
      let d2 = p2_p.distance(geo_end_line)
      path.push(p1)
      path.push(p2)
      // 根据到尾部线段距离
      if (d2 < 2 * radius) {
        path = this.tailBackRayPoint(end_pos, end_line, end_nv, path, radius, poly)
        if (!path) {
          // this.err += '尾点检测错误~'
          this.err += i18n.t('messages.toolCavityEndPosErr')
          return
        }
        path.push(end_pos)
        break
      } else {
        // 反向方向
        x_direct = this.libs.angleModify(x_direct + Math.PI)
        // 步距增加
        y_walk_d += y_step
      }
    }
    if (path.length > 1) {
      length = this.getPathLength(path, radius)
      n = parseInt(path.length / 2) - 1
      res = { points: path, radius: radius, n: n, length: length, start_pos: start_pos, end_pos: end_pos }
      return res
    }
  }

  AutoToolCavity.prototype.scalePathInflect = function (path, input_length) {
    // """ 收缩谐振腔拐角,误差均分到中间拐角,使其与输入路径长度一致
    // args:
    //     path: 路径
    //     input_length: 输入长度
    // """
    let points = path['points']
    let r = path['radius']
    // // 拐角数
    let n = parseInt(points.length / 2) - 1
    let path_dict = []
    // for i in range(0, n):
    for (let i = 0; i < n; i++) {
      let geo_p0 = new QGeosJs('Point', points[2 * i])
      let geo_p1 = new QGeosJs('Point', points[2 * i + 1])
      let geo_p2 = new QGeosJs('Point', points[2 * i + 2])
      let geo_p3 = new QGeosJs('Point', points[2 * i + 3])

      let xd1 = geo_p0.distance(geo_p1)
      let xd2 = geo_p2.distance(geo_p3)

      path_dict.push({
        id: i,
        enableMove: true,
        pos: [points[2 * i], points[2 * i + 1], points[2 * i + 2], points[2 * i + 3]],
        d1: xd1,
        d2: xd2,
        min: Math.min(xd1, xd2),
      })
    }

    // path_dict = path_dict[::-1]
    path_dict = path_dict.slice().reverse()
    // 获取偏差
    let err = Math.abs(input_length - path['length'])
    // err = path['length'] - input_length
    let deta = err / (2 * n)
    // 是否遍历完成
    let is_enable_num = 0
    // 相对运动倍数
    let circle_num = 1
    while (Math.abs(err) > 0) {
      // 检测是否所有拐角都处理完成
      is_enable_num = 0
      for (let idx = 0; idx < path_dict.length; idx++) {
        let x = path_dict[idx]
        if (x['d1'] - deta * circle_num > 2 * r && x['d2'] - deta * circle_num > 2 * r) {
          if (err > 1.9999 * deta) {
            x['pos'][1] = this.libs.movePoint(x['pos'][1], this.libs.angle_direction(x['pos'][1], x['pos'][0]), deta)
            x['pos'][2] = this.libs.movePoint(x['pos'][2], this.libs.angle_direction(x['pos'][2], x['pos'][3]), deta)
            x['d1'] = x['d1'] - deta
            x['d2'] = x['d2'] - deta
            x['min'] = Math.min(x['d1'], x['d2'])
            err = err - 2 * deta
          } else is_enable_num += 1
        } else is_enable_num += 1
      }
      circle_num += 1
      if (is_enable_num == path_dict.length) {
        if (Math.abs(err) > 0.001) {
          // this.err += `长度太短:${err}`
          this.err += `${i18n.t('messages.toolCavityLengthTooShort')}:${err}`
          // this.err += i18n.t('messages.toolCavityLengthTooShort') + err
          return
        }
        if (Math.abs(err) < 0.001) {
          break
        }
      }
    }
    // 更新路径
    for (let idx = 0; idx < path_dict.length; idx++) {
      let x = path_dict[idx]
      points[2 * x['id'] + 1] = x['pos'][1]
      points[2 * x['id'] + 2] = x['pos'][2]
    }
    path['length'] = this.getPathLength(points, r)
    return path
  }

  // """
  // 生成谐振腔， 采用二分法查找合适的拐角半径
  // """
  AutoToolCavity.prototype.generate = function () {
    // // 保存所有路径
    let paths = []
    let path_min = this.drawPath(this.conf.max_radius)
    if (!path_min) {
      //    ))
      // this.err += '最大半径输入错误;'
      this.err += i18n.t('messages.toolCavityMaxRadiusError')
      return
    }

    path_max = this.drawPath(this.conf.min_radius)
    if (!path_max) {
      // this.err += '最小半径输入错误;'
      this.err += i18n.t('messages.toolCavityMinRadiusError')
      return
    }

    let path_length_max = path_max['length']
    let path_length_min = path_min['length']
    let length_min = Math.min(path_length_min, path_length_max)
    let length_max = Math.max(path_length_min, path_length_max)

    let path_length = this.conf.length
    if (path_length > length_max || path_length < length_min) {
      // this.err += `长度范围：${Math.floor(length_min)}~${Math.ceil(length_max)}`
      this.err += `${i18n.t('messages.toolCavityLengthRange')}：${Math.floor(length_min)}~${Math.ceil(length_max)}`
      return
    }
    // // 二分查找
    let left = this.conf.min_radius
    let right = this.conf.max_radius
    let valid_path = null
    paths = []
    let r_list = []

    while (1) {
      let mid = (left + right) / 2
      let path = this.drawPath(mid)
      if (!path) {
        // this.err += '请检测输入参数是否满足规则;'
        this.err += i18n.t('messages.toolCavityPleaseCheckArgs')
        return
      }

      if (path['length'] < path_length) {
        right = mid
      } else if (path['length'] > path_length) {
        valid_path = path
        left = mid
      }
      paths.push(path)
      r_list.push(mid)
      if (!valid_path) continue
      if (r_list.length > 1 && Math.abs(r_list[r_list.length - 1] - r_list[r_list.length - 2]) < 1) {
        //    // 处理拐角，实现与输入长度一致
        valid_path = this.scalePathInflect(valid_path, path_length)
        if (!valid_path) return
        paths.push(valid_path)

        valid_path['points'] = this.pathBigNumber(valid_path['points'])
        let radius = new BigNumber(valid_path['radius'])
        valid_path['radius'] = parseFloat(radius.toFixed(3)) - 0.001
        valid_path['length'] = parseFloat(valid_path['length'].toFixed(3))
        return valid_path
      }
    }
  }

  AutoToolCavity.prototype.pathBigNumber = function (path) {
    let new_path = []
    for (let i = 0; i < path.length; i++) {
      let p = path[i]
      let x = new BigNumber(p[0])
      let y = new BigNumber(p[1])
      new_path.push([parseFloat(x.toFixed(5)), parseFloat(y.toFixed(5))])
    }
    return new_path
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolCavity
  } else {
    window.AutoToolCavity = AutoToolCavity
  }
})()
