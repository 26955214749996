// var triple18 = require('./triple18')
var QGdstk = null

var AutoToolWaveGuide = require('./wave_guide')
var AutoToolCheckLine = require('./check_line')
var AutoToolConnectLine = require('./connect_line')
var AutoToolWBPad = require('./wbpad')
var AutoToolInLinePad = require('./inlinepad')
var AutoToolAirBridge = require('./air_bridge_circle')

import { AutoToolJJ } from './jj'
var AutoToolTiNPadInpillar = require('./tinpad_inpillar_big_px')
var AutoToolCavity = require('./cavity')
var AutoToolGdsSplit = require('./gds_split')
import { AutoToolWaferLayout } from './wafer_layout'
import { KernelCell2GdstkLibrary } from './kernel_gdstk'

export { AutoToolWBPad }
export { AutoToolWaveGuide }
export { AutoToolInLinePad }
export { AutoToolConnectLine }
export { AutoToolCheckLine }

export class AutoTool {
  constructor() {
    QGdstk = window.QGdstk
    this.library = null
    this.cell = null
    this.name = ''
  }
  readGds(file_name, cell_name) {
    this.library = window.QGdstk.read_gds(file_name)
    this.name = cell_name
    this.loadCell(this.name)
  }

  load(library, cell) {
    this.library = library
    this.cell = cell
  }
  // cell对象转换gdstk对象
  fileKernelCell2Gdstk(cell) {
    // cell构建转换lib
    let cell_refs = cell.depend_cells(-1)
    let lib = new Kernel.Library(cell.name)
    lib.add_cell(cell)
    for (let i = 0; i < cell_refs.length; i++) {
      lib.add_cell(cell_refs[i])
    }
    lib.write_gds(lib.name)
    let data_u8 = Kernel.FS.readFile(lib.name)
    // 转换gdstk对象=
    window.QGdstk.FS.writeFile(lib.name, data_u8)
    let library = window.QGdstk.read_gds(lib.name)
    return library
  }

  loadKernelCell(cell) {
    this.library = KernelCell2GdstkLibrary(cell)
    // this.cell = this.loadCell(cell.name)
    this.cell = this.library.cells[0]
  }

  loadCell(cell) {
    this.cell = cell
  }

  exportGds(library, filename) {
    library.write_gds(filename)
    let mime = 'application/octet-stream'
    let content = QGdstk.FS.readFile(filename)

    var download_link = document.getElementById('download_gds')
    if (download_link == null) {
      download_link = document.createElement('a')
      download_link.id = 'download_gds'
      download_link.style.display = 'none'
      document.body.appendChild(download_link)
    }
    download_link.download = filename
    download_link.href = URL.createObjectURL(
      new Blob([content], {
        type: mime,
      })
    )
    download_link.click()
  }

  async gdsSplit(data) {
    var t = new AutoToolGdsSplit(this.library, this.cell)
    let res = t.run(data)
    return res
  }

  async checkLine(data) {
    var t = new AutoToolCheckLine(this.cell)
    return t.run(data)
  }

  async connectLine(data) {
    var t = new AutoToolConnectLine(this.library, this.cell)
    return t.run(data)
  }

  async wbpad(data) {
    let t = new AutoToolWBPad(this.library, this.cell)
    return t.run(data)
  }

  async inlinepad(data) {
    var t = new AutoToolInLinePad(this.library, this.cell)
    return t.run(data)
  }

  async airBridge(data) {
    var t = new AutoToolAirBridge(this.library, this.cell)
    let res = t.run(data)
    return res
  }

  async tinpad(data) {
    var t = new AutoToolTiNPadInpillar(this.library, this.cell)
    var res = t.run(data)
    return res
  }

  async waveGuide(data) {
    var t = new AutoToolWaveGuide(this.library, this.cell)
    return t.run(data)
  }

  async jj(data) {
    var t = new AutoToolJJ(this.library, this.cell)
    return t.run(data)
  }

  async waferLayout(data) {
    var t = new AutoToolWaferLayout()
    return t.run(data)
  }

  cavity() {
    var at = new AutoToolCavity(this.library, this.cell)
    let cavity = at.run()

    this.library = new QGdstk.Library((name = 'library'), (unit = 1e-6), (precision = 1e-9))
    this.library.add(cavity.cell)
    this.exportGds('cavity.gds')
  }

  writeGds() {
    this.library.write_gds('test.gds')
  }
}
