import { validAABB, validDrawCirclePoints } from '../validator/validator.js'
import { generateFontGeometry, generateLabelMesh, generateLabelStaticMesh, generateRulerLabelMesh } from './font-util'
import BigNumber from '../../tools/bignumber'
import { deepClone } from '../../utils'
import { distPoint, getVerticalSlope } from './collision.js'
import { CellQuadTree } from './quad-tree.js'
import { computeRefs, computeRefByAreas, divideRectangle, computeRefMats, containCell, computeTargetRefs, getExpandRefs, getOriginRepeationRefBoundingBox, getOriginExRefs } from './reference-util'
import { UndoRedo } from '../redo_undo/redo-undo.js'
import { SPECIALGRAPHIC } from '../const/board-status.js'
import { getLayerIdByNum } from './layer-util.js'
import { exChangeCpmponent, updateCharcterCell } from './cell-generate.js'
import { rotatePoints } from './layout-function.js'
const urlAlphabet = 'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict'
export const DirectionMap = {
  o: Kernel.GdsAnchor.O,
  n: Kernel.GdsAnchor.N,
  s: Kernel.GdsAnchor.S,
  w: Kernel.GdsAnchor.W,
  e: Kernel.GdsAnchor.E,
  nw: Kernel.GdsAnchor.NW,
  ne: Kernel.GdsAnchor.NE,
  sw: Kernel.GdsAnchor.SW,
  se: Kernel.GdsAnchor.SE,
}
export const AnchornMap = {
  5: Kernel.GdsAnchor.O,
  1: Kernel.GdsAnchor.N,
  9: Kernel.GdsAnchor.S,
  4: Kernel.GdsAnchor.W,
  6: Kernel.GdsAnchor.E,
  0: Kernel.GdsAnchor.NW,
  2: Kernel.GdsAnchor.NE,
  8: Kernel.GdsAnchor.SW,
  10: Kernel.GdsAnchor.SE,
}
export const DirectionMapCH = {
  中心: 'o',
  上: 'n',
  下: 's',
  左: 'w',
  右: 'e',
  左上: 'nw',
  右上: 'ne',
  左下: 'sw',
  右下: 'se',
}
export function getDirectionByAnchor(anchor) {
  for (const key in DirectionMap) {
    if (DirectionMap[key] == anchor) {
      return key
    }
  }
  return null
}
//可操控对象状态
export class ElSTATE {
  constructor() {
    this.preCheck = false //预选中
    this.checked = false //选中
    this.partChecked = false //部分选中
    this.preCut = false //预剪切
  }
  reset() {
    this.preCheck = false
    this.checked = false
    this.partChecked = false
    this.preCut = false
  }
}

//图形矩形外边框
export class BorderBox {
  constructor() {
    this.points = []
    this.rotation = 0
    this.aabb = null
  }
  //更新外边框顶点
  update(originAABB, origin, rotation, ref) {
    const rect = new QGdstk.rectangle(originAABB[0], originAABB[1])
    if (ref) {
      const x_reflection = ref.x_reflection ? -1 : 1
      rect.scale(ref.magnification, ref.magnification * x_reflection)
      rect.rotate(ref.rotation)
      rect.translate([ref.origin[0], ref.origin[1]])
    } else {
      rect.rotate(rotation, origin)
    }

    this.points = rect.get_points()
    this.aabb = rect.bounding_box()
  }
  //更新标签外边框顶点
  updateLabel(originAABB, label) {
    const rect = new QGdstk.rectangle(originAABB[0], originAABB[1])
    // const x_reflection = label.x_reflection ? -1 : 1
    // rect.scale(label.magnification, label.magnification * x_reflection)
    rect.rotate(label.angle)
    rect.translate([label.origin[0], label.origin[1]])
    this.points = rect.get_points()
    this.aabb = rect.bounding_box()
  }
  updateKP(originAABB, kp) {
    if (kp.need_be_dumped) {
      const rect = new QGdstk.rectangle(originAABB[0], originAABB[1])
      // const x_reflection = label.x_reflection ? -1 : 1
      // rect.scale(label.magnification, label.magnification * x_reflection)
      rect.rotate(kp.angle)
      rect.translate([kp.origin[0], kp.origin[1]])
      this.points = rect.get_points()
      this.aabb = getKeyPointWithLabelBoundingBox(kp.origin, rect.bounding_box())
    } else {
      this.aabb = getPointBounding_box(kp.origin)
      this.points = [this.aabb[0], [this.aabb[0][0], this.aabb[1][1]], this.aabb[1], [this.aabb[1][0], this.aabb[0][1]]]
    }
  }
  updateAABB() {
    const shape = new QGdstk.Polygon(this.points)
    this.aabb = shape.bounding_box()
  }
}

export function setObjStateCheck(obj, flag) {
  obj.js_obj.STATE.checked = flag
  if (!flag) {
    obj.js_obj.checked_points_index = []
    obj.js_obj.checked_lines = []
    obj.js_obj.checked_lines_pos = []
  }
}
export class Ruler {
  constructor(lineMat, textMat, points = []) {
    this.points = points
    this.lineMat = lineMat
    this.textMat = textMat
    this.line = initLine(lineMat)
    this.text = initShapeMesh(textMat)
    this.js_obj = {}
    this.js_obj.STATE = new ElSTATE() //状态
    this.js_obj.mouse_offset = null //鼠标的偏移量
    this.js_obj.points_mouse_offset = null //鼠标与顶点的偏移量
    this.js_obj.bounding_box = [
      [0, 0],
      [0, 0],
    ]
    this.js_obj.path = new Kernel.GdsFlexpath([[0, 0]], 0, 0, 'round', 'flush', 0, 1e-2, true, true, 0, 0) //this.js_obj.polygon = new QGdstk.Polygon([[0, 0]])
  }

  updateLine(lines) {
    let lineGeoArray = []
    lines.forEach(line => {
      lineGeoArray.push(generateLineGeometry(line))
    })
    const mergedLineGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(lineGeoArray, true)
    mergedLineGeometries.groups.forEach(g => (g.materialIndex = 0))
    this.line.material = [this.lineMat]
    this.line.geometry.dispose()
    this.line.geometry = mergedLineGeometries
  }
  updateText(texts, scale) {
    let textGeoArray = []
    texts.forEach(obj => {
      // let mesh = generateLabelMesh(obj.text, obj.pos, 0, 'sw', this.textMat, 8, 1)
      let mesh = generateRulerLabelMesh(obj.text, obj.pos, 0, 'sw', this.textMat)
      mesh.scale.set(scale, scale, 1)
      mesh.updateMatrix()
      mesh.geometry.applyMatrix4(mesh.matrix)
      textGeoArray.push(mesh.geometry)
    })
    const mergedLineGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(textGeoArray, true)
    mergedLineGeometries.groups.forEach(g => (g.materialIndex = 0))
    this.text.geometry.dispose()
    this.text.geometry = mergedLineGeometries
    this.text.geometry.computeBoundingSphere()
    this.text.frustumCulled = false
  }
  clear() {
    this.line.geometry.dispose()
    this.text.geometry.dispose()
    // this.clearText()
  }
  update(axis, LAYOUT) {
    if (this.points.length == 2) {
      updateRulerGraphic(this, axis, this.points[0], this.points[1], LAYOUT)
    }
  }
  copy() {
    const ruler = new Ruler(this.lineMat, this.textMat, this.points)
    return ruler
  }
  // clearText() {
  //   this.text.children.forEach(obj => obj.geometry?.dispose())
  //   this.text.remove(...this.text.children)
  // }
}

//清除器件额外数据
export function clearCellExtData(cell) {
  //已经被打开构建过的cell
  if (cell.js_obj.rulers) {
    for (let i = 0; i < cell.js_obj.rulers.length; i++) {
      const ruler = cell.js_obj.rulers[i]
      ruler.clear()
    }
    cell.js_obj.rulers = []
    for (let i = 0; i < cell.js_obj.cutArea3D.length; i++) {
      const cutArea = cell.js_obj.cutArea3D[i]
      disposeGraphics(cutArea.js_obj.graphics)
    }
    cell.js_obj.cutArea3D = []
    for (let i = 0; i < cell.js_obj.render3DArea.length; i++) {
      const renderArea = cell.js_obj.render3DArea[i]
      disposeGraphics(renderArea.js_obj.graphics)
    }
    cell.js_obj.render3DArea = []
  }
}

//重构四叉树
export function rebuildCellQuadTree(cell, LAYOUT) {
  const QUAD_TREE = cell.js_obj.QUAD_TREE

  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  const referens = cell.referens
  const ref_size = referens.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    reBuildGDSGraphic(path, LAYOUT, false)
    if (QUAD_TREE) {
      QUAD_TREE.updateNode(path)
    }
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    reBuildGDSGraphic(polygon, LAYOUT, false)
    if (QUAD_TREE) {
      QUAD_TREE.updateNode(polygon)
    }
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    reBuildGDSGraphic(label, LAYOUT, false, true, true)
    if (QUAD_TREE) {
      QUAD_TREE.updateNode(label)
    }
  }
  for (let i = 0; i < ref_size; i++) {
    const ref = referens[i]
    updateRefGraphic(ref)
    if (QUAD_TREE) {
      QUAD_TREE.updateNode(ref)
    }
  }
}

//初始化Lib基础数据
export function buildLib(LAYOUT, lib) {
  const cells = lib.children
  for (let i = 0; i < cells.length; i++) {
    buildCell(LAYOUT, cells[i])
  }
}

//强制初始化Cell基础数据
export function forceBuildCell(LAYOUT, cell) {
  // console.time('初始化cell数据')

  // console.time('器件包围盒')
  cell.js_obj.bounding_box = cell.bounding_box()
  // console.timeEnd('器件包围盒')
  //没有构建过的cell
  cell.js_obj.rulers = [] //添加尺子
  cell.js_obj.cutArea3D = [] //3D渲染区域
  cell.js_obj.render3DArea = [] //3D渲染区域
  cell.js_obj.cellInsMaps = initCellInstanceMap(cell)

  buildCellData(LAYOUT, cell)
  // console.timeEnd('初始化cell数据')
}

//初始化Cell基础数据
export function buildCell(LAYOUT, cell) {
  // console.time('初始化cell数据')

  // console.time('器件包围盒')
  cell.js_obj.bounding_box = cell.bounding_box()
  // console.timeEnd('器件包围盒')
  if (!cell.js_obj.cellInsMaps) {
    //没有构建过的cell
    cell.js_obj.rulers = [] //添加尺子
    cell.js_obj.cutArea3D = [] //3D渲染区域
    cell.js_obj.render3DArea = [] //3D渲染区域
    cell.js_obj.cellInsMaps = initCellInstanceMap(cell)
  }

  buildCellData(LAYOUT, cell)
  // console.timeEnd('初始化cell数据')
}

export function buildCellData(LAYOUT, cell) {
  cell.js_obj.QUAD_TREE = new CellQuadTree(cell) //构建四叉树
  if (!cell.js_obj.HISTORY) {
    cell.js_obj.HISTORY = new UndoRedo() //撤销恢复
  }
  LAYOUT.QUAD_TREE = cell.js_obj.QUAD_TREE

  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  const referens = cell.referens
  const ref_size = referens.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    if (!path.js_obj.STATE) {
      reBuildGdsData(LAYOUT, path, true, true) //新增未构建过图形
    } else {
      reBuildGDSGraphic(path, LAYOUT, true, true, false, true) //构建过重新更新
    }
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    if (!polygon.js_obj.STATE) {
      reBuildGdsData(LAYOUT, polygon, true)
    } else {
      reBuildGDSGraphic(polygon, LAYOUT, true)
    }
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    if (!label.js_obj.STATE) {
      reBuildGdsData(LAYOUT, label, true)
    } else {
      reBuildGDSGraphic(label, LAYOUT, true, true, true)
    }
  }
  // for (let i = 0; i < cell.js_obj.rulers.length; i++) {
  //   const ruler = cell.js_obj.rulers[i]
  //   if (!ruler.js_obj.STATE) {
  //     reBuildGdsData(LAYOUT, ruler, true)
  //   } else {
  //     reBuildGDSGraphic(ruler, LAYOUT, true, true, true)
  //   }
  // }
  // for (let i = 0; i < cell.js_obj.cutArea3D.length; i++) {
  //   const cutArea = cell.js_obj.cutArea3D[i]
  //   if (!cutArea.js_obj.STATE) {
  //     reBuildGdsData(LAYOUT, cutArea, true)
  //   } else {
  //     reBuildGDSGraphic(cutArea, LAYOUT, true, true, true)
  //   }
  // }
  // for (let i = 0; i < cell.js_obj.render3DArea.length; i++) {
  //   const renderArea = cell.js_obj.render3DArea[i]
  //   if (!renderArea.js_obj.STATE) {
  //     reBuildGdsData(LAYOUT, renderArea, true)
  //   } else {
  //     reBuildGDSGraphic(renderArea, LAYOUT, true, true, true)
  //   }
  // }

  // console.time('构造引用数据')

  for (let i = 0; i < ref_size; i++) {
    const ref = referens[i]
    ref.js_obj.borderBox = new BorderBox()
    ref.js_obj.STATE = new ElSTATE() //状态
    ref.js_obj.expanRefs = getExpandRefs(ref)
    updateRefGraphic(ref)
    LAYOUT.QUAD_TREE.updateNode(ref)
  }
  // console.timeEnd('构造引用数据')
}
//计算当前cell下层实例矩阵
export function initCellInstanceMap(cell) {
  // let divideAreas = divideRectangle(cell.bounding_box(), 10)
  const cellsInstanceMap = {}
  const cells = cell.depend_cells(-1)
  cells.forEach(cell => {
    cellsInstanceMap[cell.name] = { cell, refs: [], matsArray: [] }
  })
  // console.time('获取引用')
  const refs = cell.referens
  // console.timeEnd('获取引用')
  computeRefs(refs, cellsInstanceMap)
  // computeRefByAreas(cellsInstanceMap, divideAreas)
  computeRefMats(cellsInstanceMap)
  return cellsInstanceMap
}

//添加cell到当前cell的instance映射表
export function addCellInstanceMap(cell, cells, ref) {
  // let divideAreas = divideRectangle(cell.bounding_box(), 10)
  if (!cell.js_obj.cellInsMaps) {
    cell.js_obj.cellInsMaps = {}
  }
  const cellsInstanceMap = cell.js_obj.cellInsMaps
  cells.forEach(cell => {
    cellsInstanceMap[cell.name] = { cell, refs: [], matsArray: [] }
  })
  const targetCellsPtrs = cells.map(cell => cell.$$.ptr) //引用关联的器件
  computeRefs([ref], cellsInstanceMap)
  // computeRefByAreas(cellsInstanceMap, divideAreas, targetCellsPtrs)
  computeRefMats(cellsInstanceMap, targetCellsPtrs)
}

//更新器件调用引用关系
export function updateCellInsRefs(cell, cellsPtrs) {
  // let divideAreas = divideRectangle(cell.bounding_box(), 10)
  const cellInsMaps = cell.js_obj.cellInsMaps

  const child_cells = cell.depend_cells(-1)
  //可能已经删了引用，器件引用没有记录
  if (cellsPtrs.length) {
    cellsPtrs.forEach(cellPtr => {
      const res = child_cells.filter(cell => cell.$$?.ptr == cellPtr)
      if (res.length && !cellInsMaps[res[0].name]) {
        const taretCell = res[0]
        cellInsMaps[taretCell.name] = { cell: taretCell, refs: [], matsArray: [] }
      }
    })
  }
  for (const cellName in cellInsMaps) {
    const obj = cellInsMaps[cellName]
    if (cellsPtrs.indexOf(obj.cell.$$.ptr) !== -1) {
      obj.refs = [] //重置存在改动的器件引用
    }
  }
  computeTargetRefs(cell.referens, cellInsMaps, cellsPtrs)
}
//初始化点
export function initPoint(mat) {
  var kpGeometry = new THREE.BufferGeometry()
  kpGeometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
  let points = new THREE.Points(kpGeometry, mat)
  // points.frustumCulled = false
  return points
}

//初始化线
export function initLine(mat) {
  let line_geometry = new THREE.BufferGeometry()
  line_geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
  let line = new THREE.Line(line_geometry, mat)
  return line
}

//初始化边框
export function initLineLoop(mat) {
  let line_geometry = new THREE.BufferGeometry()
  line_geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
  let line = new THREE.LineLoop(line_geometry, mat)
  return line
}

//初始化连续断开线
export function initLineSegments(mat) {
  let line_geometry = new THREE.BufferGeometry()
  line_geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
  let line = new THREE.LineSegments(line_geometry, mat)
  return line
}

//初始化填充
export function initShapeMesh(mat) {
  if (!mat) {
    mat = new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0.5 })
  }

  let shapeMesh = new THREE.Mesh(new THREE.ShapeGeometry(new THREE.Shape()), mat)

  return shapeMesh
}

//绘制点，用于引用绘制
export function initPointIns(pointMat) {
  let instancedGeometry = new THREE.InstancedBufferGeometry().copy(new THREE.BufferGeometry().setFromPoints([]))
  instancedGeometry.setAttribute('globalMatrix', new THREE.InstancedBufferAttribute(new Float32Array(), 16))
  let points = new THREE.Points(instancedGeometry, pointMat)
  points.frustumCulled = false
  return points
}

//绘制线，用于引用绘制
export function initLineIns(lineMat) {
  let instancedGeometry = new THREE.InstancedBufferGeometry().copy(new THREE.BufferGeometry().setFromPoints([]))
  instancedGeometry.setAttribute('globalMatrix', new THREE.InstancedBufferAttribute(new Float32Array(), 16))
  let linesBatch = new THREE.Line(instancedGeometry, lineMat)
  return linesBatch
}

//多边形边框，用于引用绘制
export function initLineLoopIns(lineMat) {
  let instancedGeometry = new THREE.InstancedBufferGeometry().copy(new THREE.BufferGeometry().setFromPoints([]))
  instancedGeometry.setAttribute('globalMatrix', new THREE.InstancedBufferAttribute(new Float32Array(), 16))
  let linesBatch = new THREE.LineLoop(instancedGeometry, lineMat)
  return linesBatch
}

export function initLineSegmentsIns(lineMat) {
  let instancedGeometry = new THREE.InstancedBufferGeometry().copy(new THREE.BufferGeometry().setFromPoints([]))
  instancedGeometry.setAttribute('globalMatrix', new THREE.InstancedBufferAttribute(new Float32Array(), 16))
  let linesBatch = new THREE.LineSegments(instancedGeometry, lineMat)
  return linesBatch
}
//多边形填充实例，用于引用绘制
export function initFillIns(fillMat) {
  let fillGeometry = generateFillGeometry([])
  let insMesh = new THREE.InstancedMesh(fillGeometry, fillMat, 0)
  insMesh.instanceMatrix.needsUpdate = false
  return insMesh
}

//更新绘制线图形，用于引用绘制
export function updateLineIns(lineIns, lineMat, positionArray) {
  lineIns.geometry.attributes.position = positionArray
  lineIns.material = lineMat
  return lineIns
}

//更新多边形边框图形，用于引用绘制
export function updateLineLoopIns(lineIns, lineMat, positionArray) {
  lineIns.geometry.attributes.position = positionArray
  lineIns.material = lineMat
  return lineIns
}

//更新多边形填充实例图形，用于引用绘制
export function updateFillIns(fillIns, fillMat, positionArray, index) {
  fillIns.geometry.attributes.position = positionArray
  fillIns.geometry.index = index
  fillIns.material = fillMat
  return fillIns
}

//更新线引用数量和位置
export function updatePointInsAttr(pointIns, insCount, matsArray, boundingSphere) {
  pointIns.geometry.instanceCount = insCount
  // lineIns.geometry.attributes.globalMatrix.count = insCount
  // lineIns.geometry.attributes.globalMatrix.array = matsArray
  // lineIns.geometry.attributes.globalMatrix.needsUpdate = true
  // lineIns.geometry.attributes.globalMatrix = new THREE.InstancedBufferAttribute(matsArray, 16) //重新构建缓冲器
  // lineIns.geometry.attributes.globalMatrix.count = Infinity //Three.js实例化bug? 必须设置无限否则增删引用无法正确渲染
  pointIns.geometry.setAttribute('globalMatrix', new THREE.InstancedBufferAttribute(matsArray, 16))
  pointIns.geometry.attributes.globalMatrix.count = Infinity //Three.js实例化bug? 必须设置无限否则增删引用无法正确渲染

  pointIns.frustumCulled = false
  // if (insCount == 1) {
  //   if (existNaN(pointIns.geometry.attributes.position.array)) {
  //     return
  //   }
  //   pointIns.geometry.computeBoundingSphere()
  //   pointIns.geometry.boundingSphere.applyMatrix4(converArryToMat4(matsArray))
  //   pointIns.frustumCulled = true
  // }
}

//更新线引用数量和位置
export function updateLineInsAttr(lineIns, insCount, matsArray, boundingSphere) {
  lineIns.geometry.instanceCount = insCount
  // lineIns.geometry.attributes.globalMatrix.count = insCount
  // lineIns.geometry.attributes.globalMatrix.array = matsArray
  // lineIns.geometry.attributes.globalMatrix.needsUpdate = true
  // lineIns.geometry.attributes.globalMatrix = new THREE.InstancedBufferAttribute(matsArray, 16) //重新构建缓冲器
  // lineIns.geometry.attributes.globalMatrix.count = Infinity //Three.js实例化bug? 必须设置无限否则增删引用无法正确渲染
  lineIns.geometry.setAttribute('globalMatrix', new THREE.InstancedBufferAttribute(matsArray, 16))
  lineIns.geometry.attributes.globalMatrix.count = Infinity //Three.js实例化bug? 必须设置无限否则增删引用无法正确渲染

  lineIns.frustumCulled = false
  if (insCount == 1) {
    // if (boundingSphere) {
    //   //坐标点带NAN会报错
    //   return
    // } else {
    //   lineIns.geometry.computeBoundingSphere() //预先计算好包围圈
    // }
    if (existNaN(lineIns.geometry.attributes.position.array)) {
      return
    }
    lineIns.geometry.computeBoundingSphere()
    lineIns.geometry.boundingSphere.applyMatrix4(converArryToMat4(matsArray))
    lineIns.frustumCulled = true
  }
}

//更新填充引用数量和位置
export function updateFillAttr(fillIns, insCount, matsArray, isLabel, boundingSphere) {
  fillIns.count = insCount
  fillIns.instanceMatrix = new THREE.InstancedBufferAttribute(matsArray, 16)
  // fillIns.instanceMatrix.array = matsArray
  // fillIns.instanceMatrix.needsUpdate = true
  fillIns.frustumCulled = false
  if (insCount == 1 && !isLabel) {
    //标签实例化计算单个包围盒有问题
    fillIns.geometry.computeBoundingSphere()
    fillIns.geometry.boundingSphere.applyMatrix4(converArryToMat4(matsArray))
    fillIns.frustumCulled = true
  }
}

//矩阵转换
export function converArryToMat4(float32arr) {
  const m = new THREE.Matrix4()
  m.elements = Array.from(float32arr)
  return m
}

export function updateGraphicPosition(obj, points, mouse_pos) {
  let geometry = obj.geometry
  if (geometry) {
    // geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
    geometry.attributes.position = points2Float32Attr(points, mouse_pos)
    geometry.computeBoundingSphere()
  }
}

//初始化线
export function initPath(pos, LAYOUT) {
  const layer = LAYOUT.LAYER_STATE.layer
  let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
  let path = new Kernel.GdsFlexpath([[0, 0]], 0, 0, 'round', 'flush', 0, 1e-2, false, true, 0, 0)
  path.layers = [layer]
  path.id = [LAYOUT.LAYER_STATE.layerId]
  path.js_obj.STATE = new ElSTATE() //状态
  const lineIns = initLineIns(layerMat.lineInsMat) //骨架线 实例化，批量绘制
  const lineLoopIns = initLineIns(layerMat.lineInsMat) //线的多边形边框 实例化，批量绘制
  const fillIns = initFillIns(layerMat.fillInsMat) //线的多边形填充 实例化，批量绘制
  path.js_obj.graphics = { line: initLine(layerMat.lineMat), lineLoop: null, fill: null, lineIns, lineLoopIns, fillIns } //图形
  path.js_obj.points = [pos]
  initPartControlData(path)
  path.points = path.js_obj.points
  // path.width = [10, 10]
  // path.radius = [5, 5]
  // path.offset = [10, -10]
  if (path.width[0]) {
    path.layers = [layer, layer]
    path.id = [LAYOUT.LAYER_STATE.layerId, LAYOUT.LAYER_STATE.layerId]
    // path.simple_path = false
  }
  // if (path.width[0]) {
  path.js_obj.graphics.lineLoop = initLine(layerMat.lineMat)
  path.js_obj.graphics.fill = initShapeMesh(layerMat.fillMat)
  // }
  path.js_obj.bounding_box = [
    [0, 0],
    [0, 0],
  ]

  return path
}

//初始化矩形
export function initRect(pos, LAYOUT, TYPE, MAT) {
  let rect = new Kernel.GdsRectangle([0, 0], 0, 0)
  rect.js_obj.STATE = new ElSTATE() //状态
  if (TYPE) {
    rect.js_obj.type = TYPE
    rect.js_obj.graphics = { lineLoop: initLineLoop(MAT) } //图形
  } else {
    const layer = LAYOUT.LAYER_STATE.layer
    let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
    rect.layer = layer
    rect.id = LAYOUT.LAYER_STATE.layerId
    const lineLoopIns = initLineLoopIns(layerMat.lineInsMat) //线的多边形边框 实例化，批量绘制
    const fillIns = initFillIns(layerMat.fillInsMat) //线的多边形填充 实例化，批量绘制
    rect.js_obj.graphics = { lineLoop: initLineLoop(layerMat.lineMat), fill: initShapeMesh(layerMat.fillMat), lineLoopIns, fillIns } //图形
  }
  rect.js_obj.points = [pos]
  initPartControlData(rect)
  rect.js_obj.bounding_box = rect.bounding_box()
  return rect
}

//初始化圆
export function initEllipse(pos, LAYOUT) {
  const layer = LAYOUT.LAYER_STATE.layer
  let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
  let ellipse = new Kernel.GdsEllipse([0, 0], 10, 5, 40)
  ellipse.layer = layer
  ellipse.id = LAYOUT.LAYER_STATE.layerId
  ellipse.js_obj.STATE = new ElSTATE() //状态
  const lineLoopIns = initLineLoopIns(layerMat.lineInsMat) //线的多边形边框 实例化，批量绘制
  const fillIns = initFillIns(layerMat.fillInsMat) //线的多边形填充 实例化，批量绘制
  ellipse.js_obj.graphics = { lineLoop: initLineLoop(layerMat.lineMat), fill: initShapeMesh(layerMat.fillMat), lineLoopIns, fillIns } //图形
  ellipse.js_obj.points = [pos]
  initPartControlData(ellipse)
  ellipse.js_obj.bounding_box = ellipse.bounding_box()
  return ellipse
}

//初始化多边形
export function initPolygon(pos, LAYOUT, TYPE, MAT) {
  let polygon = new Kernel.GdsPolygon()
  polygon.js_obj.STATE = new ElSTATE() //状态
  if (TYPE) {
    polygon.js_obj.type = TYPE
    polygon.js_obj.graphics = { lineLoop: initLineLoop(MAT) } //图形
  } else {
    const layer = LAYOUT.LAYER_STATE.layer
    polygon.layer = layer
    polygon.id = LAYOUT.LAYER_STATE.layerId
    let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
    const lineLoopIns = initLineLoopIns(layerMat.lineInsMat) //线的多边形边框 实例化，批量绘制
    const fillIns = initFillIns(layerMat.fillInsMat) //线的多边形填充 实例化，批量绘制
    polygon.js_obj.graphics = { lineLoop: initLineLoop(layerMat.lineMat), fill: initShapeMesh(layerMat.fillMat), lineLoopIns, fillIns } //图形
  }

  polygon.js_obj.points = [pos]
  initPartControlData(polygon)
  polygon.js_obj.bounding_box = polygon.bounding_box()
  return polygon
}

//初始化标签
export function initLabel(pos, text, LAYOUT) {
  const layer = LAYOUT.STATUS.textLayer
  let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
  let label = new Kernel.GdsLabel()
  label.layer = layer
  label.id = LAYOUT.LAYER_STATE.layerId
  let direction = LAYOUT.STATUS.direction
  label.text = text
  label.js_obj.STATE = new ElSTATE() //状态
  label.font_size = LAYOUT.STATUS.font_size
  label.js_obj.mouse_offset = null //鼠标的偏移量
  label.origin = pos
  label.anchor = DirectionMap[direction]
  let fontMesh = generateLabelMesh(text, pos, 0, LAYOUT.STATUS.direction, layerMat.fontMat, LAYOUT.STATUS.font_size, 2)
  // let fontMesh = generateLabelStaticMesh(label, LAYOUT.STATUS.direction, layerMat.fontMat, 2)
  const fillIns = initFillIns(layerMat.fontInsMat) //线的多边形填充 实例化，批量绘制
  // const fillInsMesh = generateLabelMesh(text, pos, label.angle, LAYOUT.STATUS.direction, layerMat.fontMat, LAYOUT.STATUS.font_size, 2)
  updateFillIns(fillIns, fillIns.material, fontMesh.geometry.attributes.position, fontMesh.geometry.index)
  // fillIns.rotation.z = label.angle
  // fillIns.position.set(pos[0], pos[1], 0)
  label.js_obj.graphics = { fill: fontMesh, fillIns } //图形
  // label.js_obj.bounding_box = getLabelBounding_box(fontMesh)

  // let aabb = label.js_obj.bounding_box
  // label.js_obj.shape = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]]).get_points()
  label.js_obj.borderBox = new BorderBox()
  label.js_obj.borderBox.updateLabel(getLabelOriginBox(label), label)
  label.js_obj.bounding_box = label.js_obj.borderBox.aabb
  return label
}

//初始化关键点
export function initKeyPoint(pos, text, LAYOUT) {
  const layer = LAYOUT.LAYER_STATE.layer
  let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
  let direction = 's'
  let kp = new Kernel.GdsKeyPoint()
  kp.layer = layer
  kp.id = LAYOUT.LAYER_STATE.layerId
  kp.js_obj.STATE = new ElSTATE() //状态
  kp.js_obj.graphics = { point: initPoint(layerMat.keyPointMat), pointIns: initPointIns(layerMat.keyPointInsMat), fill: null, fillIns: null } //图形
  if (LAYOUT.STATUS.useLabel) {
    //是否创建标签
    kp.need_be_dumped = true
    kp.font_size = LAYOUT.STATUS.font_size
    const fillIns = initFillIns(layerMat.fontInsMat) //线的多边形填充 实例化，批量绘制
    const fontMesh = generateLabelMesh(text, pos, 0, direction, layerMat.fontMat, LAYOUT.STATUS.font_size, 2, true)
    kp.js_obj.graphics.fill = fontMesh
    kp.js_obj.graphics.fillIns = fillIns //图形
    updateFillIns(fillIns, fillIns.material, fontMesh.geometry.attributes.position, fontMesh.geometry.index)
  }
  kp.js_obj.mouse_offset = null //鼠标的偏移量
  kp.origin = pos
  kp.anchor = DirectionMap[direction]
  kp.text = text
  // kp.js_obj.bounding_box = getPointBounding_box(pos)
  // let aabb = kp.js_obj.bounding_box
  // kp.js_obj.shape = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]]).get_points()
  kp.js_obj.borderBox = new BorderBox()
  kp.js_obj.borderBox.updateKP(getLabelOriginBox(kp), kp)
  kp.js_obj.bounding_box = kp.js_obj.borderBox.aabb
  return kp
}

//初始化文本图形器件
export function initCharacterRef(txt = 'Created with GDSTK', layer = 0, anchor = '', size = 500, rotation = 0, layerId) {
  // rotation = (rotation * Math.PI) / 180 //角度转弧度
  size = parseFloat(size)
  if (!size || size < 0) {
    size = 100
  }
  // size = parseFloat(size) + parseFloat(size) * 0.15

  // let targetCell = new QGdstk.Cell(generateUniqueCellName('Character'))
  const ach = DirectionMap[anchor]

  let cell = new Kernel.Cell(generateUniqueCellName('Character'))
  let text = new Kernel.GdsText()
  text.text = txt
  text.anchor = ach

  text.size = size
  text.layer = layer
  cell.text = text
  cell.regenerate()
  cell.polygons.forEach(polygon => {
    polygon.id = layerId
    polygon.layer = layer
  })
  let ref = new Kernel.Reference()
  ref.rotation = rotation
  ref.cell = cell

  return ref
}
//计算盒子中心点
export function getBoundingBoxCenter(aabb) {
  const start_x = aabb[0][0]
  const start_y = aabb[0][1]
  let w = aabb[1][0] - start_x
  let h = aabb[1][1] - start_y
  return [start_x + w / 2, start_y + h / 2]
}
//添加部分选择记录数据
export function initPartControlData(obj) {
  obj.js_obj.checked_points_index = [] //选中点
  obj.js_obj.checked_lines = [] //选中线
  obj.js_obj.checked_lines_pos = [] //选中线顶点
  obj.js_obj.mouse_offset = null //鼠标的偏移量
  obj.js_obj.points_mouse_offset = null //顶点偏移量
  obj.js_obj.slopes = [] //斜率
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
    obj.js_obj.borderBox = new BorderBox()
  }
}

//移除部分选择操作相关记录数据
export function removePartCheckData(obj) {
  if (obj.js_obj) {
    obj.js_obj.checked_points_index = [] //选中点
    obj.js_obj.checked_lines = [] //选中线索引
    obj.js_obj.checked_lines_pos = [] //选中线顶点
    obj.js_obj.mouse_offset = null //鼠标的偏移量
  }
}
//绘制图形,更新元素图形信息
export function updateGDSGraphic(obj, mouse_pos, LAYOUT) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    obj.points = obj.js_obj.points
    updatePathGraphic(obj, mouse_pos)
    LAYOUT.QUAD_TREE.updateNode(obj)
  } else if (TYPE == 'GdsRectangle*') {
    if (!mouse_pos) return
    let data = getRectData(LAYOUT.STATUS.lastMousePos, mouse_pos, LAYOUT.STATUS.shift_press)
    if (!data) return
    obj.center = data.center
    obj.width = data.w
    obj.height = data.h
    updatePolygonGraphic(obj)
    obj.js_obj.borderBox.update(getRectOriginBox(obj), obj.center, obj.angle)
    LAYOUT.QUAD_TREE.updateNode(obj)
  } else if (TYPE == 'GdsEllipse*') {
    if (!mouse_pos) return
    let data = getCircleData(LAYOUT.STATUS.lastMousePos, mouse_pos, LAYOUT.STATUS.shift_press)
    if (!data) return
    obj.center = data.center
    obj.radius_x = data.r[0]
    obj.radius_y = data.r[1]
    updatePolygonGraphic(obj)
    obj.js_obj.borderBox.update(getEllipseOriginBox(obj), obj.center, obj.angle)
    LAYOUT.QUAD_TREE.updateNode(obj)
  } else if (TYPE == 'GdsPolygon*') {
    obj.points = obj.js_obj.points
    updatePolygonGraphic(obj, mouse_pos)
    LAYOUT.QUAD_TREE.updateNode(obj)
  } else if (TYPE == 'GdsLabel*') {
    updateLabelGraphic(obj, false, mouse_pos)
    obj.js_obj.borderBox.updateLabel(getLabelOriginBox(obj), obj)
    obj.js_obj.bounding_box = obj.js_obj.borderBox.aabb
    LAYOUT.QUAD_TREE.updateNode(obj)
  } else if (TYPE == 'GdsKeyPoint*') {
    updateKeyPointGraphic(obj, mouse_pos)
    obj.js_obj.borderBox.updateKP(getLabelOriginBox(obj), obj)
    obj.js_obj.bounding_box = obj.js_obj.borderBox.aabb
    LAYOUT.QUAD_TREE.updateNode(obj)
  } else {
    obj.pos = mouse_pos
    obj.js_obj.graphics.fill.position.set(mouse_pos[0], mouse_pos[1], 0.1)
  }
}

//重构未构建过的Gds数据,初始化基础数据结构
export function reBuildGdsData(LAYOUT, obj, updateNode = false, justBuild = false) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    const layer = obj.layers[0]
    let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
    if (!layerMat) {
      layerMat = window.DefLayerMat
    }
    const line = initLine(layerMat.lineMat) //骨架线
    const lineLoop = initLine(layerMat.lineMat) //线的多边形边框
    const fill = initShapeMesh(layerMat.fillMat) //线的多边形填充

    const lineIns = initLineIns(layerMat.lineInsMat) //骨架线 实例化，批量绘制
    const lineLoopIns = initLineIns(layerMat.lineInsMat) //线的多边形边框 实例化，批量绘制
    const fillIns = initFillIns(layerMat.fillInsMat) //线的多边形填充 实例化，批量绘制

    obj.js_obj.graphics = { line, lineLoop, fill, lineIns, lineLoopIns, fillIns } //图形
    initPartControlData(obj)
  } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
    const SP_TYPE = obj.js_obj.type
    if (SP_TYPE) {
      //特殊图形
      if (SP_TYPE == SPECIALGRAPHIC.CUTAREA3D) {
        const lineLoop = initLineLoop(LAYOUT.CONFIG.toolsMat.cutBox3D) //边框
        obj.js_obj.graphics = { lineLoop } //图形
      } else if (SP_TYPE == SPECIALGRAPHIC.RENDERAREA) {
        const lineLoop = initLineLoop(LAYOUT.CONFIG.toolsMat.renderArea3D) //边框
        obj.js_obj.graphics = { lineLoop } //图形
      }
    } else {
      const layer = obj.layer
      let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
      if (!layerMat) {
        layerMat = window.DefLayerMat
      }

      const lineLoop = initLineLoop(layerMat.lineMat) //边框
      const fill = initShapeMesh(layerMat.fillMat) //填充
      const lineLoopIns = initLineLoopIns(layerMat.lineInsMat) //边框实例化，批量绘制
      const fillIns = initFillIns(layerMat.fillInsMat) //填充实例化，批量绘制
      obj.js_obj.graphics = { lineLoop, fill, lineLoopIns, fillIns } //图形
    }

    initPartControlData(obj)
  } else if (TYPE == 'GdsLabel*') {
    const layer = obj.layer
    let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
    if (!layerMat) {
      layerMat = window.DefLayerMat
    }
    const direction = getDirectionByAnchor(obj.anchor)
    const fill = generateLabelMesh(obj.text, obj.origin, 0, direction, layerMat.fontMat, obj.font_size, 2)
    const fillIns = initFillIns(layerMat.fontInsMat) //线的多边形填充 实例化，批量绘制
    updateFillIns(fillIns, fillIns.material, fill.geometry.attributes.position, fill.geometry.index)
    fillIns.rotation.z = obj.angle
    fillIns.position.set(obj.origin[0], obj.origin[1], 0)
    obj.js_obj.graphics = { fill, fillIns } //图形
    obj.js_obj.mouse_offset = null //鼠标的偏移量
    obj.js_obj.borderBox = new BorderBox()
  } else if (TYPE == 'GdsKeyPoint*') {
    const layer = obj.layer
    let layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
    if (!layerMat) {
      layerMat = window.DefLayerMat
    }
    const direction = getDirectionByAnchor(obj.anchor)
    obj.js_obj.graphics = { point: initPoint(layerMat.keyPointMat), pointIns: initPointIns(layerMat.keyPointInsMat) } //图形
    if (obj.need_be_dumped) {
      const fill = generateLabelMesh(obj.text, obj.origin, 0, direction, layerMat.fontMat, obj.font_size, 2, true)
      // alert(obj.text, obj.origin, obj.font_size)
      const fillIns = initFillIns(layerMat.fontInsMat) //线的多边形填充 实例化，批量绘制
      updateFillIns(fillIns, fillIns.material, fill.geometry.attributes.position, fill.geometry.index)
      fillIns.rotation.z = obj.angle
      fillIns.position.set(obj.origin[0], obj.origin[1], 0)
      obj.js_obj.graphics.fill = fill
      obj.js_obj.graphics.fillIns = fillIns
    }
    obj.js_obj.mouse_offset = null //鼠标的偏移量
    obj.js_obj.borderBox = new BorderBox()
  } else if (TYPE == 'Reference*') {
    obj.js_obj.borderBox = new BorderBox()
    obj.js_obj.expanRefs = getExpandRefs(obj)
  }
  //  else if (obj.constructor.name == 'Ruler') {
  // }
  obj.js_obj.STATE = new ElSTATE() //状态
  reBuildGDSGraphic(obj, LAYOUT, updateNode, true, false, justBuild)
}

//已存在图形数据的结构重构图形数据
export function reBuildGDSGraphic(obj, LAYOUT, updateNode = true, updatePartCheckBox = true, textUpdate = false, justBuild = false) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    updatePathGraphic(obj)
    if (!justBuild) {
      if (obj.width[0]) {
        LAYOUT.STAGE.cellGroup.add(obj.js_obj.graphics.lineLoop)
        if (LAYOUT.CONFIG.showFill) {
          LAYOUT.STAGE.cellGroup.add(obj.js_obj.graphics.fill)
        }
      } else {
        LAYOUT.STAGE.cellGroup.remove(obj.js_obj.graphics.lineLoop)
        if (LAYOUT.CONFIG.showFill) {
          LAYOUT.STAGE.cellGroup.remove(obj.js_obj.graphics.fill)
        }
      }
    }
  } else if (TYPE == 'GdsRectangle*') {
    updatePolygonGraphic(obj)
    if (updatePartCheckBox) {
      obj.js_obj.borderBox.update(getRectOriginBox(obj), obj.center, obj.angle)
    }
  } else if (TYPE == 'GdsEllipse*') {
    updatePolygonGraphic(obj)
    if (updatePartCheckBox) {
      obj.js_obj.borderBox.update(getEllipseOriginBox(obj), obj.center, obj.angle)
    }
  } else if (TYPE == 'GdsPolygon*') {
    updatePolygonGraphic(obj)
  } else if (TYPE == 'GdsLabel*') {
    if (textUpdate) {
      reBuildTextMesh(LAYOUT, obj)
    }
    updateLabelGraphic(obj)
    obj.js_obj.borderBox.updateLabel(getLabelOriginBox(obj), obj)
    obj.js_obj.bounding_box = obj.js_obj.borderBox.aabb
  } else if (TYPE == 'GdsKeyPoint*') {
    if (textUpdate) {
      reBuildTextMesh(LAYOUT, obj, true)
    }
    updateKeyPointGraphic(obj)
    obj.js_obj.borderBox.updateKP(getLabelOriginBox(obj), obj)
    obj.js_obj.bounding_box = obj.js_obj.borderBox.aabb
  } else if (TYPE == 'Reference*') {
    updateRefGraphic(obj)
  } else if (obj.constructor.name == 'Ruler') {
    obj.js_obj.path.points = obj.points
    obj.update(LAYOUT.AXIS, LAYOUT)
  }
  if (updateNode) {
    LAYOUT.QUAD_TREE.updateNode(obj)
  }
}

export function updatePathGraphic(obj, next_pos = null) {
  let points = obj.points
  let lineGeo = obj.js_obj.graphics.line.geometry
  lineGeo.attributes.position = points2Float32Attr(points, next_pos)
  lineGeo.computeBoundingSphere()
  let polygons = get_path_polygons(points, next_pos, obj.width[0], obj.radius[0], obj.offset) // obj.to_polygons()

  obj.js_obj.polygons = polygons
  let lineLoopGeo = obj.js_obj.graphics.lineLoop.geometry
  const polygon_points = polygons.map(p => p.points)
  // const polygon_points = polygons.map(p => p.get_points())

  lineLoopGeo.attributes.position = mergeLinePolygonLines(polygon_points)

  // let tempLineGeo = mergeLinePolygonLines(polygon_points)
  // lineLoopGeo.attributes.position = tempLineGeo.attributes.position
  // lineLoopGeo.groups = tempLineGeo.groups
  // lineLoopGeo.computeBoundingSphere()
  // tempLineGeo.dispose()
  // lineLoopGeo.computeBoundingSphere()
  const shapeAABB = getPolygonsAABB(polygon_points, points)
  const boundingSphere = calculateBoundingCircle(shapeAABB)
  lineLoopGeo.boundingSphere = boundingSphere.clone()
  obj.js_obj.boundingSphere = boundingSphere.clone()
  let tempFillGeo = mergeFillPolygons(polygon_points) //generateFillGeometry(shape_points)
  let fillGeo = obj.js_obj.graphics.fill.geometry
  if (tempFillGeo) {
    fillGeo.attributes.position = tempFillGeo.attributes.position
    fillGeo.index = tempFillGeo.index
    fillGeo.groups = tempFillGeo.groups
    fillGeo.computeBoundingSphere()
    tempFillGeo.dispose()
  }

  const lineIns = obj.js_obj.graphics.lineIns
  updateLineIns(lineIns, lineIns.material, lineGeo.attributes.position)
  const lineLoopIns = obj.js_obj.graphics.lineLoopIns
  updateLineLoopIns(lineLoopIns, lineLoopIns.material, lineLoopGeo.attributes.position)
  const fillIns = obj.js_obj.graphics.fillIns
  updateFillIns(fillIns, fillIns.material, fillGeo.attributes.position, fillGeo.index)
  lineIns.frustumCulled = false
  lineLoopIns.frustumCulled = false
  fillIns.frustumCulled = false
  // lineIns.geometry.boundingSphere = lineGeo.boundingSphere.clone()
  // lineLoopIns.geometry.boundingSphere = boundingSphere.clone()
  // fillIns.geometry.boundingSphere = boundingSphere.clone()
  if (obj.js_obj.polygons.length) {
    obj.js_obj.bounding_box = shapeAABB
  } else {
    obj.js_obj.bounding_box = new QGdstk.Polygon(points).bounding_box()
  }
}
export function updatePolygonGraphic(obj, next_pos) {
  const points = obj.points
  const lineLoopGeo = obj.js_obj.graphics.lineLoop.geometry
  lineLoopGeo.attributes.position = points2Float32Attr(points, next_pos)
  lineLoopGeo.computeBoundingSphere()

  // obj.js_obj.graphics.lineLoopIns.geometry.boundingSphere = lineLoopGeo.boundingSphere.clone()
  const lineLoopIns = obj.js_obj.graphics.lineLoopIns
  if (lineLoopIns) {
    updateLineLoopIns(lineLoopIns, lineLoopIns.material, lineLoopGeo.attributes.position)
    lineLoopIns.frustumCulled = false
    lineLoopIns.lod = {
      originPoints: points,
      originPosition: lineLoopIns.geometry.attributes.position,
    }
  }
  if (points.length > 1 && obj.js_obj.type !== SPECIALGRAPHIC.CUTAREA3D && obj.js_obj.type !== SPECIALGRAPHIC.RENDERAREA) {
    let tempFillGeo = generateFillGeometry(points, next_pos)
    let fillGeo = obj.js_obj.graphics.fill.geometry
    fillGeo.attributes.position = tempFillGeo.attributes.position
    // fillGeo.attributes.normal = tempFillGeo.attributes.normal
    // fillGeo.attributes.uv = tempFillGeo.attributes.uv
    fillGeo.index = tempFillGeo.index
    fillGeo.computeBoundingSphere()
    tempFillGeo.dispose()
    const fillIns = obj.js_obj.graphics.fillIns
    updateFillIns(fillIns, fillIns.material, fillGeo.attributes.position, fillGeo.index)
    fillIns.frustumCulled = false
    // fillIns.geometry.boundingSphere = fillGeo.boundingSphere.clone()
    fillIns.lod = {
      originPoints: points,
      originPosition: fillIns.geometry.attributes.position,
      originIndex: fillIns.geometry.index,
    }
  }
  obj.js_obj.bounding_box = obj.bounding_box()
}

//重构文本网格
export function reBuildTextMesh(LAYOUT, obj, isKeyPoint = false) {
  const layer = obj.layer
  const layerMat = LAYOUT.LAYER_STATE.layerMats[layer]
  const direction = getDirectionByAnchor(obj.anchor)
  if (!isKeyPoint || (isKeyPoint && obj.need_be_dumped)) {
    const tempMesh = generateLabelMesh(obj.text, obj.origin, 0, direction, layerMat.fontMat, obj.font_size, 2, isKeyPoint) //重新生成文字mesh
    let tempFillGeo = tempMesh.geometry
    let fontMesh = obj.js_obj.graphics.fill
    // alert(1)
    let fillGeo = fontMesh.geometry
    fillGeo.attributes.position = tempFillGeo.attributes.position
    fillGeo.index = tempFillGeo.index
    fillGeo.computeBoundingBox()
    fillGeo.computeBoundingSphere()
    fillGeo.params = tempFillGeo.params
    tempFillGeo.dispose()
    let fillIns = obj.js_obj.graphics.fillIns
    updateFillIns(fillIns, layerMat.fontInsMat, fontMesh.geometry.attributes.position, fontMesh.geometry.index)
  }
}
export function updateLabelGraphic(obj, isKeyPoint = false, mouse_pos) {
  let font_mesh = obj.js_obj.graphics?.fill
  if (mouse_pos) {
    obj.origin = mouse_pos
  }
  const origin = obj.origin
  if (font_mesh) {
    font_mesh.position.set(origin[0], origin[1], 0.1)
    font_mesh.rotation.z = obj.angle
    let font_mesh_ins = obj.js_obj.graphics?.fillIns
    if (font_mesh_ins) {
      font_mesh_ins.position.set(origin[0], origin[1], 0.1)
      font_mesh_ins.rotation.z = obj.angle
    }
    if (isKeyPoint) {
      obj.js_obj.borderBox.updateLabel(getLabelOriginBox(obj), obj)
      // obj.js_obj.bounding_box = getKeyPointWithLabelBoundingBox(origin, getLabelBounding_box(font_mesh))
    } else {
      obj.js_obj.borderBox.updateKP(getLabelOriginBox(obj), obj)
      // obj.js_obj.bounding_box = getLabelBounding_box(font_mesh)
    }
  } else {
    // obj.js_obj.bounding_box = getPointBounding_box(obj.origin)
    if (isKeyPoint) {
      obj.js_obj.borderBox.updateLabel(getLabelOriginBox(obj), obj)
    } else {
      obj.js_obj.borderBox.updateKP(getLabelOriginBox(obj), obj)
    }
  }
  obj.js_obj.bounding_box = obj.js_obj.borderBox.aabb
  // let aabb = obj.js_obj.bounding_box
  // obj.js_obj.shape = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]]).get_points()
}

export function updateKeyPointGraphic(obj, mouse_pos) {
  let font_mesh = obj.js_obj.graphics?.fill
  if (mouse_pos) {
    obj.origin = mouse_pos
  }
  const origin = obj.origin
  obj.js_obj.graphics.point.geometry.attributes.position = points2Float32Attr([obj.origin])
  obj.js_obj.graphics.pointIns.geometry.attributes.position = points2Float32Attr([obj.origin])
  obj.js_obj.graphics.point.geometry.computeBoundingSphere()
  // obj.js_obj.graphics.point.frustumCulled = false

  if (font_mesh) {
    font_mesh.position.set(origin[0], origin[1], 0.1)
    font_mesh.rotation.z = obj.angle
    let font_mesh_ins = obj.js_obj.graphics?.fillIns
    if (font_mesh_ins) {
      font_mesh_ins.position.set(origin[0], origin[1], 0.1)
      font_mesh_ins.rotation.z = obj.angle
    }
    // obj.js_obj.bounding_box = getLabelBounding_box(font_mesh)
    obj.js_obj.bounding_box = getKeyPointWithLabelBoundingBox(origin, getLabelBounding_box(font_mesh))
  } else {
    obj.js_obj.bounding_box = getPointBounding_box(obj.origin)
  }
  let aabb = obj.js_obj.bounding_box
  obj.js_obj.shape = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]]).get_points()
}
export function updateRefGraphic(obj) {
  // obj.js_obj.bounding_box = obj.bounding_box()
  obj.js_obj.expanRefs = getExpandRefs(obj)
  obj.js_obj.borderBox.update(getRefOriginBox(obj), obj.origin, obj.rotation, obj)
  obj.js_obj.bounding_box = obj.js_obj.borderBox.aabb
}

export function getEllipseOriginBox(obj) {
  const x = obj.center[0]
  const y = obj.center[1]
  const r_x = obj.radius_x
  const r_y = obj.radius_y
  return [
    [x - r_x, y - r_y],
    [x + r_x, y + r_y],
  ]
}

export function getRectOriginBox(obj) {
  const x = obj.center[0]
  const y = obj.center[1]
  const hf_w = obj.width / 2
  const hf_h = obj.height / 2
  return [
    [x - hf_w, y - hf_h],
    [x + hf_w, y + hf_h],
  ]
}

export function getLabelOriginBox(obj) {
  if (obj.js_obj.graphics?.fill) {
    const box3d = obj.js_obj.graphics.fill.geometry.boundingBox
    return [
      [box3d.min.x, box3d.min.y],
      [box3d.max.x, box3d.max.y],
    ]
  }
  return getPointBounding_box(obj.origin)
}
//更新器件包围盒
export function updateCellBoundingBox(cell) {
  if (cell) {
    cell.js_obj.bounding_box = cell.bounding_box()
  }
}
//计算引用原始包围盒
export function getRefOriginBox(obj) {
  if (obj.cell) {
    //不存在cell
    if (!obj.cell.js_obj.bounding_box) {
      obj.cell.js_obj.bounding_box = obj.cell.bounding_box()
    }
    const exRefs = obj.js_obj.expanRefs //存在阵列
    if (exRefs.length) {
      const originExRefs = getOriginExRefs(obj)
      return getOriginRepeationRefBoundingBox(obj.cell.js_obj.bounding_box, originExRefs)
    } else {
      return obj.cell.js_obj.bounding_box
    }
  } else {
    const exRefs = obj.js_obj.expanRefs //存在阵列
    if (exRefs.length) {
      const originExRefs = getOriginExRefs(obj)
      return getOriginRepeationRefBoundingBox(obj.bounding_box(), originExRefs)
    } else {
      return obj.bounding_box()
    }
  }
}
//2D坐标转换为3D坐标
export function points2Float32Attr(points, mouse_pos) {
  let arr = []
  for (let i = 0; i < points.length; i++) {
    arr.push(points[i][0], points[i][1], 0)
  }
  if (mouse_pos) {
    arr.push(mouse_pos[0], mouse_pos[1], 0)
  }

  return new THREE.Float32BufferAttribute(arr, 3)
  // return arr
}

//生成填充几何
function generateFillGeometry(points, mouse_pos) {
  const newShape = new THREE.Shape()
  const len = points.length
  if (len) {
    newShape.moveTo(points[0][0], points[0][1])
    for (let i = 1; i < len; i++) {
      newShape.lineTo(points[i][0], points[i][1])
    }
    if (mouse_pos) {
      newShape.lineTo(mouse_pos[0], mouse_pos[1])
    }
  }
  return new THREE.ShapeGeometry(newShape)
}

//计算矩形中心点，半径
export function getRectData(start, end) {
  if (!validDrawCirclePoints(start, end)) return null
  let center = [0, 0]
  let w = Math.abs(start[0] - end[0])
  let h = Math.abs(start[1] - end[1])
  center[0] = (start[0] + end[0]) / 2
  center[1] = (start[1] + end[1]) / 2
  return { center, w, h }
}
//计算圆的中心点，半径
export function getCircleData(start, end, perfect = false) {
  if (!validDrawCirclePoints(start, end)) return null

  //以对角线生成圆
  let r = [0, 0]
  let center = [0, 0]
  r[0] = Math.abs((start[0] - end[0]) / 2)
  r[1] = Math.abs((start[1] - end[1]) / 2)
  // if (perfect) {
  //   r[1] = r[0]
  // }
  center[0] = (start[0] + end[0]) / 2
  center[1] = (start[1] + end[1]) / 2

  //  let result = []
  // const polygon = new QGdstk.ellipse(center, r, null, 0, 0, 0.001, 0, 0)
  // let points = polygon.get_points()
  // let len = points.length
  // if (len > 24) {
  //   let interval = parseInt(len / 24)
  //   result = points.filter((p, i) => i % interval === 0)
  // }
  return { center, r }
}

//获取矩形顶点
export function getRectPoints(start, end, perfect = false) {
  if (!validDrawCirclePoints(start, end)) return []
  let rect = new QGdstk.rectangle(start, end, 0, 0)
  return rect.get_points()
}

//夹角
export function getAngle({ x: x1, y: y1 }, { x: x2, y: y2 }) {
  const dot = x1 * x2 + y1 * y2
  const det = x1 * y2 - y1 * x2
  const angle = (-Math.atan2(det, dot) / Math.PI) * 180
  return angle
}

//获取连续文本
export function getCtLabelText(array) {
  for (let i = 0; i < array.length; i++) {
    let text = array.shift()
    if (text !== undefined && text !== '') {
      return text
    }
  }
  return null
}

//生成尺子图形数据
export function updateRulerGraphic(ruler, axis, p1, p2, LAYOUT) {
  ruler.points = [p1, p2]
  ruler.js_obj.path.points = ruler.points
  let res = drawDividRuler(axis, p1, p2)

  ruler.updateLine(res.lines)
  ruler.updateText(res.texts, 1 / axis.scale)
  if (LAYOUT) {
    ruler.js_obj.bounding_box = [
      [Math.min(p1[0], p2[0]), Math.min(p1[1], p2[1])],
      [Math.max(p1[0], p2[0]), Math.max(p1[1], p2[1])],
    ]
    LAYOUT.QUAD_TREE.updateNode(ruler)
  }
}
// 绘制刻度, p1: 起点， p2:终点
export function drawDividRuler(axis, p1, p2) {
  // 系数
  let unit_scale = axis.unit_scale
  // 当前视图真实区域
  let real_box = axis.real_box
  // 固定位数
  let fix_num = 6
  // 步长
  let step = axis.step_divid * unit_scale
  let angle = AUTO_TOOL_LIBS.angle_direction(p1, p2)
  let cal_angle = 0
  let quadrant = 1
  // 1象限
  if (angle >= 0 && angle <= Math.PI / 2) {
    cal_angle = angle + Math.PI / 2
    quadrant = angle == 0 ? 4 : 1
  }
  // 2象限
  if (angle > Math.PI / 2 && angle <= Math.PI) {
    cal_angle = angle - Math.PI / 2
    quadrant = 2
  }
  // 3象限
  if (angle > Math.PI && angle <= (Math.PI * 3) / 2) {
    cal_angle = angle - Math.PI / 2
    quadrant = 3
  }
  // 4象限
  if (angle > (Math.PI * 3) / 2 && angle <= Math.PI * 2) {
    cal_angle = angle + Math.PI / 2
    quadrant = 4
  }
  let dx = p2[0] - p1[0]
  let dy = p2[1] - p1[1]
  let len = Math.sqrt(dx * dx + dy * dy)
  let n = Math.floor(len / step)
  n = n < 1 ? 1 : n
  let x_step = dx / n
  let y_step = dy / n

  let lines = []
  let texts = []
  // let path = new CanvasKit.Path()
  // 绘制起点->终点
  lines.push([p1, p2])
  // path.moveTo(p1[0], p1[1])
  // path.lineTo(p2[0], p2[1])
  // 绘制中间视图区域内刻度
  let line = null
  let find_left = false
  let txt = 0
  for (let i = 0; i < n; i++) {
    // 截取刻度在视图区域内的绘制，视图歪的不绘制，减少性能消耗
    let pos_x = p1[0] + i * x_step
    let pos_y = p1[1] + i * y_step
    if (pos_x >= real_box.x_min * unit_scale && pos_x <= real_box.x_max * unit_scale && pos_y >= real_box.y_min * unit_scale && pos_y <= real_box.y_max * unit_scale) {
      find_left = true
    } else {
      if (find_left) {
        break
      }
      continue
    }
    // 绘制刻度线和刻度值
    let p = [p1[0] + i * x_step, p1[1] + i * y_step]
    if (i % 5 == 0) {
      line = AUTO_TOOL_LIBS.pointExtendToLine(p, cal_angle, 15 / axis.scale)
      txt = i * step
      texts.push({ pos: line[1], text: BigNumber(txt.toFixed(fix_num)).toString(), angle: 0 })
      //drawValue(canvas, line[1], txt.toFixed(fix_num), 1 / axis.scale, 0, font_pt, this.divid_ruler_font)
    } else {
      line = AUTO_TOOL_LIBS.pointExtendToLine(p, cal_angle, 5 / axis.scale)
    }
    lines.push([line[0], line[1]])
    // path.moveTo(line[0][0], line[0][1])
    // path.lineTo(line[1][0], line[1][1])
  }
  // 终点刻度
  line = AUTO_TOOL_LIBS.pointExtendToLine(p2, cal_angle, 15 / axis.scale)
  lines.push([line[0], line[1]])
  // path.moveTo(line[0][0], line[0][1])
  // path.lineTo(line[1][0], line[1][1])
  txt = len
  texts.push({ pos: line[1], text: BigNumber(txt.toFixed(fix_num)).toString(), angle: 0 })
  //drawValue(canvas, line[1], txt.toFixed(fix_num), 1 / axis.scale, 0, font_pt, this.divid_ruler_font)
  return { lines, texts }
}

//生成线Geometry
function generateLineGeometry(points) {
  const position = []
  const length = points.length
  for (let i = 0, l = length; i < l; i++) {
    const point = points[i]
    position.push(point[0], point[1], 0)
  }
  let line_geometry = new THREE.BufferGeometry()
  line_geometry.setAttribute('position', new THREE.Float32BufferAttribute(position, 3))
  return line_geometry
}

//计算图形包围盒
export function getBoundingBox(points) {
  let obj = new QGdstk.Polygon(points)
  return obj.bounding_box()
}

//计算屏幕包围盒
export function getScreenAABB(AXIS) {
  const offset_x = (AXIS.dx - AXIS.width / 2) / AXIS.scale
  const offset_y = (AXIS.dy - AXIS.height / 2) / AXIS.scale
  return [
    [-(AXIS.width / 2) / AXIS.scale - offset_x, -(AXIS.height / 2) / AXIS.scale + offset_y],
    [AXIS.width / 2 / AXIS.scale - offset_x, AXIS.height / 2 / AXIS.scale + offset_y],
  ]
}

//计算有宽度的线多边形
export function get_path_polygons(points, mouse_pos, width, radius = 0, lineoffset = [0, 0]) {
  let res = []
  if (!width) return res
  // radius = parseFloat(radius)
  // let type = radius > 0 ? 'round' : 'natural'
  let shape = mouse_pos ? [...points, mouse_pos] : points

  // let path = new QGdstk.FlexPath(shape, width, lineoffset, 'round', 'flush', radius, null, 1e-2, true, true, 0, 0)
  let path = new Kernel.GdsFlexpath(shape, width, lineoffset, 'round', 'flush', radius, 1e-2, true, true, 0, 0)
  return path.to_polygons()

  // let shape = mouse_pos ? [...points, mouse_pos] : deepClone(points)
  // const out_width = width + lineoffset * 2
  // const inner_width = lineoffset * 2
  // return waveGuide(shape, out_width, inner_width, radius)
}

//计算有宽度的线多边形
export function waveGuide(points = [], out_width = 8, inner_width = 0, radius = 0, layer = 0, datatype = 0) {
  function add_line(p1, p2) {
    let dx = p2[0] - p1[0]
    let dy = p2[1] - p1[1]
    let x_step = dx / 2
    let y_step = dy / 2
    const n = normalizeVector2(x_step, y_step) //归一化，避免延长过长
    return [p2[0] + n.x / 100, p2[1] + n.y / 100]
  }
  let len = points.length
  if (len < 2) {
    return []
  }
  const outer = new QGdstk.FlexPath(points, out_width, 0, 'round', 'flush', radius, null, 1e-2, false, true, layer, datatype)

  // 延长起点和终点，以免误差
  points[0] = add_line(points[1], points[0])
  points[len - 1] = add_line(points[len - 2], points[len - 1])

  const inner = new QGdstk.FlexPath(points, inner_width, 0, 'round', 'flush', radius, null, 1e-2, false, true, layer, datatype)
  return QGdstk.boolean(outer, inner, 'not', 1e-3, layer, datatype)
}
function normalizeVector2(x, y) {
  // 计算向量的长度
  const length = Math.sqrt(x * x + y * y)
  return {
    x: x / length,
    y: y / length,
  }
}
//合并线的多边形边框顶点
export function mergeLinePolygonLines(polygons) {
  const arr = []
  for (let i = 0; i < polygons.length; i++) {
    const points = polygons[i]
    if (points.length) {
      points.push(points[0])
      if (i) {
        arr.push([NaN, NaN]) //使用NaN断开绘制
        // arr.push(NaN, NaN, NaN)
      }
      arr.push(...points)
      // for (let i = 0; i < points.length; i++) {
      //   arr.push(points[i].length, points[i][1], 0)
      // }
    }
  }

  return points2Float32Attr(arr)

  // const shape_geos = []
  // polygons.forEach(points => {
  //   const shap_geo = generateLineGeometry(points.concat([points[0]]))
  //   shape_geos.push(shap_geo)
  //   shap_geo.dispose()
  // })

  // const mergedLineGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(shape_geos)
  // mergedLineGeometries.groups.forEach(g => (g.materialIndex = 0))

  // return mergedLineGeometries
}

//合并线的多边形填充
export function mergeFillPolygons(polygons) {
  if (!polygons.length) return null
  const shape_geos = []
  polygons.forEach(points => {
    const shap_geo = generateFillGeometry(points)
    shape_geos.push(shap_geo)
    shap_geo.dispose()
  })
  const mergedLineGeometries = THREE.BufferGeometryUtils.mergeBufferGeometries(shape_geos)
  mergedLineGeometries.groups.forEach(g => (g.materialIndex = 0))

  return mergedLineGeometries
}
// //合并线的多边形填充 使用一个buffer 不使用group
// export function mergeFillPolygons(polygons) {
//   const positions_bufs = []
//   const indexes_bufs = []
//   polygons.forEach(points => {
//     const shape_geo = generateFillGeometry(points)
//     positions_bufs.push(shape_geo.attributes.position.array)
//     indexes_bufs.push(shape_geo.index.array)
//     // shape_geo.dispose()
//   })

//   const position = new THREE.Float32BufferAttribute(bufjoinf32(positions_bufs), 3)
//   const index = new THREE.Uint8BufferAttribute(bufjoinu8(indexes_bufs), 3)
//   return { position, index }
// }

// function sum(a) {
//   return a.reduce(function (a, b) {
//     return a + b
//   }, 0)
// }

// //合并f32数组
// function bufjoinf32(bufs) {
//   var lens = bufs.map(function (a) {
//     return a.length
//   })
//   var aout = new Float32Array(sum(lens))
//   for (var i = 0; i < bufs.length; ++i) {
//     var start = sum(lens.slice(0, i))
//     aout.set(bufs[i], start) // copy bufs[i] to aout at start position
//   }
//   return aout
// }

// //合并u8顶点索引
// function bufjoinu8(bufs) {
//   var lens = bufs.map(function (a) {
//     return a.length
//   })
//   var aout = new Uint8Array(sum(lens) + (bufs.length - 1) * 3)
//   for (var i = 0; i < bufs.length; ++i) {
//     var start = sum(lens.slice(0, i))
//     if (i > 0) {
//       aout.set([NaN, NaN, NaN], start)
//       aout.set(bufs[i], start + i * 3)
//     } else {
//       aout.set(bufs[i], start) // copy bufs[i] to aout at start position
//     }
//   }
//   return aout
// }

//获取标签包围盒
export function getLabelBounding_box(fontMesh) {
  const aabb = new THREE.Box3()
  aabb.setFromObject(fontMesh)
  return [
    [aabb.min.x, aabb.min.y],
    [aabb.max.x, aabb.max.y],
  ]
}
//获取带有标签的关键点包围盒
export function getKeyPointWithLabelBoundingBox(pos, aabb) {
  // aabb[0][1] = pos[1]
  let temp = new QGdstk.Polygon(aabb.concat([pos]))
  return temp.bounding_box()
}

//获取关键点包围盒
export function getPointBounding_box(point) {
  const expand = 0.0001
  return [
    [point[0] - expand, point[1] - expand],
    [point[0] + expand, point[1] + expand],
  ]
}

//更新图形数据
export function updateObjMat(obj, LAYOUT, updateInsMat = false) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  const SPEC_TYPE = obj.js_obj.type
  if (TYPE == 'Reference*') return
  const PreCut = obj.js_obj.STATE.preCut
  const Chk = obj.js_obj.STATE.checked
  const PreChk = obj.js_obj.STATE.preCheck
  if (SPEC_TYPE) {
    //特殊图形
    if (SPEC_TYPE == SPECIALGRAPHIC.CUTAREA3D) {
      if (PreCut) {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.cutBox3D_pre_cut
      } else if (Chk) {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.cutBox3D_checked
      } else if (PreChk) {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.cutBox3D_pre_check
      } else {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.cutBox3D
      }
    } else if (SPEC_TYPE == SPECIALGRAPHIC.RENDERAREA) {
      if (PreCut) {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.renderArea3D_pre_cut
      } else if (Chk) {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.renderArea3D_checked
      } else if (PreChk) {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.renderArea3D_pre_check
      } else {
        obj.js_obj.graphics.lineLoop.material = LAYOUT.CONFIG.toolsMat.renderArea3D
      }
    }
  } else if (TYPE == 'GdsFlexpath*') {
    const MAT = LAYOUT.LAYER_STATE.layerMats[obj.layers[0]]
    if (MAT) {
      if (PreCut) {
        obj.js_obj.graphics.line.material = MAT.lineMat_pre_cut
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat_pre_cut
        obj.js_obj.graphics.fill.material = MAT.fillMat_pre_cut
      } else if (Chk) {
        obj.js_obj.graphics.line.material = MAT.lineMat_checked
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat_checked
        obj.js_obj.graphics.fill.material = MAT.fillMat_checked
      } else if (PreChk) {
        obj.js_obj.graphics.line.material = MAT.lineMat_pre_check
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat_pre_check
        obj.js_obj.graphics.fill.material = MAT.fillMat_pre_check
      } else {
        obj.js_obj.graphics.line.material = MAT.lineMat
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat
        obj.js_obj.graphics.fill.material = MAT.fillMat
      }
      if (updateInsMat) {
        obj.js_obj.graphics.lineIns.material = MAT.lineInsMat
        if (obj.js_obj.graphics.lineLoopIns) {
          obj.js_obj.graphics.lineLoopIns.material = MAT.lineInsMat
          obj.js_obj.graphics.fillIns.material = MAT.fillInsMat
        }
      }
    }
  } else if (TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsPolygon*') {
    const MAT = LAYOUT.LAYER_STATE.layerMats[obj.layer]
    if (MAT) {
      if (PreCut) {
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat_pre_cut
        obj.js_obj.graphics.fill.material = MAT.fillMat_pre_cut
      } else if (Chk) {
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat_checked
        obj.js_obj.graphics.fill.material = MAT.fillMat_checked
      } else if (PreChk) {
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat_pre_check
        obj.js_obj.graphics.fill.material = MAT.fillMat_pre_check
      } else {
        obj.js_obj.graphics.lineLoop.material = MAT.lineMat
        obj.js_obj.graphics.fill.material = MAT.fillMat
      }
      if (updateInsMat) {
        obj.js_obj.graphics.lineLoopIns.material = MAT.lineInsMat
        obj.js_obj.graphics.fillIns.material = MAT.fillInsMat
      }
    }
  } else if (TYPE == 'GdsLabel*') {
    const MAT = LAYOUT.LAYER_STATE.layerMats[obj.layer]
    if (MAT) {
      const fontMesh = obj.js_obj.graphics?.fill
      if (!fontMesh) return
      if (PreCut) {
        fontMesh.material = MAT.fontMat_pre_cut
      } else if (Chk) {
        fontMesh.material = MAT.fontMat_checked
      } else if (PreChk) {
        fontMesh.material = MAT.fontMat_pre_check
      } else {
        fontMesh.material = MAT.fontMat
      }
      if (updateInsMat) {
        obj.js_obj.graphics.fillIns.material = MAT.fontInsMat
      }
    }
  } else if (TYPE == 'GdsKeyPoint*') {
    const MAT = LAYOUT.LAYER_STATE.layerMats[obj.layer]
    if (MAT) {
      const point = obj.js_obj.graphics.point
      const fontMesh = obj.js_obj.graphics?.fill
      if (PreCut) {
        point.material = MAT.keyPointMat_pre_cut
        if (fontMesh) {
          fontMesh.material = MAT.fontMat_pre_cut
        }
      } else if (Chk) {
        point.material = MAT.keyPointMat_checked
        if (fontMesh) {
          fontMesh.material = MAT.fontMat_checked
        }
      } else if (PreChk) {
        point.material = MAT.keyPointMat_pre_check
        if (fontMesh) {
          fontMesh.material = MAT.fontMat_pre_check
        }
      } else {
        point.material = MAT.keyPointMat
        if (fontMesh) {
          fontMesh.material = MAT.fontMat
        }
      }
      if (updateInsMat) {
        obj.js_obj.graphics.pointIns.material = MAT.keyPointInsMat
        if (obj.js_obj.graphics.fillIns) {
          obj.js_obj.graphics.fillIns.material = MAT.fontInsMat
        }
      }
    }
  }
  if (TYPE == 'Ruler' || obj.constructor.name == 'Ruler') {
    const line = obj.line
    const text = obj.text
    if (PreCut) {
      line.material = LAYOUT.CONFIG.toolsMat.rulerLine_pre_cut
      text.material = LAYOUT.CONFIG.toolsMat.rulerText_pre_cut
    } else if (Chk) {
      line.material = LAYOUT.CONFIG.toolsMat.rulerLine_checked
      text.material = LAYOUT.CONFIG.toolsMat.rulerText_checked
    } else if (PreChk) {
      line.material = LAYOUT.CONFIG.toolsMat.rulerLine_pre_check
      text.material = LAYOUT.CONFIG.toolsMat.rulerText_pre_check
    } else {
      line.material = LAYOUT.CONFIG.toolsMat.rulerLine
      text.material = LAYOUT.CONFIG.toolsMat.rulerText
    }

    obj.lineMat = line.material
    obj.textMat = text.material
    obj.update(LAYOUT.AXIS)
  }
}

//更新对象顶点
export function updateObjCheckedPoints(LAYOUT, target, hitIndexes, replace = false, remove = false, add = false) {
  let checkedIndexes = target.js_obj.checked_points_index
  if (replace) {
    checkedIndexes = hitIndexes
    LAYOUT.STATUS.checked_objs = [target] //替换
  } else if (remove) {
    checkedIndexes = checkedIndexes.filter(index => hitIndexes.indexOf(index) == -1) //过滤
    if (!checkedIndexes.length) {
      LAYOUT.STATUS.checked_objs = LAYOUT.STATUS.checked_objs.filter(obj => obj !== target)
    }
  } else if (add) {
    checkedIndexes = noRepeatArray(checkedIndexes.concat(hitIndexes)) //合并
    LAYOUT.STATUS.checked_objs.push(target)
  }
  LAYOUT.STATUS.checked_objs = noRepeatArray(LAYOUT.STATUS.checked_objs)
  checkedIndexes.sort((a, b) => a - b) //排序
  //判断全选还是部分选择
  target.js_obj.STATE.reset()
  let checkedSize = checkedIndexes.length
  const TYPE = target.$$?.ptrType.name //target.constructor.name
  let points_size = target.points.length
  if (TYPE == 'GdsEllipse*') {
    points_size = target.js_obj.borderBox.points.length
  }
  if (checkedSize) {
    if (checkedSize == points_size) {
      target.js_obj.STATE.checked = true
      target.js_obj.checked_lines = []
      target.js_obj.checked_lines_pos = []
    } else {
      target.js_obj.STATE.partChecked = true
    }
  }
  updateObjMat(target, LAYOUT)

  target.js_obj.checked_points_index = checkedIndexes
  updateLines(target)
}

//更新选中线 拉伸操作相关数据
export function updateLines(obj) {
  let checked_points_index = obj.js_obj.checked_points_index
  const length = checked_points_index.length
  if (!length) {
    obj.js_obj.checked_lines = []
    return
  }
  let points = obj.points
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*') {
    points = obj.js_obj.borderBox.points
  }
  const lines = [] //计算需要控制的线段或顶点
  let line = []
  lines.push(line)

  let start = checked_points_index[0]
  for (let i = 0; i < length; i++) {
    const index = checked_points_index[i]
    if (start == index) {
      line.push(index)
    } else {
      start = index
      line = [index]
      lines.push(line)
    }
    start += 1
  }
  if (obj.$$?.ptrType.name !== 'GdsFlexpath*') {
    //线
    if (lines.length > 1) {
      const lines_last_index = lines.length - 1
      let first_line = lines[0]
      let last_line = lines[lines_last_index]
      if (first_line[0] === 0 && last_line.includes(points.length - 1)) {
        //首尾相连
        // first_line.push(...lines[lines_last_index])
        // lines.splice(lines_last_index,1)
        last_line.push(...first_line)
        lines.shift()
      }
    }
  }
  obj.js_obj.checked_lines = lines
  obj.js_obj.checked_lines_pos = lines.map(line => line.map(index => points[index]))
}

//计算对象和鼠标相对位置
export function getMouseOffSet(obj, pos) {
  let result = []
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
    result = [pos[0] - obj.center[0], pos[1] - obj.center[1]]
  } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
    result = [pos[0] - obj.origin[0], pos[1] - obj.origin[1]]
  } else if (obj.constructor.name == 'Ruler') {
    result = [pos[0] - obj.js_obj.path.center[0], pos[1] - obj.js_obj.path.center[1]]
  }
  return result
}

//更新部分选择对象和鼠标相对位置
export function updateMouseOffSet(objs, pos, paste = false) {
  for (let i = 0; i < objs.length; i++) {
    const obj = objs[i]
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    if (obj.js_obj.STATE?.checked || paste) {
      //整体选择
      if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
        obj.js_obj.mouse_offset = [obj.center[0] - pos[0], obj.center[1] - pos[1]]
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        obj.js_obj.mouse_offset = [obj.origin[0] - pos[0], obj.origin[1] - pos[1]]
      } else if (TYPE == 'Reference*') {
        obj.js_obj.mouse_offset = [obj.origin[0] - pos[0], obj.origin[1] - pos[1]]
      } else if (obj.constructor.name == 'Ruler') {
        obj.js_obj.points_mouse_offset = obj.points.map(p => [p[0] - pos[0], p[1] - pos[1]])
      }
    } else if (obj.js_obj.STATE?.partChecked) {
      //部分选择
      updateObjSlopes(obj)
      if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsPolygon*' || obj.constructor.name == 'Ruler') {
        const checked_points = obj.points.filter((point, index) => obj.js_obj.checked_points_index.indexOf(index) !== -1)
        obj.js_obj.points_mouse_offset = checked_points.map(p => [p[0] - pos[0], p[1] - pos[1]])
        // obj.js_obj.mouse_offset = [obj.center[0] - pos[0], obj.center[1] - pos[1]]
        if (TYPE == 'GdsFlexpath*') {
          obj.js_obj.headAndEndOffset = getHeadAndEndOffset(obj.points)
        }
      } else if (TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*') {
        const checked_points = obj.js_obj.borderBox.points.filter((point, index) => obj.js_obj.checked_points_index.indexOf(index) !== -1)
        obj.js_obj.points_mouse_offset = checked_points.map(p => [p[0] - pos[0], p[1] - pos[1]])
      } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
        obj.js_obj.mouse_offset = [obj.origin[0] - pos[0], obj.origin[1] - pos[1]]
      }
    }
  }
}

//计算首位线段偏移量，保证拖动时首尾端线长不变 a-b-...-c-d
function getHeadAndEndOffset(points) {
  let len = points.length
  let last = len - 1
  let head = []
  let end = []
  let a = points[0]
  let b = points[1]
  let c = points[last - 1]
  let d = points[last]
  head = [a[0] - b[0], a[1] - b[1]]
  if (len > 2) {
    end = [d[0] - c[0], d[1] - c[1]]
    return { head, end }
  }
  return {}
}

export function updateSlopes(objs) {
  for (let i = 0; i < objs.length; i++) {
    const obj = objs[i]
    updateObjSlopes(obj)
  }
}
export function updateObjSlopes(obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsPolygon*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
    let sp = obj.points
    if (TYPE == 'GdsEllipse*' || TYPE == 'GdsRectangle*') {
      sp = obj.js_obj.borderBox.points
    }
    const slopes = []
    const length = sp.length
    if (!length) return

    for (let i = 0; i < length - 1; i++) {
      slopes.push(getSlope(sp[i], sp[i + 1]))
    }
    if (TYPE == 'GdsPolygon*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*') {
      slopes.push(getSlope(sp[0], sp[sp.length - 1]))
    }
    obj.js_obj.slopes = slopes
  }
}
//直线斜率 0 平行于x轴 null 垂直于y轴
export function getSlope(p1, p2) {
  const x1 = p1[0]
  const x2 = p2[0]
  const y1 = p1[1]
  const y2 = p2[1]
  const delta_x = x2 - x1
  let slope = delta_x !== 0 ? (y2 - y1) / delta_x : null
  // if (slope !== null && (slope < 0.00001 || slope > -0.00001)) {
  //   slope = 0
  // }
  if (slope > 10000000) slope = null //修正斜率转换后的误差
  return slope
}

//记录当前物体旋转角度
export function updateNowAngle(objs) {
  for (let i = 0; i < objs.length; i++) {
    const obj = objs[i]
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
      obj.js_obj.angle_old = obj.angle
      obj.js_obj.past_center = deepClone(obj.center)
    } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
      obj.js_obj.angle_old = obj.angle
      obj.js_obj.past_center = deepClone(obj.origin)
    } else if (TYPE == 'Reference*') {
      obj.js_obj.angle_old = obj.rotation
      obj.js_obj.past_center = deepClone(obj.origin)
      obj.js_obj.past_v1 = deepClone(obj.repetition.v1)
      obj.js_obj.past_v2 = deepClone(obj.repetition.v2)
    } else if (obj.constructor.name == 'Ruler') {
      obj.js_obj.past_points = deepClone(obj.points)
    }
  }
}

export function getGdsPolygon(points, layer = 0) {
  const polygon = new QGdstk.Polygon(points)
  polygon.layer = layer
  return polygon
}

export function getPolygonsAABB(polygons, pathPoints) {
  let all_points = []
  polygons.forEach(p => all_points.push(...p))
  if (!all_points.length) {
    all_points = pathPoints
  }
  const temp = new QGdstk.Polygon(all_points)
  return temp.bounding_box()
}

export function calculateBoundingCircle(vertices) {
  // 计算重心
  const centroid = vertices.reduce(
    (acc, cur) => {
      return [acc[0] + cur[0] / vertices.length, acc[1] + cur[1] / vertices.length]
    },
    [0, 0]
  )
  // 计算半径
  const radius = Math.max(
    ...vertices.map(v => {
      return Math.sqrt((v[0] - centroid[0]) ** 2 + (v[1] - centroid[1]) ** 2)
    })
  )

  // 计算圆心
  const center = new THREE.Vector3(centroid[0], centroid[1], 0)
  const boundingSphere = new THREE.Sphere()
  boundingSphere.center = center
  boundingSphere.radius = radius
  return boundingSphere
}

//包围盒中心点
export function aabbCenter(aabb) {
  const start_x = aabb[0][0]
  const start_y = aabb[0][1]
  let w = aabb[1][0] - start_x
  let h = aabb[1][1] - start_y
  return [start_x + w / 2, start_y + h / 2]
}

export function copyObjs(objs) {
  const res = []

  const size = objs.length
  for (let i = 0; i < size; i++) {
    const obj = objs[i]
    const copy = obj.copy()
    if (obj.js_obj.type) {
      copy.js_obj.type = obj.js_obj.type
    }
    res.push(copy)
  }
  return res
}

export function copyFileObj(obj, pos) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  const copy = obj.copy()
  if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*' || TYPE == 'Reference*') {
    copy.origin = pos
  } else {
    copy.center = pos
  }
  if (obj.js_obj.type) {
    copy.js_obj.type = obj.js_obj.type
  }
  return copy
}

export function copyObj(LAYOUT, obj, pos, prePaste) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  const copy = obj.copy()
  if (obj.constructor.name == 'Ruler') {
    copy.js_obj.path.points = copy.points
    if (pos) {
      copy.js_obj.path.center = pos
    }
    copy.points = copy.js_obj.path.points
  } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*' || TYPE == 'Reference*') {
    if (pos) {
      copy.origin = pos
    }
  } else {
    if (pos) {
      copy.center = pos
    }
  }
  if (obj.js_obj.type) {
    copy.js_obj.type = obj.js_obj.type
  }
  // if (!prePaste) {
  reBuildGdsData(LAYOUT, copy, false, prePaste)
  // }
  return copy
}

//向版图添加对象
export function addObjToCell(cell, obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    cell.add_flexpath(obj)
  } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
    cell.add_polygon(obj)
  } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
    cell.add_label(obj)
  } else if (TYPE == 'Reference*') {
    cell.add_reference(obj)
  }
}
//获取参考点位置
export function getPosition(obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  let pos = [0, 0]
  if (obj.constructor.name == 'Ruler') {
    pos = obj.js_obj.path.center
  } else if (TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*' || TYPE == 'Reference*') {
    pos = obj.origin
  } else {
    pos = obj.center
  }
  return pos
}

//移动线的的点和边
export function moveLines(shape, slopes, checked_points_mouse_offset, mouse_pos, total_last_index, line_indexs, off_set_index) {
  const length = line_indexs.length
  if (length === 1) {
    //移动单个点
    const index = line_indexs[0] //下标

    const p = shape[index] //坐标
    let off_set = checked_points_mouse_offset[off_set_index] //偏移量
    if (!off_set) {
      off_set = [0, 0]
    }
    if (index === 0) {
      //选择首尾点
      const a = shape[1]
      const s = slopes[0]
      const line_ap = slopeAndIntercept(a, p, s)
      let slope_line_mouse_p = null
      if (s == null) {
        //bug 929 拉伸垂直线上的点
        slope_line_mouse_p = 0
      }
      const line_mouse_p = slopeAndIntercept(p, p, slope_line_mouse_p)

      let p1 = calculateIntersectionPoint(line_ap, line_mouse_p)

      if (!isValidPos(p1)) {
        return
      }
      shape[index] = p1
    } else if (index === total_last_index) {
      const a = shape[total_last_index - 1]
      const s = slopes[total_last_index - 1]
      const line_ap = slopeAndIntercept(a, p, s)
      let slope_line_mouse_p = null
      if (s == null) {
        //bug 929 拉伸垂直线上的点
        slope_line_mouse_p = 0
      }
      const line_mouse_p = slopeAndIntercept(p, p, slope_line_mouse_p)
      let p1 = calculateIntersectionPoint(line_ap, line_mouse_p)
      if (!isValidPos(p1)) {
        return
      }
      shape[index] = p1
    } else {
      //选择中间点 实际是移动选中点和上下一点直接线段
      let m_index = line_indexs[0]
      let line = []
      let count = 2
      if (m_index - 1 === 0) {
        //第二个点
        line = [m_index, m_index + 1]
      } else if (m_index + 1 === total_last_index) {
        //最后一个点前一个点
        const p_before = shape[index - 1] //跟新前一个坐标
        p_before[0] = mouse_pos[0] + off_set[0]
        p_before[1] = mouse_pos[1] + off_set[1]
        line = [m_index - 1, m_index]
      } else {
        //中间点
        line = [m_index + 1, m_index, m_index - 1]
        count = 3
      }
      moveLines(shape, slopes, checked_points_mouse_offset, mouse_pos, total_last_index, line)
    }
  } else {
    const total = total_last_index
    // const end = length-1

    // const order_index = getOrderPoints(line_indexs, length, total)
    let head_index = line_indexs[0]
    let last_index = line_indexs.at(-1) //line_indexs[end]

    //判断收尾顺序
    const head = shape[head_index]
    const last = shape[last_index]

    const before_index = getAroundIndex(head_index, total, 3, 1)
    const after_index = getAroundIndex(last_index, total, 3, 1)

    //计算需要移动点首尾坐标a, head, b, ...p, c, last, d 根据点求线的交点
    const a = shape[before_index[0]]
    const b = shape[before_index[1]]
    const c = shape[after_index[0]]
    const d = shape[after_index[1]]

    const line_a_head = slopeAndIntercept(a, head, slopes[before_index[0]])
    let line_head_b
    if (length == 2) {
      line_head_b = slopeAndIntercept(head, b, slopes[head_index])
    } else {
      line_head_b = slopeAndIntercept(b, head, slopes[head_index])
    }

    const line_c_last = slopeAndIntercept(c, last, slopes[after_index[0]])
    const line_last_d = slopeAndIntercept(d, last, slopes[last_index])

    let p1 = calculateIntersectionPoint(line_a_head, line_head_b)
    let p2 = calculateIntersectionPoint(line_c_last, line_last_d)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }
    shape[head_index] = p1
    shape[last_index] = p2
  }
  return shape
}

//移动多边形的点和边
export function movePolygonLines(slopes, total_last_index, line_indexs, total_size, shape) {
  const length = line_indexs.length
  if (length > 1) {
    //封闭图形
    const total = total_last_index
    // const end = length-1
    // const order_index = getOrderPoints(deepClone(line_indexs), length, total)
    let head_index = line_indexs[0]
    let last_index = line_indexs.at(-1) //line_indexs[end]
    //判断收尾顺序
    const head = shape[head_index]
    const last = shape[last_index]

    const before_index = getAroundIndex(head_index, total, 3, 1)
    const after_index = getAroundIndex(last_index, total, 3, 1)

    //计算需要移动点首尾坐标a, head, b, ...p, c, last, d 根据点求线的交点
    const a = shape[before_index[0]]
    const b = shape[before_index[1]]
    const c = shape[after_index[0]]
    const d = shape[after_index[1]]

    const line_a_head = slopeAndIntercept(a, head, slopes[before_index[0]])
    let line_head_b
    if (length == 2) {
      line_head_b = slopeAndIntercept(head, b, slopes[head_index])
    } else {
      line_head_b = slopeAndIntercept(b, head, slopes[head_index])
    }

    const line_c_last = slopeAndIntercept(c, last, slopes[after_index[0]])
    const line_last_d = slopeAndIntercept(d, last, slopes[last_index])

    let p1 = calculateIntersectionPoint(line_a_head, line_head_b)
    let p2 = calculateIntersectionPoint(line_c_last, line_last_d)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }

    // if (dist_points(p1, p2) == 0) return
    shape[head_index] = p1
    shape[last_index] = p2
  } else if (length === 1) {
    //移动单个点

    const index = line_indexs[0] //下标
    const p = shape[index] //坐标

    //计算点周围点坐标a, b, p, c, d
    const indexs = getAroundIndex(index, total_size - 1, 5, 2)

    const a = shape[indexs[0]]
    const b = shape[indexs[1]]
    const c = shape[indexs[2]]
    const d = shape[indexs[3]]

    const line_ab = slopeAndIntercept(a, b, slopes[indexs[0]])
    const line_pb = slopeAndIntercept(p, b, slopes[indexs[1]])

    const line_pc = slopeAndIntercept(p, c, slopes[index])
    const line_dc = slopeAndIntercept(d, c, slopes[indexs[2]])
    let p1 = calculateIntersectionPoint(line_ab, line_pb)
    let p2 = calculateIntersectionPoint(line_dc, line_pc)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }
    shape[indexs[1]] = p1
    shape[indexs[2]] = p2
  }
  return shape
}
//获取下标index周围的下标
export function getAroundIndex(index, total, length, space) {
  const result = []
  for (let i = 0; i < length; i++) {
    let temp = index - space + i
    if (temp === index) continue
    if (temp < 0) {
      temp += total + 1
    } else if (temp > total) {
      temp -= total + 1
    }
    result.push(temp)
  }

  return result
}

export function isValidPos(p) {
  return Number.isFinite(p[0]) && Number.isFinite(p[1])
}

//直线斜率和y交点 直线表达式 y = slope * x + intercept
export function slopeAndIntercept(p1, p2, s) {
  const x1 = p1[0]
  const x2 = p2[0]
  const y1 = p1[1]
  const y2 = p2[1]
  let slope = 0 //斜率
  if (s !== undefined) {
    slope = s
  } else {
    const delta_x = x2 - x1
    if (delta_x !== 0) {
      slope = (y2 - y1) / delta_x
    }
  }

  let intercept = y1 - slope * x1 //y轴交点
  if (slope === null) {
    //垂直线
    intercept = x1
  }
  return { slope, intercept }
}

//已知斜率求交点
export function calculateIntersectionPoint(line_a, line_b) {
  const a = line_a.slope
  const b = line_b.slope
  const c = line_a.intercept
  const d = line_b.intercept
  let px
  let py
  if (a === null) {
    px = c
    py = b * px + d
  } else if (b === null) {
    px = d
    py = a * px + c
  } else {
    px = (d - c) / (a - b)
    py = a * px + c
  }

  // return { x: px, y: py };
  return [px, py]
}
//两直线交点
export function calculateIntersection(p1, p2, p3, p4) {
  var c2x = p3.x - p4.x // (x3 - x4)
  var c3x = p1.x - p2.x // (x1 - x2)
  var c2y = p3.y - p4.y // (y3 - y4)
  var c3y = p1.y - p2.y // (y1 - y2)

  // down part of intersection point formula
  var d = c3x * c2y - c3y * c2x

  if (d == 0) {
    return null
    throw new Error('Number of intersection points is zero or infinity.')
  }

  // upper part of intersection point formula
  var u1 = p1.x * p2.y - p1.y * p2.x // (x1 * y2 - y1 * x2)
  var u4 = p3.x * p4.y - p3.y * p4.x // (x3 * y4 - y3 * x4)

  // intersection point formula

  var px = (u1 * c2x - c3x * u4) / d
  var py = (u1 * c2y - c3y * u4) / d

  var p = { x: px, y: py }

  return p
}

export function getRectInfo(points) {
  const temp = new QGdstk.Polygon(points)
  return { w: distPoint(points[0], points[1]), h: distPoint(points[1], points[2]), center: aabbCenter(temp.bounding_box()) }
}

export function getAABBCenter(box) {
  return [(box[1][0] + box[0][0]) / 2, (box[1][1] + box[0][1]) / 2]
}

export function getVerticalCrossPoint(point, line) {
  const slope2 = getSlope(line[0], line[1])
  const slope1 = getVerticalSlope(slope2)
  const intercept1 = point[1] - slope1 * point[0]
  const line1 = { slope: slope1, intercept: intercept1 }
  const line2 = slopeAndIntercept(line[0], line[1], getSlope(line[0], line[1]))

  return calculateIntersectionPoint(line1, line2)
}

//获取元素的多边形信息
export function getObjGdsPolygon(obj) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    // let res = []
    // obj.js_obj.polygons.forEach(obj => {
    //   res.push(new QGdstk.Polygon(obj.points))
    // })
    // return res
    return obj.js_obj.polygons
  } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
    // return [new QGdstk.Polygon(obj.points)]
    return [obj]
  }
}

//两条平行线偏移量
export function getLineOffset(l1, l2) {
  const start = l1[0]
  const slope1 = getVerticalSlope(getSlope(l1[0], l1[1]))
  const slope2 = getSlope(l2[0], l2[1])
  const line1 = slopeAndIntercept(start, [0, 0], slope1)
  const line2 = slopeAndIntercept(l2[0], l2[1], slope2)
  const cross = calculateIntersectionPoint(line1, line2)
  return [cross[0] - start[0], cross[1] - start[1]]
  // return [100,100]
}

//是否图形数据
export function disposeGraphics(graphics) {
  if (graphics) {
    for (const key in graphics) {
      if (graphics[key]?.geometry) {
        graphics[key].geometry.dispose()
      }
    }
  }
}
//生成唯一cell名
export function generateUniqueCellName(name) {
  function nanoid(size = 21) {
    let id = ''
    let i = size
    while (i--) {
      id += urlAlphabet[(Math.random() * 63) | 0]
    }
    return id
  }
  return name + '_' + nanoid(6)
}

export function cloneThreeObj(obj) {
  const TYPE = obj.constructor.name
  let copy
  if (TYPE == 'Line') {
    copy = new THREE.Line(obj.geometry.clone(), obj.material)
  } else if (TYPE == 'LineLoop') {
    copy = new THREE.LineLoop(obj.geometry.clone(), obj.material)
  } else if (TYPE == 'LineSegments') {
    copy = new THREE.LineSegments(obj.geometry.clone(), obj.material)
  } else if (TYPE == 'Mesh') {
    if (obj.geometry.constructor.name == 'TextGeometry') {
      copy = new THREE.Mesh(cloneTextGeo(obj.geometry), obj.material)
      copy.position.set(obj.position.x, obj.position.y, obj.position.z)
      copy.rotation.set(obj.rotation.x, obj.rotation.y, obj.rotation.z)
      copy.scale.set(obj.scale.x, obj.scale.y, obj.scale.z)
    } else {
      copy = new THREE.Mesh(obj.geometry.clone(), obj.material)
    }
  } else if (TYPE == 'Points') {
    copy = new THREE.Points(obj.geometry.clone(), obj.material)
  }
  return copy
}

export function cloneTextGeo(fontGeo) {
  const params = fontGeo.params
  return generateFontGeometry(params.text, params.anchor, params.size, params.cs, params.kp, params.mat4)
}
//替换对象的矩阵
export function applyNewMat(obj, mat4) {
  obj.position.set(0, 0, 0)
  obj.rotation.set(0, 0, 0)
  obj.scale.set(1, 1, 1)
  obj.updateMatrix()
  obj.applyMatrix4(mat4)
}

export function existNaN(points) {
  return points.includes(NaN)
}

//复制对象属性
export function copyObjProperty(obj, recordParams = false) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    return {
      points: deepClone(obj.points),
      center: deepClone(obj.center),
      // angle: obj.angle,
      layer: deepClone(obj.layers),
      id: deepClone(obj.id),
      offset: deepClone(obj.offset),
      width: deepClone(obj.width),
      radius: deepClone(obj.radius),
      scale_width: obj.scale_width,
      simple_path: obj.simple_path,
    }
  } else if (TYPE == 'GdsPolygon*') {
    return {
      points: deepClone(obj.points),
      center: deepClone(obj.center),
      // angle: obj.angle,
      layer: obj.layer,
      id: obj.id,
      datatype: obj.datatype,
    }
  } else if (TYPE == 'GdsRectangle*') {
    return {
      points: deepClone(obj.points),
      center: deepClone(obj.center),
      angle: obj.angle,
      layer: obj.layer,
      id: obj.id,
      width: obj.width,
      height: obj.height,
      datatype: obj.datatype,
    }
  } else if (TYPE == 'GdsEllipse*') {
    return {
      points: deepClone(obj.points),
      center: deepClone(obj.center),
      angle: obj.angle,
      layer: obj.layer,
      id: obj.id,
      radius_x: obj.radius_x,
      radius_y: obj.radius_y,
      tolerance: obj.tolerance,
      max_points: obj.max_points,
      datatype: obj.datatype,
    }
  } else if (TYPE == 'GdsLabel*') {
    return {
      anchor: obj.anchor.value,
      origin: deepClone(obj.origin),
      angle: obj.angle,
      layer: obj.layer,
      id: obj.id,
      font_size: obj.font_size,
      magnification: obj.magnification,
      text: obj.text,
      x_reflection: obj.x_reflection,
      datatpye: obj.datatpye,
    }
  } else if (TYPE == 'GdsKeyPoint*') {
    return {
      // anchor: obj.anchor.value,
      origin: deepClone(obj.origin),
      angle: obj.angle,
      layer: obj.layer,
      id: obj.id,
      font_size: obj.font_size,
      magnification: obj.magnification,
      text: obj.text,
      x_reflection: obj.x_reflection,
      need_be_dumped: obj.need_be_dumped,
      datatpye: obj.datatpye,
    }
  } else if (TYPE == 'Reference*') {
    // alert(deepClone(obj.origin))

    let property = {
      name: obj.name,
      origin: deepClone(obj.origin),
      magnification: obj.magnification,
      rotation: obj.rotation,
      x_reflection: obj.x_reflection,
      // refered_cell_id: obj.refered_cell_id,
      repetition: {
        columns: obj.repetition.columns,
        rows: obj.repetition.rows,
        // size: obj.repetition.size,
        // spacing: obj.repetition.spacing,
        type: obj.repetition.type.value,
        v1: deepClone(obj.repetition.v1),
        v2: deepClone(obj.repetition.v2),
      },
      text: copyTextParams(obj.cell),
      params: copyDeviceParams(obj.cell),
    }
    if (recordParams) {
      property.text = copyTextParams(obj.cell)
      property.paras = copyDeviceParams(obj.cell)
    }
    return property
  } else if (obj.constructor.name == 'Ruler') {
    return { points: deepClone(obj.points) }
  }
}

//文本图形参数
export function copyTextParams(cell) {
  if (cell?.text) {
    let textFrom = cell.text
    let text = {}
    text.av = textFrom.anchor.value
    text.dt = textFrom.datatype
    text.layer = textFrom.layer
    text.size = textFrom.size
    text.text = textFrom.text
    return text
  }
  return null
}

//参数化器件
export function copyDeviceParams(cell) {
  if (isParamsDevice(cell)) {
    return cell.pcell.paras
  }
  return null
}
//修改对象属性
export function applayObjProperty(obj, from, LAYOUT) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    obj.points = deepClone(from.points)
    obj.center = deepClone(from.center)
    // obj.angle = from.angle
    obj.layer = deepClone(from.layers)
    obj.id = deepClone(from.id)
    obj.offset = deepClone(from.offset)
    obj.width = deepClone(from.width)
    obj.radius = deepClone(from.radius)
    obj.scale_width = from.scale_width
    obj.simple_path = from.simple_path
  } else if (TYPE == 'GdsPolygon*') {
    obj.points = deepClone(from.points)
    obj.center = deepClone(from.center)
    // obj.angle = from.angle
    obj.layer = from.layer
    obj.id = from.id
    obj.datatype = from.datatype
  } else if (TYPE == 'GdsRectangle*') {
    // obj.points = deepClone(from.points)
    obj.center = deepClone(from.center)
    obj.angle = from.angle
    obj.layer = from.layer
    obj.id = from.id
    obj.width = from.width
    obj.height = from.height
    obj.datatype = from.datatype
  } else if (TYPE == 'GdsEllipse*') {
    // obj.points = deepClone(from.points)
    obj.center = deepClone(from.center)
    obj.angle = from.angle
    obj.layer = from.layer
    obj.id = from.id
    obj.radius_x = from.radius_x
    obj.radius_y = from.radius_y
    obj.tolerance = from.tolerance
    obj.max_points = from.max_points
    obj.datatype = from.datatype
  } else if (TYPE == 'GdsLabel*') {
    obj.anchor = AnchornMap[from.anchor]
    obj.origin = deepClone(from.origin)
    obj.angle = from.angle
    obj.layer = from.layer
    obj.id = from.id
    obj.font_size = from.font_size
    obj.magnification = from.magnification
    obj.text = from.text
    obj.x_reflection = from.x_reflection
    obj.datatpye = from.datatpye
  } else if (TYPE == 'GdsKeyPoint*') {
    // obj.anchor = AnchornMap[from.anchor]
    obj.origin = deepClone(from.origin)
    obj.angle = from.angle
    obj.layer = from.layer
    obj.id = from.id
    obj.font_size = from.font_size
    obj.magnification = from.magnification
    obj.text = from.text
    obj.x_reflection = from.x_reflection
    obj.need_be_dumped = from.need_be_dumped
    obj.datatpye = from.datatpye
  } else if (TYPE == 'Reference*') {
    // alert('应用属性' + deepClone(from.origin))
    obj.name = from.name
    obj.origin = deepClone(from.origin)
    obj.magnification = from.magnification
    obj.rotation = from.rotation
    obj.x_reflection = from.x_reflection
    // obj.refered_cell_id = from.refered_cell_id

    obj.repetition.columns = from.repetition.columns
    obj.repetition.rows = from.repetition.rows
    // obj.repetition.size = from.repetition.size
    // obj.repetition.spacing = from.repetition.spacing
    // obj.repetition.type = from.repetition.type
    // const copyV1 = deepClone(from.repetition.v1)
    // const copyV2 = deepClone(from.repetition.v2)
    if (from.repetition.columns && from.repetition.rows) {
      obj.repetition.v1 = deepClone(from.repetition.v1)
      obj.repetition.v2 = deepClone(from.repetition.v2)
    } else {
      obj.repetition.v1 = [0, 0]
      obj.repetition.v2 = [0, 0]
    }

    if (from.text) {
      let text = new Kernel.GdsText()
      text.anchor = AnchornMap[from.text.av]
      text.text = from.text.text
      text.size = from.text.size
      text.datatype = from.text.dt
      text.layer = from.text.layer
      if (obj.cell) {
        obj.cell.text = text
        updateCharcterCell(obj.cell, LAYOUT.fileInfo.fileId)
      }
    }
    if (from.params) {
      if (obj.cell.pcell) {
        obj.cell.pcell.paras = from.paras
        exChangeCpmponent(obj.cell, JSON.parse(from.paras), LAYOUT.fileInfo.fileId)
      }
    }
  } else if (obj.constructor.name == 'Ruler') {
    obj.points = deepClone(from.points)
  }
}

export function mergeSameLayerPolygons(targetCell, layers) {
  let cell = new QGdstk.Cell('3dCell')
  let polygons = targetCell.get_polygons()

  // let layers = this.stage.cell.getLayers()
  let igronLayers = [13, 14, 15, 16]

  layers.forEach(layer => {
    let targets = polygons.filter(polygon => polygon.layer == layer)

    if (igronLayers.indexOf(layer) != -1) {
      //合并指定图层
      if (targets.length) {
        if (targets.length > 1) {
          let res = new QGdstk.boolean([], targets, 'or', 0.001, layer, targets[0].datatype)
          cell.add(res)
        } else {
          cell.add(targets)
        }
      }
    } else {
      cell.add(targets)
    }
  })

  return cell
}

//复制对象转换为引用
export function transCopyDataToRef(datas, pos) {
  let cell = new Kernel.Cell('copy_cell')
  cell.js_obj.cutArea3D = []
  cell.js_obj.render3DArea = []
  cell.js_obj.rulers = []
  for (let i = 0; i < datas.length; i++) {
    const obj = datas[i]
    const TYPE = obj.$$?.ptrType.name //obj.constructor.name
    const SPEC_TYPE = obj.js_obj.type
    if (SPEC_TYPE) {
      //特殊图形
      if (SPEC_TYPE == SPECIALGRAPHIC.CUTAREA3D) {
        cell.js_obj.cutArea3D.push(obj)
      } else if (SPEC_TYPE == SPECIALGRAPHIC.RENDERAREA) {
        cell.js_obj.render3DArea.push(obj)
      }
    } else if (TYPE == 'GdsFlexpath*') {
      cell.add_flexpath(obj)
    } else if (TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*') {
      cell.add_polygon(obj)
    } else if (TYPE == 'GdsLabel*') {
      cell.add_label(obj)
    } else if (TYPE == 'GdsKeyPoint*') {
      cell.add_label(obj)
    } else if (TYPE == 'Reference*') {
      cell.add_reference(obj)
    } else if (obj.constructor.name == 'Ruler') {
      cell.js_obj.rulers.push(obj)
    }
  }
  let ref = new Kernel.Reference()
  ref.cell = cell
  return ref
}

//判断是否是参数化器件
export function isParamsDevice(cell) {
  if (cell?.pcell?.paras) {
    let params = JSON.parse(cell.pcell.paras)
    if (params.is_params_device) {
      return true
    }
  }
  return false
}

export function rotateObj(obj, angle) {
  const TYPE = obj.$$?.ptrType.name
  if (TYPE == 'GdsFlexpath*' || TYPE == 'GdsRectangle*' || TYPE == 'GdsEllipse*' || TYPE == 'GdsPolygon*' || TYPE == 'GdsLabel*' || TYPE == 'GdsKeyPoint*') {
    obj.angle += angle
  } else if (obj.constructor.name == 'Ruler') {
    obj.points = rotatePoints(obj.points, getBoundingBoxCenter(obj.js_obj.bounding_box), angle)
  }
}

//判断鼠标在画板内
export function mouseInsideCanvas(canvas, event) {
  const divRect = canvas.getBoundingClientRect()
  if (event.clientX >= divRect.left && event.clientX <= divRect.right && event.clientY >= divRect.top && event.clientY <= divRect.bottom) {
    return true
  }
  return false
}
