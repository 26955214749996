import { BOARDMODE } from '../const/board-status'
import { isAdsorb } from '../../pixi/qeda-pixi-data'
import { queryAdsropObjByPos, queryPartObjByPos } from './quad-tree'
import { getNearAdropLine, getNearAdropPoint, getVerticalSlope } from './collision'
import { calculateIntersection, calculateIntersectionPoint, getSlope, slopeAndIntercept } from './graphics-util'
import { needForceAdsorpMode } from '../validator/validator'
export function getAdsorpPoint(LAYOUT) {
  let res = LAYOUT.AXIS.mouse_point

  let real_mouse_pos = LAYOUT.AXIS.mouse_real_point //真实坐标
  let mouse_pos = LAYOUT.AXIS.mouse_point //网格吸附坐标
  let bevel_angle_pos = null //斜角吸附坐标
  let adsorp_graphic_pos = null //图形顶点，中点吸附
  let lastMousePos = LAYOUT.STATUS.lastMousePos
  let AXIS = LAYOUT.AXIS

  let draw_circle = LAYOUT.MODE == BOARDMODE.DRAW_CIRCLE
  let draw_rect = LAYOUT.MODE == BOARDMODE.DRAW_RECT
  let draw_ruler = LAYOUT.MODE == BOARDMODE.DRAW_RULER
  let drawPerfectGraphic = (draw_circle && LAYOUT.STATUS.shift_press) || (AXIS.angledAdsorption && (draw_circle || draw_rect)) //绘制正圆 正方形
  //斜角吸附计算

  let drawingSelectBox = LAYOUT.STAGE.tools.selectBox?.points?.length > 1 //处于框选状态

  if ((AXIS.angledAdsorption || drawPerfectGraphic) && !drawingSelectBox) {
    if (LAYOUT.MODE == BOARDMODE.ROTATE) {
      if (lastMousePos && LAYOUT.STATUS.checked_objs_center) {
        lastMousePos = LAYOUT.STATUS.checked_objs_center
      }
    }
    if (lastMousePos) {
      let result = isAdsorb(transVector2(lastMousePos), transVector2(mouse_pos), true, 'um', drawPerfectGraphic)

      bevel_angle_pos = transPos(result.rotatePoint)
    }
  }
  //图形吸附计算
  if (canAdsorp(LAYOUT) && !drawingSelectBox) {
    if (AXIS.adsorpPoint || draw_ruler || needForceAdsorpMode(LAYOUT.MODE)) {
      adsorp_graphic_pos = adsorpGraphic(LAYOUT, real_mouse_pos)
    }
  }

  //优先级图形吸附>斜角吸附>网格吸附
  LAYOUT.AXIS.hit_point = null
  LAYOUT.AXIS.angled_dsorp_point = null
  if (bevel_angle_pos) {
    LAYOUT.AXIS.angled_dsorp_point = bevel_angle_pos
    res = bevel_angle_pos
  }
  if (adsorp_graphic_pos) {
    LAYOUT.AXIS.hit_point = adsorp_graphic_pos.point
    res = LAYOUT.AXIS.hit_point
  }
  //更新十字光标和渲染
  AXIS.getMouseCrossLine()
  LAYOUT.STAGE.updateMouseCrossPos(AXIS)
  return res
}

function transVector2(pos) {
  return { x: pos[0], y: pos[1] }
}

function transPos(vector2) {
  return [vector2.x, vector2.y]
}
function adsorpGraphic(LAYOUT, pos) {
  let hits = queryAdsropObjByPos(LAYOUT, pos, 10)
  let all_len = hits.length
  let preCheckPoint = null

  if (all_len) {
    let dist = 20 / LAYOUT.AXIS.scale
    preCheckPoint = getNearAdropPoint(pos, dist, hits, LAYOUT.AXIS.scale, LAYOUT.LAYER_STATE)
    if (!preCheckPoint) {
      let preCheckLine = getNearAdropLine(pos, dist, hits, false, false, false, null, LAYOUT.AXIS.scale, LAYOUT.LAYER_STATE)
      if (preCheckLine) {
        let adsorptionLine = preCheckLine.line
        let mp = pos
        let start = adsorptionLine[0]
        let end = adsorptionLine[1]
        let slope = getSlope(start, end)
        let slope_v = getVerticalSlope(slope)
        let intercept = mp[1] - mp[0] * slope_v
        if (slope_v === null) {
          intercept = mp[0]
        }

        const line1 = slopeAndIntercept(start, end, slope)
        const line2 = { slope: slope_v, intercept: intercept }
        const cross = calculateIntersectionPoint(line1, line2)
        //求吸附线的交点
        preCheckLine.point = cross
        return preCheckLine
      }
    }
  }
  return preCheckPoint
}

export function canAdsorp(LAYOUT) {
  //按住中键拖动画板不执行图形吸附，否则卡顿
  if (LAYOUT.STATUS.middle_press) return false
  return true
}
